import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ReactTooltip from 'react-tooltip'
import Dropzone from 'react-dropzone'
import copy from 'copy-to-clipboard';
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import { reverse } from 'named-urls'

import routes from '../helpers/routes'
import { history } from "../helpers/history"
import arrayObjectIndexOf from '../helpers/indexOfObject'
import isIterable from "../helpers/isIterable"
import availableQuickActions from "../helpers/availableQuickActions"

import { UserActions } from '../actions/UserActions'
import { CompanyActions } from '../actions/CompanyActions'
import { MessagesActions } from '../actions/MessagesActions'
import { FileActions } from '../actions/FileActions'
import { TransfersActions } from '../actions/TransfersActions'
import { AlertActions } from '../actions/AlertActions'
import { ReportsActions } from '../actions/ReportsActions'
import { TabsControlerActions } from '../actions/V2_TabsControlerActions'

import { AvailableTabs, ActionsTabsComponents, SettingsTabsComponents, SuperadminTabsComponents } from "../reducers/V2_TabsReducer"

import ProjectSwitcher from './ProjectSwitcher'
import FullTextSearchComponent from './FullTextSearchComponent'
import Calendar from './Calendar'
import ThisMonthEvents from './DashboardComponents/ThisMonthEvents'
import ExportBankTransfers from './QuickActionsComponents/ExportBankTransfers'
import ExportDocs from './QuickActionsComponents/ExportDocs'
import ExpensesAssistant from './QuickActionsComponents/ExpensesAssistant'
import DocsRequiringAttentionAssistant from './QuickActionsComponents/DocsRequiringAttentionAssistant'

import '../css/TopToolbar.css'
import { ChatActions } from '../actions/ChatAction'

const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, application/pdf'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

export class TopToolbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showProjectsList: false,
            showMoreUploadOptions: false,
            showCalendar: false,
            showUpcomingPayments: false,
            showChatNotifications: false,
            showMessages: false,
            showUserOptions: false,

        }

        this.toolbarRef = React.createRef();
        this.projectsRef = React.createRef();
        this.uploadRef = React.createRef();
        this.calendarRef = React.createRef();
        this.upcomingPaymentsRef = React.createRef();
        this.chatNotificationsRef = React.createRef();
        this.messagesRef = React.createRef();
        this.userOptionsRef = React.createRef();
        this.userOptionsListRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)

        if (!this.props.userProjects || this.props.userProjects.length === 0) {
            this.props.getUserProjects()
        }
    }

    componentDidUpdate(prevProps) {
        const { userProjects, currentProject } = this.props
        const { waitingToOpenFilePreview, filePreviewProjectId, filePreviewFileId } = this.state
        if (waitingToOpenFilePreview) {
            if (currentProject && currentProject.id === filePreviewProjectId) {
                const { uploadedFiles } = this.props
                if (uploadedFiles) {
                    var projectFiles = uploadedFiles.find(e => e.project === filePreviewProjectId)
                    if (projectFiles && projectFiles.loaded) this.openFileAfterMessageClick(filePreviewProjectId, filePreviewFileId)
                }
            } else if (userProjects && userProjects.find(p => p.id === filePreviewProjectId)) {
                this.props.switchDashboardViewModel(filePreviewProjectId)
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.setState({
                showProjectsList: false,
                showMoreUploadOptions: false,
                showCalendar: false,
                showUpcomingPayments: false,
                showChatNotifications: false,
                showMessages: false,
                showUserOptions: false,
            })
        }
    }

    handleClick = (e) => {
        if (this.toolbarRef && this.toolbarRef.current && !this.toolbarRef.current.contains(e.target)) {
            this.setState({
                showProjectsList: false,
                showMoreUploadOptions: false,
                showCalendar: false,
                showUpcomingPayments: false,
                showChatNotifications: false,
                showMessages: false,
                showUserOptions: false,
            })
        } else {
            const { showProjectsList, showMoreUploadOptions, showCalendar, showUpcomingPayments, showChatNotifications, showMessages, showUserOptions } = this.state
            if (showProjectsList && this.projectsRef && this.projectsRef.current && !this.projectsRef.current.contains(e.target)) {
                this.setState({
                    showProjectsList: false,
                })
            } else if (showMoreUploadOptions && this.uploadRef && this.uploadRef.current && !this.uploadRef.current.contains(e.target)) {
                this.setState({
                    showMoreUploadOptions: false,
                })
            } else if (showCalendar && this.calendarRef && this.calendarRef.current && !this.calendarRef.current.contains(e.target)) {
                this.setState({
                    showCalendar: false,
                })
            } else if (showUpcomingPayments && this.upcomingPaymentsRef && this.upcomingPaymentsRef.current && !this.upcomingPaymentsRef.current.contains(e.target)) {
                this.setState({
                    showUpcomingPayments: false,
                })
            } else if (showChatNotifications && this.chatNotificationsRef && this.chatNotificationsRef.current && !this.chatNotificationsRef.current.contains(e.target)) {
                this.setState({
                    showChatNotifications: false,
                })
            } else if (showMessages && this.messagesRef && this.messagesRef.current && !this.messagesRef.current.contains(e.target)) {
                this.setState({
                    showMessages: false,
                })
            } else if (showUserOptions && this.userOptionsRef && this.userOptionsRef.current && !this.userOptionsRef.current.contains(e.target) && this.userOptionsListRef && this.userOptionsListRef.current && !this.userOptionsListRef.current.contains(e.target)) {
                this.setState({
                    showUserOptions: false,
                })
            }
        }
    }

    toggleProjectsList = () => {
        const { showProjectsList } = this.state
        this.setState({
            showProjectsList: !showProjectsList
        })
    }

    switchViewMode = (e, p, companyId) => {
        e.preventDefault()
        this.props.switchDashboardViewModel(p)
        this.setState({
            showProjectsList: false
        })

        var { userCompanies, currentCompany } = this.props

        if (currentCompany && currentCompany.id !== companyId) {
            var companyIndex = arrayObjectIndexOf(userCompanies, companyId, 'company_id')
            console.log(companyIndex)
            if (companyIndex > -1) {
                if (userCompanies[companyIndex].user_role === 'ADMIN') {
                    this.props.setCurrentCompany(userCompanies[companyIndex])
                }
            }
        }
    }

    closeProjectsList = () => {
        this.setState({
            showProjectsList: false
        })
    }

    toggleUserOptions = () => {
        const { showUserOptions } = this.state
        this.setState({
            showUserOptions: !showUserOptions
        })
    }

    hideUserOptions = () => {
        this.setState({
            showUserOptions: false
        })
    }

    toogleCalendar = () => {
        const { showCalendar } = this.state
        this.setState({
            showCalendar: !showCalendar
        })
    }

    toggleUpcomingPayments = () => {
        const { showUpcomingPayments } = this.state

        this.setState({
            showUpcomingPayments: !showUpcomingPayments
        })
        const { waitingForUpcomingPayments } = this.props
        if (!waitingForUpcomingPayments) {
            this.props.getMyUpcomingPayments()
        }
    }

    handleUpcomingPaymentClick = payment => {
        const { user, currentProject, userProjects, userCompanies, currentCompany } = this.props
        var companyIndex = -1
        if (currentProject.id !== payment.projectId) {
            const project = userProjects.find(p => p.id === payment.projectId)
            if (project && currentCompany.company_id !== project.company_id) {
                companyIndex = arrayObjectIndexOf(userCompanies, project.company_id, 'company_id')
                if (companyIndex > -1) {
                    if (userCompanies[companyIndex].user_role === 'ADMIN') {
                        this.props.setCurrentCompany(userCompanies[companyIndex])
                    }
                }
            }
        }
        if ((companyIndex > -1 && this.companyHasBanks(userCompanies[companyIndex].company)) || (currentCompany && this.companyHasBanks(currentCompany.company))) {
            if (currentProject.id !== payment.projectId) {
                this.props.switchDashboardViewModel(payment.projectId)
            }
            if (!user.new_easydocs_version) {
                this.props.toggleQuickAction(availableQuickActions.bankTransfers, true)
                var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
                var defaultDateTo = moment()
                this.props.getDocuments(defaultDateFrom, defaultDateTo, payment.projectId, 'Costs', 'importDate', null, { allUnpaid: true, unpaid: false })
                this.setState({
                    showUpcomingPayments: false
                })
            } else {
                this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.PAYMRNTS_ASSISTANT)
                this.setState({
                    showUpcomingPayments: false
                })
            }
        } else {
            this.props.alertWarn(this.context.t('You company has not provided required bank account informations yet'), 7000)
        }
    }

    companyHasBanks = (companyInstance) => {
        if (companyInstance) {
            return companyInstance.banks.length > 0
        } else {
            return null
        }
    }

    getPaymentDueDateClassName = dueDate => {
        if (dueDate) {
            if (moment(dueDate, 'YYYY-MM-DD').diff(moment(), 'days') < 1) return 'red'
            if (moment(dueDate, 'YYYY-MM-DD').diff(moment(), 'days') >= 1 && moment(dueDate, 'YYYY-MM-DD').diff(moment(), 'days') < 3) return 'orange'
            else return ''
        } else {
            return ''
        }
    }

    toggleChatNotifications = () => {
        const { showChatNotifications } = this.state

        this.setState({
            showChatNotifications: !showChatNotifications
        })

        const { skipForOlderChatNotifications } = this.props
        if (skipForOlderChatNotifications === 0) {
            this.props.getOldChatNotifications(0, true)
        }
    }

    getOlderChatNotifications = () => {
        const { hasOlderChatNotifications, skipForOlderChatNotifications } = this.props
        if (hasOlderChatNotifications) {
            this.props.getOldChatNotifications(skipForOlderChatNotifications, false)
        }
    }

    handleChatNotificationClick = notification => {
        const { uploadedFiles } = this.props
        this.setState({
            showChatNotifications: false
        })
        this.openFileAfterMessageClick(notification.projectId, notification.fileId)
    }

    handleChatNotificationsClick = () => {
        this.setState({
            showChatNotifications: false
        })
    }

    handleChatNotificationFavorite = (e, notification) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.toggleChatNotificationFavorite(notification.fileId)

    }

    getSortIconClass = field => {
        const { notificationsSortField, notificationsSortOrder } = this.props
        if (field === notificationsSortField) {
            if (notificationsSortOrder === 'DESC') return "sort-icon down"
            else return "sort-icon up"
        } else {
            return "sort-icon"
        }
    }

    sortChatNotification = field => {
        const { notificationsSortField, notificationsSortOrder } = this.props
        if (field === notificationsSortField) {
            this.props.sortChatNotification(field, notificationsSortOrder === 'DESC' ? 'ASC' : 'DESC')
        } else {
            this.props.sortChatNotification(field, 'DESC')
        }
    }

    openFileAfterMessageClick = (projectId, fileId) => {
        const { userProjects } = this.props
        var needToWaitForDataToLoad = false
        var projectIndex = arrayObjectIndexOf(userProjects, projectId, 'id')
        if (projectIndex > -1) {
            const { uploadedFiles } = this.props
            projectIndex = arrayObjectIndexOf(uploadedFiles, projectId, 'project')
            if (projectIndex > -1) {
                this.props.switchDashboardViewModel(projectId)
                var fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, fileId, 'id')
                if (fileIndex > -1) {
                    var fileToOpen = uploadedFiles[projectIndex].files[fileIndex]
                    console.log('========')
                    console.log(fileToOpen)
                    console.log('========')
                    if (fileToOpen?.acceptance_step <= 2) {
                        history.push(reverse(routes.panel.crop, { projectId: parseInt(fileToOpen.project_id), fileId: parseInt(fileToOpen.id) }));
                    } else {
                        this.props.getFileData(parseInt(fileToOpen.project_id), fileToOpen.path, fileToOpen.id, fileToOpen.ocr_status, fileToOpen.acceptance_step, true)
                    }
                } else {
                    needToWaitForDataToLoad = true
                }
            } else {
                needToWaitForDataToLoad = true
            }
        } else {
            needToWaitForDataToLoad = true
        }

        if (needToWaitForDataToLoad) {
            this.setState({
                waitingToOpenFilePreview: true,
                filePreviewProjectId: projectId,
                filePreviewFileId: fileId,
            })
        } else {
            this.setState({
                waitingToOpenFilePreview: false,
                filePreviewProjectId: null,
                filePreviewFileId: null,
            })
        }
    }

    toggleMessages = () => {
        const { showMessages } = this.state

        this.setState({
            showMessages: !showMessages
        })


        const { skipForOlderNotifications } = this.props
        if (skipForOlderNotifications === 0) {
            this.props.getOldNotifications()
        }
    }

    markAllMessagesAsSeen = e => {
        this.props.markAllMessagesAsRead()
    }

    deleteAllMessages = e => {
        this.props.deleteAllMessages()
    }

    handleNotificationClick = (e, message) => {
        const { showMessages } = this.state
        this.setState({
            showMessages: !showMessages
        })

        if (message.type === 'generatedReport' || message.type === 'generatedBanktransfersFile') {

        } else if (message.latest) {
            if (message.type === 'upload' || message.type === 'newPaymentOrder' || message.type === 'delete' || message.type === 'accepted' || message.type === 'additionalAcceptance' || message.type === 'setDocType' || message.type === 'markedAsPaid' || message.type === 'setExpenseOwner') {
                this.props.switchDashboardViewModel(message.projectId)
                this.props.markMessageAsRead(message.id)
                if (!this.props.location.pathname.includes("dashboard")) {
                    this.props.history.push(routes.panel.dashboard);
                }
                this.setState({
                    showMessages: false
                })
            } else if (message.type === 'submited') {
                this.props.getFileData(message.projectId, message.file, message.fileId, 'IN_PROGRESS', 2, true)
                this.props.markMessageAsRead(message.id)
                this.setState({
                    showMessages: false
                })
            } else if (message.type === 'deletedProject') {
                this.props.markMessageAsRead(message.id)
                this.props.switchDashboardViewModel(null)
                if (!this.props.location.pathname.includes("dashboard")) {
                    this.props.history.push(routes.panel.dashboard);
                }
            }
        } else {
            this.props.switchDashboardViewModel(message.projectId)
            this.props.markMessageAsRead(message.id)
            if (!this.props.location.pathname.includes("dashboard")) {
                this.props.history.push(routes.panel.dashboard);
            }
            this.setState({
                showMessages: false
            })
        }
    }

    getOlderNotifications = () => {
        const { hasOlderNotifications, skipForOlderNotifications } = this.props
        if (hasOlderNotifications) {
            this.props.getOldNotifications(skipForOlderNotifications)
        }
    }

    logout = e => {
        e.preventDefault()
        this.props.socket.emit('disconnect')
        this.props.socket.off()
        this.props.logout()
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            files.forEach(currentFile => {
                const currentFileType = currentFile.type
                const currentFileSize = currentFile.size
                if (currentFileSize > imageMaxSize) {
                    alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                    return false
                }
                if (!acceptedFileTypesArray.includes(currentFileType)) {
                    alert("This file is not allowed. Only images are allowed.")
                    return false
                }
            })
            return true
        }
    }

    verifiName = (name) => {
        var base = name.substring(0, name.lastIndexOf('.'))
        var extension = name.substring(name.lastIndexOf('.'))
        console.log(base)
        base = base.replace(/\s\s+/g, ' ')
        console.log(extension)
        console.log(base + extension)
        return base + extension
    }

    handleOnDrop = (projectId, files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        } else {
            var verifiedName = ''
            files.forEach((currentFile, index) => {
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    verifiedName = this.verifiName(currentFile.name)
                    const myResult = {}
                    myResult.src = myFileItemReader.result
                    myResult.name = verifiedName
                    myResult.size = currentFile.size
                    myResult.type = currentFile.type
                    myResult.docType = this.state.docType
                    myResult.projectId = projectId
                    myResult.docType = 'COSTS'
                    console.log(myResult)
                    this.parseFiles(myResult, projectId)
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            })
        }
    }

    parseFiles = (file, projectId) => {
        const { filesToVerifiLoaded } = this.props
        var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, projectId, 'project')
        if (projectIndex > -1) {
            if (!filesToVerifiLoaded[projectIndex].files.find(e => e.name === file.name)) {
                this.props.parsePDF({
                    fileName: file.name,
                    base64: file.src,
                    type: file.type,
                    senderEmail: this.props.user.email
                }, file.projectId, file.size)
                this.props.cacheUploadedFile({
                    name: file.name,
                    size: file.size,
                    projectId: file.projectId,
                })
            } else {
                var newName = this.generateUniqueName(file.name, filesToVerifiLoaded[projectIndex].files)
                this.props.parsePDF({
                    fileName: newName,
                    base64: file.src,
                    type: file.type,
                    senderEmail: this.props.user.email
                }, file.projectId, file.size)
                this.props.cacheUploadedFile({
                    name: newName,
                    size: file.size,
                    projectId: file.projectId,
                })
            }
        }
    }

    generateUniqueName = (oldName, currentFiles) => {
        var baseName = oldName.replace(/\.[^\.]+$/, "")
        var extension = oldName.replace(baseName, "")
        var nameIsUnique = false
        var number = 1
        var nameToCheck = `${baseName}(${number})${extension}`
        while (!nameIsUnique) {
            if (currentFiles.find(e => e.name === nameToCheck)) {
                number++
                nameToCheck = `${baseName}(${number})${extension}`
            } else {
                nameIsUnique = true
            }
        }

        return nameToCheck
    }

    toogleMoreUploadOptions = () => {
        const { showMoreUploadOptions } = this.state
        this.setState({
            showMoreUploadOptions: !showMoreUploadOptions
        })
    }

    copyProjectEmail = () => {
        const { currentProject } = this.props
        if (currentProject && currentProject.id) {
            copy(`${currentProject.code.toLowerCase()}@app.easydocs.pl`)
            this.props.alertInfo('Copied to clipboard', 1000)
            this.setState({
                showMoreUploadOptions: false
            })
        }
    }

    createPaymentOrderClick = () => {
        const { currentProject } = this.props
        if (currentProject && currentProject.id) {
            this.props.openAddTransferWindow(currentProject)
            this.setState({
                showMoreUploadOptions: false
            })
        }
    }

    generateInvoiceClick = () => {
        history.push(routes.panel.sale + 'create')
        this.setState({
            showMoreUploadOptions: false
        })
    }

    hasAnyOppenedList = () => {
        const { showProjectsList, showMoreUploadOptions, showCalendar, showUpcomingPayments, showChatNotifications, showMessages, showUserOptions } = this.state
        return showProjectsList || showMoreUploadOptions || showCalendar || showUpcomingPayments || showChatNotifications || showMessages || showUserOptions
    }

    render() {
        const { showProjectsList, showMoreUploadOptions, showCalendar, showUpcomingPayments, showChatNotifications, showMessages, showUserOptions } = this.state
        const {
            user, location, viewMode, currentProject,
            monthOnToolbarCalendar,
            waitingForUpcomingPayments, myUpcomingPayments,
            chatNotificationsCount, chatNotifications, waitingForOldChatNotifications, hasOlderChatNotifications,
            messagesCount, messages, waitingForOldNotifications, hasOlderNotifications,
            showExportBankTransfers, showExportDocs, showExpensesAssistant, showDocsRequiringAttentionAssistant, skipForOlderChatNotifications
        } = this.props

        return (
            <div className={`top-toolbar ${window.location.href.includes('/ocr/') && !user.new_easydocs_version ? 'short' : ''} ${user.new_easydocs_version ? 'new-ed' : ''} ${this.hasAnyOppenedList() ? 'show-on-top' : ''}`} ref={this.toolbarRef}>
                <div className="logo">
                    <NavLink className="nav-link" to={routes.panel.dashboard}></NavLink>
                </div>
                <div className="project-switcher" ref={this.projectsRef}>
                    <div className="current" onClick={() => this.toggleProjectsList()} data-for="info-project-selector" data-tip="show">
                        <h2>
                            {
                                viewMode === 'MULTI' ? (
                                    this.context.t('All Binders')
                                ) : (
                                    currentProject ? currentProject.code : ''
                                )
                            }
                        </h2>
                        <span className={`expend-icon ${showProjectsList ? 'expended' : ''}`}></span>
                    </div>
                    {
                        showProjectsList ? (
                            <ProjectSwitcher closeProjectsList={this.closeProjectsList} />
                        ) : (
                            null
                        )
                    }
                </div>

                <FullTextSearchComponent location={this.props.location} currentProject={currentProject} />

                {
                    currentProject.id && !user.new_easydocs_version ? (
                        <div className="upload-icon" ref={this.uploadRef}>
                            <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(currentProject.id, files, rejectedFiles)} accept={acceptedFileTypes} multiple={true} maxSize={imageMaxSize} >
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div {...getRootProps({ onClick: event => this.toogleMoreUploadOptions(event) })} className={`recently-uploaded dropzone ${isDragActive ? 'drop-active' : ''}`} data-for="upload-info" data-tip="show" ></div>
                                )}
                            </Dropzone>
                            {
                                showMoreUploadOptions ? (
                                    <div className="upload-options">
                                        <div className="dropzone-column">
                                            <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(currentProject.id, files, rejectedFiles)} accept={acceptedFileTypes} multiple={true} maxSize={imageMaxSize}>
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <div {...getRootProps()} className={`options-dropzone ${isDragActive ? 'drop-active' : ''}`}>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <div className="buttons-column">
                                            <div className="button" onClick={() => this.copyProjectEmail()}>{this.context.t('Copy binder email')}</div>
                                            <div className="button" onClick={() => this.createPaymentOrderClick()}>{this.context.t('Create payment order')}</div>
                                            <div className="button no-margin" onClick={() => this.generateInvoiceClick()}>{this.context.t('Issue an invoice')}</div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }


                <div className="calendar-wrapper" ref={this.calendarRef}>
                    <div className="calendar-icon" onClick={e => this.toogleCalendar()}></div>

                    {
                        showCalendar ? (
                            <div className="calendar-container">
                                <Calendar selectedDay={null} projectId={null} dashboard={true} eventTooltipHeight={'30vh'} startDate={moment().set('month', monthOnToolbarCalendar)} changeMonth={this.props.changeMontOnDashboardCalendar} />

                                <ThisMonthEvents />
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>

                <div className={`upcoming-payments`} ref={this.upcomingPaymentsRef}>
                    <div className="upcoming-payments-icon" data-for="info-upcoming-payments" data-tip="show" onClick={() => this.toggleUpcomingPayments()}></div>

                    {
                        showUpcomingPayments ? (
                            <div className="notifications-container payments">
                                {
                                    myUpcomingPayments.length === 0 && waitingForUpcomingPayments ? (
                                        <div className="waiting-for-notifications">
                                            <div className="activity-indicator">
                                                <Spinner color={'#69B792'} size={30} speed={0.8} />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <h4>{this.context.t('Today is: {n}', { n: moment().format('YYYY-MM-DD') })}</h4>
                                            <SimpleBar style={{ maxHeight: 'calc(75vh - 70px)', width: '100%', padding: '0 10px 10px' }}>
                                                {
                                                    myUpcomingPayments.length > 0 ? (
                                                        <>
                                                            {
                                                                myUpcomingPayments.map((payment, i) => {
                                                                    return (
                                                                        <div className={`notification-component ${payment.priority ? 'high-priority-payment' : ''}`} onClick={e => this.handleUpcomingPaymentClick(payment)}>
                                                                            {
                                                                                payment.priority ? (
                                                                                    <>
                                                                                        <div className="priotity-icon" data-for={`high-priority-payment-${payment.id}`} data-tip="show">!</div>


                                                                                        <ReactTooltip id={`high-priority-payment-${payment.id}`} place="left" effect="solid" className="default-tooltip info-tooltip">
                                                                                            <p>{this.context.t("High priority")}</p>
                                                                                        </ReactTooltip>
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                            <div
                                                                                className={`icon ${payment.paymentOrder ? 'paymentOrder' : 'file'} ${payment.comment ? 'has-comment' : ''}`}>
                                                                                {payment.project}
                                                                            </div>
                                                                            <div className="content">
                                                                                <div className={`due-date ${this.getPaymentDueDateClassName(payment.dueDate)}`}>
                                                                                    <span className="bold">{`${this.context.t('Due date')}: ${payment.dueDate}`}</span>
                                                                                </div>
                                                                                <div className="text">
                                                                                    {`${this.context.t('Import date')}: ${payment.importDate}`}
                                                                                </div>
                                                                                <div className="text">
                                                                                    {`${this.context.t('Imported by')}: ${payment.createdBy}`}
                                                                                </div>
                                                                                {
                                                                                    payment.comment ? (
                                                                                        <div className="file">
                                                                                            {`${this.context.t('Comment')}: ${payment.comment}`}
                                                                                        </div>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    ) : (
                                                        <div className="no-notifications">
                                                            <div className="icon"></div>
                                                            <div className="information">
                                                                <p className="big">{this.context.t('No payments to be made')}</p>
                                                                <p className="small">{this.context.t('Here you can see payments to be made in your companies')}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </SimpleBar>
                                        </>
                                    )
                                }
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>

                <div className={`chat-notifications ${location.pathname.includes("dashboard") ? 'dashboard-notifications' : ''}`} ref={this.chatNotificationsRef}>
                    {
                        chatNotificationsCount > 0 ? (
                            <div className="notifications-count" onClick={() => this.toggleChatNotifications()}><span>{chatNotificationsCount > 9 ? '9+' : chatNotificationsCount}</span></div>
                        ) : (
                            null
                        )
                    }

                    <div className="chat-notifications-icon" data-for="info-chat" data-tip="show" onClick={() => this.toggleChatNotifications()}></div>

                    {
                        showChatNotifications ? (
                            <div className="notifications-container payments chat">
                                {
                                    chatNotifications.length === 0 && waitingForOldChatNotifications ? (
                                        <div className="waiting-for-notifications">
                                            <div className="activity-indicator">
                                                <Spinner color={'#69B792'} size={30} speed={0.8} />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='notification-sort-container'>
                                                <div className='sort-name projectCode' onClick={e => this.sortChatNotification('projectCode')}>
                                                    {this.context.t("Binder name")}
                                                    <div className={this.getSortIconClass('projectCode')} ></div>
                                                </div>
                                                <div className='sort-name mentioned' onClick={e => this.sortChatNotification('is_mentioned')}>
                                                    {this.context.t("Marked")}
                                                    <div className={this.getSortIconClass('is_mentioned')}></div>
                                                </div>
                                                <div className='sort-name created' onClick={e => this.sortChatNotification('created')}>
                                                    {this.context.t("Message date")}
                                                    <div className={this.getSortIconClass('created')}></div>
                                                </div>
                                            </div>
                                            <SimpleBar style={{ maxHeight: 'calc(75vh - 70px)', width: '100%', padding: '10px' }}>
                                                {

                                                    chatNotifications.length > 0 ? (
                                                        <>

                                                            {
                                                                chatNotifications.map((message, i, tab) => {

                                                                    return (


                                                                        <div className={`notification-component ${message.favorite === true && !tab[i + 1].favorite ? 'border-favorit' : ''} ${!message.was_read ? 'not-read' : ''}`} onClick={e => this.handleChatNotificationClick(message)}>
                                                                            <div
                                                                                className={`icon file`}>
                                                                                {message.projectCode}

                                                                            </div>
                                                                            <div className="content">
                                                                                <div className="who-where-notification">
                                                                                    {message.fileName}
                                                                                </div>
                                                                                <div className="text">
                                                                                    {`${message.createdBy}: ${this.context.t(message.message)}`}
                                                                                </div>
                                                                            </div>
                                                                            <div className="time">
                                                                                <div className='symbol-container'>

                                                                                    <div data-for="info-mentioned" data-tip="show" className={`${message.is_mentioned ? message.was_read ? 'message-read' : 'message-not-read' : ''}`}></div>
                                                                                    <div data-for="info-favorite" data-tip="show" className={`${message.favorite ? 'favorite' : 'not-favorite'}`} onClick={e => this.handleChatNotificationFavorite(e, message)}></div>
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        moment().format('DD-MM') === moment(message.created).format('DD-MM') ? (
                                                                                            moment(message.created).format('HH:mm')
                                                                                        ) : (
                                                                                            moment(message.created).format('DD-MM-YYYY HH:mm')
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                            {
                                                                hasOlderChatNotifications ? (
                                                                    <div className="get-older" onClick={() => this.getOlderChatNotifications()}>{this.context.t('Get older')}</div>
                                                                ) : null
                                                            }
                                                            {
                                                                waitingForOldChatNotifications ? (
                                                                    <div className="waiting-for-older-messages">
                                                                        <div className="activity-indicator">
                                                                            <Spinner color={'#69B792'} size={15} speed={0.8} />
                                                                        </div>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </>
                                                    ) : (
                                                        <div className="no-notifications">
                                                            <div className="icon"></div>
                                                            <div className="information">
                                                                <p className="big">{this.context.t('No conversations')}</p>
                                                                <p className="small">{this.context.t('Here you can see the history of your document conversations')}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </SimpleBar>
                                        </>
                                    )
                                }
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>

                <div className={`messages-bell ${location.pathname.includes("dashboard") ? 'dashboard-notifications' : ''}`} onClick={e => this.markAllMessagesAsSeen(e)} ref={this.messagesRef}>
                    {
                        messagesCount > 0 ? (
                            <div className="notifications-count"><span>{messagesCount > 9 ? '9+' : messagesCount}</span></div>
                        ) : (
                            null
                        )
                    }

                    <div className="messages-icon" data-for="info-messages" data-tip="show" onClick={() => this.toggleMessages()}></div>

                    {
                        showMessages ? (
                            <div className="notifications-container messages">
                                {
                                    messages.length === 0 && waitingForOldNotifications ? (
                                        <div className="waiting-for-notifications">
                                            <div className="activity-indicator">
                                                <Spinner color={'#69B792'} size={30} speed={0.8} />
                                            </div>
                                        </div>
                                    ) : (
                                        <SimpleBar style={{ maxHeight: 'calc(75vh - 70px)', width: '100%', padding: '10px' }}>
                                            {
                                                messages.length > 0 ? (
                                                    <>
                                                        {
                                                            messages.map((message, i) => {
                                                                console.log(message)
                                                                return (
                                                                    <div className={`notification-component ${message.seen ? 'seen' : ''}`} onClick={e => this.handleNotificationClick(e, message)}>
                                                                        <div className={`icon message-file-${message.type}-icon`}></div>
                                                                        <div className="content">
                                                                            <div className="who-where">
                                                                                <span className="bold">{message.user}</span>{this.context.t(' at ')}<span className="bold">{message.project}</span>
                                                                            </div>
                                                                            <div className="text">
                                                                                {`${this.context.t(message.text)} ${message.oldProjectCode}`}
                                                                            </div>
                                                                            <div className="file">
                                                                                {message.file}
                                                                            </div>
                                                                        </div>
                                                                        <div className="time">
                                                                            {
                                                                                moment().format('DD-MM') === message.time.format('DD-MM') ? (
                                                                                    message.time.format('HH:mm')
                                                                                ) : (
                                                                                    message.time.format('DD-MM-YYYY HH:mm')
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            hasOlderNotifications ? (
                                                                <div className="get-older" onClick={() => this.getOlderNotifications()}>{this.context.t('Get older')}</div>
                                                            ) : null
                                                        }
                                                        {
                                                            waitingForOldNotifications ? (
                                                                <div className="waiting-for-older-messages">
                                                                    <div className="activity-indicator">
                                                                        <Spinner color={'#69B792'} size={15} speed={0.8} />
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </>
                                                ) : (
                                                    <div className="no-notifications">
                                                        <div className="icon"></div>
                                                        <div className="information">
                                                            <p className="big">{this.context.t('No new notifications')}</p>
                                                            <p className="small">{this.context.t('Here you can see notifications about other users activities in your binders')}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </SimpleBar>
                                    )
                                }
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>


                <div className={`user-info shift-left`}>
                    <div className={`user-photo ${!user.profile_pic_link ? 'default-photo' : ''}`}>
                        {
                            user.profile_pic_link ? (
                                <img src={user.profile_pic_link} alt="Users profile picture" />
                            ) : (
                                null
                            )
                        }
                    </div>
                    <div className="user-name">
                        <div className="user-name-text">
                            {user.first_name + " " + user.last_name}
                        </div>
                        <div className="user-email-text">
                            {user.email}
                        </div>
                    </div>

                    <div className="language" data-for="info-lang" data-tip="show">
                        {
                            localStorage.getItem('language') === 'en' ? (
                                <div onClick={() => this.props.changeLanguage("pl")}>EN</div>
                            ) : (
                                localStorage.getItem('language') === 'pl' ? (
                                    <div onClick={() => this.props.changeLanguage("en")}>PL</div>
                                ) : (
                                    null
                                )
                            )
                        }
                    </div>

                    <div className="user-options" data-for="info-user-options" data-tip="show" onClick={() => this.toggleUserOptions()} ref={this.userOptionsRef}></div>
                    {
                        showUserOptions ? (
                            <div className="options" id="user-options-top" ref={this.userOptionsListRef}>
                                <ul>
                                    {
                                        user.new_easydocs_version ? (
                                            <li className="link settings" onClick={() => { this.hideUserOptions(); this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.PROFILE) }}>
                                                {this.context.t('Settings')}
                                            </li>
                                        ) : (
                                            <li className="link settings" onClick={() => this.hideUserOptions()}>
                                                <Link to={routes.panel.settings.profile}>
                                                    {this.context.t('Settings')}
                                                </Link>
                                            </li>
                                        )
                                    }
                                    {
                                        user.new_easydocs_version ? (
                                            <li className="link helpdesk" onClick={() => { this.hideUserOptions(); this.props.openNewTab(AvailableTabs.HELPDESK, AvailableTabs.HELPDESK) }}>
                                                {this.context.t('Helpdesk')}
                                            </li>
                                        ) : (
                                            <li className="link helpdesk" onClick={() => this.hideUserOptions()}>
                                                <Link to={routes.panel.help}>
                                                    {this.context.t('Helpdesk')}
                                                </Link>
                                            </li>
                                        )
                                    }
                                    {
                                        user.role === "SUPERADMIN" ? (
                                            user.new_easydocs_version ? (
                                                <li className="link superadmin" onClick={() => { this.hideUserOptions(); this.props.openNewTab(AvailableTabs.SUPERADMIN, SuperadminTabsComponents.COMPANIES) }}>
                                                    {this.context.t('Superadmin')}
                                                </li>
                                            ) : (
                                                <li className="link superadmin" onClick={() => this.hideUserOptions()}>
                                                    <Link to={routes.panel.superAdmin.management}>
                                                        {this.context.t('Superadmin')}
                                                    </Link>
                                                </li>

                                            )
                                        ) : null
                                    }
                                    <li className="link logout" onClick={(e) => this.logout(e)}>
                                        {this.context.t('Logout')}
                                    </li>

                                    <li className="version-separator"></li>

                                    {
                                        [2, 3, 62].includes(user.id) ? (
                                            <li className="new-version">
                                                {this.context.t('New EasyDocs app')}
                                                <div className={`switcher  ${user.new_easydocs_version ? 'on' : 'off'}`} onClick={() => this.props.toggleNewEasyDocsVersion(!user.new_easydocs_version)}>
                                                    <span className={`ball ${user.new_easydocs_version ? 'on' : 'off'}`}></span>
                                                </div>
                                            </li>
                                        ) : null
                                    }

                                </ul>
                            </div>
                        ) : (
                            null
                        )
                    }

                </div>

                <ReactTooltip id="info-project-selector" place="right" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t('Select the Desktop view - multi-binder or one selected binder')}</p>
                </ReactTooltip>

                <ReactTooltip id="upload-info" place="left" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t("Drop your documents here and upload them quickly to EasyDocs. You can also view more options by clicking on the icon.")}</p>
                </ReactTooltip>

                <ReactTooltip id="info-upcoming-payments" place="left" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t("Here you can see payments to be made in your companies")}</p>
                </ReactTooltip>

                <ReactTooltip id="info-chat" place="left" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t("Here you can see the history of conversations about your documents")}</p>
                </ReactTooltip>

                <ReactTooltip id="info-favorite" place="right" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t("You can select or deselect conversations")}</p>
                </ReactTooltip>

                <ReactTooltip id="info-mentioned" place="right" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t("You have been tagged in this conversation")}</p>
                </ReactTooltip>

                <ReactTooltip id="info-messages" place="left" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t("Here you can see notifications about other users activities in your binders")}</p>
                </ReactTooltip>

                <ReactTooltip id="info-lang" place="bottom" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t('Application language selection')}</p>
                </ReactTooltip>

                <ReactTooltip id="info-user-options" place="bottom" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                    <p>{this.context.t('Your administration menu')}</p>
                </ReactTooltip>

                {
                    showExportBankTransfers ? (
                        <ExportBankTransfers />
                    ) : null
                }

                {
                    showExportDocs ? (
                        <ExportDocs />
                    ) : null
                }

                {
                    showExpensesAssistant ? (
                        <ExpensesAssistant />
                    ) : null
                }

                {
                    showDocsRequiringAttentionAssistant ? (
                        <DocsRequiringAttentionAssistant />
                    ) : null
                }
            </div >
        )
    }
}

TopToolbar.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    viewMode: state.User.dashboardViewModel,
    currentProject: state.User.currentProject,
    userProjects: isIterable(state.User.userProjects) ? [...state.User.userProjects] : [],
    currentCompany: state.Company.currentCompany,
    userCompanies: isIterable(state.Company.companies) ? [...state.Company.companies] : [],
    monthOnToolbarCalendar: state.User.monthOnToolbarCalendar,
    dayOnToolbarCalendar: state.User.dayOnToolbarCalendar,
    filesToVerifiLoaded: state.File.filesToVerifiLoaded,
    uploadedFiles: state.File.uploadedFiles,

    chatNotificationsCount: state.Chat.chatNotificationsCount,
    chatNotifications: state.Chat.chatNotifications,
    waitingForOldChatNotifications: state.Chat.waitingForOldChatNotifications,
    skipForOlderChatNotifications: state.Chat.skipForOlderChatNotifications,
    hasOlderChatNotifications: state.Chat.hasOlderChatNotifications,
    notificationsSortOrder: state.Chat.notificationsSortOrder,
    notificationsSortField: state.Chat.notificationsSortField,

    messagesCount: state.Messages.unreadCount,
    messages: [...state.Messages.messages],
    waitingForOldNotifications: state.Messages.waitingForOldNotifications,
    skipForOlderNotifications: state.Messages.skipForOlderNotifications,
    hasOlderNotifications: state.Messages.hasOlderNotifications,

    waitingForUpcomingPayments: state.Messages.waitingForUpcomingPayments,
    myUpcomingPayments: state.Messages.myUpcomingPayments,

    showExportBankTransfers: state.QuickActions.showExportBankTransfers,
    showExportDocs: state.QuickActions.showExportDocs,
    showExpensesAssistant: state.QuickActions.showExpensesAssistant,
    showDocsRequiringAttentionAssistant: state.QuickActions.showDocsRequiringAttentionAssistant,

    user: state.User.user
})

const mapDispatchToProps = {
    toggleNewEasyDocsVersion: UserActions.toggleNewEasyDocsVersion,
    changeLanguage: UserActions.changeLanguage,
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    getUserProjects: UserActions.getUserProjects,
    changeMontOnDashboardCalendar: UserActions.changeMontOnDashboardCalendar,
    logout: UserActions.logout,

    setCurrentCompany: CompanyActions.setCurrentCompany,

    sortChatNotification: ChatActions.sortChatNotification,
    toggleChatNotificationFavorite: ChatActions.toggleChatNotificationFavorite,
    getOldChatNotifications: ChatActions.getOldChatNotifications,


    markMessageAsRead: MessagesActions.markMessageAsRead,
    markAllMessagesAsRead: MessagesActions.markAllAsRead,
    getOldNotifications: MessagesActions.getOldNotifications,
    getMyUpcomingPayments: MessagesActions.getMyUpcomingPayments,
    toggleQuickAction: UserActions.toggleQuickAction,

    parsePDF: FileActions.parsePDF,
    cacheUploadedFile: FileActions.cacheFile,
    getFileData: FileActions.getFileData,

    openAddTransferWindow: TransfersActions.openAddTransferWindow,

    getDocuments: ReportsActions.getDocuments,

    openNewTab: TabsControlerActions.openNewTab,

    alertInfo: AlertActions.info,
    alertWarn: AlertActions.warning
}

export default connect(mapStateToProps, mapDispatchToProps)(TopToolbar)