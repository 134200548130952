const waProjects = [
    45,     //DEMO
    587,    //Wine Avenue - Marketing
    588,    //Sales On&Off trade
    589,    //Sales B2B B2C
    590,    //Admin
    591,    //Zakupy
    592,    //Wynagrodzenia
    593,    //BCH
]

module.exports = {
    waProjects
};