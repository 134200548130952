import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { Document, Page, pdfjs } from 'react-pdf';
import { Spinner, Bounce } from 'react-activity'
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ScrollContainer from 'react-indiana-drag-scroll'
import throttle from "lodash.throttle"

import Calendar from './Calendar'
import CountriesList from './CountriesList'
import moment from 'moment'
import DeleteTransferModal from './DeleteTransferModal'
import AvailableInOtherPlanLabel from './AvailableInOtherPlanLabel'
import TagsList from './TagsList'


import TagsComponent from './OcrPageComponents/TagsComponent';
import ProjectCode from './PdfPreviewComponents/WL_DevCosts/ProjectCode'
import Technology from './PdfPreviewComponents/WL_DevCosts/Technology'
import DevExCode from './PdfPreviewComponents/WL_DevCosts/DevExCode'
import DevExType from './PdfPreviewComponents/WL_DevCosts/DevExType'
import TransactionDescription from './PdfPreviewComponents/WL_DevCosts/TransactionDescription'
import { devCostsProjects } from '../helpers/DevCosts/devCostsProjects'
import { devCostsUsers } from '../helpers/DevCosts/devCostsUsers'

import arrayObjectIndexOf from "../helpers/indexOfObject"
import currencyList from '../helpers/currencyList.json'
import paymentMethodsList from '../helpers/paymentMethodsList.json'
import { checkIfAccountNumberIsValid } from '../helpers/validAccountNumber';

import { FileActions } from '../actions/FileActions'
import { CompanyActions } from '../actions/CompanyActions'
import { ReportsActions } from '../actions/ReportsActions'
import { AlertActions } from '../actions/AlertActions'
import { TransfersActions } from '../actions/TransfersActions';
import { TabsControlerActions } from '../actions/V2_TabsControlerActions'

import { AvailableTabs } from "../reducers/V2_TabsReducer"

import '../css/FilePreview.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, application/pdf'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

const accountNumberRegexList = [
    /^(PL)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}$/,
    /^(FR)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{2}[0-9A-Z]{2}\s?[0-9A-Z]{4}\s?[0-9A-Z]{4}\s?[0-9A-Z]{1}[0-9]{2}$/,
    /^(DE)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{2}$/,
    /^(CZ)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}$/,
    /^(GB)?\s?[0-9]{2}\s?[A-Z]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{2}$/
]

const priceRegexList = [
    /^[0-9]*$/,
    /^[0-9]*\.[0-9]{1,2}$/,
    /^[0-9]*\,[0-9]{1,2}$/,
]

const paymentSymbols = [
    'AKC', 'AKC-2', ' AKC-3', 'AKC-U', 'AKC2', 'AKC3', 'AKCU', 'CIT', 'CIT-10', 'CIT-11', 'CIT-2', 'CIT-2A', 'CIT-2B', 'CIT6', 'CIT-6A', 'CIT-8', 'CIT-8A', 'CIT-8B', 'CIT-9', 'CIT10', 'CIT11', 'CIT2', 'CIT2A', 'CIT2B', 'CIT6', 'CIT6A', 'CIT8', 'CIT8A', 'CIT8B', 'CIT9', 'DAR', 'DJB', 'GK', 'GKP', 'JDB', 'KP', 'NBP', 'OPR', 'PCC', 'PCC-1', 'PCC-2', 'PCC1', 'PCC2', 'PIT', 'PIT-23', 'PIT-28', 'PIT-36', 'PIT-37', 'PIT-4', 'PIT-5', 'PIT-53', 'PIT-5L', 'PIT-7', 'PIT-8AR', 'PIT23', 'PIT28', 'PIT36', 'PIT37', 'PIT4', 'PIT5', 'PIT53', 'PIT5L', 'PIT7', 'PIT8AR', 'POG-3', 'POG-3A', 'POG-3C', 'POG-3D', 'POG-R', 'POG3', 'POG3A', 'POG3C', 'POG3D', 'POGR', 'PPD', 'PPE', 'PPL', 'PPW', 'SD', ' SD-2', 'SD2', 'VAI', 'VAT', 'VAT-10', 'VAT-12', 'VAT-7', 'VAT-7K', 'VAT-8', 'VAT-9', 'VAT10', 'VAT12', 'VAT7', 'VAT7K', 'VAT8', 'VAT9', 'VAZ', 'WZD', 'WZP', 'WZP-1K', 'WZP-1M', 'WZP-1R', 'WZP1K', 'WZP1M', 'WZP1R', 'WZS', 'WZS-1K', 'WZS-1M', 'WZS1R', 'WZS1K', 'WZS1M', 'WZS1R', 'ZPZ'
]

const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

export class TransferPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDocData: true,
            file: null,
            numPages: null,
            pageNumber: 1,
            pdfScale: 1.5,
            pdfScalePercentage: 100,
            didFitToWidth: false,
            pdfRoateAngle: 0,
            imgScale: 0.6,
            imgScalePercentage: 100,
            didFitImgToWidth: false,
            imgRoateAngle: 0,
            showCurrencyList: false,
            showCalendar: false,
            editMode: false,
            deleteModalOpen: false,
            projectRole: '',
            symbol: '',
            showDuplicateWindow: false,
            orderToDuplicate: null,
            showDuplicatePeriodList: false,
            duplicatePeriod: 'month',
            duplicatePeriodText: "In a month",
            canChangeDuplicatePeriod: true,

            acceptancePermissions: '',
            zusAccount: '',
            taxAccount: '',

            swift: '',
            address: '',
            country_code: 'PL',
            account_number: '',
        }

        this.currencyInputRef = React.createRef()
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        var currentProject = this.props.userProjects.find(e => e.id === this.props.transferPreviewData.project_id)

        if (currentProject) {
            this.setState({
                projectRole: currentProject.acceptance_permissions,
                symbol: this.props.transferPreviewData.symbol || '',
                acceptancePermissions: currentProject.acceptance_permissions,
            })
            if (currentProject.company) {
                this.setState({
                    tagsList: currentProject.company.tags_list ? JSON.parse(currentProject.company.tags_list) : [],
                    paymentOrderOwnerCompany: currentProject.company
                })
            }
        } else {
            this.setState({
                acceptancePermissions: 'USER1'
            })
        }
    }

    componentDidUpdate() {
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)

        document.body.style.overflow = "auto"

        if (window.location.pathname.includes('/payment-order-preview/')) {
            window.history.replaceState(null, null, "/dashboard")
        }
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            const { showCurrencyList, showCalendar, showPaymentMethods, showDuplicatePeriodList, showTaxSymbolsList, showPeriosPopup, showTagsList, showCountryList } = this.state
            if (showCurrencyList || showCalendar || showPaymentMethods || showDuplicatePeriodList || showTaxSymbolsList || showPeriosPopup || showTagsList || showCountryList) {
                this.closeAllPopups(e)
            } else if (this.state.deleteModalOpen) {
                this.setState({
                    deleteModalOpen: false,
                })
            } else if (this.state.editMode) {
                this.setState({
                    editMode: false
                })
                this.props.cancelTransferEdit()
            } else if (this.state.showDuplicateWindow) {
                this.setState({
                    showDuplicateWindow: false,
                    orderToDuplicate: null
                })
            } else {
                this.props.hideTransferPreviewWindow()
            }
        }
    }

    polihAccountPritier = account => {
        if (account) {
            var formatedAccount = account.replace(/[^0-9]/gm, '')

            if (formatedAccount.length === 26) {
                formatedAccount = formatedAccount.slice(0, 2) + ' ' + formatedAccount.slice(2, 6) + ' ' + formatedAccount.slice(6, 10) + ' ' + formatedAccount.slice(10, 14) + ' ' + formatedAccount.slice(14, 18) + ' ' + formatedAccount.slice(18, 22) + ' ' + formatedAccount.slice(22, 26)
                if (account.startsWith('PL')) {
                    return 'PL' + formatedAccount
                } else {
                    return formatedAccount
                }
            } else {
                return account
            }
        } else return ''
    }

    verifyFile = (files) => {
        if (files && files.length > 1) {
            this.props.alertWarn(this.context.t("You can attach only one file"))
            return false
        } else if (files && files.length === 1) {
            files.forEach(currentFile => {
                const currentFileType = currentFile.type
                const currentFileSize = currentFile.size
                if (currentFileSize > imageMaxSize) {
                    this.props.alertWarn(this.context.t("Document is to big. Maximum size is 1GB"))
                    return false
                }
                if (!acceptedFileTypesArray.includes(currentFileType)) {
                    this.props.alertWarn("This file is not allowed. Only images and pdf files are allowed.")
                    return false
                }
            })
        }
        return true
    }

    handleOnDrop = (files, rejectedFiles) => {
        console.log('drop')
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        else {
            files.forEach((currentFile, index) => {
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = {}
                    myResult.base64 = myFileItemReader.result
                    myResult.name = currentFile.name
                    myResult.type = currentFile.type
                    this.props.setTransferPreviewFile(myResult)
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            })
        }
    }

    handleDropZoneClick = e => {
        if (e.target.className && e.target.className.includes('delete')) {
            e.stopPropagation()
        }
    }

    onNoFileAttachedClick = e => {
        console.log('file upload click')
        const { noFileAttached } = this.refs
        if (noFileAttached && noFileAttached) {
            console.log('found ref')
            noFileAttached.click();
        }
    }

    handleNewFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            console.log(files)
            const file = files[0];
            const myFileItemReader = new FileReader()
            myFileItemReader.addEventListener("load", () => {
                const myResult = {}
                myResult.base64 = myFileItemReader.result
                myResult.name = file.name
                myResult.type = file.type

                if (!this.state.editMode) {
                    this.props.makeEditedTransferBackup()
                    this.setState({
                        editMode: true
                    })
                }
                this.props.setTransferPreviewFile(myResult)
            }, false)
            myFileItemReader.readAsDataURL(file)
        }
    }

    showDueDateCalendar = e => {
        if (!e.target.className.includes('day-cell') && this.state.editMode) {
            this.setState({
                showCalendar: true,
            })

            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom - inputPosition.height
            var left = inputPosition.left

            if (this.state.editMode) {
                this.setState({
                    posYDueDateCalendar: top,
                    posXDueDateCalendar: left,
                    posUpDueDateCalendar: true,
                    showCalendar: true,
                })
            }
        }
    }

    onCalendarSelect = date => {
        if (date) {
            this.setState({
                showCalendar: false,
            })
            this.props.etidTransferField('due_date', moment(date).format('YYYY-MM-DD'))
        }
    }

    onCalendarUnselecet = (date) => {
        this.setState({
            showCalendar: false,
            posXDueDateCalendar: 0,
            posYDueDateCalendar: 0,
            posUpDueDateCalendar: false,
        })
        console.log(date)
    }

    onInputChange = e => {
        e.preventDefault()
        var { name, value } = e.target
        const { currency } = this.props.transferPreviewData
        const selectFirstTwoLetters = /^[A-Z]{2}/
        const selectOnlyDigits = /^[0-9\s]*$/
        if (name === 'account_number' && currency !== 'PLN' && !selectFirstTwoLetters.test(value)) return
        if (name === 'account_number' && currency === 'PLN' && !selectOnlyDigits.test(value)) return

        if (name === 'amount_to_pay') {
            value = value.replace(/[^0-9.,]/gm, '').replace(',', '.')
        }

        if (name !== 'symbol') {
            this.props.etidTransferField(name, value)
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    setSellerCountryCode = (value) => {
        const { currency, account_number } = this.props.transferPreviewData
        if (currency !== 'PLN') {
            const selectTwoFirstLetters = /^[A-Z]{2}/
            const oldAccountNumberWithoutLetters = account_number.replace(selectTwoFirstLetters, '')
            this.props.etidTransferField('account_number', value + oldAccountNumberWithoutLetters)
        }
        this.setState({
            showCountriesList: false,
        })

        this.props.etidTransferField('country_code', value)
    }

    toggleCountriesList = show => {
        this.setState({
            showCountriesList: show !== undefined ? show : !this.state.showCountriesList
        })
    }

    setPaymentType = type => {
        if (this.state.editMode) {
            this.props.etidTransferField("payment_type", type)
            this.props.etidTransferField("title", "Składka ZUS")
            this.props.etidTransferField("due_date", moment().set('date', 10).format('DD-MM-YYYY'))
        }
    }

    setPeriodType = type => {
        if (this.state.editMode) {
            this.props.etidTransferField("period_type", type)
            this.props.etidTransferField("period_number", '')
            if (type === 'year') {
                this.props.etidTransferField("period_name", 'Year')
            } else {
                this.props.etidTransferField("period_name", '')
            }

            if (type === 'year') {
                this.setState({
                    showPeriosPopup: false
                })
            }
        }
    }

    setPeriodNumber = (number, name) => {
        if (this.state.editMode) {
            this.props.etidTransferField("period_number", number)
            this.props.etidTransferField("period_name", name)

            this.setState({
                showPeriosPopup: false
            })
        }
    }

    setTaxSymbol = symbol => {
        if (this.state.editMode) {
            var dueDateText = ''
            if (symbol.includes('VAT')) {
                dueDateText = moment().set('date', 25).format('DD-MM-YYYY')
            } else if (symbol.includes('PIT') || symbol.includes('CIT')) {
                dueDateText = moment().set('date', 20).format('DD-MM-YYYY')
            }

            this.props.etidTransferField("symbol", symbol)
            this.props.etidTransferField("title", symbol)
            this.props.etidTransferField("due_date", dueDateText)

            this.setState({
                symbol: symbol,
                showTaxSymbolsList: false,
            })
        }
    }

    checkTaxSymbol = () => {
        const { symbol } = this.state

        if (paymentSymbols.includes(symbol) && this.state.editMode) {
            this.props.etidTransferField("symbol", symbol)
        }
    }

    showCurrencyList = () => {
        if (this.state.editMode) {
            this.setState({
                showCurrencyList: !this.state.showCurrencyList
            })
        }
    }

    showPaymentTypesList = () => {
        if (this.state.editMode) {
            this.setState({
                showPaymentTypesList: !this.state.showPaymentTypesList
            })
        }
    }

    selectCurrency = currency => {
        const selectLetters = /\D*/
        const { account_number, country_code } = this.props.transferPreviewData
        if (currency === 'PLN') this.props.etidTransferField('account_number', account_number.replace(selectLetters, ''))
        if (currency !== 'PLN') this.props.etidTransferField('account_number', country_code === '' ? 'PL' + account_number.replace(selectLetters, '') : country_code + account_number.replace(selectLetters, ''))
        this.setState({
            showCurrencyList: false,
        })
        this.props.etidTransferField('currency', currency)
    }

    validateAccountNumber = accountNumber => {
        var didMatch = false
        accountNumberRegexList.forEach(regex => {
            if (regex.test(accountNumber)) {
                didMatch = true
            }
        })

        return didMatch
    }

    validatePrice = price => {
        var didMatch = false
        priceRegexList.forEach(regex => {
            if (regex.test(price)) {
                didMatch = true
            }
        })

        return didMatch
    }

    saveChanges = accept => {
        const { symbol } = this.state
        const { transferPreviewData, transferPreviewFile } = this.props

        var valid = true

        if (transferPreviewData.title.length === 0) {
            this.props.alertWarn("Transfer title is required")
            valid = false
        }

        if (transferPreviewData.recipient.length === 0) {
            this.props.alertWarn(this.context.t("Recipient is required"))
            valid = false
        }

        if (transferPreviewData.account_number.length === 0) {
            this.props.alertWarn(this.context.t("Account number is required"))
            valid = false
        }
        //  else if (!this.validateAccountNumber(transferPreviewData.account_number)) {
        //     this.props.alertWarn(this.context.t("Account number is not valid"))
        //     valid = false
        // }

        if (transferPreviewData.amount_to_pay.length === 0) {
            this.props.alertWarn(this.context.t("Amount to pay is required"))
            valid = false
        } else if (!this.validatePrice(transferPreviewData.amount_to_pay)) {
            this.props.alertWarn(this.context.t("Amount to pay is not valid"))
            valid = false
        }

        if (transferPreviewData.due_date.length === 0) {
            this.props.alertWarn(this.context.t("Due date is required"))
            valid = false
        }

        if (transferPreviewData.payment_type === 'TAX') {
            if (!transferPreviewData.year || transferPreviewData.year.length < 4) {
                this.props.alertWarn("Yeare is required")
                valid = false
            }

            if (!transferPreviewData.period_type || (!['Year', 'year'].includes(transferPreviewData.period_type) && !transferPreviewData.period_number)) {
                console.log(['Year', 'year'].includes(transferPreviewData.period_type))
                console.log(transferPreviewData.period_type)
                console.log(transferPreviewData.period_number)
                this.props.alertWarn("Period is required")
                valid = false
            }

            if (symbol.length === 0) {
                this.props.alertWarn("Tax Symbol is required")
                valid = false
            } else if (!paymentSymbols.includes(symbol)) {
                this.props.alertWarn("Tax Symbol has to be selected from the list")
                valid = false
            }
        }

        if (valid) {
            this.setState({
                editMode: false
            })
            this.props.saveTransferChanges(transferPreviewData, transferPreviewFile, accept)
        }
    }

    transferNameToShow = name => {
        var temp = name.split('/')

        return temp[temp.length - 1]
    }

    removeFile = () => {
        this.props.removePreviewFile()
    }

    handleImageLoad = () => {
        if (this.refs.imageRef) {
            var imageContainer = this.refs.imageContinerRef.getBoundingClientRect()
            var image = this.refs.imageRef.getBoundingClientRect()
            console.log(imageContainer)
            console.log(image)

        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        var newPdfWidth = 0
        if (this.refs.pdfWrapperRef) {
            newPdfWidth = this.refs.pdfWrapperRef.getBoundingClientRect().width
        }
        this.setState({
            numPages: numPages,
            pdfWidth: newPdfWidth
        });
    }

    hasSomeParentTheClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }

        return false
    }

    closeAllPopups = e => {
        const { showCurrencyList } = this.state
        if (showCurrencyList && (e.keyCode === 27 || (this.currencyInputRef && this.currencyInputRef.current && !this.currencyInputRef.current.contains(e.target)))) {
            this.setState({
                showCurrencyList: false
            })
        }
        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'due-date-container') && this.state.showCalendar)) {
            console.log('close all popups func')
            this.setState({
                showCalendar: false
            })
        }

        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'payment-method-container') && this.state.showPaymentMethods)) {
            console.log('close all popups func')
            this.setState({
                showPaymentMethods: false
            })
        }

        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'period-container') && this.state.showDuplicatePeriodList)) {
            console.log('close all popups func')
            this.setState({
                showDuplicatePeriodList: false
            })
        }

        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'tax-symbol-sontainer') && this.state.showTaxSymbolsList)) {
            this.setState({
                showTaxSymbolsList: false
            })
        }

        if (e.keyCode === 27 || (!(this.hasSomeParentTheClass(e.target, 'tax-period-container') || this.hasSomeParentTheClass(e.target, 'period-popup')) && this.state.showPeriosPopup)) {
            this.setState({
                showPeriosPopup: false
            })
        }

        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'tags-container') && this.state.showTagsList)) {
            this.setState({
                showTagsList: false
            })
        }

        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'payment-type-container') && this.state.showPaymentTypesList)) {
            this.setState({
                showPaymentTypesList: false
            })
        }

    }

    prevFileExists = (id, project_id) => {
        const { prevAndNext } = this.props
        if (prevAndNext) {
            return prevAndNext.prev
        } else {
            const { transfers } = this.props

            var projectIndex = arrayObjectIndexOf(transfers, project_id, 'project')

            if (projectIndex > -1) {
                var transferIndex = arrayObjectIndexOf(transfers[projectIndex].transfers, id, 'id')
                if (transferIndex > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }

    nextFileExists = (id, project_id) => {
        const { prevAndNext } = this.props
        if (prevAndNext) {
            return prevAndNext.next
        } else {
            const { transfers } = this.props

            var projectIndex = arrayObjectIndexOf(transfers, project_id, 'project')

            if (projectIndex > -1) {
                var transferIndex = arrayObjectIndexOf(transfers[projectIndex].transfers, id, 'id')
                if (transferIndex > -1 && transfers[projectIndex].transfers[transferIndex + 1]) {
                    return true
                } else {
                    return false
                }

            } else {
                return false
            }
        }
    }

    getTransfer = (id, project_id, type) => {
        this.setState({
            pageNumber: 1
        })
        console.log("have to open other transfer")
        const { prevAndNext } = this.props
        console.log('prevAndNext')
        console.log('prevAndNext')
        console.log('prevAndNext')
        console.log('prevAndNext')
        console.log('prevAndNext')
        console.log(prevAndNext)
        if (prevAndNext) {
            if (type === 'prev' && prevAndNext.prev) {
                this.props.goToDoc(prevAndNext.index - 1, -1)
            } else if (type === 'next' && prevAndNext.next) {
                this.props.goToDoc(prevAndNext.index + 1, 1)
            }
        } else {
            const { transfers } = this.props
            var projectIndex = arrayObjectIndexOf(transfers, project_id, 'project')
            console.log('projectIndex :' + projectIndex)
            if (projectIndex > -1) {
                console.log('found project')
                var transferIndex = arrayObjectIndexOf(transfers[projectIndex].transfers, id, 'id')
                console.log('transferIndex: ' + transferIndex)
                console.log('type: ' + type)
                if (type === 'next' && transferIndex > -1 && transfers[projectIndex].transfers[transferIndex + 1]) {
                    this.props.openTransferPreviewWindow(transfers[projectIndex].transfers[transferIndex + 1])
                } else if (type === 'prev' && transferIndex > -1 && transfers[projectIndex].transfers[transferIndex - 1]) {
                    this.props.openTransferPreviewWindow(transfers[projectIndex].transfers[transferIndex - 1])
                }
            }
        }
    }

    startEdit = () => {
        if (!this.state.editMode) {
            this.setState({
                editMode: true
            })
            this.props.makeEditedTransferBackup()
        }
    }

    cancelEditMode = () => {
        this.setState({
            editMode: false
        })
        const { transferPreviewData, backupPTransferPreviewData, user } = this.props
        this.props.tooglePaymentForTransfer(transferPreviewData.id, transferPreviewData.project_id, user.id, backupPTransferPreviewData.payment_method !== null ? true : false, backupPTransferPreviewData.payment_method);
        this.props.cancelTransferEdit()
    }

    openDeleteModal = () => {
        this.setState({
            deleteModalOpen: true,
        })
    }

    cancelDelete = () => {
        this.setState({
            deleteModalOpen: false,
        })
    }

    deleteTransfer = () => {
        this.props.deleteTransfer(this.props.transferPreviewData.id, this.props.transferPreviewData.project_id)
    }

    returnPayemntType = type => {
        if (type === 'NORMAL') return 'Regular payment'
        if (type === 'TAX') return 'Tax payment'
        if (type === 'ZUS') return 'ZUS payment'
        return ''
    }

    showTaxSymbolsList = () => {
        if (!this.state.showTaxSymbolsList) {
            this.setState({
                showTaxSymbolsList: true
            })
        }
    }

    showPeriosPopup = (e) => {
        if (!this.state.showPeriosPopup) {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom
            var left = inputPosition.left

            this.setState({
                showPeriosPopup: true,
                periodPopupY: top,
                periodPopupX: left
            })
        }
    }

    makeDoubleDigits = number => {
        if (number < 10) return `0${number}`
        return `${number}`
    }

    paymentMethodClick = method => {
        const { transferPreviewData, user } = this.props
        this.props.tooglePaymentForTransfer(transferPreviewData.id, transferPreviewData.project_id, user.id, true, method);

        this.props.etidTransferField('paid_by', user.id)
        this.props.etidTransferField('payment_method', method)

        this.setState({
            showPaymentMethods: false
        })
    }

    showPaymentMethods = show => {
        this.setState({
            showPaymentMethods: show
        })
    }

    showDuplicateWindow = transfer => {
        const { transferPreviewData } = this.props

        if (transferPreviewData.payment_type === 'TAX' || transferPreviewData.payment_type === 'ZUS') {
            this.setState({
                showDuplicateWindow: true,
                orderToDuplicate: {
                    ...transfer,
                    file: this.props.transferPreviewFile
                }
            })

            if (this.props.transferPreviewData.period_type) {
                if (this.props.transferPreviewData.period_type === 'quarter') {
                    this.setState({
                        duplicatePeriod: 'quarter',
                        duplicatePeriodText: 'In a quarter',
                        canChangeDuplicatePeriod: false,
                    })
                } else if (this.props.transferPreviewData.period_type === 'year') {
                    this.setState({
                        duplicatePeriod: 'year',
                        duplicatePeriodText: 'In a year',
                        canChangeDuplicatePeriod: false
                    })
                } else if (this.props.transferPreviewData.period_type === 'month') {
                    this.setState({
                        duplicatePeriod: 'month',
                        duplicatePeriodText: 'In a month',
                        canChangeDuplicatePeriod: false
                    })
                } else {
                    this.setState({
                        canChangeDuplicatePeriod: true
                    })
                }
            } else {
                this.setState({
                    canChangeDuplicatePeriod: true
                })
            }
        } else {
            this.setState({
                orderToDuplicate: {
                    ...transfer,
                    file: this.props.transferPreviewFile
                },
                duplicatePeriod: 'NOW'
            }, () => this.duplicate(transferPreviewData.project_id))
        }

    }

    hideDuplicateWindow = () => {
        this.setState({
            showDuplicateWindow: false,
            orderToDuplicate: null
        })
    }

    changeDuplicatePeriod = (period, duplicatePeriodText) => {
        this.setState({
            duplicatePeriod: period,
            duplicatePeriodText: duplicatePeriodText,
            showDuplicatePeriodList: false,
        })
    }

    showDuplicatePeriodList = (show) => {
        if (this.state.canChangeDuplicatePeriod) {
            this.setState({
                showDuplicatePeriodList: show
            })
        } else {
            console.log('not alowed to show list')
            this.props.alertWarn(this.context.t('You can\'t change this value for tax payment with set period'))
        }
    }

    hideDuplicatePeriodList = () => {
        this.setState({
            showDuplicatePeriodList: false,
        })
    }

    duplicate = (projectId) => {
        const { orderToDuplicate, duplicatePeriod } = this.state
        if (orderToDuplicate && duplicatePeriod) {
            this.props.showDuplicatePaymentOrderWindow(this.props.userProjects.find(p => p.id === projectId), orderToDuplicate, duplicatePeriod)
        }

        this.setState({
            showDuplicateWindow: false,
            orderToDuplicate: null
        })

        const { user } = this.props
        if (user?.new_easydocs_version) {
            this.props.openNewTab(AvailableTabs.GENERATE_PAYMENT_ORDER, AvailableTabs.GENERATE_PAYMENT_ORDER)
        }
    }

    showTagsList = () => {
        if (this.state.editMode) {
            this.setState({
                showTagsList: true
            })
        }
    }

    returnTags = tags => {
        this.props.etidTransferField('tags', tags)
    }

    handleChangePriority = () => {
        if (!this.state.editMode) return

        this.props.etidTransferField('priority', !this.props.transferPreviewData.priority)
    }

    onPageLoadSuccess = obj => {
        var originalPdfWidth = obj.originalWidth
        var originalPdfHeight = obj.originalHeight
        this.setState({
            originalPdfWidth: originalPdfWidth,
            originalPdfHeight: originalPdfHeight
        })
        if (this.refs.pdfWrapper) {
            var maxPdfScale = this.refs.pdfWrapper.clientWidth / originalPdfWidth
            var maxHeigth = window.innerHeight - 68
            var fitToHeightPdfScale = maxHeigth / originalPdfHeight

            console.log(fitToHeightPdfScale)
            this.setState({
                maxPdfScale: maxPdfScale,
                fitToHeightPdfScale: fitToHeightPdfScale
            })
        }
    }

    toggleDocData = () => {
        this.setState({
            showDocData: !this.state.showDocData
        })

        setTimeout(() => {
            this.setDivSize()
        }, 500)
    }

    setDivSize = () => {
        if (this.refs.pdfWrapper) {
            var newMaxPdfScale = this.refs.pdfWrapper.clientWidth / this.state.originalPdfWidth
            var maxHeigth = window.innerHeight - 68
            var fitToHeightPdfScale = maxHeigth / this.state.originalPdfHeight

            this.setState({
                maxPdfScale: newMaxPdfScale,
                fitToHeightPdfScale: fitToHeightPdfScale
            })

            const { pdfScale } = this.state
            if (pdfScale >= newMaxPdfScale) {
                var newPdfScale = newMaxPdfScale
                var newPdfScalePercentage = parseInt(newMaxPdfScale / 1.5 * 100)

                this.setState({
                    pdfScale: newPdfScale,
                    pdfScalePercentage: newPdfScalePercentage
                })
            }
        }
    }

    zoomIn = (type) => {
        if (type === 'pdf') {
            const { pdfScalePercentage, maxPdfScale } = this.state
            var newPdfScalePercentage, newPdfScale
            if (pdfScalePercentage >= 25 && pdfScalePercentage < 33) {
                newPdfScalePercentage = 33
                newPdfScale = 1.5 * 0.33
            } else if (pdfScalePercentage >= 33 && pdfScalePercentage < 50) {
                newPdfScalePercentage = 50
                newPdfScale = 1.5 * 0.5
            } else if (pdfScalePercentage >= 50 && pdfScalePercentage < 67) {
                newPdfScalePercentage = 67
                newPdfScale = 1.5 * 0.67
            } else if (pdfScalePercentage >= 67 && pdfScalePercentage < 75) {
                newPdfScalePercentage = 75
                newPdfScale = 1.5 * 0.75
            } else if (pdfScalePercentage >= 75 && pdfScalePercentage < 80) {
                newPdfScalePercentage = 80
                newPdfScale = 1.5 * 0.8
            } else if (pdfScalePercentage >= 80 && pdfScalePercentage < 90) {
                newPdfScalePercentage = 90
                newPdfScale = 1.5 * 0.9
            } else if (pdfScalePercentage >= 90 && pdfScalePercentage < 100) {
                newPdfScalePercentage = 100
                newPdfScale = 1.5
            } else if (pdfScalePercentage >= 100 && pdfScalePercentage < 110) {
                newPdfScalePercentage = 110
                newPdfScale = 1.5 * 1.1
            } else if (pdfScalePercentage >= 110 && pdfScalePercentage < 125) {
                newPdfScalePercentage = 125
                newPdfScale = 1.5 * 1.25
            } else if (pdfScalePercentage >= 125 && pdfScalePercentage < 150) {
                newPdfScalePercentage = 150
                newPdfScale = 1.5 * 1.5
            } else if (pdfScalePercentage >= 150 && pdfScalePercentage < 175) {
                newPdfScalePercentage = 175
                newPdfScale = 1.5 * 1.75
            } else if (pdfScalePercentage >= 175 && pdfScalePercentage < 200) {
                newPdfScalePercentage = 200
                newPdfScale = 1.5 * 2
            } else {
                newPdfScalePercentage = pdfScalePercentage + 50
                newPdfScale = 1.5 * (pdfScalePercentage + 50) / 100
            }

            if (newPdfScale >= maxPdfScale) {
                newPdfScale = maxPdfScale
                newPdfScalePercentage = parseInt(maxPdfScale / 1.5 * 100)
            }

            this.setState({
                pdfScale: newPdfScale,
                pdfScalePercentage: newPdfScalePercentage
            })

        } else {
            const { imgScale, imgScalePercentage } = this.state
            var newImgScalePercentage, newImgScale
            if (imgScalePercentage >= 25 && imgScalePercentage < 33) {
                newImgScalePercentage = 33
                newImgScale = 0.6 * 0.33
            } else if (imgScalePercentage >= 33 && imgScalePercentage < 50) {
                newImgScalePercentage = 50
                newImgScale = 0.6 * 0.5
            } else if (imgScalePercentage >= 50 && imgScalePercentage < 67) {
                newImgScalePercentage = 67
                newImgScale = 0.6 * 0.67
            } else if (imgScalePercentage >= 67 && imgScalePercentage < 75) {
                newImgScalePercentage = 75
                newImgScale = 0.6 * 0.75
            } else if (imgScalePercentage >= 75 && imgScalePercentage < 80) {
                newImgScalePercentage = 80
                newImgScale = 0.6 * 0.8
            } else if (imgScalePercentage >= 80 && imgScalePercentage < 90) {
                newImgScalePercentage = 90
                newImgScale = 0.6 * 0.9
            } else if (imgScalePercentage >= 90 && imgScalePercentage < 100) {
                newImgScalePercentage = 100
                newImgScale = 0.6
            } else if (imgScalePercentage >= 100 && imgScalePercentage < 110) {
                newImgScalePercentage = 110
                newImgScale = 0.6 * 1.1
            } else if (imgScalePercentage >= 110 && imgScalePercentage < 125) {
                newImgScalePercentage = 125
                newImgScale = 0.6 * 1.25
            } else if (imgScalePercentage >= 125 && imgScalePercentage < 150) {
                newImgScalePercentage = 150
                newImgScale = 0.6 * 1.5
            } else if (imgScalePercentage >= 150 && imgScalePercentage < 175) {
                newImgScalePercentage = 175
                newImgScale = 0.6 * 1.75
            } else if (imgScalePercentage >= 175 && imgScalePercentage < 200) {
                newImgScalePercentage = 200
                newImgScale = 0.6 * 2
            }

            if (newImgScale > 1.0) {
                newImgScale = 1.0
                newImgScalePercentage = 1.0 / 0.6 * 100
            }
            this.setState({
                imgScale: newImgScale,
                imgScalePercentage: parseInt(newImgScalePercentage)
            })
        }
    }
    zoomOut = (type) => {
        if (type === 'pdf') {
            const { pdfScalePercentage } = this.state
            var newPdfScalePercentage, newPdfScale
            if (pdfScalePercentage <= 33 && pdfScalePercentage > 25) {
                newPdfScalePercentage = 25
                newPdfScale = 1.5 * 0.25
            } else if (pdfScalePercentage <= 50 && pdfScalePercentage > 33) {
                newPdfScalePercentage = 33
                newPdfScale = 1.5 * 0.33
            } else if (pdfScalePercentage <= 67 && pdfScalePercentage > 50) {
                newPdfScalePercentage = 50
                newPdfScale = 1.5 * 0.5
            } else if (pdfScalePercentage <= 75 && pdfScalePercentage > 67) {
                newPdfScalePercentage = 67
                newPdfScale = 1.5 * 0.67
            } else if (pdfScalePercentage <= 80 && pdfScalePercentage > 75) {
                newPdfScalePercentage = 75
                newPdfScale = 1.5 * 0.75
            } else if (pdfScalePercentage <= 90 && pdfScalePercentage > 80) {
                newPdfScalePercentage = 80
                newPdfScale = 1.5 * 0.8
            } else if (pdfScalePercentage <= 100 && pdfScalePercentage > 90) {
                newPdfScalePercentage = 90
                newPdfScale = 1.5 * 0.9
            } else if (pdfScalePercentage <= 110 && pdfScalePercentage > 100) {
                newPdfScalePercentage = 100
                newPdfScale = 1.5
            } else if (pdfScalePercentage <= 125 && pdfScalePercentage > 110) {
                newPdfScalePercentage = 110
                newPdfScale = 1.5 * 1.1
            } else if (pdfScalePercentage <= 150 && pdfScalePercentage > 125) {
                newPdfScalePercentage = 125
                newPdfScale = 1.5 * 1.25
            } else if (pdfScalePercentage <= 175 && pdfScalePercentage > 150) {
                newPdfScalePercentage = 150
                newPdfScale = 1.5 * 1.5
            } else if (pdfScalePercentage <= 200 && pdfScalePercentage > 175) {
                newPdfScalePercentage = 175
                newPdfScale = 1.5 * 1.75
            } else {
                newPdfScalePercentage = pdfScalePercentage - 50
                newPdfScale = 1.5 * (pdfScalePercentage - 50) / 100
            }

            if (newPdfScalePercentage < 25) {
                newPdfScale = 1.5 * 0.25
                newPdfScalePercentage = 25
            }

            this.setState({
                pdfScale: newPdfScale,
                pdfScalePercentage: newPdfScalePercentage
            })
        } else {
            const { imgScale, imgScalePercentage } = this.state
            var newImgScalePercentage, newImgScale
            if (imgScalePercentage <= 33 && imgScalePercentage > 25) {
                newImgScalePercentage = 25
                newImgScale = 0.6 * 0.25
            } else if (imgScalePercentage <= 50 && imgScalePercentage > 33) {
                newImgScalePercentage = 33
                newImgScale = 0.6 * 0.33
            } else if (imgScalePercentage <= 67 && imgScalePercentage > 50) {
                newImgScalePercentage = 50
                newImgScale = 0.6 * 0.5
            } else if (imgScalePercentage <= 75 && imgScalePercentage > 67) {
                newImgScalePercentage = 67
                newImgScale = 0.6 * 0.67
            } else if (imgScalePercentage <= 80 && imgScalePercentage > 75) {
                newImgScalePercentage = 75
                newImgScale = 0.6 * 0.75
            } else if (imgScalePercentage <= 90 && imgScalePercentage > 80) {
                newImgScalePercentage = 80
                newImgScale = 0.6 * 0.8
            } else if (imgScalePercentage <= 100 && imgScalePercentage > 90) {
                newImgScalePercentage = 90
                newImgScale = 0.6 * 0.9
            } else if (imgScalePercentage <= 110 && imgScalePercentage > 100) {
                newImgScalePercentage = 100
                newImgScale = 0.6
            } else if (imgScalePercentage <= 125 && imgScalePercentage > 110) {
                newImgScalePercentage = 110
                newImgScale = 0.6 * 1.1
            } else if (imgScalePercentage <= 150 && imgScalePercentage > 125) {
                newImgScalePercentage = 125
                newImgScale = 0.6 * 1.25
            } else if (imgScalePercentage <= 175 && imgScalePercentage > 150) {
                newImgScalePercentage = 150
                newImgScale = 0.6 * 1.5
            } else if (imgScalePercentage <= 200 && imgScalePercentage > 175) {
                newImgScalePercentage = 175
                newImgScale = 0.6 * 1.75
            }

            if (newImgScalePercentage < 25) {
                newImgScale = 0.6 * 0.25
                newImgScalePercentage = 25
            }

            this.setState({
                imgScale: newImgScale,
                imgScalePercentage: newImgScalePercentage
            })
        }
    }

    fitToWidth = (type) => {
        if (type === 'pdf') {
            const { maxPdfScale } = this.state
            this.setState({
                pdfScale: maxPdfScale,
                pdfScalePercentage: parseInt(maxPdfScale / 1.5 * 100),
                didFitToWidth: true
            })
        } else {
            this.setState({
                imgScale: 1.0,
                imgScalePercentage: parseInt(1.0 / 0.6 * 100),
                didFitImgToWidth: true,
            })
        }
    }

    fitToHeight = (type) => {
        if (type === 'pdf') {
            const { fitToHeightPdfScale } = this.state

            this.setState({
                pdfScale: fitToHeightPdfScale,
                pdfScalePercentage: parseInt(fitToHeightPdfScale / 1.5 * 100),
                didFitToWidth: false
            })
        } else {
            const img = new Image();

            img.src = this.props.pdfPreviewDataBody

            img.onload = () => {
                const imgHeight = img.naturalHeight;
                const imgWidth = img.naturalWidth;

                var maxHeigth = window.innerHeight - 68
                var maxWidth = window.innerWidth - 300
                var pageScale = maxHeigth / maxWidth
                var imageScale = imgHeight / imgWidth
                var fitImgToHeightScale = (imgWidth * maxHeigth / imgHeight) / maxWidth
                console.log(pageScale)
                console.log(imageScale)
                console.log(fitImgToHeightScale)

                this.setState({
                    imgScale: fitImgToHeightScale,
                    imgScalePercentage: parseInt(fitImgToHeightScale / 0.6 * 100),
                    didFitImgToWidth: false
                })
            };
        }
    }

    rotateDoc = (type) => {
        if (type === 'pdf') {
            const { pdfRoateAngle } = this.state
            if (pdfRoateAngle === 0) {
                this.setState({
                    pdfRoateAngle: 90
                })
            } else if (pdfRoateAngle === 90) {
                this.setState({
                    pdfRoateAngle: 180
                })
            } else if (pdfRoateAngle === 180) {
                this.setState({
                    pdfRoateAngle: 270
                })
            } else {
                this.setState({
                    pdfRoateAngle: 0
                })
            }
        } else {
            const { imgRoateAngle } = this.state
            if (imgRoateAngle === 0) {
                this.setState({
                    imgRoateAngle: 90
                })
            } else if (imgRoateAngle === 90) {
                this.setState({
                    imgRoateAngle: 180
                })
            } else if (imgRoateAngle === 180) {
                this.setState({
                    imgRoateAngle: 270
                })
            } else {
                this.setState({
                    imgRoateAngle: 0
                })
            }
        }
    }

    handleMouseDown = mouseDownEvent => {
        console.log('mouseDownEvent')
        console.log(mouseDownEvent.clientX)
        this.setState({
            startedMouseSwipe: true,
            mouseSwipeStartPossition: mouseDownEvent.clientX
        })
    }

    handleMouseMove = mouseMoveEvent => {
        if (this.state.startedMouseSwipe) {
            var currentPossition = mouseMoveEvent.clientX

            if (this.state.mouseSwipeStartPossition - currentPossition > 0 && !this.state.showNextArrow) {
                this.setState({
                    showNextArrow: true,
                    showPrevArrow: false
                })
            } else if (this.state.mouseSwipeStartPossition - currentPossition < 0 && !this.state.showPrevArrow) {
                this.setState({
                    showPrevArrow: true,
                    showNextArrow: false
                })
            }
        } else if (this.state.showPrevArrow || this.state.showNextArrow) {
            this.setState({
                showPrevArrow: false,
                showNextArrow: false
            })
        }
    }

    handleMouseUp = mouseUpEvent => {
        console.log('mouseUpEvent')
        console.log(mouseUpEvent.clientX)

        const { transferPreviewData } = this.props

        if (this.state.startedMouseSwipe && this.props.transferPreviewData) {
            var mouseSwipeEndPossition = mouseUpEvent.clientX
            var mouseMoveDifference = mouseSwipeEndPossition - this.state.mouseSwipeStartPossition

            if (mouseMoveDifference < -300) {
                this.getTransfer(transferPreviewData.id, transferPreviewData.project_id, 'next')
            } else if (mouseMoveDifference > 300) {
                this.getTransfer(transferPreviewData.id, transferPreviewData.project_id, 'prev')
            }
        }

        this.setState({
            showPrevArrow: false,
            showNextArrow: false,
            startedMouseSwipe: false
        })
    }

    getPaymentStatus = () => {
        const { transferPreviewData } = this.props
        if (transferPreviewData.paid_by) {
            if (transferPreviewData.payment_method) {
                return `${this.context.t('paid')} (${this.context.t(transferPreviewData.payment_method)})`
            } else {
                return `${this.context.t('paid')}`
            }
        } else {
            return this.context.t('unpaid')
        }
    }

    handleChangePaymentOrderAccounted = () => {
        const { transferPreviewData, user } = this.props
        this.props.toogleAccountmentStatusForTransfer(transferPreviewData.id, transferPreviewData.project_id, user.id, !transferPreviewData.accounted);

        this.props.etidTransferField('accounted', !transferPreviewData.accounted)
    }

    handlePaymentConfirmationChange = () => {
        const { transferPreviewData, user } = this.props

        var newPaymentConfirmationEmail = transferPreviewData.email_to_send_payment_confirmation || ''
        if (newPaymentConfirmationEmail.includes(user.email)) newPaymentConfirmationEmail = newPaymentConfirmationEmail.replace(user.email, '').replace(',,', ',')
        else newPaymentConfirmationEmail += `,${user.email}`

        newPaymentConfirmationEmail = newPaymentConfirmationEmail.replace(',,', ',').replace(/^,/, '').replace(/,$/, '')

        this.props.editPaymentConfirmation(transferPreviewData.id, newPaymentConfirmationEmail)
    }

    closePreview = () => {
        this.props.hideTransferPreviewWindow()
        if (this.state.editMode) this.cancelEditMode()
        if (this.props.customGoToDocControler) {
            this.props.close()
        }
    }

    WL_setDevExValue = (key, value) => {
        this.props.etidTransferField(key, value)
    }

    onPdfPassword = (callback, reason) => {
        switch (reason) {
            case 1: {
                const password = prompt(this.context.t('Enter the password to open this PDF file.'));
                this.callbackProxy(callback, password);
                break;
            }
            case 2: {
                const password = prompt(this.context.t('Invalid password. Please try again.'));
                this.callbackProxy(callback, password);
                break;
            }
            default:
        }
    }
    callbackProxy = (callback, password) => {
        // Cancel button handler
        if (password === null) {
            // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
            this.closePreview()
        }

        callback(password);
    }

    render() {
        const { showDocData, showNextArrow, showPrevArrow, showPaymentTypesList, showCurrencyList, showCalendar, showPaymentMethods, showDuplicateWindow, showDuplicatePeriodList, canChangeDuplicatePeriod, duplicatePeriod, duplicatePeriodText, editMode, deleteModalOpen, pageNumber, numPages, pdfScale, pdfScalePercentage, didFitToWidth, pdfRoateAngle, imgScale, imgScalePercentage, didFitImgToWidth, imgRoateAngle, showPeriosPopup, symbol, showTaxSymbolsList, acceptancePermissions, showTagsList, tagsList, posYDueDateCalendar, posXDueDateCalendar, posUpDueDateCalendar, periodPopupY, periodPopupX, showCountriesList } = this.state
        const { user, transferPreviewData, transferPreviewFile, prevAndNext } = this.props

        console.log('prevAndNext transfer render')
        console.log('prevAndNext transfer render')
        console.log('prevAndNext transfer render')
        console.log('prevAndNext transfer render')
        console.log('prevAndNext transfer render')
        console.log(prevAndNext)

        return (
            <div className={`transfer-preview-container`} ref="previevContainerRef" onClick={e => this.closeAllPopups(e)}>
                {
                    deleteModalOpen ? (
                        <DeleteTransferModal delete={this.deleteTransfer} cancel={this.cancelDelete} name={this.transferNameToShow(transferPreviewData.name)} />
                    ) : (
                        null
                    )
                }

                {
                    showDuplicateWindow ? (
                        <div className="duplicate-payment-order-popup popup">
                            <div className="popup-card">
                                <div className="text">{this.context.t('When to add a duplicate of this payment order?')}</div>
                                <div className="period-container">
                                    {this.context.t(duplicatePeriodText)}
                                    <span className={`arrow ${showDuplicatePeriodList ? 'extended' : ''} ${!canChangeDuplicatePeriod ? 'not-alowed' : ''}`} onClick={() => this.showDuplicatePeriodList(!showDuplicatePeriodList)}></span>
                                    {
                                        showDuplicatePeriodList ? (
                                            <ul>
                                                <li onClick={() => this.changeDuplicatePeriod('month', 'In a month')}>{this.context.t('In a month')}</li>
                                                <li onClick={() => this.changeDuplicatePeriod('quarter', 'In a quarter')}>{this.context.t('In a quarter')}</li>
                                                <li onClick={() => this.changeDuplicatePeriod('year', 'In a year')}>{this.context.t('In a year')}</li>
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                                <div className="action-buttons">
                                    <button className="btn" onClick={e => this.hideDuplicateWindow()}>{this.context.t('Cancel')}</button>
                                    <button className="btn accept" onClick={e => this.duplicate(transferPreviewData.project_id)}>{this.context.t('Confirm')}</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                <div className="transfer-order">
                    <div className="toolbar">
                        <div className="start">
                            <div className="toggle-doc-data" data-tip='show' data-for='toggle-doc-data-tooltip' onClick={() => this.toggleDocData()}></div>

                            <ReactTooltip id='toggle-doc-data-tooltip' className='default-tooltip info-tooltip' effect='solid' place='right' delayShow={1000}>
                                <p>
                                    {
                                        showDocData ?
                                            this.context.t('Hide payment order informations')
                                            :
                                            this.context.t('Show payment order informations')
                                    }
                                </p>
                            </ReactTooltip>

                            <div className="file-name">{transferPreviewData.id} {this.transferNameToShow(transferPreviewData.name)}</div>
                        </div>
                        <div className="center">
                            <div className="pdf-controls">
                                {
                                    transferPreviewFile ? (
                                        transferPreviewFile.type === 'application/pdf' ? (
                                            <>
                                                <div className="paginator">
                                                    <div className={`prev-page ${pageNumber === 1 ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumber !== 1) this.setState(prevState => ({ pageNumber: prevState.pageNumber - 1 }))
                                                    }}></div>
                                                    <div className="numeration"><span className="current-page">{pageNumber}</span> / <span className="pages-count">{numPages}</span></div>
                                                    <div className={`next-page ${pageNumber === numPages ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumber !== numPages) this.setState(prevState => ({ pageNumber: prevState.pageNumber + 1 }))
                                                    }}></div>
                                                </div>
                                                <div className="zoom">
                                                    <span className={`zoom-out ${pdfScale === 1.5 * 0.25 ? 'hide' : ''}`} onClick={() => this.zoomOut('pdf')}></span>
                                                    <span className="current-scale">{`${pdfScalePercentage}%`}</span>
                                                    <span className={`zoom-in ${pdfScale === this.state.maxPdfScale ? 'hide' : ''}`} onClick={() => this.zoomIn('pdf')}></span>
                                                </div>
                                                <div className="fit-dimensions">
                                                    {
                                                        !didFitToWidth ? (
                                                            <span className="fit-to-width" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToWidth('pdf')}></span>
                                                        ) : (
                                                            <span className="fit-to-height" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToHeight('pdf')}></span>
                                                        )
                                                    }

                                                    <ReactTooltip id='fit-file-to-dimensions' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {
                                                                didFitToWidth ?
                                                                    this.context.t('Fit the document size to the window height')
                                                                    :
                                                                    this.context.t('Fit the document size to the window width')
                                                            }
                                                        </p>
                                                    </ReactTooltip>

                                                    <span className="rotate-doc" data-tip='show' data-for='rotate-doc' onClick={() => this.rotateDoc('pdf')}></span>
                                                    <ReactTooltip id='rotate-doc' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {this.context.t('Rotate the document 90 degrees clockwise')}
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="zoom">
                                                    <span className={`zoom-out ${imgScale === 0.6 * 0.25 ? 'hide' : ''}`} onClick={() => this.zoomOut('img')}></span>
                                                    <span className="current-scale">{`${imgScalePercentage}%`}</span>
                                                    <span className={`zoom-in ${imgScale === 1.0 ? 'hide' : ''}`} onClick={() => this.zoomIn('img')}></span>
                                                </div>
                                                <div className="fit-dimensions">
                                                    {
                                                        !didFitImgToWidth ? (
                                                            <span className="fit-to-width" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToWidth('img')}></span>
                                                        ) : (
                                                            <span className="fit-to-height" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToHeight('img')}></span>
                                                        )
                                                    }

                                                    <ReactTooltip id='fit-file-to-dimensions' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {
                                                                didFitToWidth ?
                                                                    this.context.t('Fit the document size to the window height')
                                                                    :
                                                                    this.context.t('Fit the document size to the window width')
                                                            }
                                                        </p>
                                                    </ReactTooltip>

                                                    <span className="rotate-doc" onClick={() => this.rotateDoc('img')}></span>

                                                    <ReactTooltip id='rotate-doc' data-tip='show' data-for='rotate-doc' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {this.context.t('Rotate the document 90 degrees clockwise')}
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            </>
                                        )
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                        <div className="end">
                            {
                                transferPreviewData.name ? (
                                    <div className="doc-controls">
                                        {
                                            transferPreviewData.file_key ? (
                                                <a className={`download`} href={transferPreviewFile.base64} download={this.transferNameToShow(transferPreviewData.file_key)}></a>
                                            ) : (
                                                null
                                            )
                                        }

                                        <div className={`delete`} onClick={() => this.openDeleteModal()}></div>

                                        <div className={`close`} onClick={() => this.closePreview()}></div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className={`payment-order-data ${showDocData ? '' : 'doc-data-hidden'} ${!transferPreviewData.file_key ? 'no-file' : ''}`}>
                        <div className={`body ${editMode ? 'editing' : ''} ${acceptancePermissions !== 'USER1' ? 'accounted-checkbox' : ''}`}>
                            <SimpleBar style={{ height: '100%', width: '100%', minWidth: '100%', paddingRight: '12px' }}>
                                <div className="form-group">
                                    <TextareaAutosize readOnly={true} type="text" className={`read-only`} name="creator" id="creator" value={`${transferPreviewData.creator} (${moment(transferPreviewData.created).format('YYYY-MM-DD HH:mm')})`} placeholder="" autoComplete="off" />
                                    <label htmlFor="creator">{this.context.t('Created by')}</label>
                                </div>

                                {/* {
                                    transferPreviewData.paid_by ? (
                                        <div className="form-group">
                                            <TextareaAutosize readOnly={true} type="text" className={`read-only`} name="creator" id="creator" value={`${transferPreviewData.paidBy} (${moment(transferPreviewData.created).format('YYYY-MM-DD HH:mm')})`} placeholder="" autoComplete="off" />
                                            <label htmlFor="creator">{this.context.t('Created by')}</label>
                                        </div>
                                    ) : (
                                        null
                                    )
                                } */}


                                <div className="form-group payment-type-container" onClick={() => this.showPaymentTypesList()}>
                                    <TextareaAutosize readOnly={true} type="text" className={`${!editMode ? 'read-only' : ''}`} name="paymentType" id="paymentType" value={this.context.t(this.returnPayemntType(transferPreviewData.payment_type))} placeholder={this.context.t('Not selected')} autoComplete="off" />
                                    <label htmlFor="paymentType">{this.context.t('Payment type')}</label>

                                    <span className={`arrow ${showPaymentTypesList ? 'expanded' : ''} ${editMode ? '' : 'hide'}`} onClick={() => this.showPaymentTypesList()}></span>
                                    <ul className={`payment-types-list ${showPaymentTypesList ? 'expanded' : 'hide'}`}>
                                        <li className={`${transferPreviewData.payment_type === 'NORMAL' ? 'selected' : ''}`} onClick={() => this.setPaymentType('NORMAL')}>{this.context.t('Regular payment')}</li>
                                        <li className={`${transferPreviewData.payment_type === 'TAX' ? 'selected' : ''}`} onClick={() => this.setPaymentType('TAX')}>{this.context.t('Tax payment')}</li>
                                        <li className={`${transferPreviewData.payment_type === 'ZUS' ? 'selected' : ''}`} onClick={() => this.setPaymentType('ZUS')}>{this.context.t('ZUS payment')}</li>
                                    </ul>
                                </div>

                                {
                                    transferPreviewData.payment_type === 'TAX' ? (
                                        <div className="tax-container">
                                            <div className="form-group">
                                                <TextareaAutosize readOnly={!editMode} type="text" name="year" id="year" value={transferPreviewData.year} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="year">{this.context.t('Year')}</label>
                                            </div>

                                            <div className={`form-group tax-period-container ${showTaxSymbolsList ? 'in-front' : ''}`} onClick={e => { this.showPeriosPopup(e) }}>
                                                <TextareaAutosize type="text" name="period" id="period" value={this.context.t(transferPreviewData.period_name)} readOnly={true} placeholder="" autoComplete="off" />
                                                <label htmlFor="period">{this.context.t('Period')}</label>
                                            </div>

                                            <div className={`form-group tax-symbol-container  ${showTaxSymbolsList ? 'in-front' : ''}`} onClick={e => this.showTaxSymbolsList()}>
                                                <TextareaAutosize type="text" name="symbol" id="symbol" value={symbol} onChange={this.onInputChange} placeholder="" autoComplete="off" onBlur={() => this.checkTaxSymbol()} />
                                                <label htmlFor="symbol">{this.context.t('Tax Symbol')}</label>

                                                {
                                                    showTaxSymbolsList && editMode ? (
                                                        <div className="tax-symbols-list">
                                                            <SimpleBar style={{ height: "100%", maxHeight: '200px', width: "100%" }}>
                                                                <ul>
                                                                    {
                                                                        paymentSymbols.map(e => {
                                                                            if (e.startsWith(symbol.toUpperCase())) {
                                                                                return (
                                                                                    <li onClick={() => this.setTaxSymbol(e)}>
                                                                                        {e}
                                                                                    </li>
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        })
                                                                    }
                                                                </ul>
                                                            </SimpleBar>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                <div className="form-group title">
                                    <TextareaAutosize readOnly={!editMode} type="text" className={`${transferPreviewData.title?.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="title" id="title" value={transferPreviewData.title} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                    <label htmlFor="title">{this.context.t('Transfer title')}</label>
                                </div>

                                <div className={`form-group currency ${showCurrencyList ? 'list-expended in-front' : ''}`} ref={this.currencyInputRef}>
                                    <TextareaAutosize type="text" className={`${transferPreviewData.currency ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="currency" id="currency" value={transferPreviewData.currency} onChange={this.onInputChange} placeholder="" autoComplete="off" readOnly={true} onClick={() => this.showCurrencyList()} />
                                    <label htmlFor="currency" onClick={() => this.showCurrencyList()}>{this.context.t('Currency')}</label>
                                    <span className={`arrow ${showCurrencyList ? 'expanded' : ''}`} onClick={() => this.showCurrencyList()}></span>
                                    <ul className={`currency-list ${showCurrencyList ? 'expanded' : 'hide'}`}>
                                        {
                                            currencyList.map(c => {
                                                return (
                                                    <li className={`${transferPreviewData.currency === c ? 'selected' : ''}`} onClick={() => this.selectCurrency(c)}>{c}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                <div className="form-group">
                                    <TextareaAutosize readOnly={!editMode} type="text" className={`${transferPreviewData.recipient.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="recipient" id="recipient" value={transferPreviewData.recipient} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                    <label htmlFor="recipient">{this.context.t('Recipient')}</label>
                                </div>

                                {
                                    transferPreviewData.currency !== 'PLN' && transferPreviewData.payment_type === 'NORMAL' ? (
                                        <div className="currency-transfer-fields">
                                            <div className="form-group address">
                                                <TextareaAutosize readOnly={!editMode} type="text" className={`${!transferPreviewData.address || transferPreviewData.address.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="address" id="address" value={transferPreviewData.address} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="accountNumber">{this.context.t('Recipient\'s address')}</label>
                                            </div>

                                            <div className="form-group country countries-list-container">
                                                <TextareaAutosize readOnly={!editMode} type="text" className={`${!transferPreviewData.country_code || transferPreviewData.country_code.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="country_code" id="country_code" value={transferPreviewData.country_code} placeholder="" autoComplete="off" onClick={e => editMode ? this.toggleCountriesList(true) : null} />
                                                <label htmlFor="accountNumber">{this.context.t('Recipient\'s country')}</label>

                                                {
                                                    showCountriesList ? (
                                                        <CountriesList
                                                            headerText={this.context.t('Country:')}
                                                            selectedOption={transferPreviewData.country_code}
                                                            select={this.setSellerCountryCode}
                                                        />
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    transferPreviewData.currency !== 'PLN' && transferPreviewData.payment_type === 'NORMAL' ? (
                                        <>
                                            <div className="form-group part-2">
                                                <TextareaAutosize readOnly={!editMode} type="text" className={`${transferPreviewData.account_number.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="account_number" id="account_number" value={transferPreviewData.account_number} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="accountNumber">{this.context.t('Recipient\'s bank account number')}</label>
                                                {
                                                    !checkIfAccountNumberIsValid(transferPreviewData.account_number) ? <div className="text">
                                                        {this.context.t('The above account number is incompatible with the IBAN standard.')}
                                                    </div> : null
                                                }
                                            </div>
                                            <div className="form-group part-2">
                                                <TextareaAutosize readOnly={!editMode} type="text" className={`${!transferPreviewData.swift || transferPreviewData.swift.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="swift" id="swift" value={transferPreviewData.swift} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="accountNumber">{this.context.t('BIC/SWIFT')}</label>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="form-group">
                                            <TextareaAutosize readOnly={!editMode} type="text" className={`${transferPreviewData.account_number.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="account_number" id="account_number" value={this.polihAccountPritier(transferPreviewData.account_number)} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                            <label htmlFor="accountNumber">{this.context.t('Recipient\'s bank account number')}</label>
                                            {
                                                !checkIfAccountNumberIsValid(transferPreviewData.account_number) ? <div className="text">
                                                    {this.context.t('The above account number is incompatible with the IBAN standard.')}
                                                </div> : null
                                            }
                                        </div>
                                    )
                                }

                                <div className={`form-group part-3 currency-container ${!showTagsList && !showPaymentMethods && !showTaxSymbolsList ? 'in-front' : ''}`}>
                                    <TextareaAutosize readOnly={!editMode} type={!editMode ? "text" : "number"} className={`${transferPreviewData.amount_to_pay.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="amount_to_pay" id="amount_to_pay" value={!editMode ? `${transferPreviewData.amount_to_pay} ${transferPreviewData.currency}` : transferPreviewData.amount_to_pay} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                    <label htmlFor="price">{this.context.t('Amount to pay')}</label>
                                </div>

                                <div className="form-group part-3 margin-r due-date-container" onClick={e => this.showDueDateCalendar(e)}>
                                    <TextareaAutosize readOnly={!editMode} type="text" className={`${transferPreviewData.due_date.length ? 'has-content' : ''} ${!editMode ? 'read-only' : ''}`} name="dueDate" id="dueDate" value={transferPreviewData.due_date ? moment(transferPreviewData.due_date).format('YYYY-MM-DD') : transferPreviewData.due_date} placeholder="" autoComplete="off" onFocus={e => this.showDueDateCalendar(e)} />
                                    <label htmlFor="dueDate">{this.context.t('Due date')}</label>
                                    <span className="calendar-icon" onClick={e => this.showDueDateCalendar(e)}></span>
                                </div>

                                <div className={`form-group part-3 payment-method-container ${!showTagsList ? 'in-front' : ''}`}>
                                    <TextareaAutosize readOnly={true} type="text" className={` ${!editMode ? 'read-only' : ''}`} name="paymentStatus" id="paymentStatus" value={this.getPaymentStatus()} placeholder="" autoComplete="off" />
                                    <label htmlFor="price">{this.context.t('Payment status')}</label>
                                    {
                                        acceptancePermissions !== 'USER1' ? (
                                            <div className={`arrow payment-method-arrow ${showPaymentMethods ? 'expanded' : ''} ${!editMode ? 'hide' : ''}`} onClick={() => this.showPaymentMethods(!showPaymentMethods)}></div>
                                        ) : (
                                            null
                                        )
                                    }

                                    {
                                        showPaymentMethods ? (
                                            <ul className={`payment-methods-list ${showPaymentMethods ? 'expanded' : 'hide'}`}>
                                                {
                                                    paymentMethodsList.map(method => {
                                                        return (
                                                            <li onClick={e => this.paymentMethodClick(method.value)}>
                                                                {this.context.t(`Paid - ${method.title}`)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }

                                </div>


                                <div className="form-group half margin-r">
                                    <TextareaAutosize readOnly={!editMode} type="text" className={`comment ${!editMode ? 'read-only' : ''}`} id="comment" name="comment" value={transferPreviewData.comment} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                    <label htmlFor="comment">{this.context.t('Comment')}</label>
                                </div>

                                <div className="form-group payment-order-tags tags-container">
                                    {/* <AvailableInOtherPlanLabel planName='PRO' labelSize="LABEL-SHADOW" company={this.state.paymentOrderOwnerCompany} /> */}
                                    <TextareaAutosize className={`${!editMode ? 'read-only' : ''}`} readOnly={!editMode} type="text" name="tags" id="tags" value={transferPreviewData.tags} onChange={this.onInputChange} placeholder="" autoComplete="off" onFocus={() => this.showTagsList()} />
                                    <label htmlFor="comment">{this.context.t('Tags')}</label>

                                    {
                                        showTagsList && tagsList && tagsList.length > 0 && editMode ? (
                                            <TagsList companyTags={tagsList} selectedTags={transferPreviewData.tags} returnTags={this.returnTags} flipUp={true} />
                                        ) : (
                                            null
                                        )
                                    }
                                </div>

                                {
                                    devCostsProjects.includes(transferPreviewData.project_id) && devCostsUsers.includes(user.id) ? (
                                        <div className="dev-ex-container">
                                            <div className="header">{this.context.t('Required fields for DevCosts')}</div>
                                            <ProjectCode
                                                editMode={true}
                                                projectId={transferPreviewData.project_id || -1}
                                                selectedOption={transferPreviewData.wl_project_code || ''}
                                                select={value => this.WL_setDevExValue('wl_project_code', value)}
                                            />
                                            <Technology
                                                editMode={true}
                                                selectedOption={transferPreviewData.wl_technology || ''}
                                                select={value => this.WL_setDevExValue('wl_technology', value)}
                                            />
                                            <DevExCode
                                                editMode={true}
                                                selectedOption={transferPreviewData.wl_devex_type_1 || ''}
                                                select={value => this.WL_setDevExValue('wl_devex_type_1', value)}
                                            />
                                            <DevExType
                                                editMode={true}
                                                selectedOption={transferPreviewData.wl_devex_type_2 || ''}
                                                devExCode={transferPreviewData.wl_devex_type_1}
                                                select={value => this.WL_setDevExValue('wl_devex_type_2', value)}
                                            />
                                            <TransactionDescription
                                                editMode={true}
                                                lang={'PL'}
                                                selectedOption={transferPreviewData.wl_transaction_description_pl || ''}
                                                select={value => this.WL_setDevExValue('wl_transaction_description_pl', value)}
                                                onChange={value => this.WL_setDevExValue('wl_transaction_description_pl', value)}
                                            />
                                            <TransactionDescription
                                                editMode={true}
                                                lang={'EN'}
                                                selectedOption={transferPreviewData.wl_transaction_description_en || ''}
                                                select={value => this.WL_setDevExValue('wl_transaction_description_en', value)}
                                                onChange={value => this.WL_setDevExValue('wl_transaction_description_en', value)}
                                            />
                                        </div>
                                    ) : null
                                }
                            </SimpleBar>

                            <div className={`period-popup ${showPeriosPopup && editMode ? 'show' : ''}`} style={{ top: periodPopupY, left: periodPopupX }}>
                                <div className="period-type">
                                    <div className={`option ${transferPreviewData.period_type === 'month' ? 'selected' : ''}`} onClick={() => this.setPeriodType('month')}>{this.context.t('Month')}</div>
                                    <div className={`option ${transferPreviewData.period_type === 'quarter' ? 'selected' : ''}`} onClick={() => this.setPeriodType('quarter')}>{this.context.t('Quarter')}</div>
                                    <div className={`option ${transferPreviewData.period_type === 'year' ? 'selected' : ''}`} onClick={() => this.setPeriodType('year')}>{this.context.t('Year')}</div>
                                </div>

                                {
                                    transferPreviewData.period_type && transferPreviewData.period_type !== 'year' ? (
                                        <div className="period-number">
                                            {
                                                transferPreviewData.period_type === 'month' ? (
                                                    months.map((m, i) => {
                                                        return (
                                                            <div className={`option ${transferPreviewData.period_number === this.makeDoubleDigits(i + 1) ? 'selected' : ''}`} onClick={() => this.setPeriodNumber(this.makeDoubleDigits(i + 1), m)}>
                                                                {this.context.t(m)}
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    transferPreviewData.period_type === 'quarter' ? (
                                                        <>
                                                            <div className={`option ${transferPreviewData.period_number === '01' ? 'selected' : ''}`} onClick={() => this.setPeriodNumber('01', 'January - March')}>
                                                                {this.context.t('January - March')}
                                                            </div>
                                                            <div className={`option ${transferPreviewData.period_number === '02' ? 'selected' : ''}`} onClick={() => this.setPeriodNumber('02', 'April - June')}>
                                                                {this.context.t('April - June')}
                                                            </div>
                                                            <div className={`option ${transferPreviewData.period_number === '03' ? 'selected' : ''}`} onClick={() => this.setPeriodNumber('03', 'July - September')}>
                                                                {this.context.t('July - September')}
                                                            </div>
                                                            <div className={`option ${transferPreviewData.period_number === '04' ? 'selected' : ''}`} onClick={() => this.setPeriodNumber('04', 'October - December')}>
                                                                {this.context.t('October - December')}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        null
                                                    )
                                                )
                                            }
                                        </div>
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div id="due-date-container" className={`due-date-container due-date-calendar ${showCalendar ? 'show' : ''} ${posUpDueDateCalendar ? 'up' : ''}`} style={{ top: posYDueDateCalendar, left: posXDueDateCalendar }}>
                                <Calendar
                                    selectDay={this.onCalendarSelect}
                                    unselectDay={this.onCalendarUnselecet}
                                    selectedDay={
                                        moment(transferPreviewData.due_date).isValid() ? {
                                            display: moment(transferPreviewData.due_date).format('LL'),
                                            value: moment(transferPreviewData.due_date)
                                        } : {
                                            display: null,
                                            value: null
                                        }
                                    }
                                    projectId={'none'}
                                    startDate={moment(transferPreviewData.due_date).isValid() ? moment(transferPreviewData.due_date) : moment()}
                                />
                            </div>
                        </div>
                        <div className={`footer ${editMode ? 'editing' : ''} ${acceptancePermissions !== 'USER1' ? 'accounted-checkbox' : ''}`}>
                            <div className="payment-priority">
                                <span className={`payment-priority__checkbox ${this.props.transferPreviewData.priority ? 'checked' : ''}`} onClick={this.handleChangePriority}></span>
                                <p onClick={this.handleChangePriority} style={{ width: 'fit-content' }}>
                                    {this.context.t(editMode ? "Set document priority as high" : 'High priority')}
                                </p>
                            </div>
                            {
                                acceptancePermissions !== 'USER1' ? (
                                    <div className="accounted-doc">
                                        <span className={`accounted-doc__checkbox ${this.props.transferPreviewData.accounted ? 'checked' : ''}`} onClick={this.handleChangePaymentOrderAccounted}></span>
                                        <p onClick={this.handleChangePaymentOrderAccounted} style={{ width: 'fit-content' }}>
                                            {
                                                this.props.transferPreviewData.accounted ? (
                                                    this.context.t("This payment order has been accounted")
                                                ) : (
                                                    this.context.t('Mark this payment order has been accounted')
                                                )
                                            }
                                        </p>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            <div className="payment-confirmation">
                                <span className={`payment-confirmation__checkbox ${transferPreviewData.email_to_send_payment_confirmation && transferPreviewData.email_to_send_payment_confirmation.includes(user.email) ? 'checked' : ''}`} onClick={this.handlePaymentConfirmationChange}></span>
                                <p onClick={this.handleChangePriority} style={{ width: 'fit-content' }}>
                                    {this.context.t("Please send me a confirmation of payment")}
                                </p>
                            </div>
                            {
                                editMode ? (
                                    <>
                                        <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(files, rejectedFiles)} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize}>
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                <div {...getRootProps({ onClick: event => this.handleDropZoneClick(event) })} className={`dropzone${isDragActive ? ' drop-active' : ''}`}>
                                                    {
                                                        !transferPreviewData.file_key ? (
                                                            <div className="no-file">{this.context.t('Attachment')}</div>
                                                        ) : (
                                                            <div className="file">
                                                                <div className="name">{this.transferNameToShow(transferPreviewData.file_key)}</div>
                                                                <div className="delete" onClick={() => this.removeFile()}></div>
                                                            </div>
                                                        )
                                                    }
                                                    <input {...getInputProps()} />
                                                    <span className="clip-button" data-tip data-for='addFileTooltip'></span>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div className="action-buttons">
                                            <span className="btn cancel-btn" onClick={e => this.cancelEditMode()}>{this.context.t('Cancel')}</span>
                                            <span className="btn save-btn" onClick={e => this.saveChanges(this.state.projectRole !== 'USER1' && !transferPreviewData.accepted_by)}>
                                                {
                                                    this.state.projectRole !== 'USER1' && !transferPreviewData.accepted_by ? (
                                                        this.context.t('Accept & Save')
                                                    ) : (
                                                        this.context.t('Save')
                                                    )
                                                }
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <div className="action-buttons">
                                        <span className="btn save-btn" onClick={e => this.startEdit()}>{this.context.t('Edit')}</span>
                                        <span className="btn save-btn" onClick={e => this.showDuplicateWindow(transferPreviewData)}>
                                            {this.context.t('Duplicate')}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div
                        className={`file-container linked-docs-hidden ${showDocData ? '' : 'doc-data-hidden'} ${!transferPreviewData.file_key ? 'no-file' : ''}`}
                    >
                        <div className={`go-to-next-arrow linked-docs-hidden ${showNextArrow ? 'show' : ''} ${this.nextFileExists(transferPreviewData.id, transferPreviewData.project_id) ? '' : 'hide'}`} onClick={() => this.getTransfer(transferPreviewData.id, transferPreviewData.project_id, 'next')}></div>
                        <div className={`go-to-prev-arrow ${showPrevArrow ? 'show' : ''} ${this.prevFileExists(transferPreviewData.id, transferPreviewData.project_id) ? '' : 'hide'}`} onClick={() => this.getTransfer(transferPreviewData.id, transferPreviewData.project_id, 'prev')}></div>
                        {
                            transferPreviewFile ? (
                                transferPreviewFile.base64 && transferPreviewFile.base64 !== 'loading' ? (
                                    transferPreviewFile.type === 'application/pdf' ? (
                                        <SimpleBar style={{ width: '100%', maxWidth: showDocData ? 'calc(100vw - 300px)' : '100vw', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                            <div className="pdf-wrapper" ref="pdfWrapper">
                                                <Document
                                                    file={transferPreviewFile.base64}
                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                    onLoadError={console.error}
                                                    options={{ withCredentials: true }}
                                                    onPassword={this.onPdfPassword}
                                                >
                                                    <Page pageNumber={pageNumber} scale={pdfScale} rotate={pdfRoateAngle} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} />
                                                </Document>
                                            </div>
                                        </SimpleBar>
                                    ) : (
                                        <SimpleBar style={{ width: '100%', maxWidth: showDocData ? 'calc(100vw - 300px)' : '100vw', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                            <div className="image" ref="imageContinerRef">
                                                <img src={transferPreviewFile.base64} style={{ width: `${100 * imgScale}%`, transform: `rotate(${imgRoateAngle}deg)` }} ref="imageRef" />
                                            </div>
                                        </SimpleBar>
                                    )
                                ) : (
                                    <div className="pdf-wrapper" ref="pdfWrapper" >
                                        <div className="activity-indicator">
                                            <Spinner size={30} speed={0.8} color={"#444444"} />
                                        </div>
                                    </div>
                                )
                            ) : (
                                // <div className="no-file-attached" onClick={() => this.onNoFileAttachedClick()}>
                                //     <input
                                //         style={{ display: "none" }}
                                //         accept=".pdf,.jpg,.jpeg,.png"
                                //         ref="noFileAttached"
                                //         onChange={this.handleNewFileUpload}
                                //         type="file"
                                //     />
                                //     {this.context.t('No document has been attached to this payment order. Click here to add a file')}
                                // </div>
                                null
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

TransferPreview.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userProjects: state.User.userProjects,
    transfers: state.Transfers.transfers,
    transferPreviewData: state.Transfers.transferPreviewData,
    backupPTransferPreviewData: state.Transfers.backupPTransferPreviewData,
    transferPreviewFile: state.Transfers.transferPreviewFile,
    userCompanies: state.Company.companies,
})

const mapDispatchToProps = {
    hideTransferPreviewWindow: TransfersActions.hideTransferPreviewWindow,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,

    makeEditedTransferBackup: TransfersActions.makeEditedTransferBackup,
    cancelTransferEdit: TransfersActions.cancelTransferEdit,
    etidTransferField: TransfersActions.etidTransferField,
    removePreviewFile: TransfersActions.removePreviewFile,
    setTransferPreviewFile: TransfersActions.setTransferPreviewFile,
    deleteTransfer: TransfersActions.deleteTransfer,
    saveTransferChanges: TransfersActions.saveTransferChanges,
    tooglePaymentForTransfer: ReportsActions.tooglePaymentForTransfer,
    toogleAccountmentStatusForTransfer: ReportsActions.toogleAccountmentStatusForTransfer,
    editPaymentConfirmation: TransfersActions.editPaymentConfirmation,

    showDuplicatePaymentOrderWindow: TransfersActions.showDuplicatePaymentOrderWindow,

    openNewTab: TabsControlerActions.openNewTab,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferPreview)