import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import { reverse } from 'named-urls'
import routes from '../helpers/routes'
import { emailRegex } from '../helpers/regexList'
import PropTypes from 'prop-types'
import Turnstile from "react-turnstile";
import LanguageSwitcher from '../components/LanguageSwitcher'

import Logo from '../img/easy-docs-logo-big.svg'

export class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            passwordVisible: false,
            captcha: false,
            captchaNotCheckedError: false,
            captchaExpiredError: false,
            easyGuardCode: '',
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        //ReactGA.pageview(window.location.pathname + window.location.search)
        var paramsString = this.props.location.search.replace('?', '').split('&')
        var email, token, tokenType
        if (paramsString.length > 1) {
            paramsString.map((parameter, index) => {
                switch (parameter.split('=')[0]) {
                    case "email":
                        email = parameter.split('=')[1]
                        break
                    case "token":
                        token = parameter.split('=')[1]
                        break
                    case "type":
                        tokenType = parameter.split('=')[1]
                        break
                    default:
                        break
                }
            })
            if (tokenType === "emailVerification") {
                this.confirmEmail(email, token, tokenType)
            } else if (tokenType === "adminFirstLogin") {
                this.adminFirstLogin(email)
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.props.showEasyGuardPopup) {
                this.props.hideLoginEasyGuardPopup()
                this.setState({
                    easyGuardCode: ''
                })
            }
        }
    }

    confirmEmail = (email, token, tokenType) => {
        this.props.confirmEmail(email, token, tokenType)
    }

    adminFirstLogin = (email) => {
        if (email.length === 0 || !emailRegex.test(email)) {
            this.props.alertWarn(this.context.t('There was an error. Please contact EasyDocs support team.'))
        } else {
            this.props.login(email, "first" + email.split('@'[0]) + "adminPass")
        }
    }

    onInputChange = e => {
        e.preventDefault()

        console.log("on change triggered")

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    captchaChange = token => {
        console.log('captcha token: ' + token)
        if (token) {
            this.setState({
                captcha: true,
                captchaToken: token,
                captchaNotCheckedError: false,
            })
        } else {
            this.setState({
                captcha: false,
            })
        }
    }

    captchaExpire = () => {
        console.log('captcha expired')
        this.setState({
            captcha: false,
            captchaExpiredError: true
        })
    }

    LoginSubmit = e => {
        e.preventDefault()

        let valid = true

        const { email, password, captcha, easyGuardCode, captchaToken, sendLoginReq } = this.state

        if (!sendLoginReq) {
            if (process.env.REACT_APP_ENVIRONMENT !== "development") {

                if (!captcha && !this.props.showEasyGuardPopup) {
                    valid = false
                    this.setState({
                        captchaNotCheckedError: true
                    })
                }
            } 

            if (email.length === 0) {
                valid = false
                this.props.alertWarn(this.context.t('E-mail address is required'))
            }
            else if (!emailRegex.test(email)) {
                valid = false
                this.props.alertWarn(this.context.t('E-mail address is not valid'))
            }
            if (password.length === 0) {
                valid = false
                this.props.alertWarn(this.context.t('Password is required'))
            }

            if (easyGuardCode && easyGuardCode.length !== 6) {
                valid = false
                this.props.alertWarn(this.context.t('The code must be exactly six digits long'))
            } else if (easyGuardCode && /[^0-9]/.test(easyGuardCode)) {
                valid = false
                this.props.alertWarn(this.context.t('The code can only contain numbers'))
            }

            if (valid) {
                this.setState({
                    sendLoginReq: true
                })
                this.props.login(email.toLowerCase(), password, easyGuardCode, captchaToken, () => {
                    this.setState({
                        sendLoginReq: false
                    })
                })
            }
        }
    }

    changeVisibility = () => {
        if (this.state.passwordVisible === false) {
            this.refs.password.type = 'text'
            this.setState({
                passwordVisible: true
            })
        } else {
            this.refs.password.type = 'password'
            this.setState({
                passwordVisible: false
            })
        }
    }

    sendEasyGuardDeviceResetEmail = () => {
        this.setState({
            waitingForEasyGuardDeviceChange: true
        })
        this.props.sendEasyGuardDeviceResetEmail(this.state.email, () => {
            this.setState({
                waitingForEasyGuardDeviceChange: false
            })
        })
    }

    render() {
        const { email, password, passwordVisible, captchaNotCheckedError, captchaExpiredError, easyGuardCode, sendLoginReq, waitingForEasyGuardDeviceChange } = this.state
        const { user, showEasyGuardPopup } = this.props
       
        if (user !== null && this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname && this.props.location.state.from.pathname !== '/' && !this.props.location.state.from.pathname.includes('/ocr/') && !this.props.location.state.from.pathname.includes('/activate-account/')) {
            console.log(this.props.location.state.from.pathname)
            return <Redirect to={this.props.location.state.from.pathname} />
        } else if (user !== null) {
            return <Redirect to={routes.panel.dashboard} />
        }


        return (
            <div className="login-container">
                <LanguageSwitcher />
                {
                    showEasyGuardPopup ? (
                        <div className="popup">
                            <div className="popup-card password-modal easy-guard">
                                <div className="header">{this.context.t('Enter the EasyGuard code from the EasyDocs mobile app')}</div>
                                <form onSubmit={e => this.LoginSubmit(e)} autoComplete="off">
                                    <div className="form-group">
                                        <input type="text" autoFocus name="easyGuardCode" id="easyGuardCode" ref="easyGuardCode" value={easyGuardCode} onChange={this.onInputChange} placeholder="" pattern="[0-9]*" maxlength="6" />
                                        <label htmlFor="easyGuardCode">{this.context.t('EasyGuard Code')}</label>
                                    </div>
                                </form>
                                <div className="action-buttons">
                                    <button className={`accept-button btn ${sendLoginReq ? 'loading' : ''}`} onClick={e => this.LoginSubmit(e)}>{this.context.t('Confirm')}</button>
                                    <button className={`close-button btn ${waitingForEasyGuardDeviceChange ? 'loading' : ''}`} onClick={e => this.sendEasyGuardDeviceResetEmail()}>{this.context.t("I've lost access to EasyGuard")}</button>
                                    <button className="close-button btn" onClick={() => { this.props.hideLoginEasyGuardPopup(); this.setState({ easyGuardCode: '' }) }}>{this.context.t('Cancel')}</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'} />
                        <img src={Logo} alt="EasyDocs Logo" />
                    </div>
                    <div className="go-back" onClick={() => this.props.history.goBack()}>{this.context.t('Go back')}</div>
                </div>
                <div className="form-col">
                    <form onSubmit={this.LoginSubmit}>
                        <h1>{this.context.t('Login')}</h1>
                        <div className="form-group">
                            <input type="email" className={`${email.length ? 'has-content' : ''}`} name="email" id="email" value={email} onChange={this.onInputChange} placeholder="" />
                            <label htmlFor="email">{this.context.t('E-mail address')}</label>
                        </div>
                        <div className="form-group">
                            <input type="password" className={`${password.length ? 'has-content' : ''}`} name="password" id="password" value={password} onChange={this.onInputChange} placeholder="" ref="password" />
                            <label htmlFor="password">{this.context.t('Password')}</label>
                            <span className="focus-border"></span>
                            <span className={`make-visible ${passwordVisible ? 'view' : 'hide'}`} onClick={() => this.changeVisibility()}></span>
                        </div>

                        <div className="forgot-password">
                            <Link to={routes.resetPassword}>{this.context.t('Forgot password?')}</Link>
                        </div>

                        {process.env.REACT_APP_ENVIRONMENT !== "development" ? <div className="recaptcha-container">
                            <Turnstile
                                sitekey='0x4AAAAAAAGVLfL6qA6JrZ5p'
                                language={localStorage.getItem('language') || 'pl'}
                                theme="light"
                                onVerify={(token) => this.captchaChange(token)}
                            />
                        </div> : ''}
                        

                        {/* <div className="recaptcha-container">
                            <ReCAPTCHA
                                ref="loginReCaptchaRef"
                                size="normal"
                                sitekey="6LceWAEVAAAAAK6LgYLnLuisqGWH9U-XEm4ACBdd"
                                onChange={token => this.captchaChange(token)}
                                onExpired={() => this.captchaExpire()}
                            />
                            {
                                captchaNotCheckedError ? (
                                    <div className={`captcha-error-container ${captchaExpiredError ? 'expired' : ''}`}>
                                        {this.context.t('Please confirm you are not a robot')}
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div> */}
                        <input type="submit" className={`${sendLoginReq ? 'loading' : ''}`} value={this.context.t(' Login ')} />
                    </form>

                    <div className="have-account">
                        <p>{this.context.t('Your company doesn\'t use EasyDocs yet?')}</p>
                        <p>{this.context.t('Try it out!')} <Link className="link" to={"/register/try-app"}>{this.context.t('Register')}</Link></p>
                    </div>

                    {/* <div onClick={() => this.props.changeLanguage("pl")}>
                        polski
                    </div>
                    <div onClick={() => this.props.changeLanguage("en")}>
                        angielski
                    </div> */}
                    <div className="links">
                        <a href="https://easydocs.pl/polityka-prywatnosci/" target="_blank">{this.context.t('Privacy Policy ')}</a>
                        <a href="https://easydocs.pl/regulamin/" target="_blank">{this.context.t('Terms of Service ')}</a>
                        <a href="https://easydocs.pl/kontakt/" target="_blank">{this.context.t('Contact Data')}</a>
                    </div>
                </div>
                <div className="img-col">
                    <div className="text">
                        {this.context.t('Easy and convenient way to manage documents')}
                    </div>
                </div>
            </div>
        )
    }
}

Login.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    showEasyGuardPopup: state.User.showEasyGuardPopup
})

const mapDispatchToProps = {
    login: UserActions.login,
    sendEasyGuardDeviceResetEmail: UserActions.sendEasyGuardDeviceResetEmail,
    hideLoginEasyGuardPopup: UserActions.hideLoginEasyGuardPopup,
    confirmEmail: UserActions.confirmEmail,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)