export const splitAddress = (address) => {
  const splitAddress = {
    zipCode: "",
    city: "",
    address: address,
  };
  if (/, [0-9]{2}-[0-9]{3}/.test(address)) {
    splitAddress.address = address.split(",")[0];
    splitAddress.zipCode = address.match(/[0-9]{2}-[0-9]{3}/)
      ? address.match(/[0-9]{2}-[0-9]{3}/)[0]
      : "";
    splitAddress.city = address
      .replace(splitAddress.address + ", " + splitAddress.zipCode, "")
      .trim();
  }
  return splitAddress;
};
