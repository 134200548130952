import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import isIterable from "../../helpers/isIterable"
import availableQuickActions from '../../helpers/availableQuickActions'

import QuickActionPopupHeader from './QuickActionPopupHeader'
import FilesPreviewInReports from '../ReportsPageComponents/FilesPreviewInReports'

import { FileActions } from '../../actions/FileActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { AlertActions } from '../../actions/AlertActions'

import '../../css/QuickActions.css'
import '../../css/Report.css'
import { Spinner } from 'react-activity'

export class DocsRequiringAttentionAssistant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewFile: {},
            docsListForPreview: [],
            areDropzoneDocsCollapsed: false,
            areAdditionalPaymentAcceptanceDocsCollapsed: false,
            areUpcomingPaymentsCollapsed: false,
            areMentionedUnreadConversationsCollapsed: false,
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
    }

    fileIconClick = (projectId, filePath, fileId, docType, filesList) => {
        console.log(projectId, filePath, fileId, docType, filesList)
        this.props.toogleFilePreviewInActions(true)
        this.setState({
            previewFile: {
                projectId: projectId,
                file: filePath,
                id: fileId,
                docType: docType
            },
            docsListForPreview: filesList
        })
        if (docType !== 'PAYMENT_ORDER') {
            this.props.getFileBody(parseInt(projectId), filePath, fileId, 'IN_PROGRESS', 3)
        } else {
            const { transfers } = this.props
            var projectTransfers = transfers.find(e => e.project === projectId)
            if (projectTransfers && projectTransfers.transfers) {
                var transfer = projectTransfers.transfers.find(e => e.id === fileId)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                }
            } else {
                this.props.getPaymentOrders(projectId, -1, (loadedTransfers) => {
                    var transfer = loadedTransfers.find(e => e.id === fileId)
                    if (transfer) {
                        this.props.openTransferPreviewWindow(transfer)
                    }
                })
            }
        }
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: {},
            docsListForPreview: []
        })
    }

    collapseSection = (sectionName, isCollapsed) => {
        this.setState({
            [sectionName]: isCollapsed
        })
    }

    dropzoneFileClick = file => {
        const { userProjects, currentProject } = this.props
        if (currentProject.id !== file.projectId) {
            this.props.switchDashboardViewModel(file.projectId)
            var { userCompanies, currentCompany } = this.props
            const newProject = userProjects.find(p => p.id === file.projectId)
            var companyId = newProject?.company_id || -1


            if (companyId !== -1 && currentCompany && currentCompany.id !== companyId) {
                var companyIndex = userCompanies.findIndex(c => c.company_id === companyId)
                if (companyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[companyIndex])
                }
            }
        }
        this.props.toggleQuickAction(availableQuickActions.docsRequiringMyAttention, false)
    }

    render() {
        const {
            highlightTable,
            previewFile,
            docsListForPreview,
            areDropzoneDocsCollapsed, areAdditionalPaymentAcceptanceDocsCollapsed, areUpcomingPaymentsCollapsed, areMentionedUnreadConversationsCollapsed,
        } = this.state
        const {
            user, tabIsActive,
            waitingForDocsRequiringAttention,
            dropzoneDocs, additionalPaymentAcceptanceDocs, upcomingPayments, mentionedUnreadConversaons,
        } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className={`quic-actions-popup reports ${user.new_easydocs_version ? 'new-easydocs-version' : ''}`}>

                    {
                        previewFile && previewFile.id ? (
                            <FilesPreviewInReports
                                docsList={docsListForPreview}
                                location={{
                                    pathname: '/actions/preview/'
                                }}
                                previewFile={previewFile}
                                close={this.hideFilePreview}
                                setCurrentDoc={this.setCurrentDocPreview}
                                checkIfDocMatchesFilterOptions={() => true}
                            />
                        ) : (
                            null
                        )
                    }

                    <div className={`content docs-requiring-attention`}>
                        <QuickActionPopupHeader
                            title={"Documents requiring your attention"}
                            type={"docsRequiringAttentionAssistane"}
                            close={() => this.props.toggleQuickAction(availableQuickActions.docsRequiringMyAttention, false)}
                            getDocs={() => { }}
                        />

                        {
                            waitingForDocsRequiringAttention ? (
                                <div className="loading-indicator">
                                    <Spinner size={50} speed={0.8} color={"#69B792"} />
                                </div>
                            ) : (
                                <div className="columns-wrapper">
                                    <SimpleBar style={{ flex: '0 0 calc(50% - 20px)', height: 'calc(95vh - 200px)', paddingRight: '10px', marginRight: '-10px' }}>
                                        <div className="attention-docs-section">
                                            <h3 onClick={() => this.collapseSection('areDropzoneDocsCollapsed', !areDropzoneDocsCollapsed)}>
                                                <span className="section-icon dropzone-docs"></span>
                                                <span className="text">{this.context.t('Dropzone and docs to verify')}</span>

                                                <div className={`collapse-icon ${areDropzoneDocsCollapsed ? 'collapsed' : ''}`}></div>
                                            </h3>
                                            {
                                                !areDropzoneDocsCollapsed ? (
                                                    dropzoneDocs.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                dropzoneDocs.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`} onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('dropzoneDocs', groupIndex)}>
                                                                                {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                <div className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span className="file-name">{this.context.t('File name')}</span>
                                                                                            <span className="date">{this.context.t('Upload date')}</span>
                                                                                            <span className="uploaded-by">{this.context.t('Uploaded by')}</span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.dropzoneFileClick(doc)}
                                                                                                        className={`doc-row dropzone-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.fileName}</span>
                                                                                                        <span className="date">{doc.uploadDate}</span>
                                                                                                        <span className="uploaded-by">{doc.uploadedBy}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                        <div className="attention-docs-section">
                                            <h3 onClick={() => this.collapseSection('areMentionedUnreadConversationsCollapsed', !areMentionedUnreadConversationsCollapsed)}>
                                                <span className="section-icon chat-mentions"></span>
                                                <span className="text">{this.context.t('Unread conversations you\'re tagged in')}</span>
                                                <div className={`collapse-icon ${areMentionedUnreadConversationsCollapsed ? 'collapsed' : ''}`}></div>
                                            </h3>
                                            {
                                                !areMentionedUnreadConversationsCollapsed ? (
                                                    mentionedUnreadConversaons.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                mentionedUnreadConversaons.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`} onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('mentionedUnreadConversaons', groupIndex)}>
                                                                                {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                <div className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span className="file-name">{this.context.t('File name')}</span>
                                                                                            <span className="date">{this.context.t('Due date')}</span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, 'DOC', projectGroup.docs)}
                                                                                                        className={`doc-row acceptance-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.receiver || doc.fileName}</span>
                                                                                                        <span className="date">{doc.lastMessageDate}</span>
                                                                                                        <span className="message"><strong>{doc.lastMessageAuthor}</strong>: {doc.lastMessage}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                    </SimpleBar>
                                    <SimpleBar style={{ flex: '0 0 calc(50% - 20px)', height: 'calc(95vh - 200px)', paddingRight: '10px', marginRight: '-10px' }}>
                                        <div className="attention-docs-section">
                                            <h3 onClick={() => this.collapseSection('areAdditionalPaymentAcceptanceDocsCollapsed', !areAdditionalPaymentAcceptanceDocsCollapsed)}>
                                                <span className="section-icon additional-acceptance"></span>
                                                <span className="text">{this.context.t('Documents requiring additional approval for payment')}</span>
                                                <div className={`collapse-icon ${areAdditionalPaymentAcceptanceDocsCollapsed ? 'collapsed' : ''}`}></div>
                                            </h3>
                                            {
                                                !areAdditionalPaymentAcceptanceDocsCollapsed ? (
                                                    additionalPaymentAcceptanceDocs.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                additionalPaymentAcceptanceDocs.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`} onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('additionalPaymentAcceptanceDocs', groupIndex)}>
                                                                                {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                <div className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span className="file-name">{this.context.t('Counterparty')}</span>
                                                                                            <span className="date">{this.context.t('Due date')}</span>
                                                                                            <span className="amount-to-pay">{this.context.t('Amount to pay')}</span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, 'DOC', projectGroup.docs)}
                                                                                                        className={`doc-row acceptance-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.recipient || doc.fileName}</span>
                                                                                                        <span className="date">{doc.dueDate}</span>
                                                                                                        <span className="amount-to-pay">{doc.amountToPay}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                        <div className="attention-docs-section">
                                            <h3 onClick={() => this.collapseSection('areUpcomingPaymentsCollapsed', !areUpcomingPaymentsCollapsed)}>
                                                <span className="section-icon upcomeing-payments"></span>
                                                <span className="text">{this.context.t('Documents to be paid')}</span>
                                                <div className={`collapse-icon ${areUpcomingPaymentsCollapsed ? 'collapsed' : ''}`}></div>
                                            </h3>
                                            {
                                                !areUpcomingPaymentsCollapsed ? (
                                                    upcomingPayments.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                upcomingPayments.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`} onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('upcomingPayments', groupIndex)}>
                                                                                {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                <div className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span className="file-name">{this.context.t('Counterparty')}</span>
                                                                                            <span className="date">{this.context.t('Due date')}</span>
                                                                                            <span className="amount-to-pay">{this.context.t('Amount to pay')}</span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, doc.type, projectGroup.docs)}
                                                                                                        className={`doc-row acceptance-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.receiver || doc.fileName}</span>
                                                                                                        <span className="date">{doc.dueDate}</span>
                                                                                                        <span className="amount-to-pay">{`${doc.amountToPay} ${doc.currency}`}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                    </SimpleBar>
                                </div>
                            )
                        }

                    </div>
                </div >
            )
        } else {
            return null
        }
    }
}

DocsRequiringAttentionAssistant.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    waitingForDocsRequiringAttention: state.QuickActions.waitingForDocsRequiringAttention,
    dropzoneDocs: isIterable(state.QuickActions.dropzoneDocs) ? [...state.QuickActions.dropzoneDocs] : [],
    additionalPaymentAcceptanceDocs: isIterable(state.QuickActions.additionalPaymentAcceptanceDocs) ? [...state.QuickActions.additionalPaymentAcceptanceDocs] : [],
    upcomingPayments: isIterable(state.QuickActions.upcomingPayments) ? [...state.QuickActions.upcomingPayments] : [],
    mentionedUnreadConversaons: isIterable(state.QuickActions.mentionedUnreadConversaons) ? [...state.QuickActions.mentionedUnreadConversaons] : [],

    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,

    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
})

const mapDispatchToProps = {
    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    getPaymentOrders: TransfersActions.getAllMyTransfers,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,

    toggleQuickAction: UserActions.toggleQuickAction,
    collapseDocsRequiringAttentionsProjectGroup: UserActions.collapseDocsRequiringAttentionsProjectGroup,

    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocsRequiringAttentionAssistant)