import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import isIterable from "../../helpers/isIterable"
import availableQuickActions from '../../helpers/availableQuickActions'

import QuickActionPopupHeader from './QuickActionPopupHeader'
import FilesPreviewInReports from '../ReportsPageComponents/FilesPreviewInReports'

import { FileActions } from '../../actions/FileActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { AlertActions } from '../../actions/AlertActions'

import '../../css/QuickActions.css'
import '../../css/Report.css'
import { Spinner } from 'react-activity'

export class DocsRequiringAttentionAssistant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewFile: {},
            docsListForPreview: [],
            areDropzoneDocsCollapsed: false,
            areAdditionalPaymentAcceptanceDocsCollapsed: false,
            areUpcomingPaymentsCollapsed: false,
            areMentionedUnreadConversationsCollapsed: false,
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        window.addEventListener("keydown", this.keyDownHandler, false)
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDownHandler, false)
    }

    keyDownHandler = e => {
        if (e.keyCode === 27) {
            //ESC
            const { showSectionSettings } = this.state
            if (showSectionSettings) {
                this.setState({
                    showSectionSettings: false
                })
            }
        }
    }

    fileIconClick = (projectId, filePath, fileId, docType, filesList) => {
        console.log(projectId, filePath, fileId, docType, filesList)
        this.props.toogleFilePreviewInActions(true)
        this.setState({
            previewFile: {
                projectId: projectId,
                file: filePath,
                id: fileId,
                docType: docType
            },
            docsListForPreview: filesList
        })
        if (docType !== 'PAYMENT_ORDER') {
            this.props.getFileBody(parseInt(projectId), filePath, fileId, 'IN_PROGRESS', 3)
        } else {
            const { transfers } = this.props
            var projectTransfers = transfers.find(e => e.project === projectId)
            if (projectTransfers && projectTransfers.transfers) {
                var transfer = projectTransfers.transfers.find(e => e.id === fileId)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                }
            } else {
                this.props.getPaymentOrders(projectId, -1, (loadedTransfers) => {
                    var transfer = loadedTransfers.find(e => e.id === fileId)
                    if (transfer) {
                        this.props.openTransferPreviewWindow(transfer)
                    }
                })
            }
        }
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: {},
            docsListForPreview: []
        })
    }

    collapseSection = (sectionName, isCollapsed) => {
        this.setState({
            [sectionName]: isCollapsed
        })
    }

    dropzoneFileClick = file => {
        const { userProjects, currentProject } = this.props
        if (currentProject.id !== file.projectId) {
            this.props.switchDashboardViewModel(file.projectId)
            var { userCompanies, currentCompany } = this.props
            const newProject = userProjects.find(p => p.id === file.projectId)
            var companyId = newProject?.company_id || -1


            if (companyId !== -1 && currentCompany && currentCompany.id !== companyId) {
                var companyIndex = userCompanies.findIndex(c => c.company_id === companyId)
                if (companyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[companyIndex])
                }
            }
        }
        this.props.toggleQuickAction(availableQuickActions.docsRequiringMyAttention, false)
    }

    openSectionSettings = settingsType => {
        this.setState({
            showSectionSettings: true,
            settingsType: settingsType
        })
    }

    closeSectionSettings = () => {
        this.props.getDocsRequiringMyAttention()
        this.setState({
            showSectionSettings: false,
            settingsType: ''
        })
    }

    getSectionSettingsTitle = () => {
        const { settingsType } = this.state
        var text = ''
        if (settingsType === "notify_about_dropzone_docs") text = 'Dropzone and docs to verify'
        if (settingsType === "notify_about_additional_acceptance") text = 'Documents requiring additional approval for payment'
        if (settingsType === "notify_about_payments") text = 'Documents to be paid'
        if (settingsType === "notify_about_chat_mentions") text = 'Unread conversations you\'re tagged in'

        return this.context.t(text)
    }

    getCheckStateForAllProjects = () => {
        const { settingsType } = this.state
        const { userProjects } = this.props

        var oneSelected = false
        userProjects.forEach(up => {
            if (up[settingsType]) oneSelected = true
        })

        return oneSelected
    }

    toggleNotificationForAllProjects = () => {
        const { settingsType } = this.state
        const { userProjects } = this.props

        var oneSelected = false
        userProjects.forEach(up => {
            if (up[settingsType]) oneSelected = true
        })

        userProjects.forEach(up => {
            this.props.toggleNotificationSettingForProject(up.id, settingsType, !oneSelected)
        })
    }

    getDueDateCellClass = doc => {
        if (!doc.dueDate) return ''
        if (doc.dueDate <= moment().format('YYYY-MM-DD') || doc.priority) return 'overdue'
        if (doc.dueDate < moment().add(3, 'day').format('YYYY-MM-DD') && doc.dueDate >= moment().add(1, 'day').format('YYYY-MM-DD')) return 'upcoming'
    }

    getSortClass = (checkField, sortField, sortOrder) => {
        if (sortField === checkField) {
            if (sortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }

    render() {
        const {
            highlightTable,
            previewFile,
            docsListForPreview,
            areDropzoneDocsCollapsed, areAdditionalPaymentAcceptanceDocsCollapsed, areUpcomingPaymentsCollapsed, areMentionedUnreadConversationsCollapsed,
            showSectionSettings, settingsType
        } = this.state
        const {
            user, tabIsActive,
            userProjects,
            waitingForDocsRequiringAttention,
            dropzoneDocs, additionalPaymentAcceptanceDocs, upcomingPayments, mentionedUnreadConversaons,
        } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className={`quic-actions-popup reports ${user.new_easydocs_version ? 'new-easydocs-version' : ''} ${showSectionSettings ? 'waiting-for-actions' : ''}`}>

                    {
                        previewFile && previewFile.id ? (
                            <FilesPreviewInReports
                                docsList={docsListForPreview}
                                location={{
                                    pathname: '/actions/preview/'
                                }}
                                previewFile={previewFile}
                                close={this.hideFilePreview}
                                setCurrentDoc={this.setCurrentDocPreview}
                                checkIfDocMatchesFilterOptions={() => true}
                            />
                        ) : (
                            null
                        )
                    }

                    {
                        showSectionSettings ? (
                            <div className="section-settings-window">
                                <div className="settings-wrapper">
                                    <h4>{this.getSectionSettingsTitle()}</h4>
                                    <h6>{this.context.t('Select the binders you would like to see in this section of documents requiring your attention')}:</h6>
                                    <div className="project-setting all" onClick={() => this.toggleNotificationForAllProjects()}>
                                        {this.getCheckStateForAllProjects() ? this.context.t('Unselect all') : this.context.t('Select all ')}
                                        <div
                                            className={`checkbox ${this.getCheckStateForAllProjects() ? 'checked' : ''}`}
                                        ></div>
                                    </div>
                                    <SimpleBar style={{ maxHeight: '50vh', paddingRight: '10px', marginRight: '-10px' }}>
                                        {
                                            userProjects.map(up => {
                                                return (
                                                    <div className="project-setting" onClick={() => this.props.toggleNotificationSettingForProject(up.id, settingsType, !up[settingsType])}>
                                                        {up.code}
                                                        <div
                                                            className={`checkbox ${up[settingsType] ? 'checked' : ''}`}
                                                        ></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </SimpleBar>
                                    <div className="button" onClick={() => this.closeSectionSettings()}>{this.context.t('Ready')}</div>
                                </div>
                            </div>
                        ) : null
                    }

                    <div className={`content docs-requiring-attention`}>
                        <QuickActionPopupHeader
                            title={"Documents requiring your attention"}
                            type={"docsRequiringAttentionAssistane"}
                            close={() => this.props.toggleQuickAction(availableQuickActions.docsRequiringMyAttention, false)}
                            getDocs={() => { }}
                        />

                        {
                            waitingForDocsRequiringAttention ? (
                                <div className="loading-indicator">
                                    <Spinner size={50} speed={0.8} color={"#69B792"} />
                                </div>
                            ) : (
                                <div className="columns-wrapper">
                                    <SimpleBar style={{ flex: '0 0 calc(50% - 20px)', height: 'calc(95vh - 200px)', paddingRight: '10px', marginRight: '-10px' }}>
                                        <div className="attention-docs-section">
                                            <h3>
                                                <span
                                                    className="section-icon dropzone-docs"
                                                    onClick={() => this.collapseSection('areDropzoneDocsCollapsed', !areDropzoneDocsCollapsed)}
                                                ></span>
                                                <span
                                                    className="text"
                                                    onClick={() => this.collapseSection('areDropzoneDocsCollapsed', !areDropzoneDocsCollapsed)}>
                                                    {this.context.t('Dropzone and docs to verify')}
                                                </span>
                                                <div className="section-settings" onClick={() => this.openSectionSettings('notify_about_dropzone_docs')}></div>
                                                <div
                                                    className={`collapse-icon ${areDropzoneDocsCollapsed ? 'collapsed' : ''}`}
                                                    onClick={() => this.collapseSection('areDropzoneDocsCollapsed', !areDropzoneDocsCollapsed)}
                                                ></div>
                                            </h3>
                                            {
                                                !areDropzoneDocsCollapsed ? (
                                                    dropzoneDocs.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                dropzoneDocs.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`}>
                                                                                <span
                                                                                    className={`favourite ${projectGroup.favourite ? '' : 'empty'}`}
                                                                                    onClick={() => this.props.toggleFavouriteForProject(projectGroup.project_id, !projectGroup.favourite, 'dropzoneDocs')}
                                                                                ></span>
                                                                                <div
                                                                                    className="text"
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('dropzoneDocs', groupIndex)}
                                                                                >
                                                                                    {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                </div>
                                                                                <div
                                                                                    className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('dropzoneDocs', groupIndex)}
                                                                                ></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span
                                                                                                className="file-name"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('fileName', projectGroup.project_id, 'dropzoneDocs')}
                                                                                            >
                                                                                                {this.context.t('File name')}
                                                                                                <span
                                                                                                    className={this.getSortClass('fileName', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="date"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('uploadDate', projectGroup.project_id, 'dropzoneDocs')}
                                                                                            >
                                                                                                {this.context.t('Upload date')}
                                                                                                <span
                                                                                                    className={this.getSortClass('uploadDate', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="uploaded-by"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('uploadedBy', projectGroup.project_id, 'dropzoneDocs')}
                                                                                            >
                                                                                                {this.context.t('Uploaded by')}
                                                                                                <span
                                                                                                    className={this.getSortClass('uploadedBy', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.dropzoneFileClick(doc)}
                                                                                                        className={`doc-row dropzone-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.fileName}</span>
                                                                                                        <span className="date">{doc.uploadDate}</span>
                                                                                                        <span className="uploaded-by">{doc.uploadedBy}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                        <div className="attention-docs-section">
                                            <h3>
                                                <span
                                                    className="section-icon chat-mentions"
                                                    onClick={() => this.collapseSection('areMentionedUnreadConversationsCollapsed', !areMentionedUnreadConversationsCollapsed)}
                                                ></span>
                                                <span
                                                    className="text"
                                                    onClick={() => this.collapseSection('areMentionedUnreadConversationsCollapsed', !areMentionedUnreadConversationsCollapsed)}
                                                >
                                                    {this.context.t('Unread conversations you\'re tagged in')}
                                                </span>
                                                <div
                                                    className="section-settings"
                                                    onClick={() => this.openSectionSettings('notify_about_chat_mentions')}
                                                ></div>
                                                <div
                                                    className={`collapse-icon ${areMentionedUnreadConversationsCollapsed ? 'collapsed' : ''}`}
                                                    onClick={() => this.collapseSection('areMentionedUnreadConversationsCollapsed', !areMentionedUnreadConversationsCollapsed)}
                                                ></div>
                                            </h3>
                                            {
                                                !areMentionedUnreadConversationsCollapsed ? (
                                                    mentionedUnreadConversaons.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                mentionedUnreadConversaons.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`}>
                                                                                <span
                                                                                    className={`favourite ${projectGroup.favourite ? '' : 'empty'}`}
                                                                                    onClick={() => this.props.toggleFavouriteForProject(projectGroup.project_id, !projectGroup.favourite, 'mentionedUnreadConversaons')}
                                                                                ></span>
                                                                                <div
                                                                                    className="text"
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('mentionedUnreadConversaons', groupIndex)}
                                                                                >
                                                                                    {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                </div>
                                                                                <div
                                                                                    className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('mentionedUnreadConversaons', groupIndex)}
                                                                                ></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span
                                                                                                className="file-name"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('fileName', projectGroup.project_id, 'mentionedUnreadConversaons')}
                                                                                            >
                                                                                                {this.context.t('File name')}
                                                                                                <span
                                                                                                    className={this.getSortClass('fileName', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="date"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('lastMessageDate', projectGroup.project_id, 'mentionedUnreadConversaons')}
                                                                                            >
                                                                                                {this.context.t('Date')}
                                                                                                <span
                                                                                                    className={this.getSortClass('lastMessageDate', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, 'DOC', projectGroup.docs)}
                                                                                                        className={`doc-row acceptance-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.fileName}</span>
                                                                                                        <span className="date">{doc.lastMessageDate}</span>
                                                                                                        <span className="message"><strong>{doc.lastMessageAuthor}</strong>: {doc.lastMessage}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                    </SimpleBar>
                                    <SimpleBar style={{ flex: '0 0 calc(50% - 20px)', height: 'calc(95vh - 200px)', paddingRight: '10px', marginRight: '-10px' }}>
                                        <div className="attention-docs-section">
                                            <h3>
                                                <span
                                                    className="section-icon additional-acceptance"
                                                    onClick={() => this.collapseSection('areAdditionalPaymentAcceptanceDocsCollapsed', !areAdditionalPaymentAcceptanceDocsCollapsed)}
                                                ></span>
                                                <span
                                                    className="text"
                                                    onClick={() => this.collapseSection('areAdditionalPaymentAcceptanceDocsCollapsed', !areAdditionalPaymentAcceptanceDocsCollapsed)}
                                                >
                                                    {this.context.t('Documents requiring additional approval for payment')}
                                                </span>
                                                <div
                                                    className="section-settings"
                                                    onClick={() => this.openSectionSettings('notify_about_additional_acceptance')}
                                                ></div>
                                                <div
                                                    className={`collapse-icon ${areAdditionalPaymentAcceptanceDocsCollapsed ? 'collapsed' : ''}`}
                                                    onClick={() => this.collapseSection('areAdditionalPaymentAcceptanceDocsCollapsed', !areAdditionalPaymentAcceptanceDocsCollapsed)}
                                                ></div>
                                            </h3>
                                            {
                                                !areAdditionalPaymentAcceptanceDocsCollapsed ? (
                                                    additionalPaymentAcceptanceDocs.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                additionalPaymentAcceptanceDocs.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`}>
                                                                                <span
                                                                                    className={`favourite ${projectGroup.favourite ? '' : 'empty'}`}
                                                                                    onClick={() => this.props.toggleFavouriteForProject(projectGroup.project_id, !projectGroup.favourite, 'additionalPaymentAcceptanceDocs')}
                                                                                ></span>
                                                                                <div
                                                                                    className="text"
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('additionalPaymentAcceptanceDocs', groupIndex)}
                                                                                >
                                                                                    {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                </div>
                                                                                <div
                                                                                    className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('additionalPaymentAcceptanceDocs', groupIndex)}
                                                                                ></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span
                                                                                                className="file-name"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('recipient', projectGroup.project_id, 'additionalPaymentAcceptanceDocs')}
                                                                                            >
                                                                                                {this.context.t('Counterparty')}
                                                                                                <span
                                                                                                    className={this.getSortClass('recipient', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="date"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('docNumber', projectGroup.project_id, 'additionalPaymentAcceptanceDocs')}
                                                                                            >
                                                                                                {this.context.t('Due date')}
                                                                                                <span
                                                                                                    className={this.getSortClass('docNumber', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="doc-number"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('dueDate', projectGroup.project_id, 'additionalPaymentAcceptanceDocs')}
                                                                                            >
                                                                                                {this.context.t('Doc number')}
                                                                                                <span
                                                                                                    className={this.getSortClass('dueDate', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="amount-to-pay"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('amountToPay', projectGroup.project_id, 'additionalPaymentAcceptanceDocs')}
                                                                                            >
                                                                                                {this.context.t('Amount to pay')}
                                                                                                <span
                                                                                                    className={this.getSortClass('amountToPay', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, 'DOC', projectGroup.docs)}
                                                                                                        className={`doc-row acceptance-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.recipient || doc.fileName}</span>
                                                                                                        <span className="doc-number">{doc.docNumber}</span>
                                                                                                        <span className={`date ${this.getDueDateCellClass(doc)}`}>{doc.dueDate}</span>
                                                                                                        <span className="amount-to-pay">{doc.amountToPay}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                        <div className="attention-docs-section">
                                            <h3>
                                                <span
                                                    className="section-icon upcomeing-payments"
                                                    onClick={() => this.collapseSection('areUpcomingPaymentsCollapsed', !areUpcomingPaymentsCollapsed)}
                                                ></span>
                                                <span
                                                    className="text"
                                                    onClick={() => this.collapseSection('areUpcomingPaymentsCollapsed', !areUpcomingPaymentsCollapsed)}
                                                >
                                                    {this.context.t('Documents to be paid')}
                                                </span>
                                                <div
                                                    className="section-settings"
                                                    onClick={() => this.openSectionSettings('notify_about_payments')}
                                                ></div>
                                                <div
                                                    className={`collapse-icon ${areUpcomingPaymentsCollapsed ? 'collapsed' : ''}`}
                                                    onClick={() => this.collapseSection('areUpcomingPaymentsCollapsed', !areUpcomingPaymentsCollapsed)}
                                                ></div>
                                            </h3>
                                            {
                                                !areUpcomingPaymentsCollapsed ? (
                                                    upcomingPayments.length ? (
                                                        <div className="docs-list">
                                                            {
                                                                upcomingPayments.map((projectGroup, groupIndex) => {
                                                                    return (
                                                                        <>
                                                                            <h4 className={`${projectGroup.areCollapsed ? 'collapsed' : ''}`}>
                                                                                <span
                                                                                    className={`favourite ${projectGroup.favourite ? '' : 'empty'}`}
                                                                                    onClick={() => this.props.toggleFavouriteForProject(projectGroup.project_id, !projectGroup.favourite, 'upcomingPayments')}
                                                                                ></span>
                                                                                <div
                                                                                    className="text"
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('upcomingPayments', groupIndex)}
                                                                                >
                                                                                    {`${projectGroup.code} (${projectGroup.docs?.length})`}
                                                                                </div>
                                                                                <div
                                                                                    className={`collapse-icon ${projectGroup.areCollapsed ? 'collapsed' : ''}`}
                                                                                    onClick={() => this.props.collapseDocsRequiringAttentionsProjectGroup('upcomingPayments', groupIndex)}
                                                                                ></div>
                                                                            </h4>
                                                                            {
                                                                                !projectGroup.areCollapsed ? (
                                                                                    <>
                                                                                        <div className="doc-row header dropzone-doc">
                                                                                            <span
                                                                                                className="file-name"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('receiver', projectGroup.project_id, 'upcomingPayments')}
                                                                                            >
                                                                                                {this.context.t('Counterparty')}
                                                                                                <span
                                                                                                    className={this.getSortClass('receiver', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="doc-number"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('docNumber', projectGroup.project_id, 'upcomingPayments')}
                                                                                            >
                                                                                                {this.context.t('Doc number')}
                                                                                                <span
                                                                                                    className={this.getSortClass('docNumber', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="date"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('dueDate', projectGroup.project_id, 'upcomingPayments')}
                                                                                            >
                                                                                                {this.context.t('Due date')}
                                                                                                <span
                                                                                                    className={this.getSortClass('dueDate', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                            <span
                                                                                                className="amount-to-pay"
                                                                                                onClick={() => this.props.setSortFieldForDocsRequiringAttention('amountToPay', projectGroup.project_id, 'upcomingPayments')}
                                                                                            >
                                                                                                {this.context.t('Amount to pay')}
                                                                                                <span
                                                                                                    className={this.getSortClass('amountToPay', projectGroup.sortField, projectGroup.sortOrder)}
                                                                                                ></span>
                                                                                            </span>
                                                                                        </div>
                                                                                        {
                                                                                            projectGroup.docs.map((doc, docIndex) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, doc.type, projectGroup.docs)}
                                                                                                        className={`doc-row acceptance-doc ${!projectGroup.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                                                    >
                                                                                                        <span className="file-name">{doc.receiver || doc.fileName}</span>
                                                                                                        <span className="doc-number">{doc.docNumber}</span>
                                                                                                        <span className={`date ${this.getDueDateCellClass(doc)}`}>{doc.dueDate}</span>
                                                                                                        <span className="amount-to-pay">{`${doc.amountToPay} ${doc.currency}`}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="no-docs">
                                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                                        </div>
                                                    )
                                                ) : null
                                            }
                                        </div>
                                    </SimpleBar>
                                </div>
                            )
                        }

                    </div>
                </div >
            )
        } else {
            return null
        }
    }
}

DocsRequiringAttentionAssistant.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    waitingForDocsRequiringAttention: state.QuickActions.waitingForDocsRequiringAttention,
    dropzoneDocs: isIterable(state.QuickActions.dropzoneDocs) ? [...state.QuickActions.dropzoneDocs] : [],
    additionalPaymentAcceptanceDocs: isIterable(state.QuickActions.additionalPaymentAcceptanceDocs) ? [...state.QuickActions.additionalPaymentAcceptanceDocs] : [],
    upcomingPayments: isIterable(state.QuickActions.upcomingPayments) ? [...state.QuickActions.upcomingPayments] : [],
    mentionedUnreadConversaons: isIterable(state.QuickActions.mentionedUnreadConversaons) ? [...state.QuickActions.mentionedUnreadConversaons] : [],

    currentProject: state.User.currentProject,
    userProjects: isIterable(state.User.userProjects) ? [...state.User.userProjects] : [],
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,

    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
})

const mapDispatchToProps = {
    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    getPaymentOrders: TransfersActions.getAllMyTransfers,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,

    toggleQuickAction: UserActions.toggleQuickAction,
    collapseDocsRequiringAttentionsProjectGroup: UserActions.collapseDocsRequiringAttentionsProjectGroup,
    setSortFieldForDocsRequiringAttention: UserActions.setSortFieldForDocsRequiringAttention,
    toggleNotificationSettingForProject: UserActions.toggleNotificationSettingForProject,
    getDocsRequiringMyAttention: UserActions.getDocsRequiringMyAttention,

    toggleFavouriteForProject: UserActions.toggleFavouriteForProject,
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocsRequiringAttentionAssistant)