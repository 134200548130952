import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dropzone from 'react-dropzone'
import TextareaAutosize from "react-textarea-autosize";

import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";

import validateEmail from "../../helpers/validateEmail";

export class SendEmailBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      customSubject: "",
      customAttachments: [],
      defaultSubject: "",
    };
  }

  componentDidMount() {
    const { invoiceData, currentCompany } = this.props
    var defaultSubject = "", savedEmails = ""
    if (invoiceData && invoiceData.doc_number && currentCompany && currentCompany.company) {
      if (invoiceData.currency === 'PLN') defaultSubject = `Dokument nr: ${invoiceData.doc_number} od firmy: ${currentCompany.company.name}`
      else defaultSubject = `Document: ${invoiceData.doc_number} from: ${currentCompany.company.name}`
    }

    if (invoiceData && invoiceData.counterparty && invoiceData.counterparty.email) {
      savedEmails = invoiceData.counterparty.email.join(', ')
    } else if (this.props.email) {
      savedEmails = this.props.email
    }


    this.setState({
      defaultSubject: defaultSubject,
      email: savedEmails
    })
  }

  onInputChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  splitEmails = (text) => {
    return text.replaceAll(";", ",").replaceAll(" ", "").split(",");
  };

  removeDuplicatesFromArray = (data) => {
    const noDuplicates = [];
    data.map((element) => {
      if (!noDuplicates.includes(element)) {
        noDuplicates.push(element);
      }
    });
    return noDuplicates;
  };

  concatenationMails = (arrayMail) => {
    let concatenatedMails = "";
    arrayMail.map((email, index) => {
      if (arrayMail.length === 1) concatenatedMails = `${email}`;
      if (arrayMail.length > 1) {
        if (index === 0) {
          concatenatedMails = `${email} ,`;
        } else if (index < arrayMail.length - 1) {
          concatenatedMails += `${email} ,`;
        }
        if (index === arrayMail.length - 1) concatenatedMails += `${email}`;
      }
    });
    return concatenatedMails;
  };

  sendEmail = () => {
    const { email, customSubject, customAttachments } = this.state;
    const { editedInvoiceId, user, currentCompany } = this.props;
    var valid = true, emailsToSend = ""

    if (email) {
      const emailsArray = this.splitEmails(email);
      emailsArray.forEach((email, index) => {
        if (!validateEmail(email)) {
          valid = false;
        }
      });
      if(!valid) {
        this.props.alertWarn(this.context.t("Incorrect value in \"Email address\""))
      } else {
        const noDuplicates = this.removeDuplicatesFromArray(emailsArray);
        emailsToSend = this.concatenationMails(noDuplicates)
      }
    }

    if (valid) {
      this.props.sendInvoiceByEmail(editedInvoiceId, emailsToSend, customSubject, customAttachments, currentCompany.company_id, user.id, success => {
        if (success) this.props.toggleSendEmailBox(true);
      });
    }
  }

  handleDropZoneClick = e => {
    if (e.target.className && e.target.className.includes('delete')) {
      e.stopPropagation()
    }
  }

  handleOnDrop = (files, rejectedFiles) => {
    files.forEach((currentFile, index) => {
      const myFileItemReader = new FileReader()
      myFileItemReader.addEventListener("load", () => {
        const myResult = {}
        myResult.base64 = myFileItemReader.result
        myResult.name = currentFile.name
        myResult.size = currentFile.size
        myResult.type = currentFile.type
        this.setState({
          customAttachments: [...this.state.customAttachments, ...[myResult]]
        })
      }, false)
      myFileItemReader.readAsDataURL(currentFile)
    })
  }

  removeFile = index => {
    var { customAttachments } = this.state
    customAttachments.splice(index, 1)
    this.setState({
      customAttachments: customAttachments
    })
  }

  render() {
    const { email, customSubject, customAttachments, defaultSubject } = this.state;
    const { invoiceData } = this.props
    console.log(invoiceData)
    return (
      <div className="send-email-box-popup">
        <div className="popup-card">
          <div className="hide-button" onClick={() => this.props.toggleSendEmailBox(false)}></div>
          {
            invoiceData && invoiceData.doc_number && invoiceData.counterparty ? (
              <div className="invoice-info">
                <ul>
                  <li>
                    <span className="label">{this.context.t('Invoice Number')}:</span>
                    <span className="value">{invoiceData.doc_number}</span>
                  </li>
                  <li>
                    <span className="label">{this.context.t('Buyer')}:</span>
                    <span className="value"> {invoiceData.counterparty.name}</span>
                  </li>
                </ul>
              </div>
            ) : null
          }
          <div className="title">
            <h3>{this.context.t("Invoice recipient e-mail addresses")}</h3>
          </div>
          <div className="text">
            <input type="text" id="email" name="email" placeholder={this.context.t("e.g. name1@domain.com, name2@domain.com")} value={email} onChange={this.onInputChange} />
            <div className="button-container"></div>
            <div className="button" onClick={() => this.sendEmail()} >
              {this.context.t("Send")}
            </div>
          </div>

          <div className="form-group">
            <input name="customSubject" id={`customSubject`} value={customSubject} onChange={this.onInputChange} placeholder={this.context.t('Enter the email subject or leave it as default ({n})', { n: defaultSubject })} />
            <label htmlFor="customSubject">{this.context.t('Custom subject')}</label>
          </div>

          <div className="dropzone-container">
            <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(files, rejectedFiles)} multiple={true}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps({ onClick: event => this.handleDropZoneClick(event) })} className={`dropzone${isDragActive ? ' drop-active' : ''}`}>
                  {
                    customAttachments.length === 0 ? (
                      <div className="no-file">{this.context.t('Additional attachments')}</div>
                    ) : (
                      customAttachments.map((file, i) => {
                        return (
                          <div className="file">
                            <div className="name">{file.name}</div>
                            <div className="delete" onClick={() => this.removeFile(i)}></div>
                          </div>
                        )
                      })
                    )
                  }
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    );
  }
}

SendEmailBox.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  pdfPreviewDataBody: state.File.pdfPreviewDataBody,
  user: state.User.user,
  currentCompany: state.Company.currentCompany || {},
});

const mapDispatchToProps = {
  alertWarn: AlertActions.warning,
  sendInvoiceByEmail: CompanyActions.sendInvoiceByEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailBox);
