export const QuickActionsConsts = {
    TOGGLE_BANK_TRANSFERS: 'TOGGLE_BANK_TRANSFERS',
    TOGGLE_DOCS_EXPORT: 'TOGGLE_DOCS_EXPORT',
    TOGGLE_EXPENSES_ASSISTANT: 'TOGGLE_EXPENSES_ASSISTANT',
    TOGGLE_DOCS_REQUIRING_ATTENTION_ASSISTANT: 'TOGGLE_DOCS_REQUIRING_ATTENTION_ASSISTANT',
    GET_DOCS_REQUIRING_ATTENTION_REQUEST: 'GET_DOCS_REQUIRING_ATTENTION_REQUEST',
    GET_DOCS_REQUIRING_ATTENTION: 'GET_DOCS_REQUIRING_ATTENTION',
    COLLAPSE_DOCS_REQUIRING_ATTENTION_PROJECT_GROUP: 'COLLAPSE_DOCS_REQUIRING_ATTENTION_PROJECT_GROUP'
}

const initialState = {
    showExportBankTransfers: false,
    showExportDocs: false,
    showDocsRequiringAttentionAssistant: false,
    waitingForDocsRequiringAttention: false,
    dropzoneDocs: [],
    additionalPaymentAcceptanceDocs: [],
    upcomingPayments: [],
    mentionedUnreadConversaons: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case QuickActionsConsts.TOGGLE_BANK_TRANSFERS:
            return { ...state, ...{ showExportBankTransfers: action.show } }
        case QuickActionsConsts.TOGGLE_DOCS_EXPORT:
            return { ...state, ...{ showExportDocs: action.show } }
        case QuickActionsConsts.TOGGLE_EXPENSES_ASSISTANT:
            return { ...state, ...{ showExpensesAssistant: action.show } }
        case QuickActionsConsts.TOGGLE_DOCS_REQUIRING_ATTENTION_ASSISTANT:
            return { ...state, ...{ showDocsRequiringAttentionAssistant: action.show } }
        case QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION_REQUEST:
            return {
                ...state, ...{
                    waitingForDocsRequiringAttention: true,
                    dropzoneDocs: [],
                    additionalPaymentAcceptanceDocs: [],
                    upcomingPayments: [],
                    mentionedUnreadConversaons: [],
                }
            }
        case QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION:
            return {
                ...state, ...{
                    waitingForDocsRequiringAttention: false,
                    dropzoneDocs: action.dropzoneDocs,
                    additionalPaymentAcceptanceDocs: action.additionalPaymentAcceptanceDocs,
                    upcomingPayments: action.upcomingPayments,
                    mentionedUnreadConversaons: action.mentionedUnreadConversaons
                }
            }
        case QuickActionsConsts.COLLAPSE_DOCS_REQUIRING_ATTENTION_PROJECT_GROUP:
            var docsList = state[action.docsList]

            console.log('-------------------')
            console.log(docsList)
            if (docsList && docsList[action.groupIndex]) {
                console.log(docsList[action.groupIndex])
                docsList[action.groupIndex].areCollapsed = !docsList[action.groupIndex].areCollapsed
            }
            console.log({ ...state, ...{ [action.docsList]: docsList } })
            console.log('-------------------')

            return { ...state, ...{ [action.docsList]: docsList } }
        default:
            return state
    }
}