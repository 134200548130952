const gfProjects = [
    13,      //TP1
    118,    //ADRIEN-FOUCHET
    120,    //GREENFUTURE-POLAND
    122,    //GREENFUTURE-ENERGY
    141,    //CRISTALLUM45
    166,    //GFBP1
    168,    //GFJP
    170,    //GFK1
    172,    //GF-MILEJCZYCE
    174,    //GF-RYBNO
    229,    //GF-STOCZEK
    230,    //GF-PIENIEZNO
    231,    //GF-POTEGOWO
    232,    //GF-FITOWO
    233,    //GF-KOLNO
    278,    //GF-ZDROJE
    404,    //GF-ROZTOKI
    405,    //GF-BOGDANIEC
    406,    //GF-GOROWO
    407,    //GF-WINSKO
    578,    //GF-VELES
    579,    //GF-DOLA
]

module.exports = {
    gfProjects
};