import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const KeyCodes = {
    comma: 188,
    enter: 13,
    backspace: 8,
    arrowUp: 38,
    arrowDown: 40
}

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export class TagsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            suggestions: [],
            showSuggersions: false,
            arrowsOptionIndex: 0,
            tags: [],
            tagInput: '',
            tagsInputFocused: false
        }

        this.scrollableNodeRef = React.createRef();
        this.wrapperRef = React.createRef();
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { tagsList, currentTags } = this.props
        var currentTagsList = currentTags?.split(',') || []
        this.setState({
            suggestions: tagsList.length ? tagsList.map(tag => {
                return {
                    id: tag.replaceAll(' ', '_'),
                    text: tag
                }
            }) : [],
            tags: currentTags?.length && currentTagsList?.length ? currentTagsList?.map(tag => {
                var tagText = tag.trim()
                return {
                    id: tagText.replaceAll(' ', '_'),
                    text: tagText
                }
            }) : []
        })
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.keyDown, false)
    }

    componentDidUpdate(prevProps) {
        const { currentTags } = this.props
        if (currentTags !== prevProps.currentTags) {
            var currentTagsList = currentTags.split(',')
            this.setState({
                tags: currentTags.length && currentTagsList.length ? currentTagsList.map(tag => {
                    var tagText = tag.trim()
                    return {
                        id: tagText.replaceAll(' ', '_'),
                        text: tagText
                    }
                }) : []
            })
        }

        const { tagsList } = this.props
        if (tagsList !== prevProps.tagsList) {
            this.setState({
                suggestions: tagsList.length ? tagsList.map(tag => {
                    return {
                        id: tag.replaceAll(' ', '_'),
                        text: tag
                    }
                }) : []
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.keyDown, false)
    }

    handleClick = (event) => {
        console.log(event)
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showSuggersions: false
            })
        }
    }

    keyDown = e => {
        const { tagsInputFocused } = this.state

        console.log(tagsInputFocused)
        if (delimiters.includes(e.keyCode) && this.props.canAddNewTags && tagsInputFocused) {
            e.preventDefault()
            const { tagInput, suggestions, arrowsOptionIndex } = this.state
            var newTagText = tagInput
            var selectedFirstFromList = false
            if (tagInput.length >= 2 && suggestions.length > 0) {
                suggestions.forEach(option => {
                    if (!selectedFirstFromList && this.optionMatchesInput(option.text)) {
                        newTagText = option.text
                        selectedFirstFromList = true
                    }
                })
                // if (suggestions[arrowsOptionIndex] && this.optionMatchesInput(suggestions[arrowsOptionIndex].text)) newTagText = suggestions[arrowsOptionIndex].text
            }
            var tagObject = {
                id: newTagText.replaceAll(' ', '_'),
                text: newTagText
            }

            if (tagObject.text) {
                const { tags } = this.state
                const { singleTag } = this.props
                this.setState({
                    tags: singleTag ? [tagObject] : [...tags, tagObject],
                    tagInput: ''
                }, () => {
                    var tagsString = ''
                    this.state.tags.forEach(tag => {
                        tagsString += `${tag.text}, `
                    })
                    tagsString = tagsString.slice(0, -2)
                    this.props.setTags(tagsString)
                })
            }
        } else if (e.keyCode === KeyCodes.arrowUp || e.keyCode === KeyCodes.arrowDown) {
            // const { tagInput, suggestions, arrowsOptionIndex, showSuggersions } = this.state
            // if (showSuggersions || tagInput.length >= 2) {
            //     var nextArrowIndex = arrowsOptionIndex
            //     if (e.keyCode === KeyCodes.arrowUp) nextArrowIndex++
            //     if (e.keyCode === KeyCodes.arrowDown) nextArrowIndex--

            //     if (nextArrowIndex < 0) nextArrowIndex = suggestions.length - 1
            //     if (nextArrowIndex > suggestions.length - 1) nextArrowIndex = 0
            // }
        } else if (e.keyCode === KeyCodes.backspace && tagsInputFocused) {
            const { tagInput } = this.state
            if (!tagInput) {
                var tagsListCopy = [...this.state.tags]
                tagsListCopy.pop()
                this.setState({
                    tags: tagsListCopy,
                    tagInput: ''
                }, () => {
                    var tagsString = ''
                    this.state.tags.forEach(tag => {
                        tagsString += `${tag.text}, `
                    })
                    tagsString = tagsString.slice(0, -2)
                    this.props.setTags(tagsString)
                })
            }
        }
    }

    addTagFromSuggestions = tag => {
        const { tags } = this.state
        const { singleTag } = this.props
        if (!tags.find(t => t.text === tag.text)) {
            this.setState({
                tags: singleTag ? [tag] : [...tags, tag],
                showSuggersions: false,
                tagInput: ''
            }, () => {
                var tagsString = ''
                this.state.tags.forEach(tag => {
                    tagsString += `${tag.text}, `
                })
                tagsString = tagsString.slice(0, -2)
                this.props.setTags(tagsString)
            })
        } else {
            this.setState({
                showSuggersions: false
            })
        }
    }

    deleteTag = (e, i) => {
        e.stopPropagation()
        const { tags } = this.state
        this.setState({
            tags: tags.filter((tag, index) => index !== i)
        }, () => {
            var tagsString = ''
            this.state.tags.forEach(tag => {
                tagsString += `${tag.text}, `
            })
            tagsString = tagsString.slice(0, -2)
            this.props.setTags(tagsString)
        })
    }

    onChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({
            [name]: value
        })
    }

    showSuggersions = () => {
        this.setState({
            showSuggersions: true,
            tagsInputFocused: true
        })
    }

    optionMatchesInput = optionText => {
        const { tagInput } = this.state
        if (tagInput.length >= 2 && optionText.includes(tagInput)) return true
        if (optionText.startsWith(tagInput)) return true

        return false
    }

    inputFieldFocus = () => {
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus()
        }
    }

    render() {
        const { suggestions, showSuggersions, tags, tagInput, arrowsOptionIndex } = this.state
        const { maxHeight, customLabel, customPlaceholder, customMinWidth, readOnly } = this.props

        console.log(suggestions)
        return (
            <div className={`ocr-data tags-component ${showSuggersions || tagInput.length >= 2 ? 'list-active' : ''}`} ref={this.wrapperRef}>
                <label>{customLabel || this.context.t('Tags')}</label>
                {
                    !readOnly && (showSuggersions || tagInput.length >= 2) ? (
                        <ul>
                            <SimpleBar style={{ height: 'auto', maxHeight: `${maxHeight || 300}px`, width: '100%' }} scrollableNodeProps={{ ref: this.scrollableNodeRef }} >
                                {
                                    suggestions.map(option => {
                                        if (this.optionMatchesInput(option.text))
                                            return (
                                                <li key={option.id} onClick={() => this.addTagFromSuggestions(option)}>
                                                    {option.text}
                                                </li>
                                            )
                                        else return null
                                    })
                                }
                            </SimpleBar>
                        </ul>
                    ) : null
                }
                <div className="tags-wrapper" onClick={() => this.inputFieldFocus()}>
                    {
                        tags.map((t, index) => {
                            return (
                                <div key={t.id} className="selected-tag">
                                    {t.text}
                                    <span className="delete-button" onClick={e => this.deleteTag(e, index)}></span>
                                </div>
                            )
                        })
                    }

                    <input
                        ref={this.inputRef}
                        style={{ minWidth: customMinWidth || '200px' }}
                        placeholder={customPlaceholder || this.context.t('Type tags to help you find this doc later, e.g. leasing, bank')}
                        onFocus={() => this.showSuggersions()}
                        onBlur={() => this.setState({ tagsInputFocused: false })}
                        name='tagInput'
                        value={tagInput}
                        onChange={e => this.onChange(e)}
                        readOnly={readOnly || false}
                    />
                </div>
            </div >
        )
    }
}

TagsComponent.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsComponent)