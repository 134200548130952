import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import { ReportsActions } from '../../actions/ReportsActions'
import { AlertActions } from '../../actions/AlertActions'

import availableQuickActions from '../../helpers/availableQuickActions'

import CompanyBankInformationPopup from '../CompanyBankInformationPopup'

import '../../css/QuickActions.css'
import { UserActions } from '../../actions/UserActions'

export class QuickActions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addCompanyBankAccountPopup: false,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    openExportBankTransfers = () => {
        const { currentCompany } = this.props
        if (currentCompany && currentCompany.company) {
            if (currentCompany.company.banks.length > 0) {
                this.props.toggleQuickAction(availableQuickActions.bankTransfers, true)

                const { currentProject } = this.props
                var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
                var defaultDateTo = moment()
                this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { allUnpaid: true })
            } else {
                if (currentCompany.user_role === 'ADMIN') {
                    this.setState({
                        addCompanyBankAccountPopup: true
                    })
                } else {
                    this.props.alertWarn(this.context.t('You company has not provided required bank account informations yet. Please contact your companies administrator'), 7000)
                }
            }
        }
    }

    addedNewAccount = () => {
        this.setState({
            addCompanyBankAccountPopup: false
        }, () => {
            this.openExportBankTransfers()
        })
    }

    closeCompanyAccountPopup = () => {
        this.setState({
            addCompanyBankAccountPopup: false
        })
    }

    openDocsExport = () => {
        const { currentCompany } = this.props
        if (currentCompany && currentCompany.company) {
            this.props.toggleQuickAction(availableQuickActions.docsExport, true)


            const { currentProject } = this.props
            var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            var defaultDateTo = moment()
            this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { allNotExportedDocs: true })
        }
    }

    openExpensesAssstant = () => {
        const { currentProject, user } = this.props
        this.props.toggleQuickAction(availableQuickActions.expensesAssistant, true)
        var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
        var defaultDateTo = moment()
        if (currentProject.acceptance_permissions === 'USER1') {
            this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Expenses', 'importDate', null, { userId: user.id, allNotSettled: true })
        } else {
            this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Expenses', 'importDate', null, { allNotSettled: true })
        }
    }

    openDocsRequiringMyAttention = () => {
        // this.props.toggleQuickAction(availableQuickActions.docsRequiringMyAttention, true)
        this.props.getDocsRequiringMyAttention()
    }

    render() {
        const {
            addCompanyBankAccountPopup
        } = this.state
        const { currentCompany, user } = this.props
        return (
            <div className="dashboard-quick-actions">
                <div className="header">
                    <div className="text"><h2>{this.context.t('Actions - Your Assistants')}</h2></div>
                </div>

                <div className="actions-container">
                    <div className="action-button transfers active" onClick={() => this.openExportBankTransfers()} data-for="action_bank-transfers" data-tip="show">
                        <div className="text">{this.context.t("Payment\nassistant")}</div>
                    </div>
                    <div className="action-button zip active" onClick={() => this.openDocsExport()} data-for="action_zip" data-tip="show">
                        <div className="text">{this.context.t("Packages\nfor accounting")}</div>
                    </div>
                    <div className={`action-button expenses active`} onClick={() => this.openExpensesAssstant()} data-for="action_expenses" data-tip="show">
                        <div className="text"> {this.context.t("Expenses\nassistant")}</div>
                    </div>
                    {
                        true || [2, 3, 62].includes(user.id) ? (
                            <div className={`action-button attention active`} onClick={() => this.openDocsRequiringMyAttention()} data-for="docs_requiring_attention" data-tip="show">
                                <div className="text"> {this.context.t("Documents requiring\nyour attention")}</div>
                            </div>
                        ) : (
                            <>
                                <div className="action-button transactions" data-for="action_bank-transactions" data-tip="show">
                                    <div className="text"> {this.context.t("Settlement\nassistant")}</div>

                                    <div className="coming-soon">{this.context.t('Coming soon!')}</div>
                                </div>
                                <div className="action-button costs" data-for="action_repor-costs" data-tip="show">
                                    <div className="text">{this.context.t("Flow\nassistant")}</div>

                                    <div className="coming-soon">{this.context.t('Coming soon!')}</div>
                                </div>
                            </>
                        )
                    }


                    <ReactTooltip id="action_bank-transfers" className="default-tooltip quick-actons-tooltip" effect='solid' place='bottom'>
                        {this.context.t('Wizard creating a text file with a batch of transfers to be imported to your bank. Optionally, it creates a .zip package with documents and an Excel report.')}
                        {/* <span className="currently">{this.context.t("Currently, you can do this manually in the Actions menu.")}</span> */}
                    </ReactTooltip>

                    <ReactTooltip id="action_zip" className="default-tooltip quick-actons-tooltip" effect='solid' place='bottom'>
                        {this.context.t('Wizard that creates a .zip file with documents to be sent to your ledger for a given period.')}
                        {/* <span className="currently">{this.context.t("Currently, you can do this manually in the Actions menu.")}</span> */}
                    </ReactTooltip>

                    <ReactTooltip id="action_bank-transactions" className="default-tooltip quick-actons-tooltip" effect='solid' place='bottom'>
                        {this.context.t('Wizard to import your company\'s bank statement in CSV format.')}
                    </ReactTooltip>

                    <ReactTooltip id="action_repor-costs" className="default-tooltip quick-actons-tooltip" effect='solid' place='bottom'>
                        {this.context.t('Analysis of cash flow in the company - statement of revenues and costs.')}
                    </ReactTooltip>
                </div>

                {
                    addCompanyBankAccountPopup ? (
                        <CompanyBankInformationPopup
                            company={currentCompany.company}
                            close={this.closeCompanyAccountPopup}
                            closeAndGenerateFile={this.closeCompanyAccountPopup}
                            currency={'PLN'}
                            addNewAccount={false}
                            addFirstAccount={true}
                            addedNewAccount={this.addedNewAccount}
                        />
                    ) : (
                        null
                    )
                }
            </div>
        )
    }
}

QuickActions.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    currentCompany: state.Company.currentCompany,

    language: state.i18nState.lang
})

const mapDispatchToProps = {
    toggleQuickAction: UserActions.toggleQuickAction,
    getDocsRequiringMyAttention: UserActions.getDocsRequiringMyAttention,
    getDocuments: ReportsActions.getDocuments,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickActions)