const devCostsProjects = [
    4,      // GBC
    5,      // BGDL
    7,      // OPTIMA-WIND
    8,      // GB12W
    9,      // GBS
    10,     // GBK
    31,     // OPTIMA ENERGY
    32,     // OPTIMA DEVELOPMENT
    72,     // OPTIMA-KIK
    73,     // OPTIMA-CZE
    74,     // OPTIMA-SID
    75,     // WINDLIGHT
    92,     // OPTIMA-KLE
    103,    // OPTIMA-AUG
    104,    // OPTIMA-CZA
    105,    // OPTIMA-DOB
    113,    // OPTIMA-TYM
    428,    // OPTIMA-G
    429,    // OPTIMA-N1
    430,    // OPTIMA-N2
    431,    // OPTIMA-N3
    432,    // OPTIMA-N4
    433,    // OPTIMA-N5
    444,    // OPTIMA-JV1
    445,    // OPTIMA-JV2
    446,    // OPTIMA-JV3
    447,    // OPTIMA-JV4
    448,    // OPTIMA-JV5
    449,    // OPTIMA-JV6
    450,    // OPTIMA-JV7
    451,    // OPTIMA-JV8
    452,    // OPTIMA-JV9
    453,    // OPTIMA-JV10
    454,    // OPTIMA-JV11
    455,    // OPTIMA-JV12
    456,    // OPTIMA-JV13
    457,    // OPTIMA-JV14
    479,    // OPTIMA-N6
    607,    // GBC-DEVEX
    608,    // OW-DEVEX

    13,     // TP1
    45,      // DEMO
    495,    // CodeSpot
]

module.exports = {
    devCostsProjects
};