import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { Spinner, Bounce } from 'react-activity'
import ReactTooltip from 'react-tooltip'
import 'react-activity/dist/react-activity.css'
import moment from 'moment-timezone'
import ReactGA from 'react-ga';
import throttle from "lodash.throttle"
import copy from 'copy-to-clipboard';
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd';
import { PDFDocument } from 'pdf-lib';
import { pdfjs } from 'react-pdf';

import { CompanyActions } from '../../actions/CompanyActions'
import { UserActions } from '../../actions/UserActions'
import { FileActions } from '../../actions/FileActions'
import { ProjectCostsActions } from '../../actions/ProjectCostsActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { EventActions } from '../../actions/EventActions'
import { AlertActions } from '../../actions/AlertActions'

import QuickActions from '../../components/DashboardComponents/QuickActions'
import FileManager from '../../components/DashboardComponents/FileManager'
import TransfersManager from '../../components/DashboardComponents/TransfersManager'
import PendingFiles from '../../components/DashboardComponents/PendingFiles'
import PdfPagesSelector from '../../components/PdfPagesSelector'
import Calendar from '../../components/Calendar'
import ProjectCosts from '../../components/DashboardComponents/ProjectCosts'
import NoSubscriptionWarning from '../../components/DashboardComponents/NoSubscriptionWarning'
import PaymentBaner from '../../components/DashboardComponents/PaymentBaner'
import OverlayInstructions from '../../components/OverlayInstructions'
import DragFileLayer from '../../components/DashboardComponents/DragFileLayer'
import SubscriptionTransactionErrorPopup from '../../components/SubscriptionTransactionErrorPopup'
import SubscriptionTransactionSuccessPopup from '../../components/SubscriptionTransactionSuccessPopup'
import arrayObjectIndexOf from '../../helpers/indexOfObject';
import isIterable from "../../helpers/isIterable"
const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, application/pdf'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export class Dashboard extends Component {

	constructor(props) {
		super(props)
		this.state = {
			files: [],
			pdfWrapperWidth: null,
			filterField: 'created',
			order: 'DESC',
			pageLimit: 3,
			modalOpen: false,
			docType: '',
			modalProjectId: '',

			firstName: '',
			lastName: '',
			phoneNumber: '',
			password: '',
			password2: '',

			showProjectsListInSingleView: false,

			//overlay
			gotOverlayPossitionsMulti: false,
			gotOverlayPossitionsSingle: false,
			overlayPossitions: [],
		}
	}

	componentDidMount() {
		//window.addEventListener("resize", throttle(this.setOverlaySize, 500))
		//ReactGA.pageview(window.location.pathname + window.location.search)
		this.getUserProjects()

		const { location } = this.props
		var additionalUrlParams = location.pathname.replace('/dashboard', '')

		if (additionalUrlParams && (additionalUrlParams.includes('/subscription-success') || additionalUrlParams.includes('/subscription-failure'))) {
			var companyId = additionalUrlParams.replace('/subscription-success/', '').replace('/subscription-failure/', '')
			console.log(companyId)
			companyId = parseInt(companyId)
			this.handleSubscriptionCallback(companyId)
		}

		if (additionalUrlParams && (additionalUrlParams.includes('/document-preview'))) {
			var projectAndFile = additionalUrlParams.replace('/document-preview/', '')
			if (/^[0-9]{0,}\/[0-9]{0,}$/.test(projectAndFile)) {
				var projectAndFileArray = projectAndFile.split('/')
				const projectId = parseInt(projectAndFileArray[0])
				const fileId = parseInt(projectAndFileArray[1])

				this.openFilePreviewFromUrl(projectId, fileId, 'FILE')
			}
		}

		if (additionalUrlParams && (additionalUrlParams.includes('/payment-order-preview'))) {
			var projectAndFile = additionalUrlParams.replace('/payment-order-preview/', '')
			if (/^[0-9]{0,}\/[0-9]{0,}$/.test(projectAndFile)) {
				var projectAndFileArray = projectAndFile.split('/')
				const projectId = parseInt(projectAndFileArray[0])
				const fileId = parseInt(projectAndFileArray[1])

				this.openFilePreviewFromUrl(projectId, fileId, 'PAYMENT_ORDER')
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { gotOverlayPossitionsMulti, gotOverlayPossitionsSingle } = this.state
		const { user, viewMode, overlayPreferance, userProjects, currentProject, currentCompany, userCompanies } = this.props

		if (localStorage.getItem('menuExtended')) {
			if (viewMode === 'MULTI' && (!gotOverlayPossitionsMulti || prevProps.viewMode !== viewMode) && overlayPreferance['dashboard']) {
				const { dropzoneRefMulti0, fileManagerRefMulti0, paymentOrdersRefMulti0, costsRefMulti0, calendarRefMulti0 } = this.refs
				if (dropzoneRefMulti0 && fileManagerRefMulti0 && paymentOrdersRefMulti0 && calendarRefMulti0) {
					var holesToDraw = []

					var dropzoneBox = dropzoneRefMulti0.getBoundingClientRect()
					if (dropzoneBox) {
						holesToDraw.push({
							x: dropzoneBox.x,
							y: dropzoneBox.y,
							w: dropzoneBox.width,
							h: dropzoneBox.height
						})
					}
					var filesBox = fileManagerRefMulti0.getBoundingClientRect()
					if (filesBox) {
						holesToDraw.push({
							x: filesBox.x,
							y: filesBox.y,
							w: filesBox.width,
							h: 40
						})
					}

					var paymentOrdersBox = paymentOrdersRefMulti0.getBoundingClientRect()
					if (paymentOrdersBox) {
						holesToDraw.push({
							x: paymentOrdersBox.x,
							y: paymentOrdersBox.y,
							w: paymentOrdersBox.width,
							h: 40
						})
					}

					if (costsRefMulti0) {
						var costsBox = costsRefMulti0.getBoundingClientRect()
						if (costsBox) {
							holesToDraw.push({
								x: costsBox.x,
								y: costsBox.y,
								w: costsBox.width,
								h: 40
							})
						} else {
							holesToDraw.push({
								x: -9999,
								y: -9999,
								w: 0,
								h: 0
							})
						}
					} else {
						holesToDraw.push({
							x: -9999,
							y: -9999,
							w: 0,
							h: 0
						})
					}

					var calendarBox = calendarRefMulti0.getBoundingClientRect()
					if (calendarBox) {
						holesToDraw.push({
							x: calendarBox.x,
							y: calendarBox.y,
							w: calendarBox.width,
							h: calendarBox.height
						})
					}

					if (holesToDraw.length >= 4) {
						console.log(holesToDraw)
						this.setState({
							gotOverlayPossitionsMulti: true,
							overlayPossitions: holesToDraw
						})

						if (overlayPreferance['dashboard'] && userProjects && userProjects.length > 0) {

							console.log(userProjects[0])
							console.log(costsBox)
							if (costsBox) {
								this.props.extendProjectCosts(userProjects[0].id, false)
							}
						}
					}
				}
			} else if (viewMode === 'SINGLE' && (!gotOverlayPossitionsSingle || prevProps.viewMode !== viewMode)) {
				const { dropzoneRefSingle, filesRefSingle, paymentOrdersRefSingle, costsRefSingle, calendarRefSingle } = this.refs
				if (dropzoneRefSingle && filesRefSingle && paymentOrdersRefSingle && calendarRefSingle) {
					var holesToDraw = []

					var dropzoneBox = dropzoneRefSingle.getBoundingClientRect()
					if (dropzoneBox) {
						holesToDraw.push({
							x: dropzoneBox.x,
							y: dropzoneBox.y,
							w: dropzoneBox.width,
							h: dropzoneBox.height
						})
					}

					var filesBox = filesRefSingle.getBoundingClientRect()
					if (filesBox) {
						holesToDraw.push({
							x: filesBox.x,
							y: filesBox.y,
							w: filesBox.width,
							h: window.innerHeight / 2 - 87
						})
					}

					var paymentOrdersBox = paymentOrdersRefSingle.getBoundingClientRect()
					var paymentOrdersBoxHeight = 40
					if (paymentOrdersBox) {
						holesToDraw.push({
							x: paymentOrdersBox.x,
							y: paymentOrdersBox.y,
							w: paymentOrdersBox.width,
							h: 40
						})

						paymentOrdersBoxHeight = paymentOrdersBox.height
					}

					if (costsRefSingle) {
						var costsBox = costsRefSingle.getBoundingClientRect()
						if (costsBox) {
							holesToDraw.push({
								x: costsBox.x,
								y: costsBox.y - (paymentOrdersBoxHeight - 40),
								w: costsBox.width,
								h: costsBox.height
							})
						} else {
							holesToDraw.push({
								x: -9999,
								y: -9999,
								w: 0,
								h: 0
							})
						}
					} else {
						holesToDraw.push({
							x: -9999,
							y: -9999,
							w: 0,
							h: 0
						})
					}

					var calendarBox = calendarRefSingle.getBoundingClientRect()
					if (calendarBox) {
						holesToDraw.push({
							x: calendarBox.x,
							y: calendarBox.y,
							w: calendarBox.width,
							h: calendarBox.height
						})
					}

					if (holesToDraw.length >= 4) {
						this.setState({
							gotOverlayPossitionsSingle: true,
							overlayPossitions: holesToDraw
						})

						if (userProjects && userProjects.length > 0) {
							this.props.extendProjectFiles(userProjects[0].id, true)
							this.props.extendProjectPaymentOrders(userProjects[0].id, false)
						}
					}
				}
			}
		}

		if (localStorage.getItem('dashboardProject') && userProjects) {
			var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject'))
			if (dashboardProjectId > -1) {
				if (viewMode === 'MULTI' || (currentProject && currentProject.id !== dashboardProjectId)) {
					var projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
					if (projectIndex > -1) {
						this.props.setCurrentProject(userProjects[projectIndex].id)
						this.props.switchDashboardViewModel(userProjects[projectIndex].id)
					}
				}
			} else {
				if (viewMode !== 'MULTI') {
					this.props.switchDashboardViewModel(null)
				}
			}
		}

		const { waitingToOpenFilePreview, filePreviewProjectId, filePreviewFileId, filePreviewType } = this.state
		if (waitingToOpenFilePreview) {
			if (currentProject && currentProject.id === filePreviewProjectId) {
				if (filePreviewType === 'FILE') {
					const { uploadedFiles } = this.props
					if (uploadedFiles) {
						var projectFiles = uploadedFiles.find(e => e.project === filePreviewProjectId)
						if (projectFiles && projectFiles.loaded) this.openFilePreviewFromUrl(filePreviewProjectId, filePreviewFileId, filePreviewType)
					}
				} else if (filePreviewType === 'PAYMENT_ORDER') {
					const { paymentOrders } = this.props
					if (paymentOrders) {
						var projectFiles = paymentOrders.find(e => e.project === filePreviewProjectId)
						if (projectFiles && projectFiles.loaded) this.openFilePreviewFromUrl(filePreviewProjectId, filePreviewFileId, filePreviewType)
					}
				}
			} else if (userProjects && userProjects.find(p => p.id === filePreviewProjectId)) {
				this.props.switchDashboardViewModel(filePreviewProjectId)
			}
		}
	}

	componentWillUnmount() {
		//window.removeEventListener("resize", throttle(this.setOverlaySize, 500))
		if (this.state.files.length > 0) {
			console.log("files not uploaded")
		}
	}

	openFilePreviewFromUrl = (projectId, fileId, previewType) => {
		const { userProjects } = this.props
		var needToWaitForDataToLoad = false
		var projectIndex = arrayObjectIndexOf(userProjects, projectId, 'id')
		if (projectIndex > -1) {
			if (previewType === 'FILE') {
				const { uploadedFiles } = this.props
				projectIndex = arrayObjectIndexOf(uploadedFiles, projectId, 'project')
				if (projectIndex > -1) {
					this.props.switchDashboardViewModel(projectId)
					var fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, fileId, 'id')
					if (fileIndex > -1) {
						var fileToOpen = uploadedFiles[projectIndex].files[fileIndex]
						this.props.getFileData(parseInt(fileToOpen.project_id), fileToOpen.path, fileToOpen.id, fileToOpen.ocr_status, fileToOpen.acceptance_step, true)
					} else {
						needToWaitForDataToLoad = true
					}
				} else {
					needToWaitForDataToLoad = true
				}
			} else if (previewType === 'PAYMENT_ORDER') {
				const { paymentOrders } = this.props
				projectIndex = arrayObjectIndexOf(paymentOrders, projectId, "project")
				if (projectIndex > -1) {
					this.props.switchDashboardViewModel(projectId)
					var fileIndex = arrayObjectIndexOf(paymentOrders[projectIndex].transfers, fileId, 'id')
					if (fileIndex > -1) {
						var paymentOrderToOpen = paymentOrders[projectIndex].transfers[fileIndex]
						this.props.openTransferPreviewWindow(paymentOrderToOpen)
					} else {
						needToWaitForDataToLoad = true
					}
				} else {
					needToWaitForDataToLoad = true
				}
			}
		} else {
			needToWaitForDataToLoad = true
		}

		if (needToWaitForDataToLoad) {
			this.setState({
				waitingToOpenFilePreview: true,
				filePreviewProjectId: projectId,
				filePreviewFileId: fileId,
				filePreviewType: previewType
			})
		} else {
			this.setState({
				waitingToOpenFilePreview: false,
				filePreviewProjectId: null,
				filePreviewFileId: null,
				filePreviewType: null
			})
		}
	}

	setOverlaySize = () => {
		const { user, viewMode, userProjects } = this.props
		if (viewMode === 'MULTI') {
			const { dropzoneRefMulti0, fileManagerRefMulti0, paymentOrdersRefMulti0, costsRefMulti0, calendarRefMulti0 } = this.refs
			if (dropzoneRefMulti0 && fileManagerRefMulti0 && paymentOrdersRefMulti0 && calendarRefMulti0) {
				var holesToDraw = []

				var dropzoneBox = dropzoneRefMulti0.getBoundingClientRect()
				if (dropzoneBox) {
					holesToDraw.push({
						x: dropzoneBox.x,
						y: dropzoneBox.y,
						w: dropzoneBox.width,
						h: dropzoneBox.height
					})
				}

				var filesBox = fileManagerRefMulti0.getBoundingClientRect()
				var fileBoxHeight = 40
				if (filesBox) {
					holesToDraw.push({
						x: filesBox.x,
						y: filesBox.y,
						w: filesBox.width,
						h: 40
					})

					fileBoxHeight = filesBox.height
				}

				var paymentOrdersBox = paymentOrdersRefMulti0.getBoundingClientRect()
				var paymentOrdersBoxHeight = 40
				if (paymentOrdersBox) {
					holesToDraw.push({
						x: paymentOrdersBox.x,
						y: paymentOrdersBox.y - (fileBoxHeight - 40),
						w: paymentOrdersBox.width,
						h: 40
					})

					paymentOrdersBoxHeight = paymentOrdersBox.height
				}

				var costsBoxHeight = 40
				if (costsRefMulti0) {
					var costsBox = costsRefMulti0.getBoundingClientRect()
					if (costsBox) {
						holesToDraw.push({
							x: costsBox.x,
							y: costsBox.y - (fileBoxHeight - 40) - (paymentOrdersBoxHeight - 40),
							w: costsBox.width,
							h: 40
						})

						costsBoxHeight = costsBox.height
					} else {
						holesToDraw.push({
							x: -9999,
							y: -9999,
							w: 0,
							h: 0
						})
					}
				} else {
					holesToDraw.push({
						x: -9999,
						y: -9999,
						w: 0,
						h: 0
					})
				}

				var calendarBox = calendarRefMulti0.getBoundingClientRect()
				if (calendarBox) {
					holesToDraw.push({
						x: calendarBox.x,
						y: calendarBox.y - (fileBoxHeight - 40) - (paymentOrdersBoxHeight - 40) - (costsBoxHeight - 40),
						w: calendarBox.width,
						h: calendarBox.height
					})
				}

				if (holesToDraw.length >= 4) {
					this.setState({
						gotOverlayPossitionsMulti: true,
						overlayPossitions: holesToDraw
					})
				}

				if (userProjects && userProjects.length > 0) {
					this.props.extendProjectCosts(userProjects[0].id, false)
					this.props.extendProjectPaymentOrders(userProjects[0].id, false)
					this.props.extendProjectFiles(userProjects[0].id, false)
				}
			}
		} else if (viewMode === 'SINGLE') {
			const scrollBarWidth = window.innerWidth - document.body.offsetWidth
			const { dropzoneRefSingle, filesRefSingle, paymentOrdersRefSingle, costsRefSingle, calendarRefSingle } = this.refs
			if (dropzoneRefSingle && filesRefSingle && paymentOrdersRefSingle && calendarRefSingle) {
				var holesToDraw = []

				var paymentOrdersBox = paymentOrdersRefSingle.getBoundingClientRect()
				var paymentOrdersBoxHeight = 40
				if (paymentOrdersBox) {
					paymentOrdersBoxHeight = paymentOrdersBox.height
				}

				var dropzoneBox = dropzoneRefSingle.getBoundingClientRect()
				if (dropzoneBox) {
					holesToDraw.push({
						x: dropzoneBox.x,
						y: dropzoneBox.y,
						w: paymentOrdersBoxHeight === 40 ? dropzoneBox.width : dropzoneBox.width + scrollBarWidth / 2,
						h: dropzoneBox.height
					})
				}

				var filesBox = filesRefSingle.getBoundingClientRect()
				if (filesBox) {
					holesToDraw.push({
						x: filesBox.x,
						y: filesBox.y,
						w: paymentOrdersBoxHeight === 40 ? filesBox.width : filesBox.width + scrollBarWidth / 2,
						h: window.innerHeight / 2 - 87
					})
				}

				if (paymentOrdersBox) {
					holesToDraw.push({
						x: paymentOrdersBox.height === 40 ? paymentOrdersBox.x : paymentOrdersBox.x + scrollBarWidth,
						y: paymentOrdersBox.y,
						w: paymentOrdersBox.width,
						h: 40
					})

					paymentOrdersBoxHeight = paymentOrdersBox.height
				}

				if (costsRefSingle) {
					var costsBox = costsRefSingle.getBoundingClientRect()
					if (costsBox) {
						holesToDraw.push({
							x: paymentOrdersBoxHeight === 40 ? costsBox.x : costsBox.x + scrollBarWidth,
							y: costsBox.y - (paymentOrdersBoxHeight - 40),
							w: costsBox.width,
							h: costsBox.height
						})
					} else {
						holesToDraw.push({
							x: -9999,
							y: -9999,
							w: 0,
							h: 0
						})
					}
				} else {
					holesToDraw.push({
						x: -9999,
						y: -9999,
						w: 0,
						h: 0
					})
				}

				var calendarBox = calendarRefSingle.getBoundingClientRect()
				if (calendarBox) {
					holesToDraw.push({
						x: paymentOrdersBoxHeight === 40 ? calendarBox.x : calendarBox.x + scrollBarWidth,
						y: calendarBox.y,
						w: calendarBox.width,
						h: calendarBox.height
					})
				}

				if (holesToDraw.length >= 4) {
					this.setState({
						gotOverlayPossitionsSingle: true,
						overlayPossitions: holesToDraw
					})

					if (userProjects && userProjects.length > 0) {
						this.props.extendProjectFiles(userProjects[0].id, true)
						this.props.extendProjectPaymentOrders(userProjects[0].id, false)
					}
				}
			}
		}
	}

	handleSubscriptionCallback = (companyId) => {
		this.props.getMyLatestSubsctiptionPayment(companyId, (subsctiption) => {
			if (subsctiption) {
				if (subsctiption.status === 'executed') {
					this.setState({
						showLastTransactionSuccess: true,
						subscriptionEndDate: moment(subsctiption.period_end).utc()
					})
				} else {
					this.setState({
						showLastTransactionError: true,
						latestTransactionErrorCode: subsctiption.issuer_response_code
					})
				}
			}
		})
	}

	hideAfterPaymentPopup = () => {
		this.setState({
			showLastTransactionSuccess: false,
			showLastTransactionError: false,
			latestTransactionErrorCode: ''
		})
		this.props.history.push('/dashboard/')
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	}

	getUserProjects = () => {
		this.props.getUserProjects()
	}

	verifyFile = (files) => {
		if (files && files.length > 0) {
			files.forEach(currentFile => {
				const currentFileType = currentFile.type
				const currentFileSize = currentFile.size
				if (currentFileSize > imageMaxSize) {
					alert("This file is not allowed. " + currentFileSize + " bytes is too large")
					return false
				}
				if (!acceptedFileTypesArray.includes(currentFileType)) {
					alert("This file is not allowed. Only images are allowed.")
					return false
				}
			})
			return true
		}
	}

	verifiName = (name) => {
		var base = name.substring(0, name.lastIndexOf('.'))
		var extension = name.substring(name.lastIndexOf('.'))
		console.log(base)
		base = base.replace(/\s\s+/g, ' ')
		console.log(extension)
		console.log(base + extension)
		return base + extension
	}

	handleOnDrop = (projectId, files, rejectedFiles) => {
		if (rejectedFiles && rejectedFiles.length > 0) {
			this.verifyFile(rejectedFiles)
		} else {
			var verifiedName = ''
			files.forEach((currentFile, index) => {
				console.log('drop 3 for files')
				console.log(currentFile.name, currentFile.size)
				const myFileItemReader = new FileReader()
				myFileItemReader.addEventListener("load", () => {
					verifiedName = this.verifiName(currentFile.name)
					const myResult = {}
					myResult.src = myFileItemReader.result
					myResult.name = verifiedName
					myResult.size = currentFile.size
					myResult.type = currentFile.type
					myResult.docType = this.state.docType
					myResult.projectId = projectId
					myResult.docType = 'COSTS'
					console.log(myResult)
					// this.parseFiles(myResult, projectId)
					if (currentFile.type === 'application/pdf') {
						const arrayBufferReader = new FileReader()
						arrayBufferReader.addEventListener("load", () => {
							this.countPdfPages(arrayBufferReader.result, myResult, projectId)
						})
						arrayBufferReader.readAsArrayBuffer(currentFile);
					} else {
						this.parseFiles(myResult, projectId)
					}
				}, false)
				myFileItemReader.readAsDataURL(currentFile)
			})
		}
	}

	parseFiles = (file, projectId, pages = []) => {
		const { filesToVerifiLoaded } = this.props
		var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, projectId, 'project')
		if (projectIndex > -1) {
			if (!filesToVerifiLoaded[projectIndex].files.find(e => e.name === file.name)) {
				this.props.parsePDF({
					fileName: file.name,
					base64: file.src,
					type: file.type,
					senderEmail: this.props.user.email
				}, file.projectId, file.size, pages)
				this.props.cacheUploadedFile({
					name: file.name,
					size: file.size,
					projectId: file.projectId,
				})
			} else {
				var newName = this.generateUniqueName(file.name, filesToVerifiLoaded[projectIndex].files)
				this.props.parsePDF({
					fileName: newName,
					base64: file.src,
					type: file.type,
					senderEmail: this.props.user.email
				}, file.projectId, file.size, pages)
				this.props.cacheUploadedFile({
					name: newName,
					size: file.size,
					projectId: file.projectId,
				})
			}
		}
	}

	countPdfPages = async (arrayBuffer, myResult, projectId) => {
		try {
			const loadingTask = pdfjs.getDocument(arrayBuffer);
			const pdf = await loadingTask.promise;
			const permissions = await pdf.getPermissions();
			const numPages = pdf.numPages;
			// if (permissions === null) {
			// 	console.log("Brak zabezpieczeń");
			// } else {
			// 	if (permissions.includes(pdfjs.PermissionFlag.MODIFY_CONTENTS)) {
			// 		console.log("Brak hasła do edycji");
			// 	} else {
			// 		console.log("Zabezpieczony hasłem do edycji");
			// 	}
			// }

			if (numPages >= 5) {
				this.setState({
					fileToChoosePages: { ...myResult, ...{ projectId: projectId } },
					showPdfPageSelector: true
				})
			} else {
				this.parseFiles(myResult, projectId)
			}
		} catch (err) {
			if (err.name === 'PasswordException') {
				this.props.alertWarn(this.context.t('A password-protected pdf file cannot be uploaded'))
			} else {
				this.props.alertWarn(this.context.t('Error while scanning pdf document'))
			}
		}

	}

	closePdfPagesSelector = () => {
		this.setState({
			fileToChoosePages: {},
			showPdfPageSelector: false
		})
	}

	generateUniqueName = (oldName, currentFiles) => {
		var baseName = oldName.replace(/\.[^\.]+$/, "")
		var extension = oldName.replace(baseName, "")
		var nameIsUnique = false
		var number = 1
		var nameToCheck = `${baseName}(${number})${extension}`
		while (!nameIsUnique) {
			if (currentFiles.find(e => e.name === nameToCheck)) {
				number++
				nameToCheck = `${baseName}(${number})${extension}`
			} else {
				nameIsUnique = true
			}
		}

		return nameToCheck
	}

	deleteFileFromDropzone = index => {
		var files = [...this.state.files]
		if (index !== -1) {
			files.splice(index, 1)
			this.setState({
				files: files
			})
		}
	}

	openUploadModal = (type, projectId) => {
		this.setState({
			modalOpen: true,
			docType: type,
			modalProjectId: projectId
		})
	}

	closeUploadModal = () => {
		this.setState({
			modalOpen: false,
			docType: '',
			modalProjectId: null
		})
	}

	onInputChange = e => {
		e.preventDefault()

		console.log("input change trigger")
		console.log(e)
		const { name, value } = e.target

		this.setState({ [name]: value })
	}

	setCurrentProject = id => {
		console.log("set current proj func")
		this.props.setCurrentProject(id)
	}

	toggleProjectsListInSingleView = () => {
		console.log("toogle list")
		this.setState(state => {
			return {
				showProjectsListInSingleView: !state.showProjectsListInSingleView
			}
		})
	}

	switchViewMode = (e, p, companyId) => {
		e.preventDefault()
		this.props.switchDashboardViewModel(p)

		var { userCompanies, currentCompany } = this.props

		if (currentCompany.id !== companyId) {
			var companyIndex = arrayObjectIndexOf(userCompanies, companyId, 'company_id')
			console.log(companyIndex)
			if (companyIndex > -1) {
				if (userCompanies[companyIndex].user_role === 'ADMIN') {
					this.props.setCurrentCompany(userCompanies[companyIndex])
				}
			}
		}
	}

	handleDropZoneClick = (e) => {
		console.log(e.target)
		console.log(e.target.id)
		if (e.target && e.target.id && (e.target.id.startsWith('fileRow') || e.target.id.startsWith('pendingFilesFilter') || e.target.id.startsWith('pendingFilesHeaderRow'))) {
			e.stopPropagation()
		} else {
			console.log('OPEN DIALOG')
		}
	}

	formatStorageLimit = limit => {
		if (limit || limit === 0) {
			limit = limit / 1024 / 1024
			limit = limit.toFixed(2)
			if (limit.indexOf('.') > -1) {
				var parts = limit.split(".");
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
				limit = parts.join('.')
			} else {
				limit = limit.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			}

			return limit
		} else {
			return '--'
		}
	}

	showOverlay = () => {
		const { overlayPreferance } = this.props
		this.setOverlaySize()
		overlayPreferance['dashboard'] = true
		this.props.toggleOverlay(overlayPreferance)
	}

	handleSubRenewalClick = (company) => {
		const { userCompanies, currentCompany } = this.props
		if (currentCompany.id !== company.id) {
			var companyIndex = arrayObjectIndexOf(userCompanies, company.id, 'company_id')
			console.log(companyIndex)
			if (companyIndex > -1) {
				if (userCompanies[companyIndex].user_role === 'ADMIN') {
					this.props.setCurrentCompany(userCompanies[companyIndex])
				}
			}
		}

		this.props.history.push('/admin/subscription/')
	}

	render() {
		const { gotOverlayPossitionsMulti, gotOverlayPossitionsSingle, overlayPossitions, showLastTransactionSuccess, subscriptionEndDate, showLastTransactionError, latestTransactionErrorCode, showPdfPageSelector, fileToChoosePages } = this.state
		const { user, overlayPreferance, userProjects, userCompanies, loadingScreen, viewMode, currentProject, highestAcceptancePermission, dashboardExtendedCalendars } = this.props

		if (userProjects) {
			return (
				<div className={`container-fluid dashboard`}>
					<DndProvider backend={HTML5Backend}>
						<DragFileLayer />
						<div className='show-overlay dashboard' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>

						{
							loadingScreen ? (
								<div className="loading-screen">
									<div className="activity-indicator">
										<Spinner size={50} speed={0.8} color={"#ffffff"} />
									</div>
								</div>
							) : (
								null
							)
						}
						{
							showLastTransactionSuccess ? (
								<SubscriptionTransactionSuccessPopup hideAfterPaymentPopup={this.hideAfterPaymentPopup} subscriptionEndDate={subscriptionEndDate} />
							) : (
								null
							)
						}

						{
							showLastTransactionError ? (
								<SubscriptionTransactionErrorPopup paymentError={{ responseCode: latestTransactionErrorCode, status: 'rejected' }} hideAfterPaymentPopup={this.hideAfterPaymentPopup} />
							) : (
								null
							)
						}

						{
							showPdfPageSelector ? (
								<PdfPagesSelector file={fileToChoosePages} saveFile={this.parseFiles} close={this.closePdfPagesSelector} />
							) : null
						}

						{
							viewMode === 'MULTI' && gotOverlayPossitionsMulti && overlayPreferance['dashboard'] ? (
								<OverlayInstructions possitions={overlayPossitions} name="dashboard" height={window.innerHeight} />
							) : (
								viewMode === 'SINGLE' && gotOverlayPossitionsSingle && overlayPreferance['dashboard'] ? (
									<OverlayInstructions possitions={overlayPossitions} name="dashboard" height={window.innerHeight} />
								) : (
									null
								)
							)
						}

						{
							viewMode === "MULTI" ? (
								userProjects.length > 0 ? (
									<div className="row">
										{
											userProjects.map((project, i) =>
												<div className={`col-projects`} key={project.id}>
													{
														project && project.company ? (
															<PaymentBaner project={project} company={project.company} myCompanies={userCompanies} renewCompany={this.handleSubRenewalClick} viewMode={viewMode} />
														) : null
													}
													{
														project && project.company && moment(project.company.subscription_expire_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') && !project.company.has_paid_subscription ? (
															<NoSubscriptionWarning
																userCompanies={[...userCompanies]}
																project={project}
																handleSubRenewalClick={this.handleSubRenewalClick}
															/>
														) : (
															null
														)
													}
													<h2>
														<span className={`favourite ${project.favourite ? '' : 'empty'}`} onClick={() => this.props.toggleFavouriteForProject(project.id, !project.favourite)}></span>
														<span className='view-link' onClick={(e) => this.switchViewMode(e, project.id, project.company_id)}>{project.code}</span>
													</h2>
													<div ref={`dropzoneRefMulti${i}`}>
														<Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(project.id, files, rejectedFiles)} accept={acceptedFileTypes} multiple={true} maxSize={imageMaxSize}>
															{({ getRootProps, getInputProps, isDragActive }) => (
																<div {...getRootProps({ onClick: event => this.handleDropZoneClick(event) })} className={`recently-uploaded dropzone ${isDragActive ? 'drop-active' : ''}`}>
																	<PendingFiles projectId={project.id} projectCode={project.code} simpleBarHeightWithDocs={'calc(33vh - 151px)'} simpleBarHeightWithoutDocs={'calc(33vh - 107px)'} accessLevel={project.acceptance_permissions} directory={project.root_dir} />
																	<input {...getInputProps()} type="file" />
																	<span className="clip-button"></span>
																</div>
															)}
														</Dropzone>
													</div>

													{
														userProjects.length < 5 ? (
															<div className="file-manager-container" ref={`fileManagerRefMulti${i}`}>
																<FileManager projectId={project.id} directory={project.root_dir} simpleBarHeight={'calc(33vh - 133px)'} noContentImageHeight={'calc(33vh - 133px)'} />
															</div>
														) : (
															null
														)
													}

													<div className="transfers-manager-container" ref={`paymentOrdersRefMulti${i}`}>
														<TransfersManager project={project} simpleBarHeight={'calc(33vh - 133px)'} noContentImageHeight={'calc(33vh - 133px)'} />
													</div>

													{/* <dir className="charts-container">
															<ChartsForMultiProjectView projectId={project.id}/>
														</dir> */}

													{
														project.acceptance_permissions !== 'USER1' ? (
															<div className="costs-container" ref={`costsRefMulti${i}`}>
																<ProjectCosts projectId={project.id} project={project} />
															</div>
														) : (
															null
														)
													}

													{
														userProjects.length < 5 ? (
															<div className="dashboard-calendar-container" ref={`calendarRefMulti${i}`}>
																<Calendar selectDay={null} unselectDay={null} selectedDay={null} events={null} projectId={project.id} dashboard={true} eventTooltipHeight={'40vh'} />
															</div>
														) : (
															null
														)
													}
												</div>
											)
										}
									</div>
								) : (
									<div className="no-project-dashboard">
										<h2>{this.context.t("It looks like you don't have any binders")}</h2>
									</div>
								)

							) : (
								<div className="row" style={{ position: 'relative' }}>
									{
										currentProject && currentProject.company ? (
											<PaymentBaner project={currentProject} company={currentProject.company} myCompanies={userCompanies} renewCompany={this.handleSubRenewalClick} viewMode={viewMode} />
										) : null
									}
									{
										currentProject && currentProject.company && moment(currentProject.company.subscription_expire_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') && !currentProject.company.has_paid_subscription ? (
											<NoSubscriptionWarning
												userCompanies={[...userCompanies]}
												project={currentProject}
												handleSubRenewalClick={this.handleSubRenewalClick}
											/>
										) : (
											null
										)
									}


									<div className="col-project-single-view">
										{
											currentProject ? (
												<div ref="dropzoneRefSingle">
													<Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(currentProject.id, files, rejectedFiles)} accept={acceptedFileTypes} multiple={true} maxSize={imageMaxSize}>
														{({ getRootProps, getInputProps, isDragActive }) => (
															<div {...getRootProps({ onClick: event => this.handleDropZoneClick(event) })} className={`recently-uploaded single-view dropzone${isDragActive ? ' drop-active' : ''}`}>
																<PendingFiles projectId={currentProject.id} projectCode={currentProject.code} simpleBarHeightWithDocs={'calc(50vh - 167px)'} simpleBarHeightWithoutDocs={'calc(50vh - 127px)'} accessLevel={currentProject.acceptance_permissions} directory={currentProject.root_dir} />
																<input {...getInputProps()} />
																<span className="clip-button"></span>
															</div>
														)}
													</Dropzone>
												</div>
											) : null
										}

										{
											currentProject ? (
												<div className="file-manager-container single-view" ref="filesRefSingle">
													<FileManager projectId={currentProject.id} directory={currentProject.root_dir} simpleBarHeight={'calc(50vh - 165px)'} noContentImageHeight={'calc(50vh - 167px)'} />
												</div>
											) : null
										}
									</div>
									<div className="col-project-single-view">
										{
											currentProject ? (
												<QuickActions project={currentProject} />
											) : null
										}
										{
											currentProject ? (
												<div className="transfers-manager-container single-view" ref="paymentOrdersRefSingle">
													<TransfersManager project={currentProject} simpleBarHeight={'calc(50vh - 132px)'} noContentImageHeight={'calc(50vh - 132px)'} />
												</div>
											) : null
										}

										{
											currentProject && currentProject.acceptance_permissions !== 'USER1' ? (
												<div className="costs-container single-view" ref="costsRefSingle">
													<ProjectCosts projectId={currentProject.id} project={currentProject} />
												</div>
											) : (
												null
											)
										}
									</div>
								</div>
							)
						}
					</DndProvider>
				</div>
			)
		} else {
			return (
				<div className="loading-projects">
					<div className="activity-indicator">
						<Bounce size={50} speed={0.8} color={"#fff"} />
					</div>
				</div>
			)
		}
	}
}

Dashboard.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
	viewMode: state.User.dashboardViewModel,
	userCompanies: state.Company.companies || [],
	currentCompany: state.Company.currentCompany,
	user: state.User.user,
	highestAcceptancePermission: state.User.highestAcceptancePermission,
	userProjects: state.User.userProjects,
	currentProject: state.User.currentProject || {},
	selectedDay: state.Event.selectedDay,
	loadingScreen: state.File.loadingScreen || false,
	monthOnDashboardCalendar: state.User.monthOnDashboardCalendar,
	dashboardExtendedCalendars: state.Event.dashboardExtendedCalendars || [],

	filesToVerifiLoaded: state.File.filesToVerifiLoaded,
	uploadedFiles: isIterable(state.File.uploadedFiles) ? [...state.File.uploadedFiles] : [],
	paymentOrders: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],

	//overlay var
	overlayPreferance: state.User.overlayPreferance || {},
})

const mapDispatchToProps = {
	toggleFavouriteForProject: UserActions.toggleFavouriteForProject,
	switchDashboardViewModel: UserActions.switchDashboardViewModel,
	parsePDF: FileActions.parsePDF,
	cacheUploadedFile: FileActions.cacheFile,
	getUserProjects: UserActions.getUserProjects,
	setCurrentProject: UserActions.setCurrentProject,
	togglePdfPreview: FileActions.togglePdfPreview,
	changeMontOnDashboardCalendar: UserActions.changeMontOnDashboardCalendar,
	toggleOverlay: UserActions.toggleOverlay,

	getFileData: FileActions.getFileData,
	openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,

	setCurrentCompany: CompanyActions.setCurrentCompany,

	extendProjectCosts: ProjectCostsActions.extendProjectCosts,
	extendProjectPaymentOrders: TransfersActions.toggleProjectPaymentOrders,
	extendProjectFiles: FileActions.toggleProjectFiles,
	toggleDashboardCalendarForProject: EventActions.toggleDashboardCalendarForProject,

	getMyLatestSubsctiptionPayment: CompanyActions.getMyLatestSubsctiptionPayment,

	alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)