import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf';
import { Digital, Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import throttle from "lodash.throttle"

import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import Calendar from '../../components/Calendar'
import ReportsList from '../../components/ReportsPageComponents/ReportsList'
import ExportedFiles from '../../components/ReportsPageComponents/ExportedFiles'
import BankTransferFilesList from '../../components/ReportsPageComponents/BankTransferFilesList'
import AccountingFilesList from '../../components/ReportsPageComponents/AccountingFilesList'
import CompanyBankInformationPopup from '../../components/CompanyBankInformationPopup'
import CompanyBanks from '../../components/CompanyBanks'
import FilesPreviewInReports from '../../components/ReportsPageComponents/FilesPreviewInReports'
import OverlayInstructions from '../../components/OverlayInstructions'
import AvailableInOtherPlanLabel from '../../components/AvailableInOtherPlanLabel'
import ProjectSwitcher from '../../components/ProjectSwitcher'
import TableColumnsCustomizer from '../../components/TableCoumnsCustomizer/TableColumnsCustomizer'
import CostsTableVirtualized from '../../components/ReportsPageComponents/CostsTableVirtualized'
import CostsTableVirtualizedTest from '../../components/ReportsPageComponents/CostsTableVirtualized-Grid'
import ExpensesTableVirtualized from '../../components/ReportsPageComponents/ExpensesTableVirtualized'
import AgreementsTableVirtualized from '../../components/ReportsPageComponents/AgreementsTableVirtualized'
import CorrespondenceTableVirtualized from '../../components/ReportsPageComponents/CorrespondenceTableVirtualized'
import CorporateTableVirtualized from '../../components/ReportsPageComponents/CorporateTableVirtualized'
import OtherDocsTableVirtualized from '../../components/ReportsPageComponents/OtherDocsTableVirtualized'
import IncomesTableVirtualized from '../../components/ReportsPageComponents/IncomesTableVirtualized'
import FilterRow from '../../components/ReportsPageComponents/FilterRow'
import TagsBulkEditWindow from '../../components/ReportsPageComponents/TagsBulkEditWindow';

import { UserActions } from '../../actions/UserActions'
import { FileActions } from '../../actions/FileActions'
import { EventActions } from '../../actions/EventActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { ReportsActions } from '../../actions/ReportsActions'
import { AlertActions } from '../../actions/AlertActions'
import { TransfersActions } from '../../actions/TransfersActions';
import { MessagesActions } from '../../actions/MessagesActions'
import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'
import { socket } from "../../App"

import arrayObjectIndexOf from '../../helpers/indexOfObject';
import waproUsers from '../../helpers/waproUsers.json'
import currencyList from '../../helpers/currencyList.json'
import paymentMethodsList from '../../helpers/paymentMethodsList.json'

import { AvailableTabs, ActionsTabsComponents, ActionsHistoryTabsComponents } from "../../reducers/V2_TabsReducer"

import './../../css/Report.css'


export class Reports extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentTab: 'currentReport',
            todat: moment(),
            focusedField: '',
            dateFilter: 'importDate',
            dateFilterText: 'Import date',
            showDateFilterList: false,
            startDate: '',
            startDateValue: '',
            showStartDatePicker: false,
            endDate: '',
            endDateValue: '',
            showEndDatePicker: false,
            showProjectsList: false,
            selectedProject: {
                name: '<expand list>',
                id: null
            },
            selectedProjects: [],
            selectedProjectCompany: null,
            roleInSelectedProject: '',
            readOnlyProjectRole: false,
            canDownloadFilesInProject: true,
            showTypesList: false,
            selectedType: 'Costs',
            docsSelectedForReport: [],
            selectedDocsSums: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, 0])
                })
            ),
            alreadyPaidDocs: [],
            uploadedBy: '',
            sellectedExpenseCurrency: '',
            currencySelectedForTransferBuckets: '',
            docClickedToPay: null,
            fileClickedToPay: null,
            projectClickedToPay: null,
            posX: 0,
            posY: 0,
            posUp: false,
            selectedAll: false,
            selectFor: 'customReport',
            sendRequest: false,

            accountNumberPln: '',
            accountNumberEur: '',
            showUpdateAccountNumbersModal: false,

            showCompanyBankAccountPopup: false,

            showTableColumnsCustomizer: false,

            //filter values
            searchField: '',
            searchCurrency: 'ALL',
            showFilterCurrencySelector: false,
            selectOnlyAvailableForReport: false,
            selectOnlyAvailableForBankTransferFile: false,
            selectOnlyNotPaid: false,
            selectOnlyNotAccounted: false,

            //overlay
            gotOverlayPossitions: false,
            overlayPossitions: []
        }

        this.reportsFormRef = React.createRef()
        this.optionsRowRef = React.createRef()
        this.tableRef = React.createRef()

        this.tabsListContainer = React.createRef()
        this.actionsListContainer = React.createRef()
    }

    componentDidMount() {
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))
        //ReactGA.pageview(window.location.pathname + window.location.search)
        window.addEventListener('scroll', this.handleScrollToElement);
        window.addEventListener("keydown", this.escFunction, false)
        document.addEventListener("mousedown", this.handleClick);

        const { subComponent } = this.props
        if (!subComponent || subComponent === 'TABLE') {
            this.setState({
                focusedField: 'endDate'
            })

            const { user } = this.props
            var accountNumberPln = '', accountNumberEur = ''
            if (user.banks) {
                user.banks.map(b => {
                    if (b.currency === 'PLN') {
                        accountNumberPln = b.account_number
                    } else {
                        accountNumberEur = b.account_number
                    }
                })

                this.setState({
                    accountNumberPln: accountNumberPln,
                    accountNumberEur: accountNumberEur,
                })
            }

            if (socket) {
                socket.on('createNewReport', this.checkSelectedDocsForReport)
                socket.on('createNewTransfersFile', this.checkSelectedDocsForTransfers)
            }

            var queryParams = this.props.location.search
            var selectedProject = { name: '<expand list>', id: null }, defaultDateFrom, defaultDateTo = moment(), roleInSelectedProject = 'USER3', selectedType = 'Costs', dateFilter = 'importDate', dateFilterText = 'Import date', readOnlyProjectRole = false, canDownloadFilesInProject = true

            console.log('befoere if')
            const { currentSelectedProject, userProjects } = this.props
            if (currentSelectedProject && currentSelectedProject.id) {
                console.log('SET CURRENT PROJECT 1')
                selectedProject = {
                    name: currentSelectedProject.code,
                    id: currentSelectedProject.id
                }
                roleInSelectedProject = currentSelectedProject.acceptance_permissions
                readOnlyProjectRole = currentSelectedProject.read_only
                canDownloadFilesInProject = currentSelectedProject.file_download
            } else if (userProjects && userProjects.length >= 1) {
                console.log('SET CURRENT PROJECT 2')
                var projectIndex = 0
                if (localStorage.getItem('dashboardProject') && (userProjects)) {
                    var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject'))
                    if (dashboardProjectId > -1) {
                        projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
                    }
                }
                if (projectIndex === -1) projectIndex = 0
                selectedProject = {
                    name: userProjects[projectIndex].code,
                    id: userProjects[projectIndex].id
                }
                roleInSelectedProject = userProjects[0].acceptance_permissions
                readOnlyProjectRole = userProjects[0].read_only
                canDownloadFilesInProject = userProjects[0].file_download
            } else if (!userProjects || userProjects.length === 0) {
                console.log('NONO SET CURRENT PROJECT')
                this.props.getUserProjects()
            }

            if (moment().date() < 20) {
                defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            } else {
                defaultDateFrom = moment().startOf('month')
            }

            if (queryParams) {
                queryParams = queryParams.replace('?', '')

                var paramsArray = queryParams.split('&')

                paramsArray.forEach(p => {
                    var name = p.split('=')[0]
                    var value = p.split('=')[1]

                    if (name === 'p') {
                        var project = userProjects.find(e => e.id === parseInt(value))

                        if (project) {
                            selectedProject.id = project.id
                            selectedProject.name = project.code
                        }
                    } else if (name === 'm') {
                        if (value === 'c') {
                            defaultDateFrom = moment().startOf('month')
                            defaultDateTo = moment().endOf('month')
                        } else if (value === 'p') {
                            defaultDateFrom = moment().subtract(1, 'month').startOf('month')
                            defaultDateTo = moment().subtract(1, 'month').endOf('month')
                        }
                    } else if (name === 't') {
                        if (value === 'u') {
                            this.setState({
                                selectOnlyNotPaid: true
                            })
                        }
                    } else if (name === 'd') {
                        defaultDateFrom = moment(value, 'MM-DD-YYYY')
                    } else if (name === 'search') {
                        this.setState({
                            searchField: value
                        })
                    }
                })
            }

            this.setState({
                dateFilter: dateFilter,
                dateFilterText: dateFilterText,
                selectedType: selectedType,
                roleInSelectedProject: roleInSelectedProject,
                readOnlyProjectRole: readOnlyProjectRole,
                canDownloadFilesInProject: canDownloadFilesInProject,
                selectedProject: selectedProject,
                startDate: defaultDateFrom.format('YYYY-MM-DD'),
                startDateValue: defaultDateFrom,
                endDate: defaultDateTo.format('YYYY-MM-DD'),
                endDateValue: defaultDateTo
            })

            console.log(selectedProject)

            if (selectedProject.id) {
                this.props.switchDashboardViewModel(selectedProject.id)
                console.log(`getDocuments LINE 255 project: ${selectedProject.id}`)
                this.props.getDocuments(defaultDateFrom, defaultDateTo, selectedProject.id, selectedType, 'importDate')
            }
        } else if (subComponent === 'EXCELS') {
            this.setState({
                currentTab: 'listOfReports'
            })
        } else if (subComponent === 'TRANSFERS') {
            this.setState({
                currentTab: 'listOfBankTransferFiles'
            })
        } else if (subComponent === 'ZIPS') {
            this.setState({
                currentTab: 'listOfZips'
            })
        } else if (subComponent === 'ACCOUNTING') {
            this.setState({
                currentTab: 'listOfAccountingFiles'
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { subComponent } = this.props
        if (!subComponent || subComponent === 'TABLE') {
            ReactTooltip.rebuild()
            const { reportsStartDate, reportsEndDate, userProjects, currentSelectedProject, userCompanies, transfers, user } = this.props
            const { selectedProjects } = this.state
            var sentGetDocsRequest = false
            if (selectedProjects.length < 2 && prevProps.currentSelectedProject && currentSelectedProject && prevProps.currentSelectedProject.id !== currentSelectedProject.id) {
                console.log(`setCurrentProject LINE 282 project: ${currentSelectedProject.id}`)
                sentGetDocsRequest = true
                this.setCurrentProject(currentSelectedProject.id, currentSelectedProject.company_id, null)
            }

            if (reportsStartDate !== prevProps.reportsStartDate) {
                if (this.state.focusedField === 'startDate') {
                    this.setState({
                        startDate: reportsStartDate.display || '',
                        startDateValue: reportsStartDate.value || ''
                    })
                }
            } else if (reportsEndDate !== prevProps.reportsEndDate) {
                if (this.state.focusedField === 'endDate') {
                    this.setState({
                        endDate: reportsEndDate.display || '',
                        endDateValue: reportsEndDate.value || ''
                    })
                }
            }

            if (!prevProps.userProjects && userProjects) {
                var selectedProject = { name: '<expand list>', id: null }, roleInSelectedProject = 'USER3', selectedType = 'Costs', readOnlyProjectRole = false, canDownloadFilesInProject = true

                if (userProjects && userProjects.length >= 1) {
                    console.log('SET CURRENT PROJECT 2')
                    var projectIndex = 0
                    if (localStorage.getItem('dashboardProject') && (userProjects)) {
                        var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject'))
                        if (dashboardProjectId > -1) {
                            projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
                        }
                    }
                    if (projectIndex === -1) projectIndex = 0
                    selectedProject = {
                        name: userProjects[projectIndex].code,
                        id: userProjects[projectIndex].id
                    }
                    roleInSelectedProject = userProjects[0].acceptance_permissions
                    readOnlyProjectRole = userProjects[0].read_only
                    canDownloadFilesInProject = userProjects[0].file_download

                    this.setState({
                        selectedType: selectedType,
                        roleInSelectedProject: roleInSelectedProject,
                        readOnlyProjectRole: readOnlyProjectRole,
                        canDownloadFilesInProject: canDownloadFilesInProject,
                        selectedProject: selectedProject
                    })

                    this.props.switchDashboardViewModel(selectedProject.id)
                    console.log(`getDocuments LINE 327 project: ${selectedProject.id}`)
                    if (!sentGetDocsRequest) this.props.getDocuments(this.state.startDateValue, this.state.endDateValue, selectedProject.id, selectedType, this.state.dateFilter)
                }
            }

            if (!this.state.selectedProjectCompany && this.state.selectedProject.id) {
                var projectIndex = arrayObjectIndexOf(userProjects, this.state.selectedProject.id, 'id')

                if (projectIndex > -1) {
                    var companyIndex = arrayObjectIndexOf(userCompanies, userProjects[projectIndex].company_id, 'id')

                    if (companyIndex > -1) {
                        if (userCompanies[companyIndex].company) {
                            this.setState({
                                selectedProjectCompany: userCompanies[companyIndex].company
                            })
                            this.changeSelectFor(null, 'customReport')
                        }
                    }
                }
            }

            const { docsDownloadedForRaport, gotDocumentsResponse, overlayPreferance } = this.props
            if (!this.state.gotOverlayPossitions && docsDownloadedForRaport && docsDownloadedForRaport.length > 0 && gotDocumentsResponse && overlayPreferance['reports']) {
                if (this.reportsFormRef.current && this.optionsRowRef.current && this.tableRef.current) {
                    var holesToDraw = []
                    const scrollBarWidth = window.innerWidth - document.body.offsetWidth
                    var formBox = this.reportsFormRef.current.getBoundingClientRect()
                    var optionsBox = this.optionsRowRef.current.getBoundingClientRect()
                    var tableBox = this.tableRef.current.getBoundingClientRect()

                    if (formBox) {
                        holesToDraw.push({
                            x: formBox.x,
                            y: formBox.y,
                            w: formBox.width + scrollBarWidth,
                            h: formBox.height
                        })
                    }
                    if (optionsBox) {
                        holesToDraw.push({
                            x: optionsBox.x,
                            y: optionsBox.y,
                            w: optionsBox.width + scrollBarWidth,
                            h: optionsBox.height
                        })
                    }
                    if (tableBox) {
                        holesToDraw.push({
                            x: tableBox.x,
                            y: tableBox.y,
                            w: 60,
                            h: tableBox.height
                        })
                    }
                    if (holesToDraw.length === 3 && holesToDraw[2].h > 0) {
                        this.setState({
                            gotOverlayPossitions: true,
                            overlayPossitions: holesToDraw
                        })
                    }
                }
            }

            if (prevProps.user.banks !== user.banks) {
                const { user } = this.props
                var accountNumberPln = '', accountNumberEur = ''
                if (user.banks) {
                    user.banks.map(b => {
                        if (b.currency === 'PLN') {
                            accountNumberPln = b.account_number
                        } else {
                            accountNumberEur = b.account_number
                        }
                    })

                    this.setState({
                        accountNumberPln: accountNumberPln,
                        accountNumberEur: accountNumberEur,
                    })
                }
            }
        } else if (prevProps.subComponent !== subComponent) {
            if (subComponent === 'EXCELS') {
                this.setState({
                    currentTab: 'listOfReports'
                })
            } else if (subComponent === 'TRANSFERS') {
                this.setState({
                    currentTab: 'listOfBankTransferFiles'
                })
            } else if (subComponent === 'ZIPS') {
                this.setState({
                    currentTab: 'listOfZips'
                })
            } else if (subComponent === 'ACCOUNTING') {
                this.setState({
                    currentTab: 'listOfAccountingFiles'
                })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
        window.removeEventListener('scroll', this.handleScrollToElement);
        socket.off('createNewReport', this.checkSelectedDocsForReport)
        socket.on('createNewTransfersFile', this.checkSelectedDocsForTransfers)
        window.removeEventListener("keydown", this.escFunction, false)
        document.removeEventListener("mousedown", this.handleClick);
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            const { showProjectsList, showTypesList, showDateFilterList, showStartDatePicker, showEndDatePicker, showFilterCurrencySelector } = this.state
            if (showProjectsList || showTypesList || showDateFilterList || showStartDatePicker || showEndDatePicker || showFilterCurrencySelector) {
                this.closeAllSuggestions(e)
            } else if (this.state.posX !== 0) {
                this.hidePaymentMethodsList(e)
            }
            if (this.state.showUpdateAccountNumbersModal) {
                this.setState({
                    showUpdateAccountNumbersModal: false,
                    requiredAccountNumber: '',
                })
            }
        }
    }

    handleClick = (event) => {
        const { showActionsList, showTabsList } = this.state
        if (showTabsList && this.tabsListContainer && this.tabsListContainer.current && !this.tabsListContainer.current.contains(event.target)) {
            this.setState({
                showTabsList: false
            })
        } else if (showActionsList && this.actionsListContainer && this.actionsListContainer.current && !this.actionsListContainer.current.contains(event.target)) {
            this.setState({
                showActionsList: false
            }, () => {
                ReactTooltip.rebuild()
            })
        }
    }

    setOverlaySize = () => {
        const { docsDownloadedForRaport, gotDocumentsResponse, overlayPreferance } = this.props
        if (this.reportsFormRef.current && this.optionsRowRef.current && this.tableRef.current) {
            var holesToDraw = []
            const scrollBarWidth = window.innerWidth - document.body.offsetWidth
            var formBox = this.reportsFormRef.current.getBoundingClientRect()
            var optionsBox = this.optionsRowRef.current.getBoundingClientRect()
            var tableBox = this.tableRef.current.getBoundingClientRect()

            if (formBox) {
                holesToDraw.push({
                    x: formBox.x,
                    y: formBox.y,
                    w: formBox.width + scrollBarWidth,
                    h: formBox.height
                })
            }
            if (optionsBox) {
                holesToDraw.push({
                    x: optionsBox.x,
                    y: optionsBox.y,
                    w: optionsBox.width + scrollBarWidth,
                    h: optionsBox.height
                })
            }
            if (tableBox) {
                holesToDraw.push({
                    x: tableBox.x,
                    y: tableBox.y,
                    w: 60,
                    h: tableBox.height
                })
            }
            if (holesToDraw.length === 3 && holesToDraw[2].h > 0) {
                this.setState({
                    gotOverlayPossitions: true,
                    overlayPossitions: holesToDraw
                })
            }
        }
    }

    handleScrollToElement = (event) => {
        if (this.refs.summaryRow) {
            if (this.refs.summaryRow.getBoundingClientRect().top < 0 && !this.state.stickSummaryRow) {
                this.setState({
                    stickSummaryRow: true
                })
                this.refs.summaryRow.classList.add('offset')
            } else if (this.refs.summaryRow.getBoundingClientRect().top > 0 && this.state.stickSummaryRow && this.refs.summaryRow.getBoundingClientRect().top !== 240) {
                this.setState({
                    stickSummaryRow: false
                })
                this.refs.summaryRow.classList.remove('offset')
            }
        }
    }

    checkSelectedDocsForReport = (data) => {
        if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {

            const { selectFor } = this.state
            var currentSelectedDocs = [...this.state.docsSelectedForReport]
            const { docsDownloadedForRaport } = this.props

            if (docsDownloadedForRaport.length > 0) {
                var exampleDoc = docsDownloadedForRaport[0]

                if (exampleDoc.projectId === data.projectId) {
                    this.props.addMessage(data.user, 'generatedReport', this.props.userProjects.find(e => e.id === data.projectId), 0, data.projectId, data.reportName, moment())

                    if (currentSelectedDocs.length > 0 && selectFor === 'report') {
                        data.docs.forEach(d => {
                            currentSelectedDocs = currentSelectedDocs.filter(e => {
                                if (e.id === d.id && e.type === d.type) return false
                                return true
                            })
                        })

                        this.setState({
                            docsSelectedForReport: currentSelectedDocs
                        })
                    }
                }
            }
        }
    }

    checkSelectedDocsForTransfers = data => {
        if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
            const { selectFor } = this.state
            var currentSelectedDocs = [...this.state.docsSelectedForReport]
            const { docsDownloadedForRaport } = this.props

            if (docsDownloadedForRaport.length > 0) {
                var exampleDoc = docsDownloadedForRaport[0]

                if (exampleDoc.projectId === data.projectId) {
                    this.props.addMessage(data.user, 'generatedTransfersFile', this.props.userProjects.find(e => e.id === data.projectId), 0, data.projectId, data.fileName, moment())

                    if (currentSelectedDocs.length > 0 && selectFor === 'transfer') {
                        data.docs.forEach(d => {
                            currentSelectedDocs = currentSelectedDocs.filter(e => {
                                if (e.id === d.id && e.type === d.type) return false
                                return true
                            })
                        })

                        this.setState({
                            docsSelectedForReport: currentSelectedDocs
                        })
                    }
                }
            }
        }
    }

    clickOnDatePicker = (e, name) => {
        if (e.target.className.includes('day-cell')) {
            if (name === 'start') {
                this.setState({
                    showStartDatePicker: false
                })
            } else if (name === 'end') {
                this.setState({
                    showEndDatePicker: false
                })
            }

        }
    }

    showDatePicker = (e, name) => {
        {
            if (name === 'start') {
                this.setState({
                    showStartDatePicker: true,
                    focusedField: 'startDate'
                })
            } else {
                this.setState({
                    showEndDatePicker: true,
                    focusedField: 'endDate'
                })
            }
        }
    }

    hideDatePicker = (e, name) => {
        if (name === 'start') {
            this.setState({
                showStartDatePicker: false,
                focusedField: '',
            })
        } else if (name === 'end') {
            this.setState({
                showEndDatePicker: false,
                focusedField: '',
            })
        }
    }

    hideSuggestionsList = (e, name) => {
        if (name === 'projects') {
            this.setState({
                showProjectsList: false,
                focusedField: '',
            })
        } else if (name === 'types') {
            this.setState({
                showTypesList: false,
                focusedField: '',
            })
        }
    }

    inputChangeDate = (e, name) => {
        if (e.keyCode === 8) {
            if (name === 'start') this.props.unselectStartDate()
            else if (name === 'end') this.props.unselectEndDate()
        }
    }

    toggleProjectsListInSingleView = () => {
        this.setState(state => {
            return {
                showProjectsList: !state.showProjectsList,
                showTypesList: false,
                showStartDatePicker: false,
                showEndDatePicker: false,
                focusedField: '',
            }
        })
    }

    setCurrentProject = (id, companyId, e) => {
        const { selectedProject } = this.state
        const { userProjects } = this.props
        const project = userProjects.find(p => p.id === id)
        if (selectedProject && selectedProject.id !== id && project) {
            this.setState({
                selectedProject: { id: id, name: project?.code },
                roleInSelectedProject: project?.acceptance_permissions,
                readOnlyProjectRole: project?.read_only,
                canDownloadFilesInProject: project?.file_download,
                showProjectsList: false,
                selectedProjects: []
            }, () => {
                this.getDocs(e, id)

                var { userCompanies, currentCompany } = this.props
                if (currentCompany && currentCompany.company_id !== companyId) {
                    var companyIndex = arrayObjectIndexOf(userCompanies, companyId, 'company_id')
                    console.log(companyIndex)
                    if (companyIndex > -1) {
                        if (userCompanies[companyIndex].user_role === 'ADMIN') {
                            this.props.setCurrentCompany(userCompanies[companyIndex])
                        }
                    }
                }
            })
        }
    }

    toggleTypesListInSingleView = (e) => {

        if (e.target && e.target.className && !e.target.className.includes('next')) {
            this.setState(state => {
                return {
                    showTypesList: !state.showTypesList,
                    showProjectsList: false,
                    showStartDatePicker: false,
                    showEndDatePicker: false,
                    focusedField: '',
                }
            })
        }
    }

    setMultiSelectedProjects = projects => {
        this.setState({
            selectedProjects: projects,
            selectedProject: { id: null, name: null }
        })
    }

    setCurrentType = type => {
        this.setState({
            selectedType: type,
            showTypesList: false,
        })
    }

    getDocs = (e, projectId) => {
        if (e) e.preventDefault()

        const { startDateValue, endDateValue, selectedProject, selectedProjects, selectedType, docsSelectedForReport, dateFilter } = this.state
        var valid = true

        if (selectedType.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('Document type is required'))
        }

        if (selectedProjects && selectedProjects.length > 0) {
            selectedProjects.map(p => {
                if (!p.id) {
                    valid = false
                }
            })

            if (!valid) {
                this.props.alertWarn(this.context.t('Binder is required'))
            }
        } else if (!selectedProject.id) {
            valid = false
            this.props.alertWarn(this.context.t('Binder is required'))
        }

        if (!startDateValue) {
            valid = false
            this.props.alertWarn(this.context.t('Start date is required '))
        }
        if (!endDateValue) {
            valid = false
            this.props.alertWarn(this.context.t('End date is required'))
        }

        if (startDateValue && endDateValue && moment(startDateValue).diff(moment(endDateValue), 'days', true) > 0) {
            valid = false
            this.props.alertWarn(this.context.t('Start date must be before end date'))
        }

        if (valid) {
            this.resetFilters()
            var searchDocType = selectedType
            if (searchDocType === 'Warehouse') searchDocType = 'Mag'
            //this.props.switchDashboardViewModel(selectedProject.id)
            if (selectedProjects && selectedProjects.length > 0) {
                this.props.getDocumentsCrossProjects(startDateValue, endDateValue, selectedProjects, searchDocType, dateFilter)
            } else {
                console.log(`getDocuments LINE 780 project: ${selectedProject.id}`)
                this.props.getDocuments(startDateValue, endDateValue, projectId || selectedProject.id, searchDocType, dateFilter)
            }

            this.setState({
                alreadyPaid: [],
                docsSelectedForReport: [],
                selectedDocsSums: Object.fromEntries(
                    currencyList.map(c => {
                        return ([c, 0])
                    })
                ),
                sendRequest: true,
                selectFor: 'customReport',
                currencySelectedForTransferBuckets: '',
                sellectedExpenseCurrency: ''
            })

            if (selectedType !== 'Costs' && selectedType !== 'Expenses') {
                this.setState({
                    selectFor: 'zip',
                })
            }

            if (projectId || (selectedProject && selectedProject.id)) {
                const { transfers } = this.props
                if (!transfers.find(e => e.project === selectedProject.id)) {
                    this.props.getTransfers(projectId || selectedProject.id, -1)
                }
            }

            if (selectedProjects && selectedProjects.length > 0) {
                const { userProjects, userCompanies, currentCompany } = this.props
                var newMainProject = userProjects.find(p => p.id === selectedProjects[0].id)
                if (newMainProject) {
                    this.props.switchDashboardViewModel(newMainProject.id)

                    if (currentCompany && currentCompany.id !== newMainProject.company_id) {
                        var companyIndex = arrayObjectIndexOf(userCompanies, newMainProject.company_id, 'company_id')
                        console.log(companyIndex)
                        if (companyIndex > -1) {
                            if (userCompanies[companyIndex].user_role === 'ADMIN') {
                                this.props.setCurrentCompany(userCompanies[companyIndex])
                            }
                        }
                    }
                }
            }
        }
    }

    addDocToSelected = (e, doc) => {
        e.preventDefault()
        var currentSelected = this.state.docsSelectedForReport
        const { selectFor, uploadedBy, sellectedExpenseCurrency, currencySelectedForTransferBuckets } = this.state
        var canSelectExpense = true
        var canSelectForTansfersFile = true

        if (selectFor === 'report') {
            if (uploadedBy === '' && doc.uploadedByUser && this.props.downloadedDocsType === 'Expenses') {
                this.setState({
                    uploadedBy: doc.uploadedById
                })
            } else if (uploadedBy !== doc.uploadedById && doc.uploadedByUser && this.props.downloadedDocsType === 'Expenses') {
                canSelectExpense = false
                this.props.alertWarn(this.context.t('You can only select expenses of one employee at a time'))
            }
            if (sellectedExpenseCurrency === '' && doc.currency !== '' && this.props.downloadedDocsType === 'Expenses') {
                this.setState({
                    sellectedExpenseCurrency: doc.currency
                })
            } else if (sellectedExpenseCurrency !== '' && sellectedExpenseCurrency !== doc.currency && this.props.downloadedDocsType === 'Expenses') {
                canSelectExpense = false
                this.props.alertWarn(this.context.t('Selected expenses must be in the same currency'))
            }
        } else if (selectFor === 'transfer') {
            if (doc.alreadyInBankTransferFile) {
                canSelectForTansfersFile = false
            } else if (currencySelectedForTransferBuckets === '' && doc.currency !== '') {
                this.setState({
                    currencySelectedForTransferBuckets: doc.currency
                })
            } else if (currencySelectedForTransferBuckets !== '' && currencySelectedForTransferBuckets !== doc.currency) {
                canSelectForTansfersFile = false
                this.props.alertWarn(this.context.t('Selected documents must be in the same currency'))
            }
        }

        if ((selectFor === 'report' && doc.canPay && !doc.alreadyInReport && canSelectExpense) || selectFor === 'zip' || selectFor === 'tags' || selectFor === 'customReport' || (selectFor === 'transfer' && canSelectForTansfersFile)) {
            if (currentSelected.find(e => e.id === doc.id)) {
                if (currentSelected.length === 1) {
                    this.setState({
                        uploadedBy: '',
                        sellectedExpenseCurrency: '',
                        currencySelectedForTransferBuckets: ''
                    })
                }
                var { selectedDocsSums } = this.state
                selectedDocsSums[doc.currency] -= parseFloat(doc.price)

                this.setState({
                    docsSelectedForReport: currentSelected.filter(e => {
                        if (e.id === doc.id && e.type === doc.type) return false
                        return true
                    }),
                    selectedDocsSums: selectedDocsSums,
                })
            } else {
                currentSelected.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, price: doc.price, tags: doc.tags })
                var { selectedDocsSums } = this.state
                selectedDocsSums[doc.currency] += parseFloat(doc.price)

                this.setState({
                    docsSelectedForReport: currentSelected,
                    selectedDocsSums: selectedDocsSums,
                })
            }
        }

        if (selectFor === 'wapro') {
            if (doc.availableForWaproExport) {
                if (currentSelected.find(e => e.id === doc.id)) {
                    if (currentSelected.length === 1) {
                        this.setState({
                            uploadedBy: '',
                            sellectedExpenseCurrency: '',
                            currencySelectedForTransferBuckets: ''
                        })
                    }
                    var { selectedDocsSums } = this.state
                    selectedDocsSums[doc.currency] -= parseFloat(doc.price)
                    this.setState({
                        docsSelectedForReport: currentSelected.filter(e => {
                            if (e.id === doc.id && e.type === doc.type) return false
                            return true
                        }),
                        selectedDocsSums: selectedDocsSums
                    })
                } else {
                    currentSelected.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, price: doc.price, tags: doc.tags })
                    var { selectedDocsSums } = this.state
                    selectedDocsSums[doc.currency] += parseFloat(doc.price)

                    this.setState({
                        docsSelectedForReport: currentSelected,
                        selectedDocsSums: selectedDocsSums
                    })
                }
            } else {
                this.props.alertWarn(this.context.t(doc.notAvailableForWaproMsg))
            }
        }

        if (selectFor === 'symfonia') {
            if (!doc.missingSymfoniaFields) {
                if (currentSelected.find(e => e.id === doc.id)) {
                    if (currentSelected.length === 1) {
                        this.setState({
                            uploadedBy: '',
                            sellectedExpenseCurrency: '',
                            currencySelectedForTransferBuckets: ''
                        })
                    }
                    var { selectedDocsSums } = this.state
                    selectedDocsSums[doc.currency] -= parseFloat(doc.price)
                    this.setState({
                        docsSelectedForReport: currentSelected.filter(e => {
                            if (e.id === doc.id && e.type === doc.type) return false
                            return true
                        }),
                        selectedDocsSums: selectedDocsSums
                    })
                } else {
                    currentSelected.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, price: doc.price, tags: doc.tags })
                    var { selectedDocsSums } = this.state
                    selectedDocsSums[doc.currency] += parseFloat(doc.price)

                    this.setState({
                        docsSelectedForReport: currentSelected,
                        selectedDocsSums: selectedDocsSums
                    })
                }
            } else {
                this.props.alertWarn(this.context.t('This document does not have all the required fields for importing to Symfonia'))
            }
        }
    }

    selectAll = (e) => {
        e.preventDefault()

        const { docsDownloadedForRaport } = this.props
        const { selectFor } = this.state
        var { currencySelectedForTransferBuckets } = this.state
        var currentSelectedDocs = [...this.state.docsSelectedForReport]
        var coptyCurrentSelectedDocs = [...currentSelectedDocs]
        var { selectedDocsSums } = this.state
        const { sellectedExpenseCurrency } = this.state
        var oneSelected = false

        if (selectFor === 'report') {
            if (this.props.downloadedDocsType === 'Expenses') {
                console.log('selectAll 1st if 2nd if- expenses')
                var docCurrency = sellectedExpenseCurrency
                var oneCurrency = true
                var uploadedBy = this.state.uploadedBy
                var canSelectAll = true

                docsDownloadedForRaport.forEach(doc => {
                    if (canSelectAll && oneCurrency) {
                        if (doc.canPay && !doc.alreadyInReport) {
                            if (uploadedBy === '' && doc.uploadedById) {
                                uploadedBy = doc.uploadedById
                            } else if (doc.uploadedById && doc.uploadedById !== uploadedBy) {
                                canSelectAll = false
                            }

                            if (docCurrency === '') {
                                docCurrency = doc.currency
                            } else if (docCurrency !== doc.currency) {
                                oneCurrency = false
                            }

                            if (canSelectAll && oneCurrency) {
                                if (!currentSelectedDocs.find(d => d.id === doc.id) && this.checkIfDocMatchesFilterOptions(doc)) {
                                    currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, tags: doc.tags })
                                    oneSelected = true

                                    selectedDocsSums[doc.currency] += parseFloat(doc.price)
                                }
                            }
                        }
                    }
                })

                if (canSelectAll && oneCurrency) {
                    if (oneSelected) {
                        this.setState({
                            docsSelectedForReport: currentSelectedDocs,
                            selectedDocsSums: selectedDocsSums,
                            sellectedExpenseCurrency: docCurrency,
                            uploadedBy: uploadedBy,
                        })
                    } else {
                        this.setState({
                            docsSelectedForReport: [],
                            selectedDocsSums: Object.fromEntries(
                                currencyList.map(c => {
                                    return ([c, 0])
                                })
                            ),
                            sellectedExpenseCurrency: '',
                            uploadedBy: '',
                        })
                    }

                } else {

                    if (!canSelectAll) {
                        this.props.alertWarn(this.context.t("You can only select expenses of one employee at a time"))
                    } else if (!oneCurrency) {
                        this.props.alertWarn(this.context.t("Selected expenses must be in the same currency"))
                    }
                    this.setState({
                        sellectedExpenseCurrency: '',
                        uploadedBy: '',
                        docsSelectedForReport: coptyCurrentSelectedDocs
                    })
                }
            } else {
                docsDownloadedForRaport.forEach(doc => {
                    if (doc.canPay && !doc.alreadyInReport) {
                        if (!currentSelectedDocs.find(d => d.id === doc.id) && this.checkIfDocMatchesFilterOptions(doc)) {
                            currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, tags: doc.tags })
                            oneSelected = true

                            selectedDocsSums[doc.currency] += parseFloat(doc.price)
                        }
                    }
                })

                this.setState({
                    docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
                    selectedDocsSums: oneSelected ? selectedDocsSums : Object.fromEntries(
                        currencyList.map(c => {
                            return ([c, 0])
                        })
                    ),
                })
            }
        } else if (selectFor === 'transfer') {
            docsDownloadedForRaport.forEach(doc => {
                if (!currentSelectedDocs.find(d => d.id === doc.id) && !doc.alreadyInBankTransferFile && this.checkIfDocMatchesFilterOptions(doc)) {
                    if (currencySelectedForTransferBuckets === '') {
                        currencySelectedForTransferBuckets = doc.currency
                    }

                    if (currencySelectedForTransferBuckets === doc.currency) {
                        currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency })
                        oneSelected = true

                        selectedDocsSums[doc.currency] += parseFloat(doc.price)
                    }
                }
            })
            this.setState({
                currencySelectedForTransferBuckets: oneSelected ? currencySelectedForTransferBuckets : '',
                docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
                selectedDocsSums: oneSelected ? selectedDocsSums : Object.fromEntries(
                    currencyList.map(c => {
                        return ([c, 0])
                    })
                ),
            })
        } else if (selectFor === 'zip' || selectFor === 'tags') {
            docsDownloadedForRaport.forEach(doc => {
                if (!currentSelectedDocs.find(d => d.id === doc.id) && this.checkIfDocMatchesFilterOptions(doc)) {
                    currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, tags: doc.tags })
                    oneSelected = true

                    selectedDocsSums[doc.currency] += parseFloat(doc.price)
                }
            })

            this.setState({
                docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
                selectedDocsSums: oneSelected ? selectedDocsSums : Object.fromEntries(
                    currencyList.map(c => {
                        return ([c, 0])
                    })
                ),
            })
        } else if (selectFor === 'customReport') {

            docsDownloadedForRaport.forEach(doc => {
                if (!currentSelectedDocs.find(d => d.id === doc.id) && this.checkIfDocMatchesFilterOptions(doc)) {
                    currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, tags: doc.tags })
                    oneSelected = true

                    selectedDocsSums[doc.currency] += parseFloat(doc.price)
                }
            })

            this.setState({
                docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
                selectedDocsSums: oneSelected ? selectedDocsSums : Object.fromEntries(
                    currencyList.map(c => {
                        return ([c, 0])
                    })
                ),
            })
        } else if (selectFor === 'wapro') {
            docsDownloadedForRaport.forEach(doc => {
                if (!doc.missingWaproFields) {
                    if (!currentSelectedDocs.find(d => d.id === doc.id) && this.checkIfDocMatchesFilterOptions(doc)) {
                        currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, tags: doc.tags })
                        oneSelected = true

                        selectedDocsSums[doc.currency] += parseFloat(doc.price)
                    }
                }
            })

            this.setState({
                docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
                selectedDocsSums: oneSelected ? selectedDocsSums : Object.fromEntries(
                    currencyList.map(c => {
                        return ([c, 0])
                    })
                ),
            })
        }
    }

    generateReport = (e) => {
        e.preventDefault()
        if (this.state.docsSelectedForReport.length > 0) {
            if (this.state.uploadedBy === this.props.user.id && ((!this.state.accountNumberPln && this.state.docsSelectedForReport[0].currency === 'PLN') || (!this.state.accountNumberEur && this.state.docsSelectedForReport[0].currency !== 'PLN'))) {
                this.setState({
                    showUpdateAccountNumbersModal: true,
                    requiredAccountNumber: this.state.docsSelectedForReport[0].currency,
                })
            } else {
                this.setState({
                    showUpdateAccountNumbersModal: false,
                    requiredAccountNumber: '',
                    sellectedExpenseCurrency: '',
                    uploadedBy: '',
                    docsSelectedForReport: [],
                    selectedDocsSums: Object.fromEntries(
                        currencyList.map(c => {
                            return ([c, 0])
                        })
                    ),

                })

                var projectId = this.state.selectedProject ? this.state.selectedProject.id : null
                if (!projectId && this.state.selectedProjects.length > 0) {
                    projectId = this.state.selectedProjects[0].id
                }
                this.props.generateReport(this.state.docsSelectedForReport, this.state.alreadyPaidDocs, this.props.downloadedDocsType, this.state.uploadedBy === this.props.user.id, this.state.uploadedBy, projectId, this.state.docsSelectedForReport[0].currency)
            }
        }
    }

    generateCustomReport = e => {
        e.preventDefault()
        this.setState({
            showUpdateAccountNumbersModal: false,
            requiredAccountNumber: '',
            sellectedExpenseCurrency: '',
            uploadedBy: '',
        })

        var projectId = this.state.selectedProject ? this.state.selectedProject.id : null
        if (!projectId && this.state.selectedProjects.length > 0) {
            projectId = this.state.selectedProjects[0].id
        }

        if (this.props.downloadedDocsType === 'Income') {
            this.props.generateCustomIncomeReport(this.state.docsSelectedForReport, this.state.alreadyPaidDocs, this.props.downloadedDocsType, projectId)
        } else if (this.props.downloadedDocsType === 'Agreements') {
            this.props.generateCustomAgreementsReport(this.state.docsSelectedForReport, this.state.alreadyPaidDocs, this.props.downloadedDocsType, projectId)
        } else {
            this.props.generateCustomReport(this.state.docsSelectedForReport, this.state.alreadyPaidDocs, this.props.downloadedDocsType, projectId)
        }
    }

    togglePaymentList = (e, doc) => {
        //id, type, fileId, projectId, alreadyPaid
        e.preventDefault()
        let up = false
        let pos = e.target.getClientRects()[0];
        let left = pos.left;
        let top = pos.top;

        if (top + 130 > window.innerHeight) {
            up = true
        }
        if (!doc.alreadyPaid) {
            let currentSelected = this.props.alreadyPaidDocs
            if (currentSelected.find(e => e === doc.id)) {
                this.setState({
                    docClickedToPay: null,
                    docTypeClickedToPay: '',
                    fileClickedToPay: null,
                    projectClickedToPay: null,
                    posX: 0,
                    posY: 0,
                })
                if (this.props.docsDownloadedForRaport.length > 0) {
                    if (doc.type === 'Income') {
                        this.props.tooglePaymentForIncomeDoc(doc.id, doc.fileId, doc.projectId, null, doc.companyId, doc.month)
                    } else if (doc.type === 'PAYMENT_ORDER') {
                        this.props.tooglePaymentForTransfer(doc.id, doc.projectId, this.props.user.id, false, '')
                    } else {
                        this.props.tooglePaymentForDoc(doc.id, doc.fileId, doc.projectId, null)
                    }
                }
                ReactTooltip.hide()

            } else {
                this.setState({
                    docClickedToPay: doc.id,
                    docTypeClickedToPay: doc.type,
                    fileClickedToPay: doc.fileId,
                    projectClickedToPay: doc.projectId,
                    posX: left + 23,
                    posY: top + 10,
                    posUp: up,
                })
            }
        } else {
            if (doc.alreadyInBankTransferFile && doc.bankTransferFileName) {
                this.props.alertWarn("This document is included in bank transfer file. To prevent mistakes you can't unmark it", 8000)
            } else if (doc.alreadyInReport && doc.reportName) {
                this.props.alertWarn("This document is included in report file. To prevent mistakes you can't unmark it", 8000)
            } else {
                if (doc.type === 'Income') {
                    this.props.tooglePaymentForIncomeDoc(doc.id, doc.fileId, doc.projectId, null, doc.companyId, doc.month)
                } else if (doc.type === 'PAYMENT_ORDER') {
                    this.props.tooglePaymentForTransfer(doc.id, doc.projectId, this.props.user.id, false, '')
                } else {
                    this.props.tooglePaymentForDoc(doc.id, doc.fileId, doc.projectId, null)
                }
            }

        }
    }

    addDocToAlreadyPaid = (e, id, method) => {
        e.preventDefault()

        const { docsDownloadedForRaport } = this.props

        if (docsDownloadedForRaport.length > 0) {
            this.setState({
                docClickedToPay: null,
                docTypeClickedToPay: '',
                fileClickedToPay: null,
                projectClickedToPay: null,
            })
            if (this.state.docTypeClickedToPay === 'Income') {
                var docIndex = arrayObjectIndexOf(docsDownloadedForRaport, id, 'id')
                if (docIndex > -1) {
                    this.props.tooglePaymentForIncomeDoc(id, this.state.fileClickedToPay, this.state.projectClickedToPay, method, docsDownloadedForRaport[docIndex].companyId, docsDownloadedForRaport[docIndex].month)
                } else {
                    this.props.tooglePaymentForIncomeDoc(id, this.state.fileClickedToPay, this.state.projectClickedToPay, method)
                }
            } else if (this.state.docTypeClickedToPay === 'PAYMENT_ORDER') {
                this.props.tooglePaymentForTransfer(id, this.state.projectClickedToPay, this.props.user.id, true, method)
            } else {
                this.props.tooglePaymentForDoc(id, this.state.fileClickedToPay, this.state.projectClickedToPay, method)
            }
        }
    }

    hidePaymentMethodsList = e => {
        if (e.keyCode === 27 || (e.target && !e.target.className.includes('checkbox')) || !e.target) {
            if (this.state.posX !== 0) {
                this.setState({
                    docClickedToPay: null,
                    posX: 0,
                    posY: 0,
                })
            }
        }
    }

    getSortClass = (sortFieldCheck) => {
        const { sortField, sortOrder } = this.props

        if (sortField === sortFieldCheck) {
            if (sortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    getSortClassReports = (sortFieldCheck) => {
        const { reportsSortField, reportsSortOrder } = this.props

        if (reportsSortField === sortFieldCheck) {
            if (reportsSortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    fileIconClick = (e, projectId, file, id, docType, fileId, alreadyPaid) => {
        if (e.target && !e.target.className.includes('checkmarks') && !e.target.className.includes('checkbox') && !e.target.className.includes('in-report') && !e.target.className.includes('icon-xlsx')) {
            this.props.toogleFilePreviewInActions(true)
            this.setState({
                previewFile: {
                    projectId: projectId,
                    file: file,
                    id: id,
                    docType: docType
                }
            })
            if (docType === 'PAYMENT_ORDER') {
                const { transfers } = this.props
                var projectTransfers = transfers.find(e => e.project === projectId)
                if (projectTransfers && projectTransfers.transfers) {
                    var transfer = projectTransfers.transfers.find(e => e.id === id)
                    if (transfer) {
                        this.props.openTransferPreviewWindow(transfer)
                    } else {
                        this.props.alertWarn(this.context.t('Payment order not found'))
                    }
                } else {
                    this.props.alertWarn(this.context.t('Payment order not found'))
                }
            } else {
                this.props.getFileBody(parseInt(projectId), file, fileId, alreadyPaid ? 'DONE' : 'IN_PROGRESS', alreadyPaid ? 4 : 3)
            }
        }
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: {}
        })
    }

    downloadReport = (e, projectId, reportName) => {
        e.preventDefault()

        this.props.downloadReport(projectId, reportName)
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    saveAccountNumber = (e, name) => {
        e.preventDefault()

        const { accountNumberPln, accountNumberEur, requiredAccountNumber } = this.state

        if (!accountNumberPln && requiredAccountNumber === 'PLN') {
            this.props.alertWarn("Account number is to return PLN is required")
        } else if (!accountNumberEur && requiredAccountNumber !== 'PLN') {
            this.props.alertWarn("Account number is to return EUR is required")
        } else {
            this.props.saveAccountNumber(accountNumberPln, accountNumberEur)
        }
    }

    closeAccountNumbersModal = e => {
        e.preventDefault()
        this.setState({
            showUpdateAccountNumbersModal: false,
            requiredAccountNumber: '',
        })
    }

    toggleDataFilterList = (e) => {
        if (e.target && e.target.className && !e.target.className.includes('next')) {
            this.setState(state => {
                return {
                    showDateFilterList: !state.showDateFilterList,
                    showProjectsList: false,
                    showStartDatePicker: false,
                    showEndDatePicker: false,
                    focusedField: '',
                }
            })
        }
    }

    switchDateFilter = name => {
        var dateFilterText = 'Issue date'
        if (name === 'importDate') dateFilterText = 'Import date'
        if (name === 'dueDate') dateFilterText = 'Due date'
        if (name === 'paymentDate') dateFilterText = 'Payment date'
        this.setState({
            dateFilter: name,
            dateFilterText: dateFilterText,
            showDateFilterList: false
        })
    }

    downloadMultipleFiles = () => {
        var filesToDownload = {
            ids: []
        }
        var paymentOrdersToDownload = {
            ids: []
        }
        this.state.docsSelectedForReport.map(doc => {
            if (doc.type !== 'PAYMENT_ORDER') {
                filesToDownload.ids.push(doc.fileId)
            } else {
                paymentOrdersToDownload.ids.push(doc.fileId)
            }
        })
        var projectId, projectCode
        projectId = this.state.selectedProject ? this.state.selectedProject.id : null
        projectCode = this.state.selectedProject ? this.state.selectedProject.name : null
        if (!projectId && this.state.selectedProjects.length > 0) {
            projectId = this.state.selectedProjects[0].id
        }
        if (!projectCode && this.state.selectedProjects.length > 0) {
            projectCode = this.state.selectedProjects[0].name
        }
        this.props.downloadMultipleFiles(projectId, projectCode, filesToDownload, paymentOrdersToDownload, true, () => { })
    }

    bulkEditTags = () => {
        const { docsSelectedForReport } = this.state

        var combinedTagsString = ''

        if (docsSelectedForReport.length) {
            docsSelectedForReport.forEach(d => {
                if (d.tags) {
                    combinedTagsString += `${d.tags}, `
                }
            })
        }
        combinedTagsString = combinedTagsString.replace(/,\s$/, '').replace(/, /gm, ',')
        var uniqCombinedTags = [...new Set(combinedTagsString.split(','))]

        combinedTagsString = uniqCombinedTags.join(',')

        this.setState({
            showBulkTagsEditWindow: true,
            bulkTagsToEdit: combinedTagsString
        })
    }

    closeBulkTagsUpdateWindow = (setNewTags, newTagsValue) => {
        if (setNewTags) {
            const { docsSelectedForReport } = this.state
            var docsSelectedForReportCopy = [...docsSelectedForReport]
            var selectedDocsCount = docsSelectedForReportCopy.length
            for (var i = 0; i < selectedDocsCount; i++) {
                docsSelectedForReportCopy[i].tags = newTagsValue
            }

            this.setState({
                docsSelectedForReport: docsSelectedForReportCopy,
                showBulkTagsEditWindow: false,
                bulkTagsToEdit: ''
            })
        } else {
            this.setState({
                showBulkTagsEditWindow: false,
                bulkTagsToEdit: ''
            })
        }
    }

    closeAllSuggestions = (e) => {
        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "formProjectContainer") && this.state.showProjectsList)) {
            this.setState({
                showProjectsList: false
            })
        }

        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "formDocTypeContainer") && this.state.showTypesList)) {
            this.setState({
                showTypesList: false
            })
        }

        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "formSearchTypeContainer") && this.state.showDateFilterList)) {
            this.setState({
                showDateFilterList: false
            })
        }

        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "formStartDateContainer") && this.state.showStartDatePicker)) {
            this.setState({
                showStartDatePicker: false
            })
        }

        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "formEndDateContainer") && this.state.showEndDatePicker)) {
            this.setState({
                showEndDatePicker: false
            })
        }

        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "filterCurrencyContainer") && this.state.showFilterCurrencySelector)) {
            this.setState({
                showFilterCurrencySelector: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }

        return false
    }

    changeSelectFor = (e, name) => {
        var currentSelected = this.state.docsSelectedForReport
        var { selectedDocsSums, currencySelectedForTransferBuckets } = this.state
        if (name === 'report') {
            this.props.docsDownloadedForRaport.forEach(d => {
                if (d.alreadyInReport) {
                    if (currentSelected.find(e => e.id === d.id)) {
                        currentSelected = currentSelected.filter(e => e.id !== d.id)

                        selectedDocsSums[d.currency] -= parseFloat(d.price)
                    }
                }
            })
        } else if (name === 'transfer') {
            if (currencySelectedForTransferBuckets === '' && currentSelected.length !== 0) {
                currencySelectedForTransferBuckets = currentSelected[0].currency
            }

            this.props.docsDownloadedForRaport.forEach(d => {
                if (d.alreadyInBankTransferFile) {
                    if (currentSelected.find(e => e.id === d.id)) {
                        currentSelected = currentSelected.filter(e => e.id !== d.id)

                        selectedDocsSums[d.currency] -= parseFloat(d.price)
                    }
                }
                currentSelected = currentSelected.filter(e => e.currency === currencySelectedForTransferBuckets)
            })
        } else if (name === 'wapro') {
            this.props.docsDownloadedForRaport.forEach(d => {
                if (d.missingWaproFields) {
                    if (currentSelected.find(e => e.id === d.id)) {
                        currentSelected = currentSelected.filter(e => e.id !== d.id)

                        selectedDocsSums[d.currency] -= parseFloat(d.price)
                    }
                }
            })
        } else if (name === 'symfonia') {
            this.props.docsDownloadedForRaport.forEach(d => {
                if (d.missingSymfoniaFields) {
                    if (currentSelected.find(e => e.id === d.id)) {
                        currentSelected = currentSelected.filter(e => e.id !== d.id)

                        selectedDocsSums[d.currency] -= parseFloat(d.price)
                    }
                }
            })
        }

        this.setState({
            selectFor: name,
            showActionsList: false,
            docsSelectedForReport: currentSelected,
            currencySelectedForTransferBuckets: currencySelectedForTransferBuckets,
            selectedDocsSums: selectedDocsSums,
        }, () => {
            ReactTooltip.rebuild()
        })
    }

    companyHasBank = (projectId) => {
        const { userProjects, userCompanies } = this.props

        var project = userProjects.find(p => p.id === projectId)

        if (project) {
            var userCompanyRelation = userCompanies.find(c => c.company_id === project.company_id)

            if (userCompanyRelation) {
                if (userCompanyRelation.company) {
                    var company = userCompanyRelation.company
                    if (company.banks && company.banks.length > 0) {
                        this.setState({
                            companyToGenerateTransfers: company,
                            isAdminInCompanyToGenerateTransfers: userCompanyRelation.user_role === 'ADMIN'
                        })
                        return true
                    } else {
                        if (userCompanyRelation.user_role === 'ADMIN') {
                            this.setState({
                                showCompanyBankAccountPopup: true,
                                companyToGenerateTransfers: company,
                            })
                            return false
                        } else {
                            this.props.alertWarn(this.context.t('You company has not provided required bank account informations yet. Please contact your companies administrator'), 7000)
                            return false
                        }
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    bankTransferFileCheck = (e) => {
        e.preventDefault()
        var projectId = 0
        const { selectedProject, selectedProjects } = this.state
        if (selectedProject && selectedProject.id) projectId = selectedProject.id
        else if (selectedProjects && Array.isArray(selectedProjects) && selectedProjects.length > 0) projectId = selectedProjects[0].id
        if (this.companyHasBank(projectId)) {
            this.setState({
                showCompanyBanks: true
            })
        }
    }

    generteWaproXML = (e) => {
        e.preventDefault()
        if (this.state.docsSelectedForReport.length > 0) {
            this.props.generateWaproXML(this.state.docsSelectedForReport, this.state.alreadyPaidDocs, this.props.downloadedDocsType, this.state.selectedProject.id, (success) => {
                if (success) {
                    this.setState({
                        showUpdateAccountNumbersModal: false,
                        requiredAccountNumber: '',
                        sellectedExpenseCurrency: '',
                        uploadedBy: '',
                        docsSelectedForReport: [],
                        selectedDocsSums: Object.fromEntries(
                            currencyList.map(c => {
                                return ([c, 0])
                            })
                        ),
                    })
                }
            })
        }
    }

    generteSymfoniaXML = (e) => {
        e.preventDefault()
        if (this.state.docsSelectedForReport.length > 0) {
            this.props.generateSymfoniaXML(this.state.docsSelectedForReport, this.state.alreadyPaidDocs, this.props.downloadedDocsType, this.state.selectedProject.id, (success) => {
                if (success) {
                    this.setState({
                        showUpdateAccountNumbersModal: false,
                        requiredAccountNumber: '',
                        sellectedExpenseCurrency: '',
                        uploadedBy: '',
                        docsSelectedForReport: [],
                        selectedDocsSums: Object.fromEntries(
                            currencyList.map(c => {
                                return ([c, 0])
                            })
                        ),
                    })
                }
            })
        }
    }

    handleSelectedAction = (e) => {
        const { selectFor } = this.state
        if (selectFor === 'report') {
            this.generateReport(e)
        } else if (selectFor === 'customReport') {
            this.generateCustomReport(e)
        } else if (selectFor === 'zip') {
            this.downloadMultipleFiles(e)
        } else if (selectFor === 'tags') {
            this.bulkEditTags(e)
        } else if (selectFor === 'wapro') {
            this.generteWaproXML(e)
        } else if (selectFor === 'symfonia') {
            this.generteSymfoniaXML(e)
        } else {
            this.bankTransferFileCheck(e)
        }
    }

    getSelectForButtonTitle = selectFor => {
        switch (selectFor) {
            case 'report':
                return this.context.t('Generate collation')
            case 'zip':
                return this.context.t('Download')
            case 'customReport':
                return this.context.t('Generate report')
            case 'wapro':
                return this.context.t('Generate XML')
            case 'symfonia':
                return this.context.t('Generate TXT')
            case 'tags':
                return this.context.t('Edit tags')
            default:
                return this.context.t('Generate file')
        }
    }

    closeCompanyAccountPopup = () => {
        this.setState({
            showCompanyBanks: true,
            showCompanyBankAccountPopup: false,
        })
    }

    closeCompanyAccountPopupAndGenrateFile = (bankId) => {
        const { currencySelectedForTransferBuckets, docsSelectedForReport, selectedProject } = this.state

        if (docsSelectedForReport.length > 0) {
            if (docsSelectedForReport[0].currency === 'PLN') {
                this.props.generateBankTransfersFile(docsSelectedForReport, this.props.downloadedDocsType, selectedProject.id, false, bankId)
            } else {
                this.props.generateBankTransfetFileForInternationalTransfers(docsSelectedForReport, this.props.downloadedDocsType, selectedProject.id, false, bankId)
            }

            this.setState({
                showCompanyBanks: false,
                currencySelectedForTransferBuckets: '',
                uploadedBy: '',
                docsSelectedForReport: [],
                selectedDocsSums: Object.fromEntries(
                    currencyList.map(c => {
                        return ([c, 0])
                    })
                ),
                showCompanyBankAccountPopup: false,
                addNewAccountForTransfersFile: false
            })
        }
    }

    addBankToCompany = () => {
        this.setState({
            showCompanyBanks: false,
            showCompanyBankAccountPopup: true,
            addNewAccountForTransfersFile: true
        })
    }

    addedNewAccount = () => {
        this.setState({
            showCompanyBanks: true,
            showCompanyBankAccountPopup: false
        })
    }

    closeCompanyBanks = () => {
        this.setState({
            showCompanyBanks: false,
            addNewAccountForTransfersFile: false
        })
    }

    generateBankTransfersFile = (makeToday, bankId, markAsPaid) => {
        const { currencySelectedForTransferBuckets, docsSelectedForReport, selectedProject } = this.state

        if (docsSelectedForReport.length > 0) {
            if (docsSelectedForReport[0].currency === 'PLN') {
                this.props.generateBankTransfersFile(docsSelectedForReport, this.props.downloadedDocsType, selectedProject.id, makeToday ? 'TODAY' : 'DUE_DATE', bankId, markAsPaid)
            } else {
                this.props.generateBankTransfetFileForInternationalTransfers(docsSelectedForReport, this.props.downloadedDocsType, selectedProject.id, makeToday ? 'TODAY' : 'DUE_DATE', bankId, markAsPaid)
            }

            this.setState({
                showCompanyBanks: false,
                addNewAccountForTransfersFile: false,
                currencySelectedForTransferBuckets: '',
                uploadedBy: '',
                docsSelectedForReport: [],
                selectedDocsSums: Object.fromEntries(
                    currencyList.map(c => {
                        return ([c, 0])
                    })
                ),
            })
        }
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    checkIfDocMatchesFilterOptions = doc => {
        const { searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted, alreadyPaidDocs } = this.state
        const { downloadedDocsType } = this.props

        if (doc && downloadedDocsType !== 'Expenses') {
            var counterparty = doc.counterparty ? doc.counterparty.toLowerCase() : ''
            if (!counterparty) counterparty = doc.company ? doc.company.toLowerCase() : ''
            var comment = doc.comment ? doc.comment.toLowerCase() : ''
            var tags = doc.tags ? doc.tags.toLowerCase() : ''
            var docNumber = doc.documentNumber ? doc.documentNumber.toLowerCase() : ''

            var matches = true

            if (searchField) {
                if (!(counterparty.includes(searchField.toLowerCase()) || comment.includes(searchField.toLowerCase()) || tags.includes(searchField.toLowerCase()) || docNumber.includes(searchField.toLowerCase()))) {
                    return false
                }
            }

            if (selectOnlyAvailableForReport) {
                if (!(doc.canPay && (!doc.alreadyInReport || doc.reportName === 'PENDING'))) {
                    return false
                }
            }

            if (selectOnlyAvailableForBankTransferFile) {
                if (!(!doc.alreadyInBankTransferFile || doc.bankTransferFileName === 'PENDING')) {
                    return false
                }
            }

            if (selectOnlyNotPaid) {
                if (!(!doc.alreadyPaid && doc.canPay && !alreadyPaidDocs.find(e => e.id === doc.id))) {
                    return false
                }
            }

            if (selectOnlyNotAccounted) {
                if (doc.accounted) {
                    return false
                }
            }

            if (searchCurrency !== 'ALL') {
                if (doc.currency !== searchCurrency) {
                    return false
                }
            }

            return matches
        } else if (doc && downloadedDocsType === 'Expenses') {
            var matches = true

            if (searchField) {
                if (!doc.uploadedBy.toLowerCase().includes(searchField.toLowerCase()) && !doc.comment.toLowerCase().includes(searchField.toLowerCase())) {
                    matches = false
                }
            }

            if (searchCurrency !== 'ALL') {
                if (doc.currency !== searchCurrency) {
                    matches = false
                }
            }

            if (selectOnlyAvailableForReport) {
                if (doc.alreadyInReport) {
                    matches = false
                }
            }

            return matches
        } else {
            return false
        }
    }

    resetFilters = () => {
        this.setState({
            searchField: '',
            searchCurrency: 'ALL',
            selectOnlyAvailableForReport: false,
            selectOnlyAvailableForBankTransferFile: false,
            selectOnlyNotPaid: false,
            selectOnlyNotAccounted: false,
        })
    }

    showOverlay = () => {
        const { overlayPreferance } = this.props
        this.setOverlaySize()
        overlayPreferance['reports'] = true
        this.props.toggleOverlay(overlayPreferance)
    }

    toggleAccountedStatus = (id, fileId, projectId, type, accounted) => {
        if (type === 'PAYMENT_ORDER') {
            this.props.toogleAccountmentStatusForTransfer(id, projectId, 0, accounted);
        } else if (type === 'Income') {
            this.props.toogleAccountmentStatusForIncomeDoc(id, fileId, projectId, accounted);
        } else {
            this.props.toogleAccountmentStatusForDoc(id, fileId, projectId, accounted);
        }
    }

    showTableCustomizer = () => {
        this.setState({
            showTableColumnsCustomizer: true
        })
    }

    hideTableCustomizer = () => {
        this.setState({
            showTableColumnsCustomizer: false
        })
    }

    getCurrentTabTitle = currentTab => {
        switch (currentTab) {
            case 'currentReport':
                return this.context.t('Actions')
            case 'listOfReports':
                return this.context.t('Generated collations')
            case 'listOfBankTransferFiles':
                return this.context.t('Bank transfer files')
            case 'listOfZips':
                return this.context.t('Packages for accounting')
            case 'listOfAccountingFiles':
                return this.context.t('Files for the FC program')
            default:
                return 'Actions'
        }
    }

    getFlexWidthForTab = currentTab => {
        switch (currentTab) {
            case 'currentReport':
                return '150px'
            case 'listOfReports':
                return '400px'
            case 'listOfBankTransferFiles':
                return '360px'
            case 'listOfZips':
                return '360px'
            case 'listOfAccountingFiles':
                return '450px'
            default:
                return '150px'
        }
    }

    setCurrentTab = name => {
        const { user } = this.props
        if (user.new_easydocs_version) {
            switch (name) {
                case 'currentReport':
                    this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.ACTIONS)
                    break
                case 'listOfReports':
                    this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_EXCELS)
                    break
                case 'listOfBankTransferFiles':
                    this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_TRANSFERS)
                    break
                case 'listOfZips':
                    this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_ZIPS)
                    break
                case 'listOfAccountingFiles':
                    this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_ACCOUNTING_FILES)
                    break
                default:
                    this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.ACTIONS)
                    break
            }
            this.setState({
                showTabsList: false
            })
        } else {
            this.setState({
                currentTab: name,
                showTabsList: false
            })
        }
    }

    getSelectedActionTitle = selectFor => {
        const { downloadedDocsType } = this.props
        console.log(downloadedDocsType)
        console.log(selectFor)
        switch (selectFor) {
            case 'report':
                if (downloadedDocsType === 'Costs') return this.context.t('Generate payment collation')
                if (downloadedDocsType === 'Expenses') return this.context.t('Generate an expense report')
            case 'zip':
                return this.context.t('Download zipped docs')
            case 'transfer':
                return this.context.t('Generate file to import bank transfers')
            case 'wapro':
                return this.context.t('Generate XML for WAPRO')
            case 'symfonia':
                return this.context.t('Generate TXT for Symfonia')
            case 'customReport':
                return this.context.t('Generate custom report')
            case 'tags':
                return this.context.t('Bulk tags edit')
            default:
                return this.context.t('Generate custom report')
        }
    }

    render() {
        const {
            showTabsList, currentTab, showTableColumnsCustomizer, stickSummaryRow,
            previewFile,
            focusedField, dateFilterText, showDateFilterList, startDate, startDateValue, showStartDatePicker, endDate, endDateValue, showEndDatePicker, showProjectsList, selectedProject, selectedProjects, roleInSelectedProject, readOnlyProjectRole, canDownloadFilesInProject, showTypesList, selectedType,
            docsSelectedForReport, selectedDocsSums, docClickedToPay, sendRequest, selectFor, showActionsList,
            posX, posY, posUp,
            accountNumberPln, accountNumberEur, showUpdateAccountNumbersModal, requiredAccountNumber, showCompanyBankAccountPopup, showCompanyBanks,
            searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted,
            showBulkTagsEditWindow, bulkTagsToEdit
        } = this.state
        const { sendGetDocumentsRequest, docsDownloadedForRaport, overlayPreferance, downloadedDocsType, user, tabIsActive, loadingScreen } = this.props

        console.log(roleInSelectedProject, readOnlyProjectRole, canDownloadFilesInProject)

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className="reports-container" onClick={e => { this.closeAllSuggestions(e); this.hidePaymentMethodsList(e) }}>
                    {
                        loadingScreen ? (
                            <div className="loading-screen">
                                <div className="activity-indicator">
                                    <Spinner size={50} speed={0.8} color={"#ffffff"} />
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {/* {
                    docsDownloadedForRaport && docsDownloadedForRaport.length > 0 && this.props.gotDocumentsResponse ? (
                        <div className='show-overlay reports' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>
                    ) : (
                        null
                    )
                } */}

                    {
                        showTableColumnsCustomizer ? (
                            <TableColumnsCustomizer
                                close={this.hideTableCustomizer}
                            />
                        ) : null
                    }

                    {
                        showBulkTagsEditWindow ? (
                            <TagsBulkEditWindow
                                currentSelectedDocs={docsSelectedForReport}
                                tagsToEdit={bulkTagsToEdit}
                                close={this.closeBulkTagsUpdateWindow}
                            />
                        ) : null
                    }

                    <div className="page-header reports">
                        <div className={`page-name reports ${showTabsList ? 'drop-down-active' : ''}`} style={{ flex: `0 0 ${this.getFlexWidthForTab(currentTab)}` }}>
                            <div className="tabs-dropdown-list" ref={this.tabsListContainer}>
                                <div className="current-tab" onClick={() => this.setState({ showTabsList: !showTabsList })}>
                                    {this.getCurrentTabTitle(currentTab)}
                                    <span className={`list-extend-arrow ${showTabsList ? 'extended' : ''}`}></span>
                                </div>
                                {
                                    showTabsList ? (
                                        <div className="tabs-list" style={{ width: this.getFlexWidthForTab(currentTab) }}>
                                            <div className={`tab ${currentTab === 'currentReport' ? 'active' : ''}`} onClick={() => this.setCurrentTab('currentReport')}>{this.context.t('Actions')}</div>
                                            <div className={`tab ${currentTab === 'listOfReports' ? 'active' : ''}`} onClick={() => this.setCurrentTab('listOfReports')}>{this.context.t('Generated collations')}</div>
                                            <div className={`tab ${currentTab === 'listOfBankTransferFiles' ? 'active' : ''}`} onClick={() => this.setCurrentTab('listOfBankTransferFiles')}>{this.context.t('Bank transfer files')}</div>
                                            <div className={`tab ${currentTab === 'listOfZips' ? 'active' : ''}`} onClick={() => this.setCurrentTab('listOfZips')}>{this.context.t('Packages for accounting')}</div>

                                            {
                                                waproUsers.includes(this.props.user.id) ? (
                                                    <div className={`tab ${currentTab === 'listOfAccountingFiles' ? 'active' : ''}`} onClick={() => this.setCurrentTab('listOfAccountingFiles')}>{this.context.t('Files for the FC program')}</div>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                    ) : null
                                }
                            </div>
                            { }
                        </div>

                        {
                            currentTab === 'currentReport' ? (
                                <div className="reports-form" ref={this.reportsFormRef}>
                                    <form onSubmit={this.getDocs}>
                                        <div className="form-group" id="formProjectContainer">
                                            <div className="current-selected-project" onClick={e => this.toggleProjectsListInSingleView()}>
                                                <span className="label">{this.context.t("Binder")}</span>
                                                {
                                                    this.state.selectedProjects && this.state.selectedProjects.length > 0 ? (
                                                        <span className="current">{selectedProjects.map((p, i) => {
                                                            if (selectedProjects[i + 1]) return p.name + ', '
                                                            else return p.name
                                                        })}</span>
                                                    ) : (
                                                        <span className="current">{selectedProject.name}</span>
                                                    )
                                                }
                                                <span className={`expend-icon ${showProjectsList ? 'expended' : ''}`}></span>
                                            </div>
                                            {
                                                showProjectsList ? (
                                                    <ProjectSwitcher closeProjectsList={this.setCurrentProject} showAll={true} selectedProject={selectedProject} selectedProjects={selectedProjects} multiSelect={true} returnSelectedProjects={this.setMultiSelectedProjects} />
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>

                                        <div className="form-group" id="formDocTypeContainer">
                                            <div className="current-selected-type" onClick={e => this.toggleTypesListInSingleView(e)}>
                                                <span className={`label ${showTypesList || selectedType ? 'up' : ''}`}>{this.context.t("Document type")}</span>
                                                <span className="current">{this.context.t(selectedType)}</span>
                                                <span className={`expend-icon ${showTypesList ? 'expended' : ''}`}></span>
                                            </div>
                                            {
                                                showTypesList ? (
                                                    <ul className="projects-list-single-view">
                                                        <li key={'costs'} className={`${selectedType === 'Costs' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Costs') }}>
                                                            {this.context.t("Costs")}<span className="selected-indicator"></span>
                                                        </li>
                                                        <li key={'expenses'} className={`${selectedType === 'Expenses' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Expenses') }}>
                                                            {this.context.t("Expenses")}<span className="selected-indicator"></span>
                                                        </li>
                                                        <li key={'income'} className={`${selectedType === 'Income' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Income') }}>
                                                            {this.context.t("Income")}<span className="selected-indicator"></span>
                                                        </li>
                                                        <li key={'agreements'} className={`${selectedType === 'Agreements' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Agreements') }}>
                                                            {this.context.t("Agreements")}<span className="selected-indicator"></span>
                                                        </li>
                                                        <li key={'correspondence'} className={`${selectedType === 'Correspondence' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Correspondence') }}>
                                                            {this.context.t("Correspondence")}<span className="selected-indicator"></span>
                                                        </li>
                                                        <li key={'corporate'} className={`${selectedType === 'Corporate' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Corporate') }}>
                                                            {this.context.t("Corporate ")}<span className="selected-indicator"></span>
                                                        </li>
                                                        <li key={'mag'} className={`${selectedType === 'Warehouse' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Warehouse') }}>
                                                            {this.context.t("Warehouse ")}<span className="selected-indicator"></span>
                                                        </li>
                                                        <li key={'other'} className={`${selectedType === 'Other' ? 'current' : ''}`} onClick={e => { this.setCurrentType('Other') }}>
                                                            {this.context.t("Other ")}<span className="selected-indicator"></span>
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>

                                        <div className="form-group" id="formSearchTypeContainer">
                                            <div className="search-type" onClick={(e) => this.toggleDataFilterList(e)}>
                                                <span className="label">{this.context.t("Search by")}</span>
                                                <span className="search-by">{selectedType === 'Income' && this.state.dateFilter === 'importDate' ? this.context.t("Sale date") : this.context.t(dateFilterText)}</span>
                                                <span className={`expend-icon ${showDateFilterList ? 'expended' : ''}`}></span>
                                            </div>
                                            {
                                                showDateFilterList ? (
                                                    <ul>
                                                        <li className={`${this.state.dateFilter === 'importDate' ? 'current' : ''}`} onClick={e => { this.switchDateFilter('importDate') }}>
                                                            {selectedType === 'Income' ? this.context.t("Sale date") : this.context.t("Import date")}
                                                        </li>
                                                        <li className={`${this.state.dateFilter === 'issueDate' ? 'current' : ''}`} onClick={e => { this.switchDateFilter('issueDate') }}>
                                                            {this.context.t("Issue date")}
                                                        </li>
                                                        {
                                                            selectedType === 'Costs' || selectedType === 'Income' ? (
                                                                <li className={`${this.state.dateFilter === 'dueDate' ? 'current' : ''}`} onClick={e => { this.switchDateFilter('dueDate') }}>
                                                                    {this.context.t("Due date")}
                                                                    <span className="info" data-for="due-date-filter-info" data-tip="show"></span>
                                                                </li>
                                                            ) : null
                                                        }
                                                        {
                                                            selectedType === 'Costs' ? (
                                                                <li className={`${this.state.dateFilter === 'paymentDate' ? 'current' : ''}`} onClick={e => { this.switchDateFilter('paymentDate') }}>
                                                                    {this.context.t("Payment date")}
                                                                </li>
                                                            ) : null
                                                        }
                                                    </ul>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                        <div className="form-group" id="formStartDateContainer">
                                            <input type="text" name="startDate" className={`${startDate || focusedField === 'startDate' ? 'has-content' : ''} `} id="startDate" ref="startDate" value={startDate} onFocus={e => this.showDatePicker(e, 'start')} autoComplete="off" placeholder={`e.g. ${moment().format('MMMM DD, YYYY')}`} readOnly={true} />
                                            <label htmlFor="startDate">{this.context.t('From')}</label>
                                            <span className="calendar-icon" onClick={e => this.showDatePicker(e, 'start')}></span>
                                            {
                                                showStartDatePicker ? (
                                                    <div className={`date-picker ${showStartDatePicker ? 'show' : ''}`} onClick={e => this.clickOnDatePicker(e, 'start')}>
                                                        <Calendar
                                                            selectDay={this.props.selectStartDate}
                                                            unselectDay={this.props.unselectStartDate}
                                                            selectedDay={{
                                                                value: startDateValue
                                                            }}
                                                            projectId={'none'}
                                                            startDate={startDateValue || moment()}
                                                            arrowsOnLeft={false}
                                                            selectFirstDayOnMonthChange={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>

                                        <div className="form-group" id="formEndDateContainer">
                                            <input type="text" name="endDate" className={`${endDate || focusedField === 'endDate' ? 'has-content' : ''} `} id="endDate" ref="endDate" value={endDate} onFocus={e => this.showDatePicker(e, 'end')} autoComplete="off" readOnly={true} />
                                            <label htmlFor="endDate">{this.context.t('To')}</label>
                                            <span className="calendar-icon" onClick={e => this.showDatePicker(e, 'end')}></span>

                                            {
                                                showEndDatePicker ? (
                                                    <div className={`date-picker ${showEndDatePicker ? 'show' : ''}`} onClick={e => this.clickOnDatePicker(e, 'end')}>
                                                        <Calendar
                                                            selectDay={this.props.selectEndDate}
                                                            unselectDay={this.props.unselectEndDate}
                                                            selectedDay={
                                                                {
                                                                    value: endDateValue
                                                                }
                                                            }
                                                            projectId={'none'}
                                                            startDate={endDateValue || moment()}
                                                            arrowsOnLeft={false}
                                                            selectFirstDayOnMonthChange={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>

                                        <input type="submit" className="button" value={this.context.t('Search Docs')} />
                                    </form>
                                </div>
                            ) : null
                        }
                    </div>

                    {/* {
                    this.state.gotOverlayPossitions && overlayPreferance['reports'] ? (
                        <OverlayInstructions possitions={this.state.overlayPossitions} name="reports" height={window.innerHeight} />
                    ) : (
                        null
                    )
                } */}

                    {
                        previewFile && previewFile.id ? (
                            <FilesPreviewInReports
                                docsList={docsDownloadedForRaport}
                                location={this.props.location}
                                previewFile={previewFile}
                                close={this.hideFilePreview}
                                setCurrentDoc={this.setCurrentDocPreview}
                                checkIfDocMatchesFilterOptions={this.checkIfDocMatchesFilterOptions}
                            />
                        ) : (
                            null
                        )
                    }

                    {
                        currentTab === 'currentReport' ? (
                            <div>
                                {
                                    showUpdateAccountNumbersModal ? (
                                        <div className="account-numbers-update-modal">
                                            <div className="card">
                                                <span className="close-button" onClick={e => this.closeAccountNumbersModal(e)}></span>
                                                <div className="header">
                                                    <h2>{this.context.t('Before generating first expense report You have to provide your account number')}</h2>
                                                </div>
                                                <div className="body">
                                                    <div className="account-number-container">
                                                        <div className='form-group'>
                                                            <input type="text" name="accountNumberPln" id="accountNumberPln" value={accountNumberPln} onChange={e => this.onInputChange(e)} className={accountNumberPln ? 'has-content' : ''} placeholder="" autoComplete='off' />
                                                            <label htmlFor="accountNumberPln">{this.context.t('Account number to return PLN')}</label>
                                                            <span className="focus-border"></span>
                                                        </div>
                                                    </div>
                                                    <div className="account-number-container">
                                                        <div className='form-group'>
                                                            <input type="text" name="accountNumberEur" id="accountNumberEur" value={accountNumberEur} onChange={e => this.onInputChange(e)} className={accountNumberEur ? 'has-content' : ''} placeholder="" autoComplete='off' />
                                                            <label htmlFor="accountNumberEur">{this.context.t('Account number to return EUR')}</label>
                                                            <span className="focus-border"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="footer">
                                                    <button className="button" type="submit" onClick={e => this.saveAccountNumber(e)}>{this.context.t('Save')}</button>
                                                    <button className={`${(!this.state.accountNumberPln && requiredAccountNumber === 'PLN') || (!this.state.accountNumberPln && requiredAccountNumber !== 'PLN') ? 'inactive' : ''}`} onClick={e => this.generateReport(e)}>{this.context.t('Generate report')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    showCompanyBankAccountPopup ? (
                                        <CompanyBankInformationPopup
                                            company={this.state.companyToGenerateTransfers}
                                            close={this.closeCompanyAccountPopup}
                                            closeAndGenerateFile={this.closeCompanyAccountPopupAndGenrateFile}
                                            currency={this.state.currencySelectedForTransferBuckets}
                                            addNewAccount={this.state.addNewAccountForTransfersFile}
                                            addedNewAccount={this.addedNewAccount}
                                        />
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    showCompanyBanks ? (
                                        <CompanyBanks
                                            company={this.state.companyToGenerateTransfers}
                                            isAdmin={this.state.isAdminInCompanyToGenerateTransfers}
                                            close={this.closeCompanyBanks}
                                            addBank={this.addBankToCompany}
                                            generateFile={this.generateBankTransfersFile}
                                            currency={this.state.currencySelectedForTransferBuckets}
                                        />
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    docClickedToPay ? (
                                        <span className={`payment-methods-list ${posUp ? 'up' : ''}`} style={{ top: posY, left: posX }}>
                                            <ul>
                                                {
                                                    paymentMethodsList.map(method => {
                                                        return (
                                                            <li onClick={e => this.addDocToAlreadyPaid(e, docClickedToPay, method.value)}>
                                                                {this.context.t(method.title)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </span>
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    !sendGetDocumentsRequest && docsDownloadedForRaport.length === 0 && !sendRequest ? (
                                        <div className="no-docs-for-report">
                                            <div className="image"></div>
                                            <div className="text">
                                                <div className="header">
                                                    {this.context.t('Create your own reports')}
                                                </div>
                                                <div className="instruction">
                                                    {this.context.t('It\'s easy, just search for documents from a given period, then select those that interest you and click generate report.')}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        sendGetDocumentsRequest && docsDownloadedForRaport.length === 0 ? (
                                            <div className="docs-loading-for-report">
                                                <div className="image"></div>
                                                <div className="text">
                                                    <div className="header">
                                                        {this.context.t('Loading')}
                                                    </div>
                                                    <div className="instruction">
                                                        {this.context.t('Please wait a second while we are retriving, loading and calculating your data')}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            !sendGetDocumentsRequest && docsDownloadedForRaport.length > 0 ? (
                                                <div className="docs-loaded">

                                                    <div className="docs-table" ref="summaryRow">
                                                        <div className="actions-and-filters">
                                                            <div className="actions-selector" ref={this.actionsListContainer}>
                                                                <div className="current-action" onClick={() => this.setState({ showActionsList: !showActionsList })}>
                                                                    <div className="label">{this.context.t("Choose action")}</div>
                                                                    <div className="selected-option">{this.getSelectedActionTitle(selectFor)}</div>
                                                                    <span className={`expend-icon ${showActionsList ? 'expended' : ''}`}></span>
                                                                </div>
                                                                {
                                                                    showActionsList ? (
                                                                        <ul className="actions-list">
                                                                            {
                                                                                downloadedDocsType === 'Costs' || downloadedDocsType === 'Expenses' || downloadedDocsType === 'Income' || downloadedDocsType === 'Agreements' ? (
                                                                                    <>
                                                                                        <li className={`action ${selectFor === 'customReport' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'customReport')}>
                                                                                            {this.context.t('Generate custom report')}
                                                                                            <div className="custom-tooltip">
                                                                                                {this.context.t('Generates an Excel file with selected documents, report is not saved and there is no limit in how many reports on document can be')}
                                                                                            </div>
                                                                                        </li>
                                                                                    </>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                            {
                                                                                !readOnlyProjectRole && downloadedDocsType === 'Costs' ? (
                                                                                    <li className={`action ${selectFor === 'report' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'report')}>
                                                                                        {this.context.t('Generate payment collation')}

                                                                                        <div className="custom-tooltip">
                                                                                            {this.context.t('Generates an Excel file with selected documents, the collation is saved in \"Generated collations\" and the files contained therein cannot be part of another list. Generating cost collation is available only to users with U2 or U3 permissions')}
                                                                                        </div>
                                                                                    </li>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                !readOnlyProjectRole && downloadedDocsType === 'Expenses' ? (
                                                                                    <li className={`action ${selectFor === 'report' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'report')}>
                                                                                        {this.context.t('Generate an expense report')}

                                                                                        <div className="custom-tooltip">
                                                                                            {this.context.t('It generates an Excel file with marked expenses, the statements are saved in the history and the files contained therein cannot be part of another statement. In addition, the list in the form of a pdf file will go to the cost files in the binder')}
                                                                                        </div>
                                                                                    </li>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                            {
                                                                                canDownloadFilesInProject ? (
                                                                                    <li className={`action ${selectFor === 'zip' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'zip')}>
                                                                                        {this.context.t('Download zipped docs')}

                                                                                        <div className="custom-tooltip">
                                                                                            {this.context.t('Selected documents are packed and downloaded as a zip file')}
                                                                                        </div>
                                                                                    </li>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                !readOnlyProjectRole && downloadedDocsType === 'Costs' && roleInSelectedProject !== 'USER1' ? (
                                                                                    <li className={`action ${selectFor === 'transfer' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'transfer')}>
                                                                                        {this.context.t('Generate file to import bank transfers')}

                                                                                        <div className="custom-tooltip">
                                                                                            {this.context.t('Generates a basket of bank transfers, which can then be imported directly to your bank, each document can be part of only one basket of transfers - option available only to users with the U2 or U3 permissions')}
                                                                                        </div>
                                                                                    </li>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                            {
                                                                                !readOnlyProjectRole && (downloadedDocsType === 'Costs' || downloadedDocsType === 'Income') && waproUsers.includes(this.props.user.id) ? (
                                                                                    <li className={`action ${selectFor === 'wapro' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'wapro')} data-for="wapro-tooltip" data-tip="show">
                                                                                        {this.context.t('Generate XML for WAPRO')}
                                                                                    </li>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }

                                                                            {
                                                                                !readOnlyProjectRole && downloadedDocsType === 'Costs' && (this.props.currentCompany?.company_id === 1607 || this.props.user.id === 2 || this.props.user.id === 76) ? (
                                                                                    <li className={`action ${selectFor === 'symfonia' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'symfonia')} data-for="wapro-tooltip" data-tip="show">
                                                                                        {this.context.t('Generate TXT for Symfonia')}
                                                                                    </li>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }

                                                                            {
                                                                                !readOnlyProjectRole ? (
                                                                                    <li className={`action ${selectFor === 'tags' ? 'selected' : ''}`} onClick={(e) => this.changeSelectFor(e, 'tags')} data-for="wapro-tooltip" data-tip="show">
                                                                                        {this.context.t('Bulk tags edit')}
                                                                                    </li>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </ul>
                                                                    ) : null
                                                                }
                                                            </div>

                                                            <FilterRow
                                                                searchField={searchField}
                                                                searchCurrency={searchCurrency}
                                                                selectOnlyAvailableForReport={selectOnlyAvailableForReport}
                                                                selectOnlyAvailableForBankTransferFile={selectOnlyAvailableForBankTransferFile}
                                                                selectOnlyNotPaid={selectOnlyNotPaid}
                                                                selectOnlyNotAccounted={selectOnlyNotAccounted}
                                                                downloadedDocsType={downloadedDocsType}
                                                                setParentState={obj => this.setState(obj)}
                                                            />

                                                            {
                                                                downloadedDocsType === 'Costs' || downloadedDocsType === 'Expenses' ? (
                                                                    <>
                                                                        <div className='table-edit-icon' data-for="edit-table-messages" data-tip="show" onClick={() => this.showTableCustomizer()} />
                                                                        <ReactTooltip id="edit-table-messages" place="left" effect="solid" className="default-tooltip info-tooltip">
                                                                            <p>{this.context.t("Column editor")}</p>
                                                                        </ReactTooltip>
                                                                    </>
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                        </div>

                                                        <div className={`summary-row ${docsSelectedForReport.length > 0 ? 'show' : ''} ${stickSummaryRow ? 'sticky' : ''}`}>
                                                            <div className="group">
                                                                <div className="label">
                                                                    {this.context.t('Selected docs count')}
                                                                </div>
                                                                <div className="value">
                                                                    {docsSelectedForReport.length}
                                                                </div>
                                                            </div>

                                                            {
                                                                currencyList.map(c => {
                                                                    if (selectedDocsSums[c]) {
                                                                        return (
                                                                            <div className="group">
                                                                                <div className="label">
                                                                                    {this.context.t(`Total {n}`, { n: c })}
                                                                                </div>
                                                                                <div className="value">
                                                                                    {this.formatPrice(selectedDocsSums[c])}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })
                                                            }
                                                            <div className={`action-button ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' ? 'green-frame pulsing-frame' : ''}`} onClick={e => this.handleSelectedAction(e)}>
                                                                {this.getSelectForButtonTitle(selectFor)}
                                                            </div>
                                                        </div>

                                                        {
                                                            downloadedDocsType === 'Costs' || downloadedDocsType === 'Mag' ? (
                                                                <CostsTableVirtualizedTest
                                                                    setTableRef={this.tableRef}
                                                                    searchField={searchField}
                                                                    searchCurrency={searchCurrency}
                                                                    selectOnlyAvailableForReport={selectOnlyAvailableForReport}
                                                                    selectOnlyAvailableForBankTransferFile={selectOnlyAvailableForBankTransferFile}
                                                                    selectOnlyNotPaid={selectOnlyNotPaid}
                                                                    selectOnlyNotAccounted={selectOnlyNotAccounted}
                                                                    selectFor={selectFor}
                                                                    roleInSelectedProject={roleInSelectedProject}
                                                                    docsSelectedForReport={[...docsSelectedForReport]}
                                                                    selectAll={this.selectAll}
                                                                    addDocToSelected={this.addDocToSelected}
                                                                    fileIconClick={this.fileIconClick}
                                                                    downloadReport={this.downloadReport}
                                                                    togglePaymentList={this.togglePaymentList}
                                                                    toggleAccountedStatus={this.toggleAccountedStatus}
                                                                />
                                                                // ) : (
                                                                //     <CostsTableVirtualized
                                                                //         setTableRef={this.tableRef}
                                                                //         searchField={searchField}
                                                                //         searchCurrency={searchCurrency}
                                                                //         selectOnlyAvailableForReport={selectOnlyAvailableForReport}
                                                                //         selectOnlyAvailableForBankTransferFile={selectOnlyAvailableForBankTransferFile}
                                                                //         selectOnlyNotPaid={selectOnlyNotPaid}
                                                                //         selectOnlyNotAccounted={selectOnlyNotAccounted}
                                                                //         selectFor={selectFor}
                                                                //         roleInSelectedProject={roleInSelectedProject}
                                                                //         docsSelectedForReport={[...docsSelectedForReport]}
                                                                //         selectAll={this.selectAll}
                                                                //         addDocToSelected={this.addDocToSelected}
                                                                //         fileIconClick={this.fileIconClick}
                                                                //         downloadReport={this.downloadReport}
                                                                //         togglePaymentList={this.togglePaymentList}
                                                                //         toggleAccountedStatus={this.toggleAccountedStatus}
                                                                //     />
                                                                // )
                                                            ) : (
                                                                downloadedDocsType === 'Expenses' ? (
                                                                    <ExpensesTableVirtualized
                                                                        setTableRef={this.tableRef}
                                                                        searchField={searchField}
                                                                        searchCurrency={searchCurrency}
                                                                        selectOnlyAvailableForReport={selectOnlyAvailableForReport}
                                                                        selectFor={selectFor}
                                                                        roleInSelectedProject={roleInSelectedProject}
                                                                        docsSelectedForReport={[...docsSelectedForReport]}
                                                                        selectAll={this.selectAll}
                                                                        addDocToSelected={this.addDocToSelected}
                                                                        fileIconClick={this.fileIconClick}
                                                                        downloadReport={this.downloadReport}
                                                                    />
                                                                ) : (
                                                                    downloadedDocsType === 'Agreements' ? (
                                                                        <AgreementsTableVirtualized
                                                                            setTableRef={this.tableRef}
                                                                            searchField={searchField}
                                                                            selectFor={selectFor}
                                                                            roleInSelectedProject={roleInSelectedProject}
                                                                            docsSelectedForReport={[...docsSelectedForReport]}
                                                                            selectAll={this.selectAll}
                                                                            addDocToSelected={this.addDocToSelected}
                                                                            fileIconClick={this.fileIconClick}
                                                                        />
                                                                    ) : (
                                                                        downloadedDocsType === 'Correspondence' ? (
                                                                            <CorrespondenceTableVirtualized
                                                                                setTableRef={this.tableRef}
                                                                                searchField={searchField}
                                                                                selectFor={selectFor}
                                                                                roleInSelectedProject={roleInSelectedProject}
                                                                                docsSelectedForReport={[...docsSelectedForReport]}
                                                                                selectAll={this.selectAll}
                                                                                addDocToSelected={this.addDocToSelected}
                                                                                fileIconClick={this.fileIconClick}
                                                                            />
                                                                        ) : (
                                                                            downloadedDocsType === 'Corporate' ? (
                                                                                <CorporateTableVirtualized
                                                                                    setTableRef={this.tableRef}
                                                                                    searchField={searchField}
                                                                                    selectFor={selectFor}
                                                                                    roleInSelectedProject={roleInSelectedProject}
                                                                                    docsSelectedForReport={[...docsSelectedForReport]}
                                                                                    selectAll={this.selectAll}
                                                                                    addDocToSelected={this.addDocToSelected}
                                                                                    fileIconClick={this.fileIconClick}
                                                                                />
                                                                            ) : (
                                                                                downloadedDocsType === 'Other' ? (
                                                                                    <OtherDocsTableVirtualized
                                                                                        setTableRef={this.tableRef}
                                                                                        searchField={searchField}
                                                                                        selectFor={selectFor}
                                                                                        roleInSelectedProject={roleInSelectedProject}
                                                                                        docsSelectedForReport={[...docsSelectedForReport]}
                                                                                        selectAll={this.selectAll}
                                                                                        addDocToSelected={this.addDocToSelected}
                                                                                        fileIconClick={this.fileIconClick}
                                                                                    />
                                                                                ) : (
                                                                                    downloadedDocsType === 'Income' ? (
                                                                                        <IncomesTableVirtualized
                                                                                            setTableRef={this.tableRef}
                                                                                            searchField={searchField}
                                                                                            searchCurrency={searchCurrency}
                                                                                            selectOnlyAvailableForReport={selectOnlyAvailableForReport}
                                                                                            selectOnlyAvailableForBankTransferFile={selectOnlyAvailableForBankTransferFile}
                                                                                            selectOnlyNotPaid={selectOnlyNotPaid}
                                                                                            selectOnlyNotAccounted={selectOnlyNotAccounted}
                                                                                            selectFor={selectFor}
                                                                                            roleInSelectedProject={roleInSelectedProject}
                                                                                            docsSelectedForReport={[...docsSelectedForReport]}
                                                                                            selectAll={this.selectAll}
                                                                                            addDocToSelected={this.addDocToSelected}
                                                                                            fileIconClick={this.fileIconClick}
                                                                                            downloadReport={this.downloadReport}
                                                                                            togglePaymentList={this.togglePaymentList}
                                                                                            toggleAccountedStatus={this.toggleAccountedStatus}
                                                                                        />
                                                                                    ) : (
                                                                                        null
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            ) : (
                                                !sendGetDocumentsRequest && docsDownloadedForRaport.length === 0 && sendRequest ? (
                                                    <div className="no-docs-found">
                                                        <div className="image"></div>
                                                        <div className="text">
                                                            <div className="header">
                                                                {this.context.t('No results were found for your search')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            )
                                        )
                                    )
                                }
                            </div>
                        ) : (
                            currentTab === 'listOfReports' ? (
                                <ReportsList location={this.props.location} />
                            ) : (
                                currentTab === 'listOfZips' ? (
                                    <ExportedFiles location={this.props.location} />
                                ) : (
                                    currentTab === 'listOfBankTransferFiles' ? (
                                        <BankTransferFilesList location={this.props.location} />
                                    ) : (
                                        <AccountingFilesList location={this.props.location} />
                                    )
                                )
                            )
                        )
                    }

                    <ReactTooltip id="due-date-filter-info" place="right" effect="solid" className="default-tooltip report-type-tooltip">
                        <p>{this.context.t('Documents without an assigned due date and those marked as paid during the verification stage will not be included in this search')}</p>
                    </ReactTooltip>
                </div>
            )
        } else {
            return null
        }
    }
}

Reports.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    overlayPreferance: state.User.overlayPreferance || {},
    userProjects: state.User.userProjects,
    highestAcceptancePermission: state.User.highestAcceptancePermission,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
    currentMonth: state.Event.currentMonth,
    reportsStartDate: state.Reports.reportsStartDate,
    reportsEndDate: state.Reports.reportsEndDate,
    docsDownloadedForRaport: state.Reports.docsDownloadedForRaport ? [...state.Reports.docsDownloadedForRaport] : [],
    alreadyPaidDocs: state.Reports.alreadyPaidDocs,
    documentsAvalibleForReport: state.Reports.documentsAvalibleForReport,
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    gotDocumentsResponse: state.Reports.gotDocumentsResponse,
    xlsxReportData: state.Reports.xlsxReportData,
    generatingXlsxReport: state.Reports.generatingXlsxReport,
    previousGeneratedReports: state.Reports.previousGeneratedReports,
    previousGeneratedReportsLoaded: state.Reports.previousGeneratedReportsLoaded,
    reportsSortField: state.Reports.reportsSortField,
    reportsSortOrder: state.Reports.reportsSortOrder,

    projectSelectedFromDashboard: state.Company.dashboardProjectForReports,

    sortField: state.Reports.docsSortField,
    sortOrder: state.Reports.docsSortOrder,
    downloadedDocsType: state.Reports.downloadedDocsType,
    transfers: [...state.Transfers.transfers],
    uploadedFiles: state.File.uploadedFiles,
    loadingScreen: state.File.loadingScreen || false,

    currentSelectedProject: state.User.currentProject
})

const mapDispatchToProps = {
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    datePickerSelect: EventActions.datePickerSelect,
    unselectDatePickerDay: EventActions.datePickerUnselect,
    getUserProjects: UserActions.getUserProjects,
    setCurrentProject: UserActions.setCurrentProject,
    getDocuments: ReportsActions.getDocuments,
    getDocumentsCrossProjects: ReportsActions.getDocumentsCrossProjects,
    generateReport: ReportsActions.generateReport,
    generateCustomReport: ReportsActions.generateCustomReport,
    generateCustomIncomeReport: ReportsActions.generateCustomIncomeReport,
    generateCustomAgreementsReport: ReportsActions.generateCustomAgreementsReport,
    generateWaproXML: ReportsActions.generateWaproXML,
    generateSymfoniaXML: ReportsActions.generateSymfoniaXML,
    tooglePaymentForDoc: ReportsActions.tooglePaymentForDoc,
    tooglePaymentForTransfer: ReportsActions.tooglePaymentForTransfer,
    tooglePaymentForIncomeDoc: ReportsActions.tooglePaymentForIncomeDoc,
    toogleAccountmentStatusForDoc: ReportsActions.toogleAccountmentStatusForDoc,
    toogleAccountmentStatusForTransfer: ReportsActions.toogleAccountmentStatusForTransfer,
    toogleAccountmentStatusForIncomeDoc: ReportsActions.toogleAccountmentStatusForIncomeDoc,
    downloadReport: ReportsActions.downloadReport,
    downloadMultipleFiles: FileActions.downloadMultipleFiles,
    getMyReportsList: ReportsActions.getMyReportsList,
    downloadReportFiles: ReportsActions.downloadReportFiles,
    generateBankTransfersFile: ReportsActions.generateBankTransfersFile,
    generateBankTransfetFileForInternationalTransfers: ReportsActions.generateBankTransfetFileForInternationalTransfers,

    setCurrentCompany: CompanyActions.setCurrentCompany,
    selectStartDate: ReportsActions.selectStartDate,
    unselectStartDate: ReportsActions.unselectStartDate,
    selectEndDate: ReportsActions.selectEndDate,
    unselectEndDate: ReportsActions.unselectEndDate,

    switchSortingFilter: ReportsActions.switchDocsSortingFilter,
    switchSortingFilterReports: ReportsActions.switchSortingFilterReports,

    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
    getTransfers: TransfersActions.getAllMyTransfers,
    getFiles: FileActions.getFiles,

    saveAccountNumber: UserActions.saveAccountNumber,
    addMessage: MessagesActions.addMessage,
    alertWarn: AlertActions.warning,

    toggleOverlay: UserActions.toggleOverlay,
    openNewTab: TabsControlerActions.openNewTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)