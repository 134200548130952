import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf';
import { Spinner, Bounce } from 'react-activity'
import TextareaAutosize from 'react-textarea-autosize'
import SimpleBar from 'simplebar-react';
import ScrollContainer from 'react-indiana-drag-scroll'
import 'simplebar/dist/simplebar.min.css';
import throttle from "lodash.throttle"

import { FileActions } from '../actions/FileActions'
import { CompanyActions } from '../actions/CompanyActions'
import { ReportsActions } from '../actions/ReportsActions'
import { AlertActions } from '../actions/AlertActions'
import DeleteInvoiceModal from './DeleteInvoiceModal'
import ChangeDocTypeModal from './ChangeDocTypeModal'
import Calendar from './Calendar'

import FileAcceptanceStepsSection from './PdfPreviewComponents/FileAcceptanceStepsSection'
import CostsFrom from './PdfPreviewComponents/CostsFrom'
import ExpensesFrom from './PdfPreviewComponents/ExpensesFrom'
import IncomesFrom from './PdfPreviewComponents/IncomesFrom'
import AgreementsForm from './PdfPreviewComponents/AgreementsForm'
import CorrespondenceForm from './PdfPreviewComponents/CorrespondenceForm'
import CorporateForm from './PdfPreviewComponents/CorporateForm'
import OtherDocsFrom from './PdfPreviewComponents/OtherDocsFrom'
import ProtocolFrom from './PdfPreviewComponents/ProtocolFrom'
import LinkedDocs from './PdfPreviewComponents/LinkedDocs'
import PreviewToolbar from './PdfPreviewComponents/PreviewToolbar'
import MoveToOtherProjectPopup from './PdfPreviewComponents/MoveToOtherProjectPopup'

import AvailableInOtherPlanLabel from './AvailableInOtherPlanLabel'
import moment from 'moment'
import capitalCase from '../helpers/capitalCase'


import routes from '../helpers/routes'
import { zoomInPdf, zoomInImg, zoomOutPdf, zoomOutImg } from '../helpers/pdfPreviewHelpers'

import '../css/FilePreview.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export class PdfPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            originalcounterpartyId: -1,
            showDocData: true,
            showLinkedDoc: false,
            numPages: null,
            pageNumber: 1,
            pdfScale: 1.5,
            pdfScalePercentage: 100,
            didFitToWidth: false,
            pdfRoateAngle: 0,
            imgScale: 0.6,
            imgScalePercentage: 100,
            imgRoateAngle: 0,
            didFitImgToWidth: false,
            deleteModal: false,

            editMode: false,

            showDueDateDocCalendar: false,
            posUpDueDateDocCalendar: false,
            posUpDueDateDocCalendar: 0,

            showIssueDateDocCalendar: false,
            posUpIssueDateDocCalendar: false,
            posUpIssueDateDocCalendar: 0,

            showSaleDateDocCalendar: false,
            posUpSaleDateDocCalendar: false,
            posUpSaleDateDocCalendar: 0,

            showDueDateExpenseCalendar: false,
            dueDateCalendarExpenseId: -1,
            posUpDueDateExpenseCalendar: false,
            posYDueDateExpenseCalendar: 0,

            acceptancePermissions: '',
            cursourWait: false,

            showPrevArrow: true,
            showNextArrow: true,
        }
    }

    componentDidMount() {
        this.setDivSize()
        window.addEventListener("resize", throttle(this.setDivSize, 500))
        window.addEventListener("keydown", this.escFunction, false)

        if (this.props.pdfPreviewData.projectId) {
            var project = this.props.userProjects.find(e => e.id === this.props.pdfPreviewData.projectId)
            if (project && project.company) {
                this.setState({
                    tagsList: project.company.tags_list ? JSON.parse(project.company.tags_list) : [],
                    docOwnerCompany: project.company
                })

                this.props.getCompanyWaproNetAccounts(project.company.id, (success, accounts) => {
                    if (success) {
                        this.setState({
                            netAccountList: accounts
                        })
                    }
                })
            }
        }

        if (this.showLinkedDocsOnLoad()) {
            this.setState({
                showLinkedDoc: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { pdfPreviewData, userProjects, userCompanies } = this.props
        const { originalcounterpartyId, tagsList } = this.state
        if (!prevProps.pdfPreviewData.saveChangesError && pdfPreviewData.saveChangesError) {
            this.setState({
                editMode: true
            })
        }

        if (!prevProps.pdfPreviewData.projectId && pdfPreviewData.projectId) {
            console.log(pdfPreviewData.projectId)
            var project = userProjects.find(e => e.id === pdfPreviewData.projectId)
            if (project) {
                this.setState({
                    acceptancePermissions: project.acceptance_permissions
                })
                if (userCompanies) {
                    var currentCompany = userCompanies.find(e => e.company_id === project.company_id)
                    if (currentCompany) {
                        this.setState({
                            isAccountantInCurrentCompany: currentCompany.is_accountant
                        })
                    }
                }
                if (project.company) {
                    this.setState({
                        tagsList: project.company.tags_list ? JSON.parse(project.company.tags_list) : [],
                        docOwnerCompany: project.company
                    })
                    this.props.getCompanyWaproNetAccounts(project.company.id, (success, accounts) => {
                        if (success) {
                            this.setState({
                                netAccountList: accounts
                            })
                        }
                    })
                }
            } else {
                this.setState({
                    acceptancePermissions: 'USER1'
                })
            }
        }

        if (pdfPreviewData.projectId && !tagsList) {
            var project = userProjects.find(e => e.id === pdfPreviewData.projectId)
            if (project && project.company) {
                console.log(project.company)
                this.setState({
                    tagsList: project.company.tags_list ? JSON.parse(project.company.tags_list) : [],
                    docOwnerCompany: project.company
                })
            }
        }

        if (prevProps.pdfPreviewData.id !== pdfPreviewData.id) {
            if (!this.showLinkedDocsOnLoad() && this.state.showLinkedDoc) {
                this.setState({
                    showLinkedDoc: false
                })
            }
        }

        if (originalcounterpartyId === -1 && pdfPreviewData.counterpartyId) {
            this.setState({
                originalcounterpartyId: pdfPreviewData.counterpartyId
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", throttle(this.setDivSize, 500))
        window.removeEventListener("keydown", this.escFunction, false)

        document.body.style.overflow = "auto"

        if (window.location.pathname.includes('/document-preview/')) {
            window.history.replaceState(null, null, "/dashboard")
        }
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            const { showDueDateDocCalendar, showIssueDateDocCalendar, showSaleDateDocCalendar, showDueDateExpenseCalendar } = this.state
            if (showDueDateDocCalendar || showIssueDateDocCalendar || showSaleDateDocCalendar || showDueDateExpenseCalendar) {
                this.closeAllPopups(e)
            } else if (this.state.deleteModal) {
                this.setState({
                    deleteModal: false,
                    fileToDeletId: null,
                    fileToDeleteType: null,
                    deleteInvoiceName: null
                })
            } else if (this.state.editMode) {
                this.setState({
                    editMode: false
                })
                this.cancelEditMode()
            } else {
                this.closePreview()
            }
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages: numPages,
        });

        setTimeout(() => {
            this.setState({
                showPrevArrow: false,
                showNextArrow: false,
            })
        }, 300)

        this.setDivSize()
    }

    onSecondDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            secondDocNumPages: numPages,
            pageNumberSecondDoc: 1,
        });

        this.setDivSize()
    }

    onPageLoadSuccess = obj => {
        console.log('---------')
        console.log(obj._pageInfo)
        console.log('---------')
        var originalPdfWidth = obj.originalWidth
        var originalPdfHeight = obj.originalHeight
        var pdfRoateAngle = 0
        if (obj._pageInfo && obj._pageInfo.rotate) {
            pdfRoateAngle = obj._pageInfo.rotate
        }
        this.setState({
            originalPdfWidth: originalPdfWidth,
            originalPdfHeight: originalPdfHeight,
            pdfRoateAngle: pdfRoateAngle
        })
        if (this.refs.pdfWrapper) {
            var maxPdfScale = this.refs.pdfWrapper.clientWidth / originalPdfWidth
            var maxHeigth = window.innerHeight - 68
            var fitToHeightPdfScale = maxHeigth / originalPdfHeight

            console.log(fitToHeightPdfScale)
            this.setState({
                maxPdfScale: maxPdfScale,
                fitToHeightPdfScale: fitToHeightPdfScale
            })
        }
    }

    showLinkedDocsOnLoad = () => {
        const { user, pdfPreviewData } = this.props
        console.log(pdfPreviewData)
        console.log(pdfPreviewData.name && (!pdfPreviewData.name.includes('_corporate') || !pdfPreviewData.name.includes('_expenses.') || !pdfPreviewData.name.includes('_expenses(')))
        console.log(!pdfPreviewData.deleted)
        return (pdfPreviewData.name && (!pdfPreviewData.name.includes('_corporate') || !pdfPreviewData.name.includes('_expenses.') || !pdfPreviewData.name.includes('_expenses('))) && !pdfPreviewData.deleted
    }

    setDivSize = () => {
        if (this.refs.pdfWrapper) {
            var newMaxPdfScale = this.refs.pdfWrapper.clientWidth / this.state.originalPdfWidth
            var maxHeigth = window.innerHeight - 68
            var fitToHeightPdfScale = maxHeigth / this.state.originalPdfHeight

            this.setState({
                maxPdfScale: newMaxPdfScale,
                fitToHeightPdfScale: fitToHeightPdfScale
            })

            const { pdfScale } = this.state
            if (pdfScale >= newMaxPdfScale) {
                var newPdfScale = newMaxPdfScale
                var newPdfScalePercentage = parseInt(newMaxPdfScale / 1.5 * 100)

                this.setState({
                    pdfScale: newPdfScale,
                    pdfScalePercentage: newPdfScalePercentage
                })
            }
        }
    }

    zoomIn = (type) => {
        if (type === 'pdf') {
            const { pdfScalePercentage, maxPdfScale } = this.state
            var newPdfScale = zoomInPdf(pdfScalePercentage, maxPdfScale)

            this.setState(newPdfScale)
        } else {
            const { imgScale, imgScalePercentage } = this.state
            var newImgScale = zoomInImg(imgScale, imgScalePercentage)

            this.setState(newImgScale)
        }
    }

    zoomOut = (type) => {
        if (type === 'pdf') {
            const { pdfScalePercentage } = this.state
            var newPdfScale = zoomOutPdf(pdfScalePercentage)

            this.setState(newPdfScale)
        } else {
            const { imgScale, imgScalePercentage } = this.state
            var newImgScale = zoomOutImg(imgScale, imgScalePercentage)

            this.setState(newImgScale)
        }
    }

    fitToWidth = (type) => {
        if (type === 'pdf') {
            const { maxPdfScale } = this.state
            this.setState({
                pdfScale: maxPdfScale,
                pdfScalePercentage: parseInt(maxPdfScale / 1.5 * 100),
                didFitToWidth: true
            })
        } else {
            this.setState({
                imgScale: 1.0,
                imgScalePercentage: parseInt(1.0 / 0.6 * 100),
                didFitImgToWidth: true,
            })
        }
    }

    fitToHeight = (type) => {
        if (type === 'pdf') {
            const { fitToHeightPdfScale } = this.state

            this.setState({
                pdfScale: fitToHeightPdfScale,
                pdfScalePercentage: parseInt(fitToHeightPdfScale / 1.5 * 100),
                didFitToWidth: false
            })
        } else {
            const img = new Image();

            img.src = this.props.pdfPreviewDataBody

            img.onload = () => {
                const imgHeight = img.naturalHeight;
                const imgWidth = img.naturalWidth;

                var maxHeigth = window.innerHeight - 68
                var maxWidth = window.innerWidth - 300
                var pageScale = maxHeigth / maxWidth
                var imageScale = imgHeight / imgWidth
                var fitImgToHeightScale = (imgWidth * maxHeigth / imgHeight) / maxWidth
                console.log(pageScale)
                console.log(imageScale)
                console.log(fitImgToHeightScale)

                this.setState({
                    imgScale: fitImgToHeightScale,
                    imgScalePercentage: parseInt(fitImgToHeightScale / 0.6 * 100),
                    didFitImgToWidth: false
                })
            };
        }
    }

    rotateDoc = (type) => {
        if (type === 'pdf') {
            const { pdfRoateAngle } = this.state
            if (pdfRoateAngle === 0) {
                this.setState({
                    pdfRoateAngle: 90
                })
            } else if (pdfRoateAngle === 90) {
                this.setState({
                    pdfRoateAngle: 180
                })
            } else if (pdfRoateAngle === 180) {
                this.setState({
                    pdfRoateAngle: 270
                })
            } else {
                this.setState({
                    pdfRoateAngle: 0
                })
            }
        } else {
            const { imgRoateAngle } = this.state
            if (imgRoateAngle === 0) {
                this.setState({
                    imgRoateAngle: 90
                })
            } else if (imgRoateAngle === 90) {
                this.setState({
                    imgRoateAngle: 180
                })
            } else if (imgRoateAngle === 180) {
                this.setState({
                    imgRoateAngle: 270
                })
            } else {
                this.setState({
                    imgRoateAngle: 0
                })
            }
        }
    }

    resetZoom = (type) => {
        if (type === 'pdf') {
            this.setState({ pdfScale: 1.0 })
        } else {
            this.setState({ imgScale: 1.0 })
        }
    }

    deleteInvoice = () => {
        const { pdfPreviewData, user } = this.props
        const { fileToDeletId, fileToDeleteType, deleteInvoiceName } = this.state

        if (pdfPreviewData.deleted) {
            console.log('prmanent deleted')
            if (fileToDeletId !== pdfPreviewData.id || !fileToDeletId || !fileToDeleteType || !deleteInvoiceName) {
                return
            } else {
                this.setState({
                    deleteModal: false,
                    fileToDeletId: null,
                    fileToDeleteType: null,
                    deleteInvoiceName: null
                })
                this.props.permanentDeleteInvoice(this.props.pdfPreviewData.projectId, fileToDeletId, fileToDeleteType, deleteInvoiceName, false, user.first_name + ' ' + user.last_name)
            }
        } else {
            if (fileToDeletId !== pdfPreviewData.id || !fileToDeletId || !fileToDeleteType || !deleteInvoiceName) {
                return
            } else {
                this.setState({
                    deleteModal: false,
                    fileToDeletId: null,
                    fileToDeleteType: null,
                    deleteInvoiceName: null
                })
                this.props.deleteInvoice(this.props.pdfPreviewData.projectId, fileToDeletId, fileToDeleteType, deleteInvoiceName, false, user.first_name + ' ' + user.last_name)
            }
        }
    }

    startEdit = () => {
        if (!this.state.editMode) {
            this.setState({
                editMode: true
            })
            this.props.makeEditedDocBackup()
        }
    }

    cancelEditMode = () => {
        if (!this.state.cursourWait) {
            this.setState({
                editMode: false
            })
            this.props.cancelDocEdit()
            const { pdfPreviewData, backupPdfPreviewData } = this.props
            var changetMPK = pdfPreviewData?.mpkSplitWineAvenue?.length ? pdfPreviewData?.mpkSplitWineAvenue : null
            var backupMpk = backupPdfPreviewData?.mpkSplitWineAvenue?.length ? backupPdfPreviewData?.mpkSplitWineAvenue : null
            if (changetMPK && backupMpk && JSON.stringify(changetMPK) !== JSON.stringify(backupMpk)) {
                this.restoreMpkAfterEditCancel(changetMPK, backupMpk)
            }
        }
    }

    restoreMpkAfterEditCancel = (changedMpk, backupMpk) => {
        const { pdfPreviewData } = this.props
        changedMpk.forEach(mpkInstance => {
            let originalInstance = backupMpk.find(e => e.id === mpkInstance.id)
            if (originalInstance) {
                this.props.editMpkSplitInstance(pdfPreviewData.id, 0, originalInstance.id, originalInstance)
            } else {
                this.props.deleteMpkSplitInstance(pdfPreviewData.id, 0, mpkInstance.id)
            }
        })

        backupMpk.forEach(originalInstance => {
            let changedMpkInstance = changedMpk.find(e => e.id === originalInstance.id)
            if (!changedMpkInstance) {
                this.props.addMpkSplitInstance(pdfPreviewData.id, 0, originalInstance, splitId => {
                    if (splitId) {
                        originalInstance.id = splitId
                        this.props.editDocInput(backupMpk, 'mpkSplitWineAvenue', pdfPreviewData.docType, pdfPreviewData.docId)
                    }
                })
            }
        })
    }

    saveChanges = () => {
        const { pdfPreviewData } = this.props
        const { originalcounterpartyId, cursourWait } = this.state
        if (!cursourWait) {
            if (['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(pdfPreviewData.docType) && pdfPreviewData.sellerVatId && pdfPreviewData.sellerVatId.length > 12) {
                this.props.alertWarn(this.context.t('Seller VatID to long'))
            } else {
                this.setState({
                    cursourWait: true
                })
                this.props.saveDocChanges(pdfPreviewData, () => {
                    this.setState({
                        editMode: false,
                        cursourWait: false
                    })
                    if (pdfPreviewData.counterpartyId && originalcounterpartyId !== pdfPreviewData.counterpartyId) {
                        this.props.getCounterpartyDocs(pdfPreviewData.id, pdfPreviewData.counterpartyId)
                        this.setState({
                            originalcounterpartyId: pdfPreviewData.counterpartyI
                        })
                    }
                })
            }
        }
    }

    cancelDeleteInvoice = () => {
        this.setState({
            deleteModal: false,
            fileToDeletId: null,
            fileToDeleteType: null,
            deleteInvoiceName: null
        })
    }

    openDeleteModal = (id, status, name, alreadtDeleted) => {
        this.setState({
            deleteModal: true,
            moveToTrash: !alreadtDeleted,
            fileToDeletId: id,
            fileToDeleteType: status,
            deleteInvoiceName: name
        })
    }

    restoreDocument = (id, status, name, projectId) => {
        this.props.restoreDocument(id, status, name, projectId)

    }

    prevFileExists = (i) => {
        const { prevAndNext } = this.props
        if (prevAndNext) {
            return prevAndNext.prev
        } else {
            if (i) {
                var projectFiles = this.props.uploadedFiles.find(e => e.project === this.props.pdfPreviewData.projectId)
                if (i > 0 && projectFiles) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }

    nextFileExists = (i) => {
        const { prevAndNext } = this.props
        if (prevAndNext) {
            return prevAndNext.next
        } else {
            if (i || i === 0) {
                var projectFiles = this.props.uploadedFiles.find(e => e.project === this.props.pdfPreviewData.projectId)
                if (projectFiles) {
                    if (projectFiles.files[i + 1]) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }

    getFile = (i, type) => {
        if (i === 0) {
            i = this.props.pdfPreviewData.fileIndex
            if (type === 'next') i = i + 1
            else i = i - 1
        }
        const { prevAndNext } = this.props
        if (prevAndNext) {
            if (type === 'prev' && prevAndNext.prev) {
                this.props.goToDoc(prevAndNext.index - 1, -1)
            } else if (type === 'next' && prevAndNext.next) {
                this.props.goToDoc(prevAndNext.index + 1, 1)
            }
        } else {
            var projectFiles = this.props.uploadedFiles.find(e => e.project === this.props.pdfPreviewData.projectId)
            if (projectFiles) {
                var file = projectFiles.files[i]

                if (file) {
                    this.props.getFileBody(parseInt(file.project_id), file.path, file.id, file.ocr_status, file.acceptance_step)
                }
            }
        }

        this.setState({
            showPrevArrow: false,
            showNextArrow: false,
            startedMouseSwipe: false,
            pageNumber: 1
        })
    }

    goToFile = (nextId, projectId) => {
        if (projectId) {
            var projectFiles = this.props.uploadedFiles.find(e => e.project === projectId)
            if (projectFiles) {
                var file = projectFiles.files.find(f => f.id === nextId)

                if (file) {
                    this.props.getFileBody(parseInt(file.project_id), file.path, file.id, file.ocr_status, file.acceptance_step)
                }
            }
        } else {
            var projectFiles = this.props.uploadedFiles.find(e => e.project === this.props.pdfPreviewData.projectId)
            if (projectFiles) {
                var file = projectFiles.files.find(f => f.id === nextId)

                if (file) {
                    this.props.getFileBody(parseInt(file.project_id), file.path, file.id, file.ocr_status, file.acceptance_step)
                }
            }
        }
    }

    closeAllPopups = e => {
        if (e.keyCode === 27 || !this.hasSomeParentTheClass(e.target, 'more-options')) {
            this.setState({
                showMoreDocOptions: false
            })
        }

        if (e.keyCode === 27 || !this.hasSomeParentTheClass(e.target, 'download')) {
            this.setState({
                showDownloadOptions: false
            })
        }

        if (e.keyCode === 27 || (this.state.showDueDateDocCalendar && !this.hasSomeParentTheClass(e.target, 'due-date-calendar') && !this.hasSomeParentTheClass(e.target, 'due-date-container'))) {
            console.log('close all popups func')
            this.setState({
                showDueDateDocCalendar: false,
                posXDueDateDocCalendar: 0,
                posYDueDateDocCalendar: 0,
                posUpDueDateDocCalendar: false
            })
        }

        if (e.keyCode === 27 || (this.state.showIssueDateDocCalendar && !this.hasSomeParentTheClass(e.target, 'issue-date-calendar') && !this.hasSomeParentTheClass(e.target, 'issue-date-container'))) {
            console.log('close all popups func')
            this.setState({
                showIssueDateDocCalendar: false,
                posXIssueDateDocCalendar: 0,
                posYIssueDateDocCalendar: 0,
                posUpIssueDateDocCalendar: false
            })
        }

        if (e.keyCode === 27 || (this.state.showSaleDateDocCalendar && !this.hasSomeParentTheClass(e.target, 'sale-date-calendar') && !this.hasSomeParentTheClass(e.target, 'sale-date-container'))) {
            console.log('close all popups func')
            this.setState({
                showSaleDateDocCalendar: false,
                posXSaleDateDocCalendar: 0,
                posYSaleDateDocCalendar: 0,
                posUpSaleDateDocCalendar: false
            })
        }

        if (e.keyCode === 27 || (this.state.showDueDateExpenseCalendar && !this.hasSomeParentTheClass(e.target, 'issue-date-container'))) {
            console.log('close all popups func')
            this.setState({
                showDueDateExpenseCalendar: false,
                posYDueDateExpenseCalendar: 0,
                posUpDueDateExpenseCalendar: false
            })
        }
    }

    hasSomeParentTheClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }

        return false
    }

    showDueDateDocCalendar = e => {
        console.log('showDueDateDocCalendar func call ' + moment().format('HH:mm:ss:SSS'))

        var inputPosition = e.target.getBoundingClientRect()
        var left = inputPosition.left
        var top = inputPosition.bottom
        var flipTop = false


        if (window.innerHeight - top < 300) {
            flipTop = true
            top -= inputPosition.height
        }

        if (this.state.editMode) {
            this.setState({
                posXDueDateDocCalendar: left,
                posYDueDateDocCalendar: top,
                posUpDueDateDocCalendar: flipTop,
                showDueDateDocCalendar: true,
            })
        }
    }

    showIssueDateDocCalendar = e => {
        console.log('showIssueDateDocCalendar func call ' + moment().format('HH:mm:ss:SSS'))

        var inputPosition = e.target.getBoundingClientRect()
        var top = inputPosition.bottom
        var left = inputPosition.left
        var flipTop = false

        if (window.innerHeight - top < 300) {
            flipTop = true
            top -= inputPosition.height
        }

        if (this.state.editMode) {
            this.setState({
                posXIssueDateDocCalendar: left,
                posYIssueDateDocCalendar: top,
                posUpIssueDateDocCalendar: flipTop,
                showIssueDateDocCalendar: true,
            })
        }
    }

    showSaleDateDocCalendar = e => {
        console.log('showSaleDateDocCalendar func call ' + moment().format('HH:mm:ss:SSS'))

        var inputPosition = e.target.getBoundingClientRect()
        var top = inputPosition.bottom
        var left = inputPosition.left
        var flipTop = false

        if (window.innerHeight - top < 300) {
            flipTop = true
            top -= inputPosition.height
        }

        if (this.state.editMode) {
            this.setState({
                posXSaleDateDocCalendar: left,
                posYSaleDateDocCalendar: top,
                posUpSaleDateDocCalendar: flipTop,
                showSaleDateDocCalendar: true,
            })
        }
    }

    showDueDateExpenseCalendar = (e, id) => {
        console.log('showDueDateExpenseCalendar func call ' + moment().format('HH:mm:ss:SSS'))

        var inputPosition = e.target.getBoundingClientRect()
        var top = inputPosition.bottom
        var flipTop = false

        if (window.innerHeight - top < 300) {
            flipTop = true
            top -= inputPosition.height
        }

        if (this.state.editMode) {
            this.setState({
                posYDueDateExpenseCalendar: top,
                posUpDueDateExpenseCalendar: flipTop,
                showDueDateExpenseCalendar: true,
                dueDateCalendarExpenseId: id,
            })
        }
    }

    onCalendarSelect = date => {
        const { pdfPreviewData } = this.props
        const { showDueDateDocCalendar, showIssueDateDocCalendar, showSaleDateDocCalendar, showDueDateExpenseCalendar, dueDateCalendarExpenseId } = this.state
        if (date) {
            if (showDueDateDocCalendar) {
                this.setState({
                    showDueDateDocCalendar: false,
                    posXDueDateDocCalendar: 0,
                    posYDueDateDocCalendar: 0,
                    posUpDueDateDocCalendar: false
                })
                this.props.editDocInput(moment(date).format('YYYY-MM-DD'), 'dueDate', pdfPreviewData.docType, pdfPreviewData.docId)
            } else if (showIssueDateDocCalendar) {
                this.setState({
                    showIssueDateDocCalendar: false,
                    posXIssueDateDocCalendar: 0,
                    posYIssueDateDocCalendar: 0,
                    posUpIssueDateDocCalendar: false
                })
                if (pdfPreviewData.docType === 'PROTOCOL') {
                    this.props.editDocInput(moment(date).format('YYYY-MM-DD'), 'date', pdfPreviewData.docType, pdfPreviewData.docId)
                } else {
                    this.props.editDocInput(moment(date).format('YYYY-MM-DD'), 'issueDate', pdfPreviewData.docType, pdfPreviewData.docId)
                }
            } else if (showSaleDateDocCalendar) {
                this.setState({
                    showSaleDateDocCalendar: false,
                    posXSaleDateDocCalendar: 0,
                    posYSaleDateDocCalendar: 0,
                    showSaleDateDocCalendar: false
                })
                this.props.editDocInput(moment(date).format('YYYY-MM-DD'), 'saleDate', pdfPreviewData.docType, pdfPreviewData.docId)
            } else if (showDueDateExpenseCalendar) {
                if (this.state.dueDateCalendarExpenseId > -1) {
                    this.props.editDocInput(moment(date).format('YYYY-MM-DD'), 'issue_date', 'EXPENSES', dueDateCalendarExpenseId)
                }
                this.setState({
                    showDueDateExpenseCalendar: false,
                    posYDueDateExpenseCalendar: 0,
                    posUpDueDateExpenseCalendar: false,
                    dueDateCalendarExpenseId: -1,
                })
            }
        }
    }

    onCalendarUnselecet = (date) => {
        this.setState({
            showDueDateDocCalendar: false,
            posXDueDateDocCalendar: 0,
            posYDueDateDocCalendar: 0,
            posUpDueDateDocCalendar: false,
            showIssueDateDocCalendar: false,
            posXIssueDateDocCalendar: 0,
            posYIssueDateDocCalendar: 0,
            posUpIssueDateDocCalendar: false,
            showDueDateExpenseCalendar: false,
            posYDueDateExpenseCalendar: 0,
            posUpDueDateExpenseCalendar: false,
            dueDateCalendarExpenseId: -1,
        })
        console.log(date)
    }

    toggleDocData = () => {
        this.setState({
            showDocData: !this.state.showDocData
        })

        setTimeout(() => {
            this.setDivSize()
        }, 500)
    }

    toggleLinkedDocs = () => {
        this.setState({
            showLinkedDoc: !this.state.showLinkedDoc
        })

        setTimeout(() => {
            this.setDivSize()
        }, 500)
    }

    showMoveToOtherBinderPopup = () => {
        this.setState({
            showMoveToOtherProjectPopup: true
        })
    }

    hideMoveToOtherProjectPopup = () => {
        this.setState({
            showMoveToOtherProjectPopup: false
        })
    }

    moveToOtherProject = newProjectId => {
        const { pdfPreviewData } = this.props

        this.setState({
            cursourWait: true
        })
        this.props.moveAcceptedFileToOtherProject(pdfPreviewData.id, pdfPreviewData.projectId, newProjectId, (success) => {
            this.setState({
                cursourWait: false
            })
            if (success) {
                this.setState({
                    showMoveToOtherProjectPopup: false
                })
            }
        })
    }

    handleChangeDocAccounted = () => {
        const { isAccountantInCurrentCompany } = this.state
        if (isAccountantInCurrentCompany) {
            const { pdfPreviewData } = this.props
            this.props.toogleAccountmentStatusForDoc(pdfPreviewData.docId, pdfPreviewData.id, pdfPreviewData.projectId, !pdfPreviewData.accounted);

            this.props.editDocInput(!pdfPreviewData.accounted, 'accounted', pdfPreviewData.docType, pdfPreviewData.docId)

            if (this.state.editMode) {
                this.props.changeAccountmentStatusInPdfBackup(!pdfPreviewData.accounted)
            }
        } else {
            this.props.alertWarn(this.context.t('Only users with the accountant role can change this document status'))
        }
    }

    downloadDocsInExpensesReport = e => {
        const { pdfPreviewData } = this.props
        this.setState({
            cursourWait: true
        })
        this.props.downloadReportFiles(pdfPreviewData.id, pdfPreviewData.projectId, pdfPreviewData.name, () => {
            this.setState({
                cursourWait: false
            })
        })
    }

    downloadAllAssignedDocuments = filesToDownload => {
        const { pdfPreviewData } = this.props

        this.setState({
            cursourWait: true
        })
        this.props.downloadFilesAssignedToAgreement(pdfPreviewData.projectId, filesToDownload, pdfPreviewData.name, () => {
            this.setState({
                cursourWait: false
            })
        })
    }

    closePreview = () => {
        this.props.togglePdfPreview(false)

        if (this.props.customGoToDocControler) {
            this.props.close()
        }
    }

    closeSideBySidePreview = () => {
        this.props.closeSideBySidePreview()
    }

    onPdfPassword = (callback, reason) => {
        switch (reason) {
            case 1: {
                const password = prompt(this.context.t('Enter the password to open this PDF file.'));
                this.callbackProxy(callback, password);
                break;
            }
            case 2: {
                const password = prompt(this.context.t('Invalid password. Please try again.'));
                this.callbackProxy(callback, password);
                break;
            }
            default:
        }
    }
    callbackProxy = (callback, password) => {
        // Cancel button handler
        if (password === null) {
            // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
            this.props.closeSideBySidePreview()
        }

        callback(password);
    }

    render() {
        const { cursourWait, showDocData, showLinkedDoc, showMoreDocOptions, showDownloadOptions, pageNumber, pageNumberSecondDoc, numPages, secondDocNumPages, pdfScale, pdfScalePercentage, maxPdfScale, didFitToWidth, pdfRoateAngle, imgScale, imgScalePercentage, imgRoateAngle, didFitImgToWidth, deleteModal, moveToTrash, editMode, acceptancePermissions, isAccountantInCurrentCompany, showNextArrow, showPrevArrow, showDueDateDocCalendar, posUpDueDateDocCalendar, posYDueDateDocCalendar, posXDueDateDocCalendar, showIssueDateDocCalendar, posYIssueDateDocCalendar, posXIssueDateDocCalendar, posUpIssueDateDocCalendar, showSaleDateDocCalendar, posYSaleDateDocCalendar, posXSaleDateDocCalendar, posUpSaleDateDocCalendar, showMoveToOtherProjectPopup } = this.state
        const { pdfPreviewOpen, pdfPreviewData, pdfPreviewDataBody, showSideBySidePreview, loadingScreen, previewClickFromChatNotification } = this.props

        return (
            <>
                {
                    loadingScreen ? (
                        <div className="loading-screen">
                            <div className="activity-indicator">
                                <Spinner size={50} speed={0.8} color={"#ffffff"} />
                            </div>
                        </div>
                    ) : null
                }
                {
                    deleteModal ? (
                        <DeleteInvoiceModal delete={this.deleteInvoice} moveToTrash={moveToTrash} cancel={this.cancelDeleteInvoice} name={this.state.deleteInvoiceName} />
                    ) : null
                }
                {
                    showMoveToOtherProjectPopup ? (
                        <MoveToOtherProjectPopup
                            currentProjectId={pdfPreviewData.projectId}
                            cancel={this.hideMoveToOtherProjectPopup}
                            move={this.moveToOtherProject}
                        />
                    ) : null
                }

                <div className={`pdf-preview-container ${pdfPreviewOpen ? 'show' : ''} ${cursourWait ? 'loading' : ''}`} ref="previevContainer" onClick={e => this.closeAllPopups(e)}>
                    {
                        showSideBySidePreview && showSideBySidePreview.show ? (
                            <div className="side-by-side">
                                <PreviewToolbar
                                    sideBySide={true}
                                    pdfScale={pdfScale}
                                    pdfScalePercentage={pdfScalePercentage}
                                    pageNumber={pageNumber}
                                    numPages={numPages}
                                    pageNumberSecondDoc={pageNumberSecondDoc}
                                    secondDocNumPages={secondDocNumPages}
                                    prevPdfPage={() => this.setState({ pageNumber: pageNumber - 1 })}
                                    nextPdfPage={() => this.setState({ pageNumber: pageNumber + 1 })}
                                    prevPdfPageSecondDoc={() => this.setState({ pageNumberSecondDoc: pageNumberSecondDoc - 1 })}
                                    nextPdfPageSecondDoc={() => this.setState({ pageNumberSecondDoc: pageNumberSecondDoc + 1 })}
                                    zoomOut={this.zoomOut}
                                    zoomIn={this.zoomIn}
                                    closeSideBySidePreview={this.closeSideBySidePreview}
                                />

                                <div className={`file-container first-file`}>
                                    {
                                        pdfPreviewDataBody ? (
                                            pdfPreviewDataBody.includes('application/pdf') ? (
                                                <SimpleBar style={{ width: '100%', maxWidth: 'calc(50vw - 155px)', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                                    <div className="pdf-wrapper" ref="pdfWrapper">
                                                        <Document file={pdfPreviewDataBody} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error}>
                                                            <Page pageNumber={pageNumber} scale={pdfScale} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} />
                                                        </Document>
                                                    </div>
                                                </SimpleBar>
                                            ) : (
                                                <SimpleBar style={{ width: '100%', maxWidth: 'calc(50vw - 155px)', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                                    <div className="image" ref="imageContinerRef">
                                                        <img src={pdfPreviewDataBody} style={{ width: `${100 * imgScale}%`, transform: `rotate(${imgRoateAngle}deg)` }} ref="imageRef" />
                                                    </div>
                                                </SimpleBar>
                                            )
                                        ) : (
                                            <div className="pdf-wrapper" ref="pdfWrapper" >
                                                <div className="activity-indicator">
                                                    <Spinner size={30} speed={0.8} color={"#444444"} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className={`file-container second-file`}>
                                    {
                                        showSideBySidePreview.fileBody ? (
                                            showSideBySidePreview.fileBody.includes('application/pdf') ? (
                                                <SimpleBar style={{ width: '100%', maxWidth: 'calc(50vw - 155px)', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                                    <div className="pdf-wrapper" ref="pdfWrapper">
                                                        <Document
                                                            file={showSideBySidePreview.fileBody}
                                                            onLoadSuccess={this.onSecondDocumentLoadSuccess}
                                                            onLoadError={console.error}
                                                            options={{ withCredentials: true }}
                                                            onPassword={this.onPdfPassword}
                                                        >
                                                            <Page pageNumber={pageNumberSecondDoc} scale={pdfScale} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} />
                                                        </Document>
                                                    </div>
                                                </SimpleBar>
                                            ) : (
                                                <SimpleBar style={{ width: '100%', maxWidth: 'calc(50vw - 155px)', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                                    <div className="image" ref="imageContinerRef">
                                                        <img src={showSideBySidePreview.fileBody} style={{ width: `${100 * imgScale}%`, transform: `rotate(${imgRoateAngle}deg)` }} ref="imageRef" />
                                                    </div>
                                                </SimpleBar>
                                            )
                                        ) : (
                                            <div className="pdf-wrapper" ref="pdfWrapper" >
                                                <div className="activity-indicator">
                                                    <Spinner size={30} speed={0.8} color={"#444444"} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                                <LinkedDocs
                                    showLinkedDoc={showLinkedDoc}
                                    setCursorWait={(value) => { this.setState({ cursourWait: value }) }}
                                    openFile={this.goToFile}
                                    secondPreviewName={showSideBySidePreview ? showSideBySidePreview.fileName : ''}
                                />
                            </div>
                        ) : (
                            pdfPreviewData.name ? (
                                <>
                                    <PreviewToolbar
                                        sideBySide={false}
                                        pageNumber={pageNumber}
                                        numPages={numPages}
                                        pdfScale={pdfScale}
                                        pdfScalePercentage={pdfScalePercentage}
                                        maxPdfScale={maxPdfScale}
                                        didFitToWidth={didFitToWidth}
                                        imgScale={imgScale}
                                        imgScalePercentage={imgScalePercentage}
                                        didFitImgToWidth={didFitImgToWidth}
                                        showDocData={showDocData}
                                        showLinkedDoc={showLinkedDoc}
                                        prevPdfPage={() => this.setState({ pageNumber: pageNumber - 1 })}
                                        nextPdfPage={() => this.setState({ pageNumber: pageNumber + 1 })}
                                        zoomOut={this.zoomOut}
                                        zoomIn={this.zoomIn}
                                        fitToWidth={this.fitToWidth}
                                        fitToHeight={this.fitToHeight}
                                        rotateDoc={this.rotateDoc}
                                        restoreDocument={this.restoreDocument}
                                        downloadDocsInExpensesReport={this.downloadDocsInExpensesReport}
                                        openDeleteModal={this.openDeleteModal}
                                        toggleDocData={this.toggleDocData}
                                        toggleLinkedDocs={this.toggleLinkedDocs}
                                        showMoveToOtherBinderPopup={this.showMoveToOtherBinderPopup}
                                        closePreview={this.closePreview}
                                    />

                                    <div className={`invoice-data ${editMode ? 'edit-mode' : ''} ${showDocData ? '' : 'doc-data-hidden'}`}>
                                        <div className={`body ${pdfPreviewData.docType !== 'EXPENSES' && acceptancePermissions !== 'USER1' ? 'editing' : ''}`}>
                                            {
                                                pdfPreviewData.docType && pdfPreviewData.docType !== 'LOADING' ? (
                                                    <SimpleBar style={{ height: '100%', width: '100%', minWidth: '100%', paddingRight: '12px' }}>
                                                        {
                                                            ['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(pdfPreviewData.docType) ? (
                                                                <CostsFrom
                                                                    isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                                                    additionaAcceptanceClick={this.additionaAcceptanceClick}
                                                                    editMode={editMode}
                                                                    docOwnerCompany={this.state.docOwnerCompany}
                                                                    tagsList={this.state.tagsList}
                                                                    netAccountList={this.state.netAccountList}
                                                                    showIssueDateDocCalendar={this.showIssueDateDocCalendar}
                                                                    showSaleDateDocCalendar={this.showSaleDateDocCalendar}
                                                                    showDueDateDocCalendar={this.showDueDateDocCalendar}
                                                                    goToFile={this.goToFile}
                                                                />
                                                            ) : (
                                                                pdfPreviewData.docType === 'SALE_INVOICE' ? (
                                                                    <IncomesFrom
                                                                        additionaAcceptanceClick={this.additionaAcceptanceClick}
                                                                        editMode={editMode}
                                                                        docOwnerCompany={this.state.docOwnerCompany}
                                                                        tagsList={this.state.tagsList}
                                                                        netAccountList={this.state.netAccountList}
                                                                        showIssueDateDocCalendar={this.showIssueDateDocCalendar}
                                                                        showDueDateDocCalendar={this.showDueDateDocCalendar}
                                                                    />
                                                                ) : (

                                                                    pdfPreviewData.docType === 'EXPENSES' ? (
                                                                        <ExpensesFrom
                                                                            editMode={editMode}
                                                                            tagsList={this.state.tagsList}
                                                                        />
                                                                    ) : (
                                                                        pdfPreviewData.docType === 'AGREEMENT' ? (
                                                                            <AgreementsForm
                                                                                editMode={editMode}
                                                                                tagsList={this.state.tagsList}
                                                                                showIssueDateDocCalendar={this.showIssueDateDocCalendar}
                                                                                downloadAllAssignedDocuments={this.downloadAllAssignedDocuments}
                                                                            />
                                                                        ) : (
                                                                            pdfPreviewData.docType === 'CORRESPONDENCE' ? (
                                                                                <CorrespondenceForm
                                                                                    editMode={editMode}
                                                                                    tagsList={this.state.tagsList}
                                                                                    showIssueDateDocCalendar={this.showIssueDateDocCalendar}
                                                                                />
                                                                            ) : (
                                                                                pdfPreviewData.docType === 'CORPORATE' ? (
                                                                                    <CorporateForm
                                                                                        editMode={editMode}
                                                                                        tagsList={this.state.tagsList}
                                                                                        showIssueDateDocCalendar={this.showIssueDateDocCalendar}
                                                                                    />
                                                                                ) : (
                                                                                    pdfPreviewData.docType === 'OTHER' ? (
                                                                                        <OtherDocsFrom
                                                                                            editMode={editMode}
                                                                                            tagsList={this.state.tagsList}
                                                                                            showIssueDateDocCalendar={this.showIssueDateDocCalendar}
                                                                                        />
                                                                                    ) : (
                                                                                        pdfPreviewData.docType === 'PROTOCOL' ? (
                                                                                            <ProtocolFrom
                                                                                                editMode={editMode}
                                                                                                tagsList={this.state.tagsList}
                                                                                                showIssueDateDocCalendar={this.showIssueDateDocCalendar}
                                                                                            />
                                                                                        ) : (
                                                                                            <div className="no-doc-data">
                                                                                                <div className="image"></div>
                                                                                                <h4>{this.context.t('The document has not been approved yet and therefore does not have verified data to display.')}</h4>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    </SimpleBar>
                                                ) : (
                                                    <div className="activity-indicator">
                                                        <Spinner size={30} speed={0.8} color={"#444444"} />
                                                    </div>
                                                )
                                            }

                                            {
                                                showIssueDateDocCalendar ? (
                                                    <div className={`issue-date-calendar ${showIssueDateDocCalendar ? 'show' : ''} ${posUpIssueDateDocCalendar ? 'up' : ''}`} style={{ top: posYIssueDateDocCalendar, left: posXIssueDateDocCalendar }}>
                                                        <Calendar
                                                            selectDay={this.onCalendarSelect}
                                                            unselectDay={this.onCalendarSelect}
                                                            selectedDay={
                                                                moment(pdfPreviewData.issueDate, 'YYYY-MM-DD').isValid() ? {
                                                                    display: moment(pdfPreviewData.issueDate, 'YYYY-MM-DD').format('LL'),
                                                                    value: moment(pdfPreviewData.issueDate, 'YYYY-MM-DD')
                                                                } : {
                                                                    display: null,
                                                                    value: null
                                                                }
                                                            }
                                                            projectId={'none'}
                                                            startDate={moment(pdfPreviewData.issueDate, 'YYYY-MM-DD').isValid() ? moment(pdfPreviewData.issueDate, 'YYYY-MM-DD') : moment()}
                                                        />
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }

                                            {
                                                showSaleDateDocCalendar ? (
                                                    <div className={`sale-date-calendar ${showSaleDateDocCalendar ? 'show' : ''} ${posUpSaleDateDocCalendar ? 'up' : ''}`} style={{ top: posYSaleDateDocCalendar, left: posXSaleDateDocCalendar }}>
                                                        <Calendar
                                                            selectDay={this.onCalendarSelect}
                                                            unselectDay={this.onCalendarSelect}
                                                            selectedDay={
                                                                moment(pdfPreviewData.saleDate, 'YYYY-MM-DD').isValid() ? {
                                                                    display: moment(pdfPreviewData.saleDate, 'YYYY-MM-DD').format('LL'),
                                                                    value: moment(pdfPreviewData.saleDate, 'YYYY-MM-DD')
                                                                } : {
                                                                    display: null,
                                                                    value: null
                                                                }
                                                            }
                                                            projectId={'none'}
                                                            startDate={moment(pdfPreviewData.saleDate, 'YYYY-MM-DD').isValid() ? moment(pdfPreviewData.saleDate, 'YYYY-MM-DD') : moment()}
                                                        />
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }

                                            {
                                                showDueDateDocCalendar ? (
                                                    <div className={`due-date-calendar ${showDueDateDocCalendar ? 'show' : ''} ${posUpDueDateDocCalendar ? 'up' : ''}`} style={{ top: posYDueDateDocCalendar, left: posXDueDateDocCalendar }}>
                                                        <Calendar
                                                            selectDay={this.onCalendarSelect}
                                                            unselectDay={this.onCalendarSelect}
                                                            selectedDay={
                                                                moment(pdfPreviewData.dueDate, 'YYYY-MM-DD').isValid() ? {
                                                                    display: moment(pdfPreviewData.dueDate, 'YYYY-MM-DD').format('LL'),
                                                                    value: moment(pdfPreviewData.dueDate, 'YYYY-MM-DD')
                                                                } : {
                                                                    display: null,
                                                                    value: null
                                                                }
                                                            }
                                                            projectId={'none'}
                                                            startDate={moment(pdfPreviewData.dueDate, 'YYYY-MM-DD').isValid() ? moment(pdfPreviewData.dueDate, 'YYYY-MM-DD') : moment()}
                                                        />
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }

                                        </div>

                                        <div className={`footer ${pdfPreviewData.docType !== 'EXPENSES' && acceptancePermissions !== 'USER1' ? 'editing' : ''}`}>
                                            {
                                                editMode ? (
                                                    <>
                                                        {
                                                            ['COSTS', 'EXPENSES_REPORT', 'MAG', 'SALE_INVOICE'].includes(pdfPreviewData.docType) && acceptancePermissions !== 'USER1' && !pdfPreviewData.deleted && isAccountantInCurrentCompany ? (
                                                                <div className="accounted-doc">
                                                                    <span className={`accounted-doc__checkbox ${pdfPreviewData.accounted ? 'checked' : ''}`} onClick={this.handleChangeDocAccounted}></span>
                                                                    <p onClick={this.handleChangeDocAccounted} style={{ width: 'fit-content' }}>
                                                                        {
                                                                            pdfPreviewData.accounted ? (
                                                                                this.context.t("This document has been accounted")
                                                                            ) : (
                                                                                this.context.t("Mark this document has been accounted")
                                                                            )
                                                                        }
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        <div className="action-buttons">
                                                            <span className="btn cancel-btn" onClick={e => this.cancelEditMode()}>{this.context.t('Cancel')}</span>
                                                            <span className="btn save-btn" onClick={e => this.saveChanges()}>{this.context.t('Save')}</span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    pdfPreviewData.deleted ? (
                                                        null
                                                    ) : (
                                                        pdfPreviewData.status === 'DONE' || (pdfPreviewData.status === 'IN_PROGRESS') ? (
                                                            <>
                                                                {
                                                                    ['COSTS', 'EXPENSES_REPORT', 'MAG', 'SALE_INVOICE'].includes(pdfPreviewData.docType) && acceptancePermissions !== 'USER1' && isAccountantInCurrentCompany ? (
                                                                        <div className="accounted-doc">
                                                                            <span className={`accounted-doc__checkbox ${pdfPreviewData.accounted ? 'checked' : ''}`} onClick={this.handleChangeDocAccounted}></span>
                                                                            <p onClick={this.handleChangeDocAccounted} style={{ width: 'fit-content' }}>
                                                                                {
                                                                                    pdfPreviewData.accounted ? (
                                                                                        this.context.t("This document has been accounted")
                                                                                    ) : (
                                                                                        this.context.t("Mark this document has been accounted")
                                                                                    )
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                <div className="action-buttons">
                                                                    <span className="btn save-btn" onClick={e => this.startEdit()}>{this.context.t('Edit')}</span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            null
                                                        )
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className={`file-container ${showDocData ? '' : 'doc-data-hidden'} ${showLinkedDoc ? '' : 'linked-docs-hidden'}`}>
                                        <div className={`go-to-next-arrow ${showNextArrow && !previewClickFromChatNotification ? 'show' : ''} ${this.nextFileExists(pdfPreviewData.fileIndex) ? '' : 'hide'} ${showLinkedDoc ? '' : 'linked-docs-hidden'}`} onClick={() => this.getFile(0, 'next')}></div>
                                        <div className={`go-to-prev-arrow ${showPrevArrow && !previewClickFromChatNotification ? 'show' : ''} ${this.prevFileExists(pdfPreviewData.fileIndex) ? '' : 'hide'}`} onClick={() => this.getFile(0, 'prev')}></div>
                                        {
                                            pdfPreviewDataBody ? (
                                                pdfPreviewDataBody.includes('application/pdf') ? (
                                                    <SimpleBar style={{ width: '100%', maxWidth: showDocData && showLinkedDoc ? 'calc(100vw - 600px)' : showDocData || showLinkedDoc ? 'calc(100vw - 300px)' : '100vw', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                                        <div className="pdf-wrapper" ref="pdfWrapper">
                                                            <Document file={pdfPreviewDataBody} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error}>
                                                                <Page pageNumber={pageNumber} scale={pdfScale} rotate={pdfRoateAngle} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} />
                                                            </Document>
                                                        </div>
                                                    </SimpleBar>
                                                ) : (
                                                    <SimpleBar style={{ width: '100%', maxWidth: showDocData && showLinkedDoc ? 'calc(100vw - 600px)' : showDocData || showLinkedDoc ? 'calc(100vw - 300px)' : '100vw', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                                        <div className="image" ref="imageContinerRef">
                                                            <img src={pdfPreviewDataBody} style={{ width: `${100 * imgScale}%`, transform: `rotate(${imgRoateAngle}deg)` }} ref="imageRef" />
                                                        </div>
                                                    </SimpleBar>
                                                )
                                            ) : (
                                                <div className="pdf-wrapper" ref="pdfWrapper" >
                                                    <div className="activity-indicator">
                                                        <Spinner size={30} speed={0.8} color={"#444444"} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <LinkedDocs
                                        showLinkedDoc={showLinkedDoc}
                                        setCursorWait={(value) => { this.setState({ cursourWait: value }) }}
                                        openFile={this.goToFile}
                                        secondPreviewName={showSideBySidePreview ? showSideBySidePreview.fileName : ''}
                                    />
                                </>
                            ) : (
                                null
                            )
                        )
                    }
                </div>
            </>
        )
    }
}

PdfPreview.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,
    userProjects: state.User.userProjects,
    uploadedFiles: state.File.uploadedFiles,
    pdfPreviewData: state.File.pdfPreviewData,
    pdfPreviewOpen: state.File.pdfPreviewOpen,
    backupPdfPreviewData: state.File.backupPdfPreviewData,
    pdfPreviewDataBody: state.File.pdfPreviewDataBody,
    counterpartyLinkedDocs: state.File.counterpartyLinkedDocs,
    showSideBySidePreview: state.File.showSideBySidePreview,
    loadingScreen: state.File.loadingScreen || false,
    previewClickFromChatNotification: state.File.previewClickFromChatNotification,

    downloadedDocs: state.Reports.docsDownloadedForRaport,
})

const mapDispatchToProps = {
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    deleteInvoice: FileActions.deleteInvoice,
    downloadReportFiles: FileActions.downloadReportFiles,
    toogleAccountmentStatusForDoc: ReportsActions.toogleAccountmentStatusForDoc,

    downloadFilesAssignedToAgreement: FileActions.downloadFilesAssignedToAgreement,

    makeEditedDocBackup: FileActions.makeEditedDocBackup,
    cancelDocEdit: FileActions.cancelDocEdit,
    saveDocChanges: FileActions.saveDocChanges,
    editDocInput: FileActions.editDocInput,
    changeAccountmentStatusInPdfBackup: FileActions.changeAccountmentStatusInPdfBackup,
    restoreDocument: FileActions.restoreDocument,
    permanentDeleteInvoice: FileActions.permanentDeleteInvoice,
    confirmDocTypeChange: FileActions.confirmDocTypeChange,
    getCounterpartyDocs: FileActions.getCounterpartyDocs,

    getCompanyWaproNetAccounts: CompanyActions.getCompanyWaproNetAccounts,

    toggleLinkedDocs: FileActions.toggleLinkedDocs,
    closeSideBySidePreview: FileActions.closeSideBySidePreview,

    moveAcceptedFileToOtherProject: FileActions.moveAcceptedFileToOtherProject,

    addMpkSplitInstance: FileActions.addMpkSplitInstance,
    deleteMpkSplitInstance: FileActions.deleteMpkSplitInstance,
    editMpkSplitInstance: FileActions.editMpkSplitInstance,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfPreview)