import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import TextareaAutosize from 'react-textarea-autosize'

import currencyList from '../../helpers/currencyList.json'
import { waProjects } from '../../helpers/MpkSplitWineAvenue/waProjects'

import { FileActions } from '../../actions/FileActions'

import AvailableInOtherPlanLabel from '../AvailableInOtherPlanLabel'
import FileAcceptanceStepsSection from './FileAcceptanceStepsSection'
import TagsComponent from '../OcrPageComponents/TagsComponent';
import TagsList from '../TagsList'
import Calendar from '../Calendar'
import MpkSplitWineAvenue from '../OcrPageComponents/MpkSplitWineAvenue/MpkSplitWineAvenue'

export class ExpensesFrom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTagsList: false,
            showDueDateExpenseCalendar: false,
            dueDateCalendarExpenseId: -1,
            posUpDueDateExpenseCalendar: false,
            posYDueDateExpenseCalendar: 0,
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
            })
        } else {
            if (event.keyCode === 27 || (this.state.showTagsList && !this.hasSomeParentTheClass(event.target, 'tags-container'))) {
                this.setState({
                    showTagsList: false
                })
            }

            if (event.keyCode === 27 || (this.state.showDueDateExpenseCalendar && !this.hasSomeParentTheClass(event.target, 'issue-date-container'))) {
                this.setState({
                    showDueDateExpenseCalendar: false,
                    dueDateCalendarExpenseId: -1,
                    posYDueDateExpenseCalendar: 0,
                    posUpDueDateExpenseCalendar: false
                })
            }
        }
    }

    hasSomeParentTheClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }

        return false
    }

    changeInputValue = (e, field, docType, docId) => {
        e.preventDefault()
        this.props.editDocInput(e.target.value, field, docType, docId)
    }

    setTags = (newTags, index) => {
        this.props.editDocInput(newTags, 'tags', 'EXPENSES', index)
    }

    showTagsList = () => {
        this.setState({
            showTagsList: true
        })
    }

    returnTags = (tags, docId, docType) => {
        this.props.editDocInput(tags, 'tags', docType, docId)
    }

    showCurrencyListForExpenses = i => {
        this.setState({
            showCurrencyList: !this.state.showCurrencyList,
            expenseToShowCurrencyList: i,
        })
    }

    showDueDateExpenseCalendar = (e, id) => {
        console.log('showDueDateExpenseCalendar func call ' + moment().format('HH:mm:ss:SSS'))

        var inputPosition = e.target.getBoundingClientRect()
        var top = inputPosition.bottom
        var flipTop = false

        if (window.innerHeight - top < 300) {
            flipTop = true
            top -= inputPosition.height
        }

        if (this.props.editMode) {
            this.setState({
                posYDueDateExpenseCalendar: top,
                posUpDueDateExpenseCalendar: flipTop,
                showDueDateExpenseCalendar: true,
                dueDateCalendarExpenseId: id,
            })
        }
    }

    onCalendarSelect = date => {
        const { showDueDateExpenseCalendar, dueDateCalendarExpenseId } = this.state
        if (date) {
            if (showDueDateExpenseCalendar) {
                if (this.state.dueDateCalendarExpenseId > -1) {
                    this.props.editDocInput(moment(date).format('DD-MM-YYYY'), 'issue_date', 'EXPENSES', dueDateCalendarExpenseId)
                }
                this.setState({
                    showDueDateExpenseCalendar: false,
                    posYDueDateExpenseCalendar: 0,
                    posUpDueDateExpenseCalendar: false,
                    dueDateCalendarExpenseId: -1,
                })
            }
        }
    }

    selectCurrency = (name, docType, docId) => {
        this.setState({
            showCurrencyList: false
        })
        this.props.editDocInput(name, 'currency', docType, docId)
    }

    setMpkSplitWineAvenue = newData => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(newData, 'mpkSplitWineAvenue', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    render() {
        const { showTagsList, showCurrencyList, showDueDateExpenseCalendar, expenseToShowCurrencyList, dueDateCalendarExpenseId, posYDueDateExpenseCalendar, posUpDueDateExpenseCalendar } = this.state
        const { user, pdfPreviewData, acceptancePermissions, editMode, docOwnerCompany, tagsList, isAccountantInCurrentCompany } = this.props
        return (
            <div onClick={e => this.handleClick(e)} ref={this.wrapperRef}>
                <FileAcceptanceStepsSection pdfPreviewData={pdfPreviewData} />
                {
                    pdfPreviewData.priority &&
                    <div className="doc-prop">
                        <span className="name">{this.context.t('Priority')}:</span>
                        <span className="uneditable-prop">{this.context.t(pdfPreviewData.priority === 'high' ? 'Important' : 'Normal')}</span>
                    </div>
                }

                <div className="expense-spacer"></div>
                {
                    pdfPreviewData.docs.map((doc, i) => {
                        return (
                            <>
                                <div className="doc-prop" key={`docNumberInput-${doc.id}-${i}`}>
                                    <span className="name">{this.context.t('Document number:')}</span>
                                    <TextareaAutosize
                                        className={`${!editMode ? 'read-only' : ''}`}
                                        //style={{padding: editMode ? '10px' : '0px'}}
                                        readOnly={!editMode}
                                        name="invoiceNumber"
                                        id={`docNumberInput-${doc.id}-${i}`}
                                        value={doc.doc_number}
                                        onChange={(e) => this.changeInputValue(e, 'doc_number', 'EXPENSES', i)}
                                    />
                                </div>
                                <div className="doc-prop issue-date-container" key={`issueDateIntpu-${doc.id}-${i}`}>
                                    <span className="name">{this.context.t('Issue date:')}</span>
                                    <TextareaAutosize
                                        className={`${!editMode ? 'read-only' : ''}`}
                                        //style={{padding: editMode ? '10px' : '0px'}}
                                        readOnly={true}
                                        name="invoiceNumber"
                                        id={`issueDateIntpu-${doc.id}-${i}`}
                                        value={doc.issue_date}
                                        onChange={(e) => this.changeInputValue(e, 'issue_date', 'EXPENSES', i)}
                                        onFocus={e => this.showDueDateExpenseCalendar(e, i)}
                                    />
                                    <div className={`issue-date-calendar ${showDueDateExpenseCalendar && dueDateCalendarExpenseId === i ? 'show' : ''} ${posUpDueDateExpenseCalendar ? 'up' : ''} ${showDueDateExpenseCalendar && dueDateCalendarExpenseId === i ? 'higher-z-index' : ''}`} style={{ top: posYDueDateExpenseCalendar }}>
                                        <Calendar
                                            selectDay={this.onCalendarSelect}
                                            unselectDay={this.onCalendarSelect}
                                            selectedDay={
                                                moment(doc.issue_date, 'DD-MM-YYYY').isValid() ? {
                                                    display: moment(doc.issue_date, 'DD-MM-YYYY').format('LL'),
                                                    value: moment(doc.issue_date, 'DD-MM-YYYY')
                                                } : {
                                                    display: null,
                                                    value: null
                                                }
                                            }
                                            projectId={'none'}
                                            startDate={moment(doc.issue_date, 'DD-MM-YYYY').isValid() ? moment(doc.issue_date, 'DD-MM-YYYY') : moment()}
                                        />
                                    </div>
                                </div>
                                <div className="doc-prop" key={`priceInput-${doc.id}-${i}`}>
                                    <span className="name">{this.context.t('Amount to pay:')}</span>
                                    <TextareaAutosize
                                        className={`${!editMode ? 'read-only' : ''}`}
                                        //style={{padding: editMode ? '10px' : '0px'}}
                                        readOnly={!editMode}
                                        name="invoiceNumber"
                                        id={`priceInput-${doc.id}-${i}`}
                                        value={!editMode ? doc.price + " " + doc.currency : doc.price}
                                        onChange={(e) => this.changeInputValue(e, 'price', 'EXPENSES', i)}
                                    />
                                    <span className={`currency-selector ${!editMode ? 'hide' : ''} ${showCurrencyList && expenseToShowCurrencyList === i ? 'list-expended' : ''}`} onClick={() => this.showCurrencyListForExpenses(i)}>
                                        <span className='current-currency'>{doc.currency}</span>
                                        <span className={`arrow ${showCurrencyList && expenseToShowCurrencyList === i ? 'expanded' : ''}`}></span>
                                        <ul className={`currency-list ${showCurrencyList && expenseToShowCurrencyList === i ? 'expanded' : 'hide'}`}>
                                            {
                                                currencyList.map(c => {
                                                    return (
                                                        <li className={`${doc.currency === c ? 'selected' : ''}`} onClick={() => this.selectCurrency(c, 'EXPENSES', i)}>{c}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </span>
                                </div>
                                <div className="doc-prop" key={`commentInput-${doc.id}-${i}`}>
                                    <span className="name">{this.context.t('Comment:')}</span>
                                    <TextareaAutosize
                                        className={`${!editMode ? 'read-only' : ''}`}
                                        readOnly={!editMode}
                                        name="invoiceNumber"
                                        id={`commentInput-${doc.id}-${i}`}
                                        value={doc.comment}
                                        onChange={(e) => this.changeInputValue(e, 'comment', 'EXPENSES', i)}
                                    />
                                </div>
                                <div className="doc-prop" key={`doc_descriptionInput-${doc.id}-${i}`}>
                                    <span className="name">{this.context.t('Document description')}:</span>
                                    <TextareaAutosize
                                        className={`${!editMode ? 'read-only' : ''}`}
                                        readOnly={!editMode}
                                        name="doc_description"
                                        id={`doc_descriptionInput-${doc.id}-${i}`}
                                        value={doc.doc_description}
                                        onChange={(e) => this.changeInputValue(e, 'doc_description', 'EXPENSES', i)}
                                    />
                                </div>


                                <TagsComponent
                                    currentTags={doc.tags}
                                    tagsList={tagsList || []}
                                    canAddNewTags={true}
                                    setTags={this.setTags}
                                    customPlaceholder={editMode ? this.context.t('Add tags') : ' '}
                                    customMinWidth={'100px'}
                                    readOnly={!editMode}
                                />

                                {
                                    waProjects.includes(pdfPreviewData.projectId) && pdfPreviewData.mpkSplitWineAvenue?.length ? (
                                        <MpkSplitWineAvenue
                                            customTitle={'Division of payment into MPK'}
                                            fileId={pdfPreviewData?.id}
                                            ftvId={0}
                                            totalNetAmount={doc.price}
                                            mpkSplitWineAvenue={pdfPreviewData.mpkSplitWineAvenue}
                                            setMpkSplitWineAvenue={this.setMpkSplitWineAvenue}
                                            isInDocPreview={true}
                                            readOnly={!editMode}
                                            isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                            companyId={docOwnerCompany?.id || 0}
                                            currency={'PLN'}
                                            paid={false}
                                        />
                                    ) : null
                                }
                                {
                                    pdfPreviewData.docs[i + 1] ? (
                                        <div className="expense-spacer" key={`spacer-${i}`}></div>
                                    ) : (
                                        null
                                    )
                                }
                            </>
                        )
                    })
                }
            </div>
        )
    }
}

ExpensesFrom.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    pdfPreviewData: state.File.pdfPreviewData,
    user: state.User.user,
})

const mapDispatchToProps = {
    editDocInput: FileActions.editDocInput,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesFrom)