import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { Document, Page, pdfjs } from 'react-pdf';
import { Spinner, Bounce } from 'react-activity'
import TextareaAutosize from 'react-textarea-autosize'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import throttle from "lodash.throttle"
import ReactTooltip from 'react-tooltip'

import Calendar from './Calendar'
import TagsList from './TagsList'
import CountriesList from './CountriesList'
import AvailableInOtherPlanLabel from './AvailableInOtherPlanLabel'
import moment from 'moment'

import '../css/FilePreview.css'

import TagsComponent from './OcrPageComponents/TagsComponent';
import ProjectCode from './PdfPreviewComponents/WL_DevCosts/ProjectCode'
import Technology from './PdfPreviewComponents/WL_DevCosts/Technology'
import DevExCode from './PdfPreviewComponents/WL_DevCosts/DevExCode'
import DevExType from './PdfPreviewComponents/WL_DevCosts/DevExType'
import TransactionDescription from './PdfPreviewComponents/WL_DevCosts/TransactionDescription'
import { devCostsProjects } from '../helpers/DevCosts/devCostsProjects'
import { devCostsUsers } from '../helpers/DevCosts/devCostsUsers'

import { TabsControlerActions } from '../actions/V2_TabsControlerActions'
import { AlertActions } from '../actions/AlertActions'
import { TransfersActions } from '../actions/TransfersActions';
import { checkIfAccountNumberIsValid } from './../helpers/validAccountNumber';

import currencyList from '../helpers/currencyList.json'
import { times } from 'lodash';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, application/pdf'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

const accountNumberRegexList = [
    /^(PL)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}$/,
    /^(FR)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{2}[0-9A-Z]{2}\s?[0-9A-Z]{4}\s?[0-9A-Z]{4}\s?[0-9A-Z]{1}[0-9]{2}$/,
    /^(DE)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{2}$/,
    /^(CZ)?\s?[0-9]{2}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}$/,
    /^(GB)?\s?[0-9]{2}\s?[A-Z]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{2}$/
]

const priceRegexList = [
    /^[0-9]*$/,
    /^[0-9]*\.[0-9]{1,2}$/,
    /^[0-9]*\,[0-9]{1,2}$/,
]

const paymentSymbols = [
    'AKC', 'AKC-2', ' AKC-3', 'AKC-U', 'AKC2', 'AKC3', 'AKCU', 'CIT', 'CIT-10', 'CIT-11', 'CIT-2', 'CIT-2A', 'CIT-2B', 'CIT6', 'CIT-6A', 'CIT-8', 'CIT-8A', 'CIT-8B', 'CIT-9', 'CIT10', 'CIT11', 'CIT2', 'CIT2A', 'CIT2B', 'CIT6', 'CIT6A', 'CIT8', 'CIT8A', 'CIT8B', 'CIT9', 'DAR', 'DJB', 'GK', 'GKP', 'JDB', 'KP', 'NBP', 'OPR', 'PCC', 'PCC-1', 'PCC-2', 'PCC1', 'PCC2', 'PIT', 'PIT-23', 'PIT-28', 'PIT-36', 'PIT-37', 'PIT-4', 'PIT-5', 'PIT-53', 'PIT-5L', 'PIT-7', 'PIT-8AR', 'PIT23', 'PIT28', 'PIT36', 'PIT37', 'PIT4', 'PIT5', 'PIT53', 'PIT5L', 'PIT7', 'PIT8AR', 'POG-3', 'POG-3A', 'POG-3C', 'POG-3D', 'POG-R', 'POG3', 'POG3A', 'POG3C', 'POG3D', 'POGR', 'PPD', 'PPE', 'PPL', 'PPW', 'SD', ' SD-2', 'SD2', 'VAI', 'VAT', 'VAT-10', 'VAT-12', 'VAT-7', 'VAT-7K', 'VAT-8', 'VAT-9', 'VAT10', 'VAT12', 'VAT7', 'VAT7K', 'VAT8', 'VAT9', 'VAZ', 'WZD', 'WZP', 'WZP-1K', 'WZP-1M', 'WZP-1R', 'WZP1K', 'WZP1M', 'WZP1R', 'WZS', 'WZS-1K', 'WZS-1M', 'WZS1R', 'WZS1K', 'WZS1M', 'WZS1R', 'ZPZ'
]

const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

export class CreateTransferOrderWindow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            counterpartiesList: [],
            showCounterpartiesList: false,
            counterpartyId: 0,
            showDocData: true,
            title: '',
            recipient: '',
            accountNumber: '',
            price: '',
            currency: 'PLN',
            dueDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            showCalendar: false,
            posYDatePicker: 0,
            posXDatePicker: 0,
            posUpDatePicker: false,
            comment: '',
            tags: '',
            paymentType: 'NORMAL',
            file: null,
            numPages: null,
            pageNumber: 1,
            pdfScale: 1.5,
            pdfScalePercentage: 100,
            didFitToWidth: false,
            pdfRoateAngle: 0,
            imgScale: 0.6,
            imgScalePercentage: 100,
            didFitImgToWidth: false,
            imgRoateAngle: 0,
            removeFileTop: 0,
            removeFileLeft: 0,
            numPages: null,
            pageNumber: 1,
            pdfWidth: 0,
            pdfScale: 1.0,
            showCurrencyList: false,
            priority: false,
            paid: false,
            paymentConfirmation: '',

            year: moment().format('YYYY'),
            periodType: '',
            periodNumber: '',
            symbol: '',

            zusAccount: '',
            taxAccount: '',
            tagsList: [],

            swift: '',
            address: '',
            countryCode: 'PL',

            wlProjectCode: '',
            wlTechnology: '',
            wlDevExType1: '',
            wlDevExType2: '',
            wlTransactionDescriptionPl: '',
            wlTransactionDescriptionEn: '',
        }

        this.currencyInputRef = React.createRef()
        this.recipientInputRef = React.createRef()
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        const { transferDataToDuplicate, userProjects, currentProject, userCompanies, currentCompany, transferOrderProject } = this.props

        this.setState({
            counterpartiesList: currentCompany?.company?.counterparties || [],

        })

        if (transferDataToDuplicate) {
            console.log(transferDataToDuplicate)
            this.setState({
                title: transferDataToDuplicate.title || '',
                recipient: transferDataToDuplicate.recipient || '',
                accountNumber: transferDataToDuplicate.accountNumber || '',
                price: transferDataToDuplicate.price || '',
                currency: transferDataToDuplicate.currency || 'PLN',
                dueDate: transferDataToDuplicate.dueDate || moment().add(1, 'days').format('YYYY-MM-DD'),
                comment: transferDataToDuplicate.comment || '',
                paymentType: transferDataToDuplicate.paymentType || '',
                year: transferDataToDuplicate.year || moment().format('YYYY'),
                periodType: transferDataToDuplicate.periodType || '',
                periodNumber: transferDataToDuplicate.periodNumber || '',
                periodName: transferDataToDuplicate.periodName || '',
                symbol: transferDataToDuplicate.symbol || '',
                file: transferDataToDuplicate.file,
                tags: transferDataToDuplicate.tags || '',
                swift: transferDataToDuplicate.swift || '',
                address: transferDataToDuplicate.address || '',
                countryCode: transferDataToDuplicate.countryCode || '',
                priority: transferDataToDuplicate.priority,
                paymentConfirmation: transferDataToDuplicate.email_to_send_payment_confirmation || '',

                wlProjectCode: transferDataToDuplicate.wl_project_code || '',
                wlTechnology: transferDataToDuplicate.wl_technology || '',
                wlDevExType1: transferDataToDuplicate.wl_devex_type_1 || '',
                wlDevExType2: transferDataToDuplicate.wl_devex_type_2 || '',
                wlTransactionDescriptionPl: transferDataToDuplicate.wl_transaction_description_pl || '',
                wlTransactionDescriptionEn: transferDataToDuplicate.wl_transaction_description_en || '',

            })
        }


        this.setState({
            paymentOrderOwnerCompany: transferOrderProject?.company || currentProject?.company || {},
            zusAccount: transferOrderProject?.company?.zus_account || currentProject?.company?.zus_account || '',
            taxAccount: transferOrderProject?.company?.tax_account || currentProject?.company?.tax_account || '',
            tagsList: transferOrderProject?.company?.tags_list || currentProject?.company?.tags_list ? JSON.parse(transferOrderProject?.company?.tags_list || currentProject?.company?.tags_list) : [],
        })

    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
    }

    foundAtLeastOneSimilarCounterparty = (text) => {
        if (!text) return false;
        const { counterpartiesList } = this.state;
        var counterpartiesCount = counterpartiesList.length;

        for (var i = 0; i < counterpartiesCount; i++) {
            if (
                text.length === 1 &&
                counterpartiesList[i].name &&
                counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())
            ) {
                return true;
            } else if (
                text.length > 1 &&
                counterpartiesList[i].name &&
                counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())
            ) {
                return true;
            }
        }

        return false;
    };

    checkIfCounterpartyNameIsSimilar = (text, name) => {
        if (!text) {
            return false;
        }

        if (
            text.length === 1 &&
            name &&
            name.toLowerCase().startsWith(text.toLowerCase())
        ) {
            return true;
        } else if (
            text.length > 1 &&
            name &&
            name.toLowerCase().includes(text.toLowerCase())
        ) {
            return true;
        }

        return false;
    };

    escFunction = (e) => {
        if (e.keyCode === 27) {
            const { showCurrencyList, showCounterpartiesList, showCalendar, showTagsList, showTaxSymbolsList, showPeriosPopup } = this.state
            if (showCurrencyList || showCounterpartiesList || showCalendar || showTagsList || showTaxSymbolsList || showPeriosPopup) {
                this.closeAllPopups(e)
            } else {
                this.closePaymentOrderGenerator()
            }
        }
    }

    closePaymentOrderGenerator = () => {
        const { user } = this.props
        if (user.new_easydocs_version) {
            const { currentTabIndex } = this.props
            this.props.closeTab(currentTabIndex)
        } else {
            this.props.hideAddTransferWindow()
        }
    }

    polihAccountPritier = account => {
        if (account) {
            var formatedAccount = account.replace(/[^0-9]/gm, '')

            if (formatedAccount.length === 26) {
                formatedAccount = formatedAccount.slice(0, 2) + ' ' + formatedAccount.slice(2, 6) + ' ' + formatedAccount.slice(6, 10) + ' ' + formatedAccount.slice(10, 14) + ' ' + formatedAccount.slice(14, 18) + ' ' + formatedAccount.slice(18, 22) + ' ' + formatedAccount.slice(22, 26)
                if (account.startsWith('PL')) {
                    return 'PL' + formatedAccount
                } else {
                    return formatedAccount
                }
            } else {
                return account
            }
        } else return ''
    }

    verifyFile = (files) => {
        if (files && files.length > 1) {
            this.props.alertWarn(this.context.t("You can attach only one file"))
            return false
        } else if (files && files.length === 1) {
            files.forEach(currentFile => {
                const currentFileType = currentFile.type
                const currentFileSize = currentFile.size
                if (currentFileSize > imageMaxSize) {
                    this.props.alertWarn("Document is to big. Maximum size is 1GB")
                    return false
                }
                if (!acceptedFileTypesArray.includes(currentFileType)) {
                    this.props.alertWarn("This file is not allowed. Only images and pdf files are allowed.")
                    return false
                }
            })
        }
        return true
    }

    handleOnDrop = (files, rejectedFiles) => {
        console.log('drop')
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        else {
            files.forEach((currentFile, index) => {
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = {}
                    myResult.base64 = myFileItemReader.result
                    myResult.name = currentFile.name
                    myResult.size = currentFile.size
                    myResult.type = currentFile.type
                    this.setState({
                        file: myResult
                    })
                    setTimeout(() => {
                        this.setDivSize()
                    }, 500)
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            })
        }
    }

    onNoFileAttachedClick = e => {
        const { noFileAttached } = this.refs
        if (noFileAttached && noFileAttached) {
            noFileAttached.click();
        }
    }

    handleNewFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            console.log(files)
            const file = files[0];
            const myFileItemReader = new FileReader()
            myFileItemReader.addEventListener("load", () => {
                const myResult = {}
                myResult.base64 = myFileItemReader.result
                myResult.name = file.name
                myResult.type = file.type

                this.setState({
                    file: myResult
                })
                setTimeout(() => {
                    this.setDivSize()
                }, 500)
            }, false)
            myFileItemReader.readAsDataURL(file)
        }
    }

    showCurrencyList = () => {
        this.setState({
            showCurrencyList: !this.state.showCurrencyList
        })
    }

    showPaymentTypesList = () => {
        this.setState({
            showPaymentTypesList: !this.state.showPaymentTypesList
        })
    }

    onInputChange = e => {
        e.preventDefault()
        const selectFirstTwoLetters = /^[A-Z]{2}/
        const selectOnlyDigits = /^[0-9\s]*$/
        var { name, value } = e.target
        if (name === 'price') value = value.trim()
        if (name === 'price') {
            value = value.replace(/[^0-9.,]/gm, '').replace(',', '.')
        }
        this.setState({ [name]: value })
    }

    selectCurrency = currency => {
        const selectLetters = /\D*/
        if (currency === 'PLN') this.setState({ accountNumber: this.state.accountNumber.replace(selectLetters, '') })
        if (currency !== 'PLN') this.setState({ accountNumber: this.state.countryCode + this.state.accountNumber.replace(selectLetters, '') })
        this.setState({
            currency: currency,
            showCurrencyList: false,
        })
    }

    showDueDateCalendar = e => {
        var inputPosition = e.target.getBoundingClientRect()
        var top = inputPosition.bottom - inputPosition.height
        var left = inputPosition.left

        if (!e.target.className.includes('day-cell')) {
            this.setState({
                posYDatePicker: top,
                posXDatePicker: left,
                posUpDatePicker: true,
                showCalendar: true,
            })
        }
    }

    onCalendarSelect = date => {
        if (date) {
            this.setState({
                dueDate: date.format('YYYY-MM-DD'),
                showCalendar: false,
            })
        }
    }

    onCalendarUnselecet = (date) => {
        this.setState({
            showCalendar: false,
        })
        console.log(date)
    }

    validatePrice = price => {
        var didMatch = false
        priceRegexList.forEach(regex => {
            if (regex.test(price)) {
                didMatch = true
            }
        })

        return didMatch
    }

    checkIsExistsRecipient = (e) => {
        if (this.state.counterpartyId === 0) {
            let result = this.state.counterpartiesList.find((el) => el.name.trim().toLowerCase() === this.state.recipient.trim().toLowerCase())
            e.persist();
            if (result) {
                this.setState({
                    counterpartyId: result.id
                },
                    () => { this.submitTransfer(e) }
                );
                return
            } else {
                this.submitTransfer(e)
                return
            }

        }
        this.submitTransfer(e)
    }

    submitTransfer = e => {
        e.preventDefault()
        const {
            priority, paymentConfirmation, paid, title, recipient, accountNumber, price, currency, dueDate, comment, tags,
            file,
            paymentType, year, periodType, periodNumber, symbol,
            address, countryCode, swift,
            counterpartyId,
            wlProjectCode, wlTechnology, wlDevExType1, wlDevExType2, wlTransactionDescriptionEn, wlTransactionDescriptionPl
        } = this.state
        const { transferOrderProject, currentProject, currentCompany } = this.props
        var valid = true

        if (paymentType.length === 0) {
            this.props.alertWarn("Payment type is required")
            valid = false
        }

        if (title.length === 0) {
            this.props.alertWarn("Payment title is required")
            valid = false
        }

        if (recipient.length === 0) {
            this.props.alertWarn("Recipient is required")
            valid = false
        }

        if (accountNumber.length === 0) {
            this.props.alertWarn("Account number is required")
            valid = false
        }

        if (currency !== 'PLN' && swift === '' && paymentType === 'NORMAL') {
            this.props.alertWarn("BIC/SWIFT number is required")
            valid = false
        }

        if (currency !== 'PLN' && countryCode === '' && paymentType === 'NORMAL') {
            this.props.alertWarn("Country code is required")
            valid = false
        }

        if (price.length === 0) {
            this.props.alertWarn("Amount to pay is required")
            valid = false
        } else if (!this.validatePrice(price)) {
            this.props.alertWarn("Amount to pay is not valid")
            valid = false
        }

        if (dueDate.length === 0) {
            this.props.alertWarn("Due date is required")
            valid = false
        }

        if (paymentType === 'TAX') {
            if (year.length < 4) {
                this.props.alertWarn("Yeare is required")
                valid = false
            }

            if (!periodType.length || (periodType !== 'year' && !periodNumber.length)) {
                this.props.alertWarn("Period is required")
                valid = false
            }

            if (symbol.length === 0) {
                this.props.alertWarn("Tax Symbol is required")
                valid = false
            } else if (!paymentSymbols.includes(symbol)) {
                this.props.alertWarn("Tax Symbol has to be selected from the list")
                valid = false
            }
        }

        if (valid) {
            var paymentOrderData = {
                title: title.replace(/\n/gm, ' '),
                recipient: recipient.replace(/\n/gm, ' '),
                account_number: accountNumber,
                price: price.replace(',', '.'),
                currency: currency,
                comment: comment,
                due_date: dueDate,
                file: file,
                projectId: transferOrderProject?.id || currentProject?.id,
                paymentType: paymentType,
                year: year,
                periodType: periodType,
                periodNumber: periodNumber,
                taxSymbol: symbol,
                tags: tags,
                priority: priority,
                paid: paid,
                paymentConfirmation: paymentConfirmation,
                address: address,
                countryCode: countryCode,
                swift: swift,
                counterpartyId: counterpartyId,
                currentCompanyId: transferOrderProject?.company_id || currentProject?.company_id,
                wlProjectCode: wlProjectCode,
                wlTechnology: wlTechnology,
                wlDevExType1: wlDevExType1,
                wlDevExType2: wlDevExType2,
                wlTransactionDescriptionEn: wlTransactionDescriptionEn,
                wlTransactionDescriptionPl: wlTransactionDescriptionPl
            }
            this.props.createTransfer(paymentOrderData)
        }
    }

    removeFile = () => {
        this.setState({
            file: null
        })
    }

    handleImageLoad = () => {
        if (this.refs.imageRef) {
            var imageContainer = this.refs.imageContinerRef.getBoundingClientRect()
            var image = this.refs.imageRef.getBoundingClientRect()
            console.log(imageContainer)
            console.log(image)

            var topOffset = (imageContainer.height - image.height) / 2
            var leftOffset = imageContainer.width / 2 + image.width / 2

            console.log(topOffset, leftOffset)

            this.setState({
                removeFileTop: topOffset,
                removeFileLeft: leftOffset
            })
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        var newPdfWidth = 0
        if (this.refs.pdfWrapperRef) {
            newPdfWidth = this.refs.pdfWrapperRef.getBoundingClientRect().width
        }
        this.setState({
            numPages: numPages,
            pdfWidth: newPdfWidth
        });
    }

    onPageLoadSuccess = obj => {
        var originalPdfWidth = obj.originalWidth
        var originalPdfHeight = obj.originalHeight
        this.setState({
            originalPdfWidth: originalPdfWidth,
            originalPdfHeight: originalPdfHeight
        })
        if (this.refs.pdfWrapper) {
            var maxPdfScale = this.refs.pdfWrapper.clientWidth / originalPdfWidth
            var maxHeigth = window.innerHeight - 68
            var fitToHeightPdfScale = maxHeigth / originalPdfHeight

            console.log(fitToHeightPdfScale)
            this.setState({
                maxPdfScale: maxPdfScale,
                fitToHeightPdfScale: fitToHeightPdfScale
            })
        }
    }

    closeAllPopups = e => {
        const { showCurrencyList, showCounterpartiesList } = this.state
        if (showCurrencyList && (e.keyCode === 27 || (this.currencyInputRef && this.currencyInputRef.current && !this.currencyInputRef.current.contains(e.target)))) {
            this.setState({
                showCurrencyList: false
            })
        }

        if (showCounterpartiesList && (e.keyCode === 27 || (this.recipientInputRef && this.recipientInputRef.current && !this.recipientInputRef.current.contains(e.target)))) {
            this.setState({
                showCounterpartiesList: false
            })
        }

        if (e.keyCode === 27 || !this.hasSomeParentTheClass(e.target, 'due-date-container')) {
            this.setState({
                showCalendar: false
            })
        }

        if (e.keyCode === 27 || !this.hasSomeParentTheClass(e.target, 'tags-container')) {
            this.setState({
                showTagsList: false
            })
        }

        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'tax-symbol-container') && this.state.showTaxSymbolsList)) {
            this.setState({
                showTaxSymbolsList: false
            })
        }

        if (e.keyCode === 27 || (!(this.hasSomeParentTheClass(e.target, 'tax-period-container') || this.hasSomeParentTheClass(e.target, 'period-popup')) && this.state.showPeriosPopup)) {
            this.setState({
                showPeriosPopup: false
            })
        }

        if (e.keyCode === 27 || (!this.hasSomeParentTheClass(e.target, 'payment-type-container') && this.state.showPaymentTypesList)) {
            this.setState({
                showPaymentTypesList: false
            })
        }

        if (e.keyCode === 27 || (this.state.showCountriesList && !this.hasSomeParentTheClass(e.target, 'countries-list-container'))) {
            this.toggleCountriesList(false)
        }
    }

    hasSomeParentTheClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }
        return false
    }

    handleDropZoneClick = e => {
        if (e.target.className && e.target.className.includes('delete')) {
            e.stopPropagation()
        }
    }

    setPaymentType = type => {
        this.setState({
            paymentType: type
        })

        if (type === 'TAX') {
            this.setState({
                recipient: 'Urząd Skarbowy',
                title: '',
                accountNumber: this.state.taxAccount
            })
        } else if (type === 'ZUS') {
            this.setState({
                recipient: 'ZUS',
                title: 'Składka ZUS',
                dueDate: moment().set('date', 9).format('YYYY-MM-DD'),
                accountNumber: this.state.zusAccount
            })
        }
    }

    showTaxSymbolsList = () => {
        if (!this.state.showTaxSymbolsList) {
            this.setState({
                showTaxSymbolsList: true
            })
        }
    }

    setTaxSymbol = (symbol) => {
        var dueDateText = this.state.dueDate

        if (symbol.includes('VAT')) {
            dueDateText = moment().set('date', 24).format('YYYY-MM-DD')
        } else if (symbol.includes('PIT') || symbol.includes('CIT')) {
            dueDateText = moment().set('date', 19).format('YYYY-MM-DD')
        }

        this.setState({
            symbol: symbol,
            showTaxSymbolsList: false,
            title: symbol,
            dueDate: dueDateText
        })
    }

    showPeriosPopup = (e) => {
        if (!this.state.showPeriosPopup) {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom
            var left = inputPosition.left

            this.setState({
                showPeriosPopup: true,
                periodPopupY: top,
                periodPopupX: left
            })
        }
    }

    makeDoubleDigits = number => {
        if (number < 10) return `0${number}`
        return `${number}`
    }

    showTagsList = () => {
        this.setState({
            showTagsList: true
        })
    }

    returnTags = tags => {
        this.setState({
            tags: tags
        })
    }

    handleChangePriority = () => {
        this.setState(prevState => ({
            priority: !prevState.priority,
            dueDate: moment().format('YYYY-MM-DD')
        }))
    }

    handlePaymentConfirmationChange = () => {
        const { user } = this.props
        var newPaymentConfirmationEmail = this.state.paymentConfirmation || ''
        if (newPaymentConfirmationEmail.includes(user.email)) newPaymentConfirmationEmail = newPaymentConfirmationEmail.replace(user.email, '').replace(',,', ',')
        else newPaymentConfirmationEmail += `,${user.email}`

        newPaymentConfirmationEmail = newPaymentConfirmationEmail.replace(',,', ',').replace(/^,/, '').replace(/,$/, '')

        this.setState({
            paymentConfirmation: newPaymentConfirmationEmail
        })
    }

    changeStatusPayment = () => {
        this.setState({
            paid: !this.state.paid
        })
    }

    toggleDocData = () => {
        this.setState({
            showDocData: !this.state.showDocData
        })

        setTimeout(() => {
            this.setDivSize()
        }, 500)
    }

    setDivSize = () => {
        if (this.refs.pdfWrapper) {
            var newMaxPdfScale = this.refs.pdfWrapper.clientWidth / this.state.originalPdfWidth
            var maxHeigth = window.innerHeight - 68
            var fitToHeightPdfScale = maxHeigth / this.state.originalPdfHeight

            this.setState({
                maxPdfScale: newMaxPdfScale,
                fitToHeightPdfScale: fitToHeightPdfScale
            })

            const { pdfScale } = this.state
            if (pdfScale >= newMaxPdfScale) {
                var newPdfScale = newMaxPdfScale
                var newPdfScalePercentage = parseInt(newMaxPdfScale / 1.5 * 100)

                this.setState({
                    pdfScale: newPdfScale,
                    pdfScalePercentage: newPdfScalePercentage
                })
            }
        }
    }

    zoomIn = (type) => {
        if (type === 'pdf') {
            const { pdfScalePercentage, maxPdfScale } = this.state
            var newPdfScalePercentage, newPdfScale
            if (pdfScalePercentage >= 25 && pdfScalePercentage < 33) {
                newPdfScalePercentage = 33
                newPdfScale = 1.5 * 0.33
            } else if (pdfScalePercentage >= 33 && pdfScalePercentage < 50) {
                newPdfScalePercentage = 50
                newPdfScale = 1.5 * 0.5
            } else if (pdfScalePercentage >= 50 && pdfScalePercentage < 67) {
                newPdfScalePercentage = 67
                newPdfScale = 1.5 * 0.67
            } else if (pdfScalePercentage >= 67 && pdfScalePercentage < 75) {
                newPdfScalePercentage = 75
                newPdfScale = 1.5 * 0.75
            } else if (pdfScalePercentage >= 75 && pdfScalePercentage < 80) {
                newPdfScalePercentage = 80
                newPdfScale = 1.5 * 0.8
            } else if (pdfScalePercentage >= 80 && pdfScalePercentage < 90) {
                newPdfScalePercentage = 90
                newPdfScale = 1.5 * 0.9
            } else if (pdfScalePercentage >= 90 && pdfScalePercentage < 100) {
                newPdfScalePercentage = 100
                newPdfScale = 1.5
            } else if (pdfScalePercentage >= 100 && pdfScalePercentage < 110) {
                newPdfScalePercentage = 110
                newPdfScale = 1.5 * 1.1
            } else if (pdfScalePercentage >= 110 && pdfScalePercentage < 125) {
                newPdfScalePercentage = 125
                newPdfScale = 1.5 * 1.25
            } else if (pdfScalePercentage >= 125 && pdfScalePercentage < 150) {
                newPdfScalePercentage = 150
                newPdfScale = 1.5 * 1.5
            } else if (pdfScalePercentage >= 150 && pdfScalePercentage < 175) {
                newPdfScalePercentage = 175
                newPdfScale = 1.5 * 1.75
            } else if (pdfScalePercentage >= 175 && pdfScalePercentage < 200) {
                newPdfScalePercentage = 200
                newPdfScale = 1.5 * 2
            } else {
                newPdfScalePercentage = pdfScalePercentage + 50
                newPdfScale = 1.5 * (pdfScalePercentage + 50) / 100
            }

            if (newPdfScale >= maxPdfScale) {
                newPdfScale = maxPdfScale
                newPdfScalePercentage = parseInt(maxPdfScale / 1.5 * 100)
            }

            this.setState({
                pdfScale: newPdfScale,
                pdfScalePercentage: newPdfScalePercentage
            })

        } else {
            const { imgScale, imgScalePercentage } = this.state
            var newImgScalePercentage, newImgScale
            if (imgScalePercentage >= 25 && imgScalePercentage < 33) {
                newImgScalePercentage = 33
                newImgScale = 0.6 * 0.33
            } else if (imgScalePercentage >= 33 && imgScalePercentage < 50) {
                newImgScalePercentage = 50
                newImgScale = 0.6 * 0.5
            } else if (imgScalePercentage >= 50 && imgScalePercentage < 67) {
                newImgScalePercentage = 67
                newImgScale = 0.6 * 0.67
            } else if (imgScalePercentage >= 67 && imgScalePercentage < 75) {
                newImgScalePercentage = 75
                newImgScale = 0.6 * 0.75
            } else if (imgScalePercentage >= 75 && imgScalePercentage < 80) {
                newImgScalePercentage = 80
                newImgScale = 0.6 * 0.8
            } else if (imgScalePercentage >= 80 && imgScalePercentage < 90) {
                newImgScalePercentage = 90
                newImgScale = 0.6 * 0.9
            } else if (imgScalePercentage >= 90 && imgScalePercentage < 100) {
                newImgScalePercentage = 100
                newImgScale = 0.6
            } else if (imgScalePercentage >= 100 && imgScalePercentage < 110) {
                newImgScalePercentage = 110
                newImgScale = 0.6 * 1.1
            } else if (imgScalePercentage >= 110 && imgScalePercentage < 125) {
                newImgScalePercentage = 125
                newImgScale = 0.6 * 1.25
            } else if (imgScalePercentage >= 125 && imgScalePercentage < 150) {
                newImgScalePercentage = 150
                newImgScale = 0.6 * 1.5
            } else if (imgScalePercentage >= 150 && imgScalePercentage < 175) {
                newImgScalePercentage = 175
                newImgScale = 0.6 * 1.75
            } else if (imgScalePercentage >= 175 && imgScalePercentage < 200) {
                newImgScalePercentage = 200
                newImgScale = 0.6 * 2
            }

            if (newImgScale > 1.0) {
                newImgScale = 1.0
                newImgScalePercentage = 1.0 / 0.6 * 100
            }
            this.setState({
                imgScale: newImgScale,
                imgScalePercentage: parseInt(newImgScalePercentage)
            })
        }
    }
    zoomOut = (type) => {
        if (type === 'pdf') {
            const { pdfScalePercentage } = this.state
            var newPdfScalePercentage, newPdfScale
            if (pdfScalePercentage <= 33 && pdfScalePercentage > 25) {
                newPdfScalePercentage = 25
                newPdfScale = 1.5 * 0.25
            } else if (pdfScalePercentage <= 50 && pdfScalePercentage > 33) {
                newPdfScalePercentage = 33
                newPdfScale = 1.5 * 0.33
            } else if (pdfScalePercentage <= 67 && pdfScalePercentage > 50) {
                newPdfScalePercentage = 50
                newPdfScale = 1.5 * 0.5
            } else if (pdfScalePercentage <= 75 && pdfScalePercentage > 67) {
                newPdfScalePercentage = 67
                newPdfScale = 1.5 * 0.67
            } else if (pdfScalePercentage <= 80 && pdfScalePercentage > 75) {
                newPdfScalePercentage = 75
                newPdfScale = 1.5 * 0.75
            } else if (pdfScalePercentage <= 90 && pdfScalePercentage > 80) {
                newPdfScalePercentage = 80
                newPdfScale = 1.5 * 0.8
            } else if (pdfScalePercentage <= 100 && pdfScalePercentage > 90) {
                newPdfScalePercentage = 90
                newPdfScale = 1.5 * 0.9
            } else if (pdfScalePercentage <= 110 && pdfScalePercentage > 100) {
                newPdfScalePercentage = 100
                newPdfScale = 1.5
            } else if (pdfScalePercentage <= 125 && pdfScalePercentage > 110) {
                newPdfScalePercentage = 110
                newPdfScale = 1.5 * 1.1
            } else if (pdfScalePercentage <= 150 && pdfScalePercentage > 125) {
                newPdfScalePercentage = 125
                newPdfScale = 1.5 * 1.25
            } else if (pdfScalePercentage <= 175 && pdfScalePercentage > 150) {
                newPdfScalePercentage = 150
                newPdfScale = 1.5 * 1.5
            } else if (pdfScalePercentage <= 200 && pdfScalePercentage > 175) {
                newPdfScalePercentage = 175
                newPdfScale = 1.5 * 1.75
            } else {
                newPdfScalePercentage = pdfScalePercentage - 50
                newPdfScale = 1.5 * (pdfScalePercentage - 50) / 100
            }

            if (newPdfScalePercentage < 25) {
                newPdfScale = 1.5 * 0.25
                newPdfScalePercentage = 25
            }

            this.setState({
                pdfScale: newPdfScale,
                pdfScalePercentage: newPdfScalePercentage
            })
        } else {
            const { imgScale, imgScalePercentage } = this.state
            var newImgScalePercentage, newImgScale
            if (imgScalePercentage <= 33 && imgScalePercentage > 25) {
                newImgScalePercentage = 25
                newImgScale = 0.6 * 0.25
            } else if (imgScalePercentage <= 50 && imgScalePercentage > 33) {
                newImgScalePercentage = 33
                newImgScale = 0.6 * 0.33
            } else if (imgScalePercentage <= 67 && imgScalePercentage > 50) {
                newImgScalePercentage = 50
                newImgScale = 0.6 * 0.5
            } else if (imgScalePercentage <= 75 && imgScalePercentage > 67) {
                newImgScalePercentage = 67
                newImgScale = 0.6 * 0.67
            } else if (imgScalePercentage <= 80 && imgScalePercentage > 75) {
                newImgScalePercentage = 75
                newImgScale = 0.6 * 0.75
            } else if (imgScalePercentage <= 90 && imgScalePercentage > 80) {
                newImgScalePercentage = 80
                newImgScale = 0.6 * 0.8
            } else if (imgScalePercentage <= 100 && imgScalePercentage > 90) {
                newImgScalePercentage = 90
                newImgScale = 0.6 * 0.9
            } else if (imgScalePercentage <= 110 && imgScalePercentage > 100) {
                newImgScalePercentage = 100
                newImgScale = 0.6
            } else if (imgScalePercentage <= 125 && imgScalePercentage > 110) {
                newImgScalePercentage = 110
                newImgScale = 0.6 * 1.1
            } else if (imgScalePercentage <= 150 && imgScalePercentage > 125) {
                newImgScalePercentage = 125
                newImgScale = 0.6 * 1.25
            } else if (imgScalePercentage <= 175 && imgScalePercentage > 150) {
                newImgScalePercentage = 150
                newImgScale = 0.6 * 1.5
            } else if (imgScalePercentage <= 200 && imgScalePercentage > 175) {
                newImgScalePercentage = 175
                newImgScale = 0.6 * 1.75
            }

            if (newImgScalePercentage < 25) {
                newImgScale = 0.6 * 0.25
                newImgScalePercentage = 25
            }

            this.setState({
                imgScale: newImgScale,
                imgScalePercentage: newImgScalePercentage
            })
        }
    }

    fitToWidth = (type) => {
        if (type === 'pdf') {
            const { maxPdfScale } = this.state
            this.setState({
                pdfScale: maxPdfScale,
                pdfScalePercentage: parseInt(maxPdfScale / 1.5 * 100),
                didFitToWidth: true
            })
        } else {
            this.setState({
                imgScale: 1.0,
                imgScalePercentage: parseInt(1.0 / 0.6 * 100),
                didFitImgToWidth: true,
            })
        }
    }

    fitToHeight = (type) => {
        if (type === 'pdf') {
            const { fitToHeightPdfScale } = this.state

            this.setState({
                pdfScale: fitToHeightPdfScale,
                pdfScalePercentage: parseInt(fitToHeightPdfScale / 1.5 * 100),
                didFitToWidth: false
            })
        } else {
            const img = new Image();

            img.src = this.props.pdfPreviewDataBody

            img.onload = () => {
                const imgHeight = img.naturalHeight;
                const imgWidth = img.naturalWidth;

                var maxHeigth = window.innerHeight - 68
                var maxWidth = window.innerWidth - 300
                var pageScale = maxHeigth / maxWidth
                var imageScale = imgHeight / imgWidth
                var fitImgToHeightScale = (imgWidth * maxHeigth / imgHeight) / maxWidth
                console.log(pageScale)
                console.log(imageScale)
                console.log(fitImgToHeightScale)

                this.setState({
                    imgScale: fitImgToHeightScale,
                    imgScalePercentage: parseInt(fitImgToHeightScale / 0.6 * 100),
                    didFitImgToWidth: false
                })
            };
        }
    }

    rotateDoc = (type) => {
        if (type === 'pdf') {
            const { pdfRoateAngle } = this.state
            if (pdfRoateAngle === 0) {
                this.setState({
                    pdfRoateAngle: 90
                })
            } else if (pdfRoateAngle === 90) {
                this.setState({
                    pdfRoateAngle: 180
                })
            } else if (pdfRoateAngle === 180) {
                this.setState({
                    pdfRoateAngle: 270
                })
            } else {
                this.setState({
                    pdfRoateAngle: 0
                })
            }
        } else {
            const { imgRoateAngle } = this.state
            if (imgRoateAngle === 0) {
                this.setState({
                    imgRoateAngle: 90
                })
            } else if (imgRoateAngle === 90) {
                this.setState({
                    imgRoateAngle: 180
                })
            } else if (imgRoateAngle === 180) {
                this.setState({
                    imgRoateAngle: 270
                })
            } else {
                this.setState({
                    imgRoateAngle: 0
                })
            }
        }
    }

    handleMouseDown = mouseDownEvent => {
        console.log('mouseDownEvent')
        console.log(mouseDownEvent.clientX)
        this.setState({
            startedMouseSwipe: true,
            mouseSwipeStartPossition: mouseDownEvent.clientX
        })
    }

    handleMouseMove = mouseMoveEvent => {
        if (this.state.startedMouseSwipe) {
            var currentPossition = mouseMoveEvent.clientX

            if (this.state.mouseSwipeStartPossition - currentPossition > 0 && !this.state.showNextArrow) {
                this.setState({
                    showNextArrow: true,
                    showPrevArrow: false
                })
            } else if (this.state.mouseSwipeStartPossition - currentPossition < 0 && !this.state.showPrevArrow) {
                this.setState({
                    showPrevArrow: true,
                    showNextArrow: false
                })
            }
        } else if (this.state.showPrevArrow || this.state.showNextArrow) {
            this.setState({
                showPrevArrow: false,
                showNextArrow: false
            })
        }
    }

    handleMouseUp = mouseUpEvent => {
        console.log('mouseUpEvent')
        console.log(mouseUpEvent.clientX)

        const { transferPreviewData } = this.props

        if (this.state.startedMouseSwipe && this.props.transferPreviewData) {
            var mouseSwipeEndPossition = mouseUpEvent.clientX
            var mouseMoveDifference = mouseSwipeEndPossition - this.state.mouseSwipeStartPossition

            if (mouseMoveDifference < -300) {
                this.getTransfer(transferPreviewData.id, transferPreviewData.project_id, 'next')
            } else if (mouseMoveDifference > 300) {
                this.getTransfer(transferPreviewData.id, transferPreviewData.project_id, 'prev')
            }
        }

        this.setState({
            showPrevArrow: false,
            showNextArrow: false,
            startedMouseSwipe: false
        })
    }

    returnPayemntType = type => {
        if (type === 'NORMAL') return 'Regular payment'
        if (type === 'TAX') return 'Tax payment'
        if (type === 'ZUS') return 'ZUS payment'
        return ''
    }

    setSellerCountryCode = (value) => {
        if (this.state.currency !== 'PLN') {
            const selectTwoFirstLetters = /^[A-Z]{2}/
            const oldAccountNumberWithoutLetters = this.state.accountNumber.replace(selectTwoFirstLetters, '')
            this.setState({ accountNumber: `${value}${oldAccountNumberWithoutLetters}` })
        }
        this.setState({
            showCountriesList: false,
            countryCode: value
        })
    }

    toggleCountriesList = show => {
        this.setState({
            showCountriesList: show !== undefined ? show : !this.state.showCountriesList
        })
    }

    WL_setDevExValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    onPdfPassword = (callback, reason) => {
        switch (reason) {
            case 1: {
                const password = prompt(this.context.t('Enter the password to open this PDF file.'));
                this.callbackProxy(callback, password);
                break;
            }
            case 2: {
                const password = prompt(this.context.t('Invalid password. Please try again.'));
                this.callbackProxy(callback, password);
                break;
            }
            default:
        }
    }
    callbackProxy = (callback, password) => {
        // Cancel button handler
        if (password === null) {
            // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
            this.removeFile()
        }

        callback(password);
    }

    render() {
        const {
            showDocData,
            priority, paid, paymentConfirmation,
            title, recipient, accountNumber, price, currency, dueDate,
            comment, tags, tagsList,
            paymentType, year, periodType, periodNumber, periodName, symbol,
            file, pageNumber, numPages,
            pdfScale, pdfScalePercentage, didFitToWidth, pdfRoateAngle, imgScale, imgScalePercentage, didFitImgToWidth, imgRoateAngle,
            periodPopupY, periodPopupX, posYDatePicker, posXDatePicker, posUpDatePicker,
            swift, address, countryCode,
            showCountriesList, counterpartiesList, showCounterpartiesList, showTagsList, showCurrencyList, showPeriosPopup, showTaxSymbolsList, showPaymentTypesList, showCalendar,
            wlProjectCode, wlTechnology, wlDevExType1, wlDevExType2, wlTransactionDescriptionEn, wlTransactionDescriptionPl
        } = this.state
        const { currentProject, transferOrderProject, user, tabIsActive, newEasyDocsVersion } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className={`create-payment-order-container ${newEasyDocsVersion ? 'new-easydocs-version' : ''}`} ref="previevContainerRef" onClick={e => this.closeAllPopups(e)}>
                    <div className="toolbar">
                        <div className="start">
                            <div className="toggle-doc-data" data-tip='show' data-for='toggle-doc-data-tooltip' onClick={() => this.toggleDocData()}></div>

                            <ReactTooltip id='toggle-doc-data-tooltip' className='default-tooltip info-tooltip' effect='solid' place='right' delayShow={1000}>
                                <p>
                                    {
                                        showDocData ?
                                            this.context.t('Hide payment order informations')
                                            :
                                            this.context.t('Show payment order informations')
                                    }
                                </p>
                            </ReactTooltip>

                            <div className="file-name">
                                {
                                    transferOrderProject || currentProject ? (
                                        this.context.t('New payment order in the {n} binder', { n: transferOrderProject?.code || currentProject?.code })
                                    ) : (
                                        this.context.t('New payment order')
                                    )
                                }
                            </div>
                        </div>
                        <div className="center">
                            <div className="pdf-controls">
                                {
                                    file ? (
                                        file.type === 'application/pdf' ? (
                                            <>
                                                <div className="paginator">
                                                    <div className={`prev-page ${pageNumber === 1 ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumber !== 1) this.setState(prevState => ({ pageNumber: prevState.pageNumber - 1 }))
                                                    }}></div>
                                                    <div className="numeration"><span className="current-page">{pageNumber}</span> / <span className="pages-count">{numPages}</span></div>
                                                    <div className={`next-page ${pageNumber === numPages ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumber !== numPages) this.setState(prevState => ({ pageNumber: prevState.pageNumber + 1 }))
                                                    }}></div>
                                                </div>
                                                <div className="zoom">
                                                    <span className={`zoom-out ${pdfScale === 1.5 * 0.25 ? 'hide' : ''}`} onClick={() => this.zoomOut('pdf')}></span>
                                                    <span className="current-scale">{`${pdfScalePercentage}%`}</span>
                                                    <span className={`zoom-in ${pdfScale === this.state.maxPdfScale ? 'hide' : ''}`} onClick={() => this.zoomIn('pdf')}></span>
                                                </div>
                                                <div className="fit-dimensions">
                                                    {
                                                        !didFitToWidth ? (
                                                            <span className="fit-to-width" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToWidth('pdf')}></span>
                                                        ) : (
                                                            <span className="fit-to-height" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToHeight('pdf')}></span>
                                                        )
                                                    }

                                                    <ReactTooltip id='fit-file-to-dimensions' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {
                                                                didFitToWidth ?
                                                                    this.context.t('Fit the document size to the window height')
                                                                    :
                                                                    this.context.t('Fit the document size to the window width')
                                                            }
                                                        </p>
                                                    </ReactTooltip>

                                                    <span className="rotate-doc" data-tip='show' data-for='rotate-doc' onClick={() => this.rotateDoc('pdf')}></span>
                                                    <ReactTooltip id='rotate-doc' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {this.context.t('Rotate the document 90 degrees clockwise')}
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="zoom">
                                                    <span className={`zoom-out ${imgScale === 0.6 * 0.25 ? 'hide' : ''}`} onClick={() => this.zoomOut('img')}></span>
                                                    <span className="current-scale">{`${imgScalePercentage}%`}</span>
                                                    <span className={`zoom-in ${imgScale === 1.0 ? 'hide' : ''}`} onClick={() => this.zoomIn('img')}></span>
                                                </div>
                                                <div className="fit-dimensions">
                                                    {
                                                        !didFitImgToWidth ? (
                                                            <span className="fit-to-width" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToWidth('img')}></span>
                                                        ) : (
                                                            <span className="fit-to-height" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.fitToHeight('img')}></span>
                                                        )
                                                    }

                                                    <ReactTooltip id='fit-file-to-dimensions' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {
                                                                didFitToWidth ?
                                                                    this.context.t('Fit the document size to the window height')
                                                                    :
                                                                    this.context.t('Fit the document size to the window width')
                                                            }
                                                        </p>
                                                    </ReactTooltip>

                                                    <span className="rotate-doc" data-tip='show' data-for='rotate-doc' onClick={() => this.rotateDoc('img')}></span>
                                                    <ReactTooltip id='rotate-doc' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                        <p>
                                                            {this.context.t('Rotate the document 90 degrees clockwise')}
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            </>
                                        )
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                        <div className="end">
                            <div className="doc-controls">
                                <div className={`close`} onClick={() => this.props.hideAddTransferWindow()}></div>
                            </div>
                        </div>
                    </div>

                    <div className={`payment-order-data ${showDocData ? '' : 'doc-data-hidden'} ${!file || newEasyDocsVersion ? 'no-file' : ''}`} onClick={e => this.closeAllPopups(e)}>
                        {
                            user.new_easydocs_version ? (
                                <h3>
                                    {this.context.t('New payment order in the {n} binder', { n: transferOrderProject?.code || currentProject?.code })}
                                </h3>
                            ) : null
                        }
                        <div className={`body editing`}>
                            <SimpleBar style={{ height: '100%', width: '100%', paddingRight: '12px' }}>
                                <div className={`form-group payment-type-container ${!showTagsList && !showCurrencyList ? 'in-front' : ''}`} onClick={() => this.showPaymentTypesList()}>
                                    <TextareaAutosize readOnly={true} type="text" name="paymentType" id="paymentType" value={this.context.t(this.returnPayemntType(paymentType))} placeholder={this.context.t('Not selected')} autoComplete="off" />
                                    <label htmlFor="paymentType">{this.context.t('Payment type')}</label>

                                    <span className={`arrow ${showPaymentTypesList ? 'expanded' : ''}`} onClick={() => this.showPaymentTypesList()}></span>
                                    <ul className={`payment-types-list ${showPaymentTypesList ? 'expanded' : 'hide'}`}>
                                        <li className={`${paymentType === 'NORMAL' ? 'selected' : ''}`} onClick={() => this.setPaymentType('NORMAL')}>{this.context.t('Regular payment')}</li>
                                        <li className={`${paymentType === 'TAX' ? 'selected' : ''}`} onClick={() => this.setPaymentType('TAX')}>{this.context.t('Tax payment')}</li>
                                        <li className={`${paymentType === 'ZUS' ? 'selected' : ''}`} onClick={() => this.setPaymentType('ZUS')}>{this.context.t('ZUS payment')}</li>
                                    </ul>

                                </div>

                                {
                                    paymentType === 'TAX' ? (
                                        <div className="tax-container">
                                            <div className="form-group">
                                                <TextareaAutosize type="text" name="year" id="year" value={year} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="year">{this.context.t('Year')}</label>
                                            </div>

                                            <div className="form-group tax-period-container" onClick={(e) => { this.showPeriosPopup(e) }}>
                                                <TextareaAutosize type="text" name="period" id="period" value={this.context.t(periodName)} readOnly={true} placeholder="" autoComplete="off" />
                                                <label htmlFor="period">{this.context.t('Period')}</label>
                                            </div>

                                            <div className="form-group tax-symbol-container" onClick={e => this.showTaxSymbolsList()}>
                                                <TextareaAutosize type="text" name="symbol" id="symbol" value={symbol} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="symbol">{this.context.t('Tax Symbol')}</label>

                                                {
                                                    showTaxSymbolsList ? (
                                                        <div className="tax-symbols-list">
                                                            <SimpleBar style={{ height: "100%", maxHeight: '200px', width: "100%" }}>
                                                                <ul>
                                                                    {
                                                                        paymentSymbols.map(e => {
                                                                            if (e.startsWith(symbol.toUpperCase())) {
                                                                                return (
                                                                                    <li onClick={() => this.setTaxSymbol(e)}>
                                                                                        {e}
                                                                                    </li>
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        })
                                                                    }
                                                                </ul>
                                                            </SimpleBar>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                <div className="form-group title">
                                    <TextareaAutosize type="text" className={`${title.length ? 'has-content' : ''}`} name="title" id="title" value={title} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                    <label htmlFor="title">{this.context.t('Transfer title')}</label>

                                </div>

                                <div className={`form-group currency ${showCurrencyList ? 'list-expended in-front' : ''}`} ref={this.currencyInputRef}>
                                    <TextareaAutosize type="text" className={`${currency ? 'has-content' : ''}`} name="currency" id="currency" value={currency} onChange={this.onInputChange} placeholder="" autoComplete="off" readOnly={true} onClick={() => this.showCurrencyList()} />
                                    <label htmlFor="currency" onClick={() => this.showCurrencyList()}>{this.context.t('Currency')}</label>
                                    <span className={`arrow ${showCurrencyList ? 'expanded' : ''}`} onClick={() => this.showCurrencyList()}></span>
                                    <ul className={`currency-list ${showCurrencyList ? 'expanded' : 'hide'}`}>
                                        {
                                            currencyList.map(c => {
                                                return (
                                                    <li className={`${currency === c ? 'selected' : ''}`} onClick={() => this.selectCurrency(c)}>{c}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                <div className={`form-group ${showCounterpartiesList ? 'in-front' : ''}`} ref={this.recipientInputRef}>
                                    <TextareaAutosize type="text" className={`${recipient.length ? 'has-content' : ''}`} name="recipient" id="recipient" value={recipient} onChange={this.onInputChange} onClick={() => { this.setState(prevState => ({ showCounterpartiesList: !prevState.showCounterpartiesList, counterpartyId: 0 })) }} placeholder="" autoComplete="off" />
                                    <label htmlFor="recipient">{this.context.t('Recipient')}</label>
                                    <span className="search-icon" />
                                    {
                                        counterpartiesList.length > 0 && this.foundAtLeastOneSimilarCounterparty(recipient) && showCounterpartiesList ? (
                                            <div className="counterparties-list">
                                                <SimpleBar style={{ height: "auto", maxHeight: "200px", width: "100%" }}>
                                                    <ul>
                                                        {
                                                            counterpartiesList.map((c) => {
                                                                if (this.checkIfCounterpartyNameIsSimilar(recipient, c.name)) {
                                                                    return (
                                                                        <li
                                                                            tabIndex={"0"}
                                                                            className={`item counterparty-item`}
                                                                            onClick={() => {

                                                                                this.setState({
                                                                                    showCounterpartiesList: false,
                                                                                    recipient: c.name ? c.name : "",
                                                                                    accountNumber: c.last_account_number ? c.last_account_number : "",
                                                                                    counterpartyId: c.id

                                                                                });
                                                                            }}
                                                                        >
                                                                            {c.name}
                                                                        </li>
                                                                    );
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </SimpleBar>
                                            </div>
                                        ) : showCounterpartiesList && !recipient ? (
                                            <div className="counterparties-list">
                                                <SimpleBar style={{ height: "auto", maxHeight: "200px", width: "100%" }}>
                                                    <ul>
                                                        {
                                                            counterpartiesList.map((c) => {
                                                                return (
                                                                    <li
                                                                        tabIndex={"0"}
                                                                        className={`item counterparty-item`}
                                                                        onClick={() => {

                                                                            this.setState({
                                                                                showCounterpartiesList: false,
                                                                                recipient: c.name ? c.name : "",
                                                                                accountNumber: c.last_account_number ? c.last_account_number : "",
                                                                                counterpartyId: c.id
                                                                            });
                                                                        }}
                                                                    >
                                                                        {c.name}
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </SimpleBar>
                                            </div>
                                        ) : null
                                    }
                                </div>

                                {
                                    currency !== 'PLN' && paymentType === 'NORMAL' ? (
                                        <div className="currency-transfer-fields">
                                            <div className="form-group address">
                                                <TextareaAutosize type="text" className={`${address.length ? 'has-content' : ''}`} name="address" id="address" value={address} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="accountNumber">{this.context.t('Recipient\'s address')}</label>
                                            </div>

                                            <div className="form-group country countries-list-container">
                                                <TextareaAutosize type="text" className={`${countryCode.length ? 'has-content' : ''}`} name="countryCode" id="countryCode" value={countryCode} placeholder="" autoComplete="off" readOnly={true} onClick={e => this.toggleCountriesList(true)} />
                                                <label htmlFor="accountNumber">{this.context.t('Recipient\'s country')}</label>

                                                {
                                                    showCountriesList ? (
                                                        <CountriesList
                                                            headerText={this.context.t('Country:')}
                                                            selectedOption={countryCode}
                                                            select={this.setSellerCountryCode}
                                                        />
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    currency !== 'PLN' && paymentType === 'NORMAL' ? (
                                        <>
                                            <div className="form-group part-2">
                                                <TextareaAutosize type="text" className={`${accountNumber.length ? 'has-content' : ''}`} name="accountNumber" id="accountNumber" value={accountNumber} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="accountNumber">{this.context.t('Recipient\'s bank account number')}</label>
                                                {
                                                    !checkIfAccountNumberIsValid(accountNumber) ? <div className="text">
                                                        {this.context.t('The account number provided does not comply with the IBAN standard.')}
                                                    </div> : null
                                                }
                                            </div>
                                            <div className="form-group part-2">
                                                <TextareaAutosize type="text" className={`${swift.length ? 'has-content' : ''}`} name="swift" id="swift" value={swift} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                                <label htmlFor="accountNumber">{this.context.t('BIC/SWIFT')}</label>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="form-group">
                                            <TextareaAutosize type="text" className={`${accountNumber.length ? 'has-content' : ''}`} name="accountNumber" id="accountNumber" value={this.polihAccountPritier(accountNumber)} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                            <label htmlFor="accountNumber">{this.context.t('Recipient\'s bank account number')}</label>
                                            {
                                                !checkIfAccountNumberIsValid(accountNumber) ? <div className="text">
                                                    {this.context.t('The account number provided does not comply with the IBAN standard.')}
                                                </div> : null
                                            }
                                        </div>
                                    )
                                }

                                <div className="part-2 form-group">
                                    <TextareaAutosize type="number" className={`${price.length ? 'has-content' : ''}`} name="price" id="price" value={price} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                    <label htmlFor="price">{this.context.t('Amount to pay')}</label>
                                </div>

                                <div className="part-2 form-group due-date-container">
                                    <TextareaAutosize type="text" className={`${dueDate.length ? 'has-content' : ''}`} name="dueDate" id="dueDate" value={dueDate} placeholder="" autoComplete="off" onFocus={e => this.showDueDateCalendar(e)} />
                                    <label htmlFor="dueDate">{this.context.t('Due date')}</label>
                                    <span className="calendar-icon" onClick={e => this.showDueDateCalendar(e)}></span>
                                </div>

                                <div className="form-group">
                                    <TextareaAutosize type="text" className={`${comment.length ? 'has-content' : ''}`} name="comment" id="comment" value={comment} onChange={this.onInputChange} placeholder="" autoComplete="off" />
                                    <label htmlFor="comment">{this.context.t('Comment')}</label>
                                </div>

                                <TagsComponent
                                    currentTags={tags}
                                    tagsList={tagsList || []}
                                    canAddNewTags={true}
                                    setTags={this.returnTags}
                                    readOnly={false}
                                />

                                {
                                    devCostsProjects.includes(transferOrderProject.id) && devCostsUsers.includes(user.id) ? (
                                        <div className="dev-ex-container">
                                            <div className="header">{this.context.t('Required fields for DevCosts')}</div>
                                            <ProjectCode
                                                editMode={true}
                                                projectId={transferOrderProject?.id || currentProject?.id || -1}
                                                selectedOption={wlProjectCode || ''}
                                                select={value => this.WL_setDevExValue('wlProjectCode', value)}
                                            />
                                            <Technology
                                                editMode={true}
                                                selectedOption={wlTechnology || ''}
                                                select={value => this.WL_setDevExValue('wlTechnology', value)}
                                            />
                                            <DevExCode
                                                editMode={true}
                                                selectedOption={wlDevExType1 || ''}
                                                select={value => this.WL_setDevExValue('wlDevExType1', value)}
                                            />
                                            <DevExType
                                                editMode={true}
                                                selectedOption={wlDevExType2 || ''}
                                                devExCode={wlDevExType1}
                                                select={value => this.WL_setDevExValue('wlDevExType2', value)}
                                            />
                                            <TransactionDescription
                                                editMode={true}
                                                lang={'PL'}
                                                selectedOption={wlTransactionDescriptionPl || ''}
                                                select={value => this.WL_setDevExValue('wlTransactionDescriptionPl', value)}
                                                onChange={value => this.WL_setDevExValue('wlTransactionDescriptionPl', value)}
                                            />
                                            <TransactionDescription
                                                editMode={true}
                                                lang={'EN'}
                                                selectedOption={wlTransactionDescriptionEn || ''}
                                                select={value => this.WL_setDevExValue('wlTransactionDescriptionEn', value)}
                                                onChange={value => this.WL_setDevExValue('wlTransactionDescriptionEn', value)}
                                            />
                                        </div>
                                    ) : null
                                }
                            </SimpleBar>

                            <div className={`period-popup ${showPeriosPopup ? 'show' : ''}`} style={{ top: periodPopupY, left: periodPopupX }}>
                                <div className="period-type">
                                    <div className={`option ${periodType === 'month' ? 'selected' : ''}`} onClick={() => this.setState({ periodType: 'month', periodNumber: '', periodName: '' })}>{this.context.t('Month')}</div>
                                    <div className={`option ${periodType === 'quarter' ? 'selected' : ''}`} onClick={() => this.setState({ periodType: 'quarter', periodNumber: '', periodName: '' })}>{this.context.t('Quarter')}</div>
                                    <div className={`option ${periodType === 'year' ? 'selected' : ''}`} onClick={() => this.setState({ periodType: 'year', periodNumber: '', periodName: 'Year', showPeriosPopup: false })}>{this.context.t('Year')}</div>
                                </div>

                                {
                                    periodType && periodType !== 'year' ? (
                                        <div className="period-number">
                                            {
                                                periodType === 'month' ? (
                                                    months.map((m, i) => {
                                                        return (
                                                            <div className={`option ${periodNumber === this.makeDoubleDigits(i + 1) ? 'selected' : ''}`} onClick={() => this.setState({ periodNumber: this.makeDoubleDigits(i + 1), periodName: m, showPeriosPopup: false })}>
                                                                {this.context.t(m)}
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    periodType === 'quarter' ? (
                                                        <>
                                                            <div className={`option ${periodNumber === '01' ? 'selected' : ''}`} onClick={() => this.setState({ periodNumber: '01', showPeriosPopup: false, periodName: 'January - March' })}>
                                                                {this.context.t('January - March')}
                                                            </div>
                                                            <div className={`option ${periodNumber === '02' ? 'selected' : ''}`} onClick={() => this.setState({ periodNumber: '02', showPeriosPopup: false, periodName: 'April - June' })}>
                                                                {this.context.t('April - June')}
                                                            </div>
                                                            <div className={`option ${periodNumber === '03' ? 'selected' : ''}`} onClick={() => this.setState({ periodNumber: '03', showPeriosPopup: false, periodName: 'July - September' })}>
                                                                {this.context.t('July - September')}
                                                            </div>
                                                            <div className={`option ${periodNumber === '04' ? 'selected' : ''}`} onClick={() => this.setState({ periodNumber: '04', showPeriosPopup: false, periodName: 'October - December' })}>
                                                                {this.context.t('October - December')}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        null
                                                    )
                                                )
                                            }
                                        </div>
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div id="due-date-container" className={`due-date-container create-transfer-calendar ${showCalendar ? 'show' : ''} ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker, left: posXDatePicker }}>
                                <Calendar
                                    selectDay={this.onCalendarSelect}
                                    unselectDay={this.onCalendarUnselecet}
                                    selectedDay={
                                        moment(dueDate, 'YYYY-MM-DD').isValid() ? {
                                            display: moment(dueDate, 'YYYY-MM-DD').format('LL'),
                                            value: moment(dueDate, 'YYYY-MM-DD')
                                        } : {
                                            display: null,
                                            value: null
                                        }
                                    }
                                    projectId={'none'}
                                    startDate={moment(dueDate, 'YYYY-MM-DD').isValid() ? moment(dueDate, 'YYYY-MM-DD') : moment()}
                                />
                            </div>
                        </div>
                        <div className="footer editing">
                            <div className="payment-completed">
                                <span className={`payment-completed__checkbox ${paid ? 'checked' : ''}`} onClick={this.changeStatusPayment}></span>
                                <p onClick={this.changeStatusPayment} style={{ width: 'fit-content' }}>
                                    {this.context.t("Payment completed")}
                                </p>
                            </div>
                            <div className="payment-priority">
                                <span className={`payment-priority__checkbox ${priority ? 'checked' : ''}`} onClick={this.handleChangePriority}></span>
                                <p onClick={this.handleChangePriority} style={{ width: 'fit-content' }}>
                                    {this.context.t("Set document priority as high")}
                                </p>
                            </div>
                            <div className="payment-confirmation">
                                <span className={`payment-confirmation__checkbox ${paymentConfirmation.includes(user.email) ? 'checked' : ''}`} onClick={this.handlePaymentConfirmationChange}></span>
                                <p onClick={this.handleChangePriority} style={{ width: 'fit-content' }}>
                                    {this.context.t("Please send me a confirmation of payment")}
                                </p>
                            </div>
                            <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(files, rejectedFiles)} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div {...getRootProps({ onClick: event => this.handleDropZoneClick(event) })} className={`dropzone${isDragActive ? ' drop-active' : ''}`}>
                                        {
                                            !file ? (
                                                <div className="no-file">{this.context.t('Attachment')}</div>
                                            ) : (
                                                <div className="file">
                                                    <div className="name">{file.name}</div>
                                                    <div className="delete" onClick={() => this.removeFile()}></div>
                                                </div>
                                            )
                                        }
                                        <input {...getInputProps()} />
                                        <span className="clip-button" data-tip data-for='addFileTooltip'></span>
                                    </div>
                                )}
                            </Dropzone>
                            <div className="action-buttons">
                                <span className="btn cancel-btn" onClick={e => this.closePaymentOrderGenerator()}>{this.context.t('Cancel')}</span>
                                <span className="btn save-btn" onClick={e => this.checkIsExistsRecipient(e)}>{this.context.t('Add')}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`file-container linked-docs-hidden ${showDocData ? '' : 'doc-data-hidden'} ${!file ? 'no-file' : ''}`}
                    // onTouchStart={touchStartEvent => this.handleMouseUp(touchStartEvent)}
                    // onTouchMove={touchMoveEvent => this.handleMouseMove(touchMoveEvent)}
                    // onTouchEnd={touchEndEvent => this.handleMouseUp(touchEndEvent)}
                    // onMouseDown={mouseDownEvent => this.handleMouseDown(mouseDownEvent)}
                    // onMouseMove={mouseMoveEvent => this.handleMouseMove(mouseMoveEvent)}
                    // onMouseUp={mouseUpEvent => this.handleMouseUp(mouseUpEvent)}
                    >
                        {
                            file && !newEasyDocsVersion ? (
                                file.base64 && file.base64 !== 'loading' ? (
                                    file.type === 'application/pdf' ? (
                                        <SimpleBar style={{ width: '100%', maxWidth: showDocData ? 'calc(100vw - 300px)' : '100vw', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                            <div className="pdf-wrapper" ref="pdfWrapper">
                                                <Document
                                                    file={file.base64}
                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                    onLoadError={console.error}
                                                    options={{ withCredentials: true }}
                                                    onPassword={this.onPdfPassword}
                                                >
                                                    <Page pageNumber={pageNumber} scale={pdfScale} rotate={pdfRoateAngle} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} />
                                                </Document>
                                            </div>
                                        </SimpleBar>
                                    ) : (
                                        <SimpleBar style={{ width: '100%', maxWidth: showDocData ? 'calc(100vw - 300px)' : '100vw', height: 'calc(100vh - 68px)', transition: '.2s ease-in-out' }}>
                                            <div className="image" ref="imageContinerRef">
                                                <img src={file.base64} style={{ width: `${100 * imgScale}%`, transform: `rotate(${imgRoateAngle}deg)` }} ref="imageRef" />
                                            </div>
                                        </SimpleBar>
                                    )
                                ) : (
                                    <div className="pdf-wrapper" ref="pdfWrapper" >
                                        <div className="activity-indicator">
                                            <Spinner size={30} speed={0.8} color={"#444444"} />
                                        </div>
                                    </div>
                                )
                            ) : (
                                null
                            )
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

CreateTransferOrderWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    transferOrderProject: state.Transfers.transferOrderProject,
    transferDataToDuplicate: state.Transfers.transferDataToDuplicate,
    userProjects: state.User.userProjects,
    currentProject: state.User.currentProject,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
    currentTabIndex: state.V2_TabsCronroller.currentTabIndex,
})

const mapDispatchToProps = {
    hideAddTransferWindow: TransfersActions.hideAddTransferWindow,
    createTransfer: TransfersActions.addTransfer,
    alertWarn: AlertActions.warning,
    closeTab: TabsControlerActions.closeTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransferOrderWindow)